import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { getSourceOptions } from '@helpers/marketing-source-data.helper';
import { PhoneNumberCountryCodes, PhoneNumberExamples } from '@helpers/phone-number.helper';
import { ProspectHelper } from '@helpers/prospect.helper';
import { SupportedCountryCode } from '@models/backend/common';
import { MarketingChannel } from '@models/backend/prospects';
import { ProspectViewModel } from '@models/prospect';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '@services/country.service';
import { emailOrPhoneValidator } from '@validators/email-phone-custom-validation';
import { EMAIL_REGEX, PHONE_REGEX } from '@validators/regex';
import moment, { Moment } from 'moment';
import { String } from './../../../helpers/string.helper';
import { ProspectFormChange } from './types';

export type ProspectForm = { isValid: boolean; data: ProspectViewModel };

@Component({
    selector: 'prospect-form',
    templateUrl: 'prospect-form.component.html',
    styleUrls: ['prospect-form.component.less'],
})
export class ProspectFormComponent implements OnInit {
    private translateService: TranslateService = inject(TranslateService);
    private countryService: CountryService = inject(CountryService);

    @Output() formChange = new EventEmitter<ProspectForm>();
    @Input() prospect: ProspectViewModel;

    countryCode: SupportedCountryCode = this.countryService.getCurrentCountry();
    statusOptions: string[] = ProspectHelper.getStatusOptions(this.countryCode);
    marketingChannelSources: IAutocompleteData<MarketingChannel>[] = getSourceOptions(this.countryCode);

    prospectForm: FormGroup;
    today: Moment = moment();

    get selectedMarketingSource(): IAutocompleteData<MarketingChannel> {
        const selected = this.marketingChannelSources.find((item) => item.id === this.prospect.marketingChannel);
        return selected;
    }

    get pinErrorMessage(): string {
        return this.prospect.personalIdentityNumber && this.prospect.personalIdentityNumber.length > 12
            ? 'SHARED_COMPONENT.FORMAT_NOT_VALID'
            : 'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN';
    }

    get isPinFieldVisible(): boolean {
        return this.countryService.isSweden();
    }

    get phoneFieldPlaceholder(): string {
        const forExempleText = this.translateService.instant('SHARED_COMPONENT.FOR_EXEMPLE');

        const phoneNumberExample = PhoneNumberExamples[this.countryCode];

        return `${forExempleText} ${phoneNumberExample}`;
    }

    get emailFieldError(): ValidationErrors | null {
        return this.prospectForm.get('email').errors;
    }

    get phoneFieldError(): ValidationErrors | null {
        return this.prospectForm.get('phone').errors;
    }

    private resolvePhoneNumber(): string {
        return this.prospect.phone?.replace(/[^+0-9]+/g, '') || PhoneNumberCountryCodes[this.countryCode];
    }

    phoneChangedEvent(): void {
        const phoneControl = this.prospectForm.get('phone');
        const sanitizedPhone = phoneControl.value.replace(/[^+0-9]+/g, '');

        phoneControl.setValue(sanitizedPhone);
    }

    ngOnInit(): void {
        this.initializeForm();
        this.subscribeToFormChanges();
    }

    private initializeForm(): void {
        const pinValidators = this.isPinFieldVisible
            ? [Validators.required, Validators.minLength(12), Validators.maxLength(12)]
            : undefined;

        this.prospectForm = new FormGroup(
            {
                name: new FormControl(this.prospect.name, Validators.required),
                email: new FormControl(this.prospect.email, [Validators.required, Validators.pattern(EMAIL_REGEX)]),
                phone: new FormControl(this.resolvePhoneNumber(), [
                    Validators.required,
                    Validators.pattern(PHONE_REGEX),
                ]),
                marketingChannel: new FormControl(this.selectedMarketingSource, Validators.required),
                status: new FormControl(this.prospect.status, Validators.required),
                preferredMoveInDate: new FormControl(this.prospect.preferredMoveInDate),
                personalIdentityNumber: new FormControl(this.prospect.personalIdentityNumber, pinValidators),
                message: new FormControl(this.prospect.message),
            },
            { validators: emailOrPhoneValidator },
        );
    }

    private subscribeToFormChanges(): void {
        this.prospectForm.valueChanges.subscribe((value) => {
            this.formChange.emit({ isValid: this.prospectForm.valid, data: this.prospectFormChanged(value) });
        });
    }

    private prospectFormChanged(data: ProspectFormChange): ProspectViewModel {
        const dateValue = String.isValidDateString(data.preferredMoveInDate)
            ? moment(data.preferredMoveInDate).format('YYYY-MM-DD')
            : null;

        this.prospect.name = data.name;
        this.prospect.email = data.email;
        this.prospect.phone = data.phone;
        this.prospect.status = data.status;
        this.prospect.message = data.message;
        this.prospect.preferredMoveInDate = dateValue;
        this.prospect.personalIdentityNumber = data.personalIdentityNumber;
        this.prospect.marketingChannel = data.marketingChannel?.id;

        return this.prospect;
    }
}
