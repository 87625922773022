<div class="advert-details-section" id="contact-details-section" [class.disabled]="isLoading">
    <section class="header">
        <span class="subtitle">{{ 'ADVERT_SECTIONS.contactDetails' | translate }}</span>
        <i class="material-icons validation-icon" [ngClass]="isValid ? 'valid' : 'invalid'">{{ validationIcon }}</i>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="navigateToAdvert()"
            *ngIf="isPreview"
            matTooltip="{{ 'SHARED_COMPONENT.EDIT' | translate }}"
        >
            <mat-icon fontSet="material-icons-outlined">create</mat-icon>
        </button>
        <div class="loader" *ngIf="isLoading">
            <loading-indicator [isPermanent]="true" [isEmbedded]="true" [isSmall]="true"></loading-indicator>
        </div>
    </section>

    <div class="form" *ngIf="!isPreview">
        <section class="properties">
            <div class="property bool">
                <mat-checkbox
                    [ngModel]="isSubstituteContactEnabled"
                    (ngModelChange)="toggleSubstituteContact($event)"
                    [attr.data-test]="getDomId('vacation-contact-enabled')"
                    >{{ 'KEY_FACTS.isVacationEnabled' | translate }}</mat-checkbox
                >
            </div>
            <div class="property" [class.hidden]="!isSubstituteContactEnabled">
                <autocomplete
                    [translateLabels]="false"
                    [formControl]="substituteContactForm"
                    [options]="substituteContactAutocompleteItems"
                    (optionSelected)="onOptionSelected($event)"
                    [attr.data-test]="getDomId('vacation-contact-selection')"
                >
                </autocomplete>
            </div>
        </section>

        <section class="properties" *ngIf="!isSubstituteContactEnabled">
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'KEY_FACTS.contactFirstName' | translate }}</mat-label>
                    <input
                        matInput
                        disabled
                        type="text"
                        tabindex="-1"
                        [attr.data-test]="getDomId('contact-first-name')"
                        [class.ng-invalid]="!(isSubstituteContactEnabled || advert.mainContact.firstName)"
                        [value]="advert.mainContact.firstName"
                    />
                </mat-form-field>
            </div>
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'KEY_FACTS.contactLastName' | translate }}</mat-label>
                    <input
                        matInput
                        disabled
                        type="text"
                        tabindex="-1"
                        [attr.data-test]="getDomId('contact-last-name')"
                        [class.ng-invalid]="!(isSubstituteContactEnabled || advert.mainContact.lastName)"
                        [value]="advert.mainContact.lastName"
                    />
                </mat-form-field>
            </div>
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'KEY_FACTS.email' | translate }}</mat-label>
                    <input
                        matInput
                        disabled
                        type="email"
                        tabindex="-1"
                        [attr.data-test]="getDomId('contact-email')"
                        [class.ng-invalid]="!(isSubstituteContactEnabled || advert.mainContact.email)"
                        [value]="advert.mainContact.email"
                    />
                </mat-form-field>
            </div>
        </section>

        <section class="properties" *ngIf="!isSubstituteContactEnabled && isPhoneNumberVisible">
            <div class="property single">
                <phone-number
                    [phoneNumber]="advert.mainContact.phoneNumber"
                    [isMandatory]="true"
                    [countryCode]="advert.countryCode"
                ></phone-number>
            </div>
        </section>

        <section class="properties" *ngIf="isSubstituteContactEnabled && advert.hasSubstituteContact">
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'KEY_FACTS.contactFirstName' | translate }}</mat-label>
                    <input
                        [attr.data-test]="getDomId('substitute-contact-first-name')"
                        matInput
                        disabled
                        type="text"
                        tabindex="-1"
                        [value]="advert.substituteContact?.firstName"
                    />
                </mat-form-field>
            </div>
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'KEY_FACTS.contactLastName' | translate }}</mat-label>
                    <input
                        [attr.data-test]="getDomId('substitute-contact-last-name')"
                        matInput
                        disabled
                        type="text"
                        tabindex="-1"
                        [value]="advert.substituteContact?.lastName"
                    />
                </mat-form-field>
            </div>
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'KEY_FACTS.email' | translate }}</mat-label>
                    <input
                        [attr.data-test]="getDomId('substitute-contact-email')"
                        matInput
                        disabled
                        type="email"
                        tabindex="-1"
                        [value]="advert.substituteContact?.email"
                    />
                </mat-form-field>
            </div>
        </section>

        <section class="properties" *ngIf="isSubstituteContactEnabled && isPhoneNumberVisible">
            <div class="property single">
                <phone-number
                    [phoneNumber]="advert.substituteContact?.phoneNumber"
                    [isMandatory]="true"
                    [countryCode]="advert.countryCode"
                ></phone-number>
            </div>
        </section>

        <section class="properties" *ngIf="hasServiceCenterOption">
            <div class="property bool">
                <mat-checkbox
                    class="service-center"
                    [ngModel]="advert.useServiceCenterPhoneNumber"
                    (ngModelChange)="useServiceCenterPhoneNumberChanged($event)"
                    [attr.data-test]="getDomId('use-service-center-phone-number')"
                    >{{ 'SHARED_COMPONENT.USE_SERVICE_CENTER_NUMBER' | translate }}</mat-checkbox
                >
            </div>
        </section>

        <section class="properties" *ngIf="advert.isGermanUnit">
            <div class="property bool">
                <mat-checkbox
                    class="show-number-on-portal"
                    [ngModel]="advert.isPhoneNumberShownOnPortal"
                    (ngModelChange)="isPhoneNumberShownOnPortalChanged($event)"
                    [attr.data-test]="getDomId('is-phone-number-shown')"
                    >{{ 'SHARED_COMPONENT.DISPLAY_PHONE_NUMBER' | translate }}</mat-checkbox
                >
            </div>
        </section>
    </div>

    <div class="form preview" *ngIf="isPreview">
        <section class="properties">
            <div class="property">
                <span class="preview-label">{{ 'KEY_FACTS.contactFirstName' | translate }}</span>
                <span class="value">{{ selectedContact.firstName }}</span>
            </div>
            <div class="property">
                <span class="preview-label">{{ 'KEY_FACTS.contactLastName' | translate }}</span>
                <span class="value">{{ selectedContact.lastName }}</span>
            </div>
            <div class="property" *ngIf="isPhoneNumberVisible">
                <span class="preview-label">{{ 'KEY_FACTS.phoneNumber' | translate }}</span>
                <span class="value"
                    >{{ selectedContact.phoneNumber.phoneCountryCode }}
                    {{ selectedContact.phoneNumber.phoneAreaCode }}
                    {{ selectedContact.phoneNumber.mainNumber }}</span
                >
            </div>
            <div class="property">
                <span class="preview-label">{{ 'KEY_FACTS.email' | translate }}</span>
                <span class="value">{{ selectedContact.email }}</span>
            </div>
        </section>
    </div>

    <warning-box [text]="warningMessage" [type]="'error'" *ngIf="!isPreview && !isValid"> </warning-box>
</div>
