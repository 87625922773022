import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output, inject } from '@angular/core';

@Directive({
    selector: '[infinityScrolling]',
})
export class InfinityScrollingDirective implements AfterViewInit {
    private element = inject(ElementRef);
    private ngZone = inject(NgZone);


    @Output() elementVisible = new EventEmitter<boolean>();
    @Input() isTargetElement: boolean;

    private intersectionOptions = {
        root: null, // implies the root is the document viewport
        rootMargin: '0px',
        threshold: [0.9, 1.0],
    };

    ngAfterViewInit(): void {
        const observer = new IntersectionObserver(this.intersectionEventStarted.bind(this), this.intersectionOptions);

        if (this.isTargetElement) {
            // should run outside angular change detection strategie to prevent tree checking
            this.ngZone.runOutsideAngular(() => {
                observer.observe(this.element.nativeElement);
            });
        }
    }

    intersectionEventStarted(entries): void {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (entry.boundingClientRect.top > entry.rootBounds.top) {
                    if (entry.intersectionRatio === 1) {
                        this.elementVisible.emit(true);
                    } else {
                        this.elementVisible.emit(false);
                    }
                }
            }
        });
    }
}
