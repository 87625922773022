import { Component, EventEmitter, Input, OnChanges, OnInit, Output, WritableSignal, computed, signal, inject } from '@angular/core';
import {
    CategoryItem,
    CategoryValueType,
    ICategory,
    ICategoryContext,
    Operation,
    OperationType,
} from '@components/dashboard/sections/adverts/types';
import { DashboardHelper } from '@helpers/dashboard.helper';
import { AdvertType } from '@models/backend/advert';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'category',
    templateUrl: 'category.component.html',
    styleUrls: ['category.component.less'],
})
export class CategoryComponent<T extends CategoryItem> implements OnInit, OnChanges {
    private translateService = inject(TranslateService);

    @Input({ required: true })
    category: ICategory<T>;

    @Output()
    publishedListChanged = new EventEmitter<[T, OperationType]>();

    @Output()
    bookmarkedListChanged = new EventEmitter<[T, OperationType]>();

    @Output()
    checkHasPublishedAdverts = new EventEmitter<void>();

    hasItems: boolean = false;

    titleSignal: WritableSignal<string> = signal('');
    categoryTitle = computed(() => {
        return this.titleSignal();
    });

    ngOnInit(): void {
        this.titleSignal.set(this.setTitle(this.category.title, this.category.itemsCount));
        this.checkAvailableItems();
    }

    ngOnChanges(): void {
        this.checkAvailableItems();
    }

    updateBookmarkedList(item: T): void {
        DashboardHelper.updateContext(this.category.contexts, item, Operation.Remove);
        this.category.itemsCount = this.getItemsFromContexts(this.category.contexts).length;
        this.checkAvailableItems();
    }

    updateTopPublishedAdverts(item: T): void {
        const { id } = this.category;
        if (id === 'bookmarked') {
            DashboardHelper.updateContext(this.category.contexts, item, Operation.Update);
            this.publishedListChanged.emit([item, Operation.Remove]);
        }
        if (id === 'topPublished') {
            DashboardHelper.updateContext(this.category.contexts, item, Operation.Remove);
            this.bookmarkedListChanged.emit([item, Operation.Update]);
        }

        this.category.itemsCount = this.getItemsFromContexts(this.category.contexts).length;
        this.checkAvailableItems();
    }

    updateReservedAdverts(item: T): void {
        const { id } = this.category;
        if (id === 'bookmarked') {
            this.publishedListChanged.emit([item, Operation.Update]);
        }
        if (id === 'topPublished') {
            this.bookmarkedListChanged.emit([item, Operation.Update]);
        }

        DashboardHelper.updateContext(this.category.contexts, item, Operation.Update);
    }

    checkAvailableItems(): void {
        this.hasItems = this.category.contexts.some((c) => c.items.some((a) => a));
        if (!this.hasItems) {
            this.checkHasPublishedAdverts.emit();
            return;
        }
        this.category.currentFilter = this.getCurrentFilter(this.category.contexts, this.category.currentFilter);
        this.updateSelected(this.category.currentFilter);
    }

    updateSelected(filter: CategoryValueType): void {
        this.titleSignal.set(this.setTitle(this.category.title, this.category.itemsCount));
        this.updateFilter(this.category, filter);
    }

    updateFilter(category: ICategory<T>, filter: CategoryValueType): void {
        category.contexts.find((c) => c.type === category.currentFilter).display = false;
        category.currentFilter = filter;
        category.contexts.find((c) => c.type === filter).display = true;
    }

    private getItemsFromContexts(contexts: ICategoryContext<T>[]): T[] {
        const items: T[] = [];
        for (const context of contexts) {
            items.push(...context.items);
        }

        return items;
    }

    private getCurrentFilter(contexts: ICategoryContext<T>[], currentFilter: CategoryValueType): CategoryValueType {
        const items = this.getItemsFromContexts(contexts);

        const itemsAreAvailable = this.getAvailableType(items, currentFilter);
        if (itemsAreAvailable) {
            return currentFilter;
        }

        const hasApartmentItems = this.getAvailableType(items, AdvertType.Living);
        if (hasApartmentItems) {
            return AdvertType.Living;
        }

        return items.find((a) => a).type;
    }

    private getAvailableType(items: T[], type: CategoryValueType): boolean {
        return !!items.find((a) => a.type === type);
    }

    private setTitle(titleName: string, itemsCount: number): string {
        return `${this.translateService.instant(titleName)} ${itemsCount}`;
    }
}
