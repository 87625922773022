import { String } from '@helpers/string.helper';
import { IContactDetails } from '@models/backend/contacts';
import { AdvertViewModel } from '../advert';
import { IPhoneNumber } from '../phone-number';

export function extractContacts(vm: AdvertViewModel): IContactDetails {
    let phoneNumber = '';
    const selectedContact = vm.hasSubstituteContact ? vm.substituteContact : vm.mainContact;

    if (isPhoneNumberValid(selectedContact.phoneNumber)) {
        phoneNumber = `${selectedContact.phoneNumber.phoneCountryCode} ${selectedContact.phoneNumber.phoneAreaCode} ${selectedContact.phoneNumber.mainNumber}`;
    }

    return {
        contactFirstName: selectedContact.firstName,
        contactLastName: selectedContact.lastName,
        email: selectedContact.email,
        phoneNumber: phoneNumber,
    };
}

function isPhoneNumberValid(phoneNumber: IPhoneNumber): boolean {
    return !String.isEmpty(phoneNumber.phoneAreaCode) && !String.isEmpty(phoneNumber.phoneCountryCode);
}
