import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { generateReservationEvent } from '@helpers/google-analytics.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { IAdvertPublicationResult } from '@models/backend/advert';
import { AnalyticsService } from '@services/analytics.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { EnvironmentService } from '@services/environment.service';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IPublishModalArgs, IReservationModalArgs, PublishComponent, ReservationComponent } from '../shared';

@Component({
    selector: 'advert-teaser',
    templateUrl: 'advert-teaser.component.html',
    styleUrls: ['advert-teaser.component.less'],
})
export class AdvertTeaserComponent extends UnSubscriptionDirective implements OnInit {
    private userSettingsService = inject(UserSettingsService);
    private dialog = inject(MatDialog);
    private browserWindowService = inject(BrowserWindowService);
    private gaService = inject(AnalyticsService);
    private environmentService = inject(EnvironmentService);

    @Input()
    advert: AdvertViewModel;
    @Output()
    advertDataChanged = new EventEmitter();

    @Output()
    unpublishAdvertEvent = new EventEmitter();

    isStandaloneDisplayMode: boolean = false;

    get isAdvertBookmarked(): boolean {
        return this.userSettingsService.isAdvertBookmarked(this.advert.id);
    }

    get bookmarkTooltipMessage(): string {
        return this.isAdvertBookmarked ? 'SHARED_COMPONENT.REMOVE_BOOKMARK' : 'SHARED_COMPONENT.BOOKMARK';
    }

    // TODO(LA-5482): remove getter and usage in html
    get isUsAdvertOnProdEnvironment(): boolean {
        return (
            this.advert.isUnitedStatesUnit &&
            this.advert.isUnsupportedUsRegionForProspectsAndViewingsManagement &&
            this.environmentService.getEnvinronment().name === 'production'
        );
    }

    thumbnailImageStyle() {
        const teaserImageUrl = this.environmentService
            .getEnvinronment()
            .documentServiceTeaserImageUrl.replace('{costCenterCode}', this.advert.costCenterCode);

        return this.advert.thumbnailImageStyle(teaserImageUrl);
    }
    ngOnInit(): void {
        this.isStandaloneDisplayMode = this.userSettingsService.isStandaloneDisplayMode();
    }

    preventNavigation(event: Event): void {
        event.stopPropagation();
    }

    unpublish(): void {
        const args: IPublishModalArgs = {
            advert: this.advert,
            mode: 'unpublish',
        };
        const dialog = this.dialog.open(PublishComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((results: IAdvertPublicationResult[]) => {
                if (results.length > 0) {
                    this.advert.isPublished = !results.every((r) => r.isSuccess);
                    this.unpublishAdvertEvent.emit();
                }
            });
    }

    toggleBookmark(event: Event): void {
        if (this.isAdvertBookmarked) {
            this.userSettingsService.removeBookmark(this.advert.id);
        } else {
            this.userSettingsService.addBookmark(this.advert.id);
        }

        this.preventNavigation(event);

        this.userSettingsService
            .updateBookmarks()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.advertDataChanged.emit();
            });
    }

    openInNewTab(event: Event): void {
        this.preventNavigation(event);
        const location = this.browserWindowService.getWindowObject().location;
        const url = `${location.origin}/advert/${this.advert.id}`;
        this.browserWindowService.openExternalLink(url, true);
    }

    toggleReservation(): void {
        generateReservationEvent(this.advert, this.gaService);

        const args: IReservationModalArgs = {
            advertId: this.advert.id,
            isReserved: this.advert.isReserved,
        };

        const dialog = this.dialog.open(ReservationComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.advertDataChanged.emit());
    }
}
