import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SupportedCountryCode } from '@models/backend/common';
import { IAutocompleteData } from '../autocomplete/autocomplete';

@Component({
    selector: 'unit-search',
    templateUrl: './unit-search.component.html',
    styleUrls: ['./unit-search.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UnitSearchComponent),
            multi: true,
        },
    ],
})
export class UnitSearchComponent implements ControlValueAccessor {
    private changeDetectorRef = inject(ChangeDetectorRef);

    @Input() displayHint = true;
    @Output() clearUnitSearchFormEvent = new EventEmitter<void>();
    @Output() unitCodeSearchCleanedEvent = new EventEmitter<void>();

    get showCloseOrSearchIcon(): string {
        return this.value ? 'close' : 'search';
    }

    unitCodes: IAutocompleteData[] = [];

    value: string;
    showUnitCodeSearchLoader: boolean = false;

    // eslint-disable-next-line
    writeValue(value: any): void {
        this.value = value;
        this.changeDetectorRef.markForCheck();
    }

    // eslint-disable-next-line
    onChange: any = () => {};

    // eslint-disable-next-line
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line
    onTouched: any = () => {};

    // eslint-disable-next-line
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onInput(event: Event): void {
        this.value = (event.target as HTMLInputElement).value;
        this.onChange(this.value);
    }

    clearUnitSearchForm(): void {
        this.value = null;
        this.onChange(this.value);
        this.clearUnitSearchFormEvent.emit();
    }

    displaySearchUnitHintByCountry(): { unitId: string; id: string } {
        const countryMappings: { [key: string]: { unitId: string; id: string } } = {
            DE: { unitId: '7466-0001', id: '7466' },
            GB: { unitId: '40008-1', id: '40008' },
            US: { unitId: '5525-102-21C', id: '5525' },
            CY: { unitId: '7700-B100-102', id: '7700' },
            FR: { unitId: '6301_A11', id: '6301' },
            CA: { unitId: '50001a-1, 50001a', id: '50001' },
            SE: { unitId: '1191-3-471011, Rimsjö 3:1', id: '1191' },
        };

        const selectedCountry = localStorage.getItem('countryCode') as SupportedCountryCode;
        return countryMappings[selectedCountry] || countryMappings['GB'];
    }
}
