<mat-button-toggle-group
    [disabled]="disabled"
    [value]="showStatus"
    (change)="changeState($event)"
    name="fontStyle"
    aria-label="confirm or decline viewing events"
>
    <mat-button-toggle value="confirm" [matTooltip]="'SHARED_COMPONENT.CONFIRM' | translate">
        <mat-icon>check</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="decline" [matTooltip]="'SHARED_COMPONENT.DECLINED' | translate">
        <mat-icon>clear</mat-icon>
    </mat-button-toggle>
</mat-button-toggle-group>
