import { SupportedCountryCode } from './common';
import { ProspectStatus } from './prospects';

export enum GeneralInquiryStatus {
    New = ProspectStatus.New,
    MessageAnswered = ProspectStatus.MessageAnswered,
}

export interface IGeneralInquiry {
    id: string;
    countryCode: SupportedCountryCode;
    tenantId: string;
    dateOfReceipt: Date;
    email: string | null;
    message: string | null;
    name: string;
    phone: string | null;
    portalName: 'CallCenter';
    region: string | null;
    status: GeneralInquiryStatus;
    sortingScore: number;
}
