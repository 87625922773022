import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'free-text-search',
    templateUrl: './free-text-search.component.html',
    styleUrls: ['./free-text-search.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeTextSearchComponent extends UnSubscriptionDirective implements OnInit {
    @Input() labelText: string = '';
    @Input() debounceTime: number = 0;
    @Output() freeTextSearchEvent = new EventEmitter<string>();
    @Output() clearfreeTextSearchEvent = new EventEmitter<void>();

    get showCloseOrSearchIcon(): 'close' | 'search' {
        return this.freeTextSearchForm.value ? 'close' : 'search';
    }

    freeTextSearchForm: UntypedFormControl = new UntypedFormControl();

    ngOnInit(): void {
        this.freeTextSearchForm.valueChanges
            .pipe(debounceTime(this.debounceTime))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((searchTerm: string) => this.freeTextSearchEvent.emit(searchTerm));
    }

    clearUnitSearchForm(): void {
        if (!this.freeTextSearchForm) {
            return;
        }

        this.clearfreeTextSearchEvent.emit();
        this.freeTextSearchForm.setValue(null);
    }
}
