import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationMessage } from '@validators/types';

@Component({
    selector: 'multiple-messages-box',
    templateUrl: './multiple-messages-box.component.html',
    styleUrls: ['./multiple-messages-box.component.less'],
})
export class MultipleMessagesBoxComponent implements OnChanges {
    @Input()
    validationMessages: ValidationMessage[];

    ngOnChanges(changes: SimpleChanges) {
        this.validationMessages = changes.validationMessages.currentValue;
    }

    byIndex(index: number): number {
        return index;
    }
}
