import { SupportedCultureInfo } from '@models/backend/common';
import { DayTimeFormat, TimeFormat } from '@models/common/time';
import moment from 'moment';

export class TimeHelper {
    static getHourFromCombinedValue(value?: number): number {
        if (value === undefined || value === null) {
            return 0;
        }

        if (this.isAmPmFormat()) {
            const fullHour = Math.floor(value / 60);
            return fullHour < 13 ? fullHour : fullHour - 12;
        } else {
            return Math.floor(value / 60);
        }
    }

    static getMinuteFromCombinedValue(value?: number): number {
        if (value === undefined || value === null) {
            return 0;
        }

        const time = moment().minute(value);
        const minDisplay = time.format('mm');
        return Number.parseInt(minDisplay, 10);
    }

    static getFormatFromCombinedValue(value: number): DayTimeFormat {
        if (value === undefined || value === null) {
            return 'am';
        }

        return value < 12 * 60 ? 'am' : 'pm';
    }

    static getCombinedValue(hours: number, minutes: number, amPm?: DayTimeFormat): number {
        if (hours > 12 && this.isAmPmFormat()) {
            throw new Error('TimeHelper: hours in AM/PM cannot exceed 12');
        }

        let convertedHours = hours;
        if (this.isAmPmFormat() && amPm) {
            convertedHours = amPm === 'pm' && hours !== 12 ? hours + 12 : hours;
        }

        return convertedHours * 60 + minutes;
    }

    static getAvailableHours(timeFormat: TimeFormat): number[] | string[] {
        const length = this.isAmPmFormat() ? 13 : 24;
        const options = [...Array(length).keys()];

        if (timeFormat === 'two-digit') {
            return options.map((hour) => this.pad(hour));
        }

        return options;
    }

    static getAvailableMinutes(timeFormat: TimeFormat): number[] | string[] {
        const options = Array.from({ length: 12 }, (_, i) => i * 5);

        if (timeFormat === 'two-digit') {
            return options.map((minute) => this.pad(minute));
        }

        return options;
    }

    static isAmPmFormat(): boolean {
        const cultInfo = localStorage.getItem('userCultureInfo') as SupportedCultureInfo;
        return cultInfo === 'en-US';
    }

    static getInputFormattedValue(value?: number): string | null {
        if (value === null || value === undefined) {
            return null;
        }

        const amPmPart = this.isAmPmFormat() ? ` ${this.getFormatFromCombinedValue(value)}` : '';
        const hour = this.getHourFromCombinedValue(value);
        const hourPart = hour < 10 ? `0${hour}` : hour;
        const minute = this.getMinuteFromCombinedValue(value);
        const minutePart = minute < 10 ? `0${minute}` : minute;
        return `${hourPart}:${minutePart}${amPmPart}`;
    }

    // TODO: rename me
    static geDayLabel(weekdayNumber: number): string {
        const days = {
            1: 'DAYS.MONDAY',
            2: 'DAYS.TUESDAY',
            3: 'DAYS.WEDNESDAY',
            4: 'DAYS.THURSDAY',
            5: 'DAYS.FRIDAY',
            6: 'DAYS.SATURDAY',
            0: 'DAYS.SUNDAY',
        };

        return days[weekdayNumber];
    }

    static pad(number: string | number): string {
        const width = 2;
        const zero = '0';
        number = number + '';
        return number.length >= width ? number : new Array(width - number.length + 1).join(zero) + number;
    }
}
