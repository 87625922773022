import { Component, Input } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';

@Component({
    selector: 'apartment-sale-stats',
    templateUrl: './apartment-sale-stats.component.html',
})
export class ApartmentSaleStatsComponent {
    @Input() advert: AdvertViewModel;
}
