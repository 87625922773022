import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
    selector: 'toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent {
    @Input() disabled = false;

    @Input() status = null;

    @Output() btnToggleChange = new EventEmitter<string>();

    get showStatus(): string {
        switch (this.status) {
            case 'confirmed':
                return 'confirm';
            case 'declined':
                return 'decline';
            default:
                return null;
        }
    }

    changeState(action: MatButtonToggleChange): void {
        this.btnToggleChange.emit(action.value);
    }
}
