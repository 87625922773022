import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IDashboardViewResponseBody, IGeneralInquiryPageResponseBody } from '@models/backend/responses';
import { Observable, Subject } from 'rxjs';

export type DashboardItems = {
    dashboardResponse: IDashboardViewResponseBody;
    generalInquiryResponse?: IGeneralInquiryPageResponseBody;
};
@Injectable({ providedIn: 'root' })
export class DashboardService {
    private httpClient = inject(HttpClient);

    private dashboardData = new Subject<DashboardItems>();
    dashboardData$ = this.dashboardData.asObservable();

    getData(): Observable<IDashboardViewResponseBody> {
        return this.httpClient.get<IDashboardViewResponseBody>('views/dashboard?state=publication');
    }

    setDashboardItems(dashboardItems: DashboardItems) {
        this.dashboardData.next(dashboardItems);
    }
}
