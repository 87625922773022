import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'time-picker-dialog',
    templateUrl: 'time-picker-dialog.component.html',
    styleUrls: ['time-picker-dialog.component.less'],
})
export class TimePickerDialogComponent implements OnInit {
    dialogRef = inject<MatDialogRef<TimePickerDialogComponent>>(MatDialogRef);
    args = inject(MAT_DIALOG_DATA);

    form: UntypedFormControl;

    ngOnInit(): void {
        this.form = new UntypedFormControl(this.args);
    }

    save(): void {
        const result = this.form.value;
        this.dialogRef.close(result);
    }
}
