<div
    class="let-mat-dialog viewing-editor option-modal flex-footer"
    [ngClass]="{
        safariMobileHeight: isMobileSafariBrowser,
    }"
>
    <section class="head">
        <article class="subtitle">{{ 'VIEWINGS_VIEW.EDITOR_TITLE' | translate }}</article>
        <button mat-icon-button matDialogClose *ngIf="!isLoading">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="options form" *ngIf="currentView === 'form'" [formGroup]="form">
        <aside class="row">
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'SHARED_COMPONENT.DATE' | translate }}</mat-label>
                    <input
                        formControlName="date"
                        [min]="today"
                        matInput
                        [matDatepicker]="datePicker"
                        autocomplete="off"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-error *ngIf="!form.get('date').valid">{{
                        'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                    }}</mat-error>
                </mat-form-field>
            </div>
        </aside>
        <aside class="row" [class.display-block]="isAmPmFormat">
            <div class="property start-time-picker">
                <article [ngClass]="startTimeIsValid ? 'label' : 'error-label'">
                    {{ 'VIEWINGS_VIEW.START_TIME' | translate }}
                </article>
                <time-picker formControlName="startTime" [isShortForm]="true" timeFormat="two-digit"></time-picker>
                <mat-error class="time-error" *ngIf="!startTimeIsValid">{{
                    'VALIDATION_MESSAGES.START_TIME_IS_NOT_VALID' | translate
                }}</mat-error>
            </div>
            <div class="property">
                <article [ngClass]="endTimeIsValid ? 'label' : 'error-label'">
                    {{ 'VIEWINGS_VIEW.END_TIME' | translate }}
                </article>
                <time-picker formControlName="endTime" [isShortForm]="true" timeFormat="two-digit"></time-picker>
                <mat-error class="time-error" *ngIf="!form.get('endTime').valid">{{
                    'VALIDATION_MESSAGES.TIME_END_INVALID_SELECTION' | translate
                }}</mat-error>
            </div>
        </aside>
        <aside class="row">
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'VIEWINGS_VIEW.MEETING_POINT' | translate }}</mat-label>
                    <mat-select formControlName="meetingPoint">
                        <mat-option [value]="'mainEntrance'">{{
                            'VIEWINGS_VIEW.MAIN_ENTRANCE' | translate
                        }}</mat-option>
                        <mat-option [value]="'rearEntrance'">{{
                            'VIEWINGS_VIEW.REAR_ENTRANCE' | translate
                        }}</mat-option>
                        <mat-option [value]="'apartmentDoor'">{{
                            'VIEWINGS_VIEW.APARTMENT_DOOR' | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </aside>
        <h4 class="h4-header">{{ 'VIEWINGS_VIEW.ADDITIONAL_TITLE' | translate }}</h4>
        <aside class="row">
            <div class="property">
                <mat-form-field>
                    <textarea
                        placeholder="{{
                            'VIEWINGS_VIEW.ADDITIONAL' | additionalInfoByCountry: advert.countryCode : advert.region
                        }}"
                        formControlName="description"
                        matInput
                        rows="3"
                    ></textarea>
                    <mat-error *ngIf="!form.get('description').valid">{{
                        'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                    }}</mat-error>
                </mat-form-field>
            </div>
        </aside>
        <aside class="row" *ngIf="isAdditionalInfoEngAvailableFor">
            <div class="property">
                <mat-form-field>
                    <textarea
                        placeholder="{{ 'VIEWINGS_VIEW.ADDITIONAL_EN' | translate }}"
                        formControlName="descriptionEn"
                        matInput
                        rows="3"
                    ></textarea>
                    <mat-error *ngIf="!form.get('descriptionEn').valid">{{
                        'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                    }}</mat-error>
                </mat-form-field>
            </div>
        </aside>
    </section>

    <section class="loading" *ngIf="currentView === 'loading'">
        <loading-indicator [isPermanent]="true"></loading-indicator>
    </section>

    <section class="actions" *ngIf="!isLoading">
        <button mat-flat-button matDialogClose>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()" [disabled]="!canSave">
            {{ 'SHARED_COMPONENT.SAVE' | translate }}
        </button>
    </section>
</div>
