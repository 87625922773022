<nav>
    <section class="header">
        <button mat-icon-button class="header-button" clickAndKeydown (actionTriggered)="isNavigationExpanded = true">
            <i class="material-icons">menu</i>
        </button>

        <article class="heading" routerLink="/">{{ 'SHARED_COMPONENT.LETTINGS' | translate }}</article>
    </section>

    <section class="actions">
        <button mat-icon-button class="header-button" clickAndKeydown (actionTriggered)="openSupportPage()">
            <mat-icon fontSet="material-icons-outlined">contact_support</mat-icon>
        </button>

        <button mat-icon-button class="header-button" [matMenuTriggerFor]="languageMenu">
            <mat-icon class="large-icon language-icon" svgIcon="ak-globe-web"></mat-icon>
        </button>

        <mat-menu #languageMenu="matMenu" xPosition="before">
            <div class="language-section-title">
                <b>{{ 'SHARED_COMPONENT.COUNTRY' | translate }}</b>
            </div>
            <button
                *ngFor="let country of countries"
                clickAndKeydown
                (actionTriggered)="setCountry(country.code)"
                mat-menu-item
                mat-button-toggle
            >
                {{ country.name | translate }}
                <mat-icon *ngIf="isSelectedCountry(country.code)">done</mat-icon>
            </button>

            <div class="language-section-title">
                <b>{{ 'SHARED_COMPONENT.LANGUAGE' | translate }}</b>
            </div>
            <button
                *ngFor="let cultureInfo of cultureInfos"
                clickAndKeydown
                (actionTriggered)="setCultureInfo(cultureInfo.cultureInfo)"
                mat-menu-item
            >
                {{ cultureInfo.name | translate }}
                @if (cultureInfo.description.length) {
                    , {{ cultureInfo.description | translate }}
                }
                <mat-icon *ngIf="isSelectedCultureInfo(cultureInfo.cultureInfo)">done</mat-icon>
            </button>
        </mat-menu>

        <button mat-icon-button class="header-button person" [matMenuTriggerFor]="personMenu">
            <mat-icon fontSet="material-icons-outlined">person</mat-icon>
        </button>
        <mat-menu #personMenu="matMenu" xPosition="before" class="matMenu">
            <button mat-menu-item clickAndKeydown (actionTriggered)="logout()">
                <mat-icon class="material-icons-outlined">logout</mat-icon>
                {{ 'SHARED_COMPONENT.LOGOUT' | translate }}
            </button>
        </mat-menu>
    </section>
</nav>

<div
    class="backdrop"
    [class.open]="isNavigationExpanded"
    clickAndKeydown
    (actionTriggered)="isNavigationExpanded = false"
></div>

<section class="sidebar left" [class.open]="isNavigationExpanded">
    <aside class="header">
        <article>{{ 'SHARED_COMPONENT.LETTINGS' | translate }}</article>
        <i class="material-icons" clickAndKeydown (actionTriggered)="isNavigationExpanded = false">close</i>
    </aside>
    <aside class="items">
        <div class="navi-item" [class.active]="isDashboardRoute" clickAndKeydown (actionTriggered)="navigateToHome()">
            {{ 'SHARED_COMPONENT.DASHBOARD' | translate }}
        </div>
        <div
            class="navi-item"
            [class.active]="isTeasersRoute"
            clickAndKeydown
            (actionTriggered)="navigateToTeaserList()"
        >
            {{ 'SHARED_COMPONENT.UNIT_LIST' | translate }}
        </div>

        <div
            *ngIf="isRotationFeatureAvailable"
            class="navi-item"
            [class.active]="isRotationRoute"
            clickAndKeydown
            (actionTriggered)="navigateToRotationTeaser()"
        >
            {{ 'ROTATION.MENU' | translate }}
        </div>
    </aside>
</section>
