import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { generateReservationEvent } from '@helpers/google-analytics.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { AnalyticsService } from '@services/analytics.service';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IReservationModalArgs, ReservationComponent } from '../reservation/reservation.component';

@Component({
    selector: 'publish-bar',
    templateUrl: 'publish-bar.component.html',
    styleUrls: ['publish-bar.component.less'],
})
export class PublishBarComponent extends UnSubscriptionDirective {
    private dialog = inject(MatDialog);
    private gaService = inject(AnalyticsService);
    private userSettingsService = inject(UserSettingsService);

    @Input() advert: AdvertViewModel;
    @Input() isPreview: boolean = false;
    @Output() published = new EventEmitter();
    @Output() unpublished = new EventEmitter();
    @Output() reservationChanged = new EventEmitter();

    get isStandaloneDisplayMode(): boolean {
        return this.userSettingsService.isStandaloneDisplayMode();
    }

    publish(): void {
        this.published.emit();
    }

    unpublish(): void {
        this.unpublished.emit();
    }

    toggleReservation(): void {
        generateReservationEvent(this.advert, this.gaService);

        const args: IReservationModalArgs = {
            advertId: this.advert.id,
            isReserved: this.advert.isReserved,
        };

        const dialog = this.dialog.open(ReservationComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.reservationChanged.emit());
    }
}
