import { String } from '@helpers/string.helper';
import { IImage, MimeType } from '@models/backend/images';

export class ImageViewModel {
    id: string;
    name: string;
    assetName: string;
    mimeType: MimeType;
    isTitleImage: boolean;
    isFloorplan: boolean;
    isExample: boolean;
    isPortrait: boolean;
    pictureType: string;
    pictureDetail: string;
    index: number;
    originalImageUrl: string;
    thumbnailImageUrl: string;
    smallUrl: string;
    mediumUrl: string;
    largeUrl: string;

    static factory(image: IImage): ImageViewModel {
        const vm = new ImageViewModel();
        vm.id = image.id;
        vm.pictureDetail = image.pictureDetail;
        vm.isExample = image.isExample;
        vm.thumbnailImageUrl = image.thumbnailImageUrl;
        vm.originalImageUrl = image.originalImageUrl;
        vm.index = image.index;
        vm.isFloorplan = image.isFloorplan;
        vm.isTitleImage = image.isTitleImage;
        vm.mimeType = image.mimeType;
        vm.name = image.mimeType;
        vm.assetName = image.assetName;
        vm.pictureType = image.pictureDetail;
        vm.isPortrait = image.isPortrait;
        vm.smallUrl = image.smallUrl;
        vm.mediumUrl = image.mediumUrl;
        vm.largeUrl = image.largeUrl;
        return vm;
    }

    get originalImageStyle() {
        return {
            'background-image': `url("${this.largeUrl}")`,
            'background-position': 'center',
            'background-repeat': 'no-repeat',
        };
    }

    get tagText(): string {
        return this.pictureDetail ? `IMAGE_LABELS.${String.toKebabCase(this.pictureDetail)}` : '-';
    }

    get belongsToUnit(): string {
        return this.assetName;
    }

    get hasUnitCodeForExampleImage(): boolean {
        return this.isExample && !!this.belongsToUnit;
    }
}
