<a href="tel:{{ phoneNumber }}" class="link" *ngIf="!isMobileDevice">{{ phoneNumber }}</a>

<a *ngIf="isMobileDevice" class="link" [matMenuTriggerFor]="phoneMenu">{{ phoneNumber }}</a>

<mat-menu class="phone-menu" #phoneMenu="matMenu">
    <button mat-menu-item>
        <a class="no-bold" href="tel:{{ phoneNumber }}">{{ 'SHARED_COMPONENT.CALL' | translate }}</a>
    </button>
    <button mat-menu-item>
        <a class="no-bold" href="sms:{{ phoneNumber }}">{{ 'SHARED_COMPONENT.SMS' | translate }}</a>
    </button>
</mat-menu>
