import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAdvertEvent } from '../models/backend/advert';

@Pipe({
    name: 'totalRent',
})
export class TotalRentPipe implements PipeTransform {
    private translateService = inject(TranslateService);


    transform(value: IAdvertEvent): string {
        const advert = value.data;
        const keyfact = advert.keyfacts;
        const totalRent = keyfact['total-rent'];
        const baseRent = keyfact['base-rent'];

        if (baseRent === totalRent || !totalRent || !baseRent) {
            return;
        }

        return `${this.translateService.instant('KEY_FACTS.totalRent')} ${totalRent} ${advert.localization.currency}`;
    }
}
