import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BrowserWindowService } from '@services/browser-window.service';
import { Observable, from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    private router = inject(Router);
    private dialog = inject(MatDialog);
    private browserWindowService = inject(BrowserWindowService);


    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    return this.handleResponse(error);
                }
                return from([request]);
            }),
        ) as Observable<HttpEvent<unknown>>;
    }

    private handleResponse(errorResponse: HttpErrorResponse): Observable<unknown> {
        if (errorResponse.status === 500) {
            // navigate to 500 route
            this.router.navigate(['/error']);
            this.dialog.closeAll();
            return throwError([errorResponse]);
        }
        if (errorResponse.status === 401 && errorResponse.error.message !== 'Unauthenticated') {
            // TODO: replace with silent token renewal and request retry in the future
            this.browserWindowService.reloadWindow();
        }

        return throwError([errorResponse]);
    }
}
