<div class="advert-teaser" *ngIf="advert">
    <section class="header" [ngStyle]="thumbnailImageStyle()" routerLink="/advert/{{ advert.id }}"></section>

    <section class="content" [ngSwitch]="advert.type">
        <aside class="content-header">
            <div class="actions">
                <span class="heading" routerLink="/advert/{{ advert.id }}">{{ advert.id }}</span>
                <button
                    mat-icon-button
                    clickAndKeydown
                    (actionTriggered)="toggleBookmark($event)"
                    matTooltip="{{ bookmarkTooltipMessage | translate }}"
                >
                    <mat-icon>{{ isAdvertBookmarked ? 'star' : 'star_border' }}</mat-icon>
                </button>
                <button
                    class="new-tab"
                    *ngIf="!isStandaloneDisplayMode"
                    mat-icon-button
                    clickAndKeydown
                    (actionTriggered)="openInNewTab($event)"
                    matTooltip="{{ 'SHARED_COMPONENT.OPEN_IN_NEW_TAB' | translate }}"
                >
                    <mat-icon class="material-icons-outlined">open_in_new</mat-icon>
                </button>
            </div>

            <div class="main-actions">
                <div
                    mat-icon-button
                    class="prospect-count"
                    *ngIf="!isUsAdvertOnProdEnvironment"
                    routerLink="/advert/{{ advert.id }}/prospects"
                >
                    <div class="prospect-count-icon">
                        <mat-icon class="material-icons-outlined">person</mat-icon>
                        {{ advert.numberOfNewProspects }}
                    </div>
                </div>

                <button class="message" mat-icon-button *ngIf="advert.comment" matTooltip="{{ advert.comment }}">
                    <mat-icon fontSet="material-icons-outlined">chat</mat-icon>
                </button>

                <button
                    class="menu"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    *ngIf="advert.isPublished"
                    clickAndKeydown
                    (actionTriggered)="preventNavigation($event)"
                >
                    <mat-icon class="no-margin-right">more_horiz</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                    <button class="action-item" mat-menu-item clickAndKeydown (actionTriggered)="toggleReservation()">
                        <mat-icon>{{ advert.isReserved ? 'close' : 'schedule' }}</mat-icon>
                        <span>{{
                            advert.isReserved
                                ? ('SHARED_COMPONENT.CANCEL_RESERVATION' | translate)
                                : ('SHARED_COMPONENT.RESERVE' | translate)
                        }}</span>
                    </button>
                    <button mat-menu-item clickAndKeydown (actionTriggered)="unpublish()">
                        <mat-icon class="material-icons-outlined">cloud_off</mat-icon>
                        {{ 'SHARED_COMPONENT.UNPUBLISH' | translate }}
                    </button>
                </mat-menu>
            </div>
        </aside>

        <div routerLink="/advert/{{ advert.id }}">
            <apartment-stats *ngSwitchCase="'living'" [advert]="advert"></apartment-stats>

            <!-- TODO: create for commercial another stats component  -->
            <apartment-stats *ngSwitchCase="'commercial'" [advert]="advert"></apartment-stats>

            <parking-space-stats *ngSwitchCase="'parking'" [advert]="advert"></parking-space-stats>

            <apartment-sale-stats *ngSwitchCase="'apartmentForSale'" [advert]="advert"></apartment-sale-stats>

            <hunting-area-stats *ngSwitchCase="'huntingArea'" [advert]="advert"></hunting-area-stats>

            <!-- TODO: LA-5065: enhance header stats with forest types  -->
            <parking-space-stats *ngSwitchCase="'summerHouse'" [advert]="advert"></parking-space-stats>
            <parking-space-stats *ngSwitchCase="'agriculturalArea'" [advert]="advert"></parking-space-stats>
        </div>

        <section class="pills">
            <advert-state [advert]="advert"></advert-state>
        </section>
    </section>
</div>
