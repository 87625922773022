<div class="general-inquiry-modal option-modal let-mat-dialog">
    <section class="head">
        <article class="subtitle ellipsis-title">
            {{ 'GENERAL_INQUIRIES_VIEW.CONVERT_TO_PROSPECT' | translate }}
        </article>
        <button mat-icon-button clickAndKeydown (actionTriggered)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <mat-stepper [linear]="true" #stepper (selectionChange)="onStepChange($event)">
        <mat-step [stepControl]="firstFormGroup" label="Select an Advert">
            <form [formGroup]="firstFormGroup">
                <div class="content">
                    <copy-filter (advertsFilterEvent)="filterChanged($event)" />

                    <div class="scrollable-content">
                        <copy-advert-list
                            (advertSelected)="advertSelected($event)"
                            (scrollEventTriggered)="onScroll()"
                            [partialAdvertList]="partialAdverts"
                        ></copy-advert-list>
                    </div>

                    <section class="stepper-actions">
                        <button mat-flat-button matDialogClose>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
                        <button mat-button matStepperNext color="primary" [disabled]="!firstFormGroup.valid">
                            {{ 'SHARED_COMPONENT.NEXT' | translate }}
                        </button>
                    </section>
                </div>
            </form>
        </mat-step>

        <mat-step [stepControl]="secondFormGroup" label="Prospect information">
            <form [formGroup]="secondFormGroup">
                <div class="content">
                    <prospect-form [prospect]="prospect" (formChange)="prospectChanged($event)"></prospect-form>

                    <section class="stepper-actions">
                        <button mat-flat-button matStepperPrevious>{{ 'SHARED_COMPONENT.BACK' | translate }}</button>

                        <button mat-flat-button color="primary" matStepperNext [disabled]="!secondFormGroup.valid">
                            {{ 'SHARED_COMPONENT.NEXT' | translate }}
                        </button>
                    </section>
                </div>
            </form>
        </mat-step>

        <mat-step label="Confirm">
            <div class="content">
                @switch (currentView) {
                    @case ('creationConfirmation') {
                        <section>
                            <p>
                                {{ 'PROSPECTS.PROSPECT' | translate }}
                                <br />
                                - {{ prospect.name }}
                            </p>

                            {{ 'SHARED_COMPONENT.PROSPECT_COPY.BODY' | translate }}
                            <br />
                            <ng-container *ngFor="let item of partialAdverts; trackBy: byAdvertId">
                                <ng-container *ngIf="item.isSelected">
                                    - {{ item.context.advertId }}, {{ item.context.streetName }}
                                    <ng-container *ngIf="item.context.numberOfBedrooms"
                                        >, {{ item.context.numberOfBedrooms }}
                                        {{ 'SHARED_COMPONENT.COPY_TO_ADVERT.BED' | translate }}</ng-container
                                    >
                                    <ng-container *ngIf="item.context.numberOfBathrooms"
                                        >, {{ item.context.numberOfBathrooms }}
                                        {{ 'SHARED_COMPONENT.COPY_TO_ADVERT.BATH' | translate }}</ng-container
                                    >
                                    <ng-container *ngIf="item.context.totalRent"
                                        >, {{ item.context.totalRent }}</ng-container
                                    >
                                </ng-container>
                            </ng-container>
                        </section>

                        <section class="stepper-actions">
                            <button mat-flat-button matStepperPrevious>
                                {{ 'SHARED_COMPONENT.BACK' | translate }}
                            </button>
                            <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="copy()">
                                {{ 'SHARED_COMPONENT.PROSPECT_COPY.CONFIRM' | translate }}
                            </button>
                        </section>
                    }

                    @case ('createdTemplate') {
                        <warning-box
                            [type]="'success'"
                            text="{{ 'SHARED_COMPONENT.PROSPECT_COPY.SUCCESS' | translate }}"
                            linkText="{{ advertId }}."
                            link="/advert/{{ advertId }}/prospects"
                            clickAndKeydown
                            (actionTriggered)="cancel()"
                            (onkeydown)="cancel()"
                        ></warning-box>

                        <section class="stepper-actions">
                            <button mat-flat-button matDialogClose clickAndKeydown (actionTriggered)="close()">
                                {{ 'SHARED_COMPONENT.CLOSE' | translate }}
                            </button>
                        </section>
                    }

                    @case ('createdWarningTemplate') {
                        <warning-box
                            [type]="'warning'"
                            text="{{ 'SHARED_COMPONENT.PROSPECT_COPY.WARNING' | translate }}"
                            linkText="{{ advertId }}."
                            link="/advert/{{ advertId }}/prospects"
                            clickAndKeydown
                            (actionTriggered)="cancel()"
                            (onkeydown)="cancel()"
                        ></warning-box>

                        <section class="stepper-actions">
                            <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="stepper.reset()">
                                {{ 'SHARED_COMPONENT.BACK' | translate }}
                            </button>
                        </section>
                    }

                    @case ('creationForbiddenTemplate') {
                        <warning-box
                            [type]="'warning'"
                            text="{{ 'SHARED_COMPONENT.PROSPECT_COPY.NOT_ALLOWED' | translate }}"
                            linkText="{{ advertId }}."
                            link="/advert/{{ advertId }}/prospects"
                            clickAndKeydown
                            (actionTriggered)="cancel()"
                            (onkeydown)="cancel()"
                        ></warning-box>

                        <section class="stepper-actions">
                            <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="stepper.reset()">
                                {{ 'SHARED_COMPONENT.BACK' | translate }}
                            </button>
                        </section>
                    }

                    @case ('createdErrorTemplate') {
                        <warning-box
                            [type]="'error'"
                            text="{{ 'SHARED_COMPONENT.PROSPECT_COPY.ERROR' | translate }}"
                        ></warning-box>

                        <section class="stepper-actions">
                            <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="stepper.reset()">
                                {{ 'SHARED_COMPONENT.BACK' | translate }}
                            </button>
                        </section>
                    }
                }
            </div>
        </mat-step>
    </mat-stepper>
</div>

<loading-indicator></loading-indicator>
