<mat-form-field>
    <input
        highlightActiveFormControl
        matInput
        type="text"
        autocomplete="off"
        [value]="value"
        (input)="onInput($event)"
    />
    <mat-label>{{ 'SHARED_COMPONENT.SEARCH_PLACEHOLDER' | translate }}</mat-label>
    <mat-icon
        class="searchIcon"
        matSuffix
        clickAndKeydown
        (actionTriggered)="clearUnitSearchForm()"
        (onkeydown)="clearUnitSearchForm()"
        >{{ showCloseOrSearchIcon }}</mat-icon
    >
    <!-- prettier-ignore -->
    <mat-hint *ngIf="displayHint">{{
            'SHARED_COMPONENT.SEARCH_PLACEHOLDER_HINT' | translate : displaySearchUnitHintByCountry()
        }}</mat-hint>
</mat-form-field>
