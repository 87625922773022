<div class="message-box modal-base">
    <section class="head">
        <article class="subtitle">{{ args.translatedTitleText }}</article>
        <button mat-icon-button matDialogClose>
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="content">{{ args.translatedMessageText }}</section>

    <section class="actions">
        <button mat-flat-button matDialogClose>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="ok()">
            {{ 'SHARED_COMPONENT.YES' | translate }}
        </button>
    </section>
</div>
