import { AbstractControl, ValidationErrors } from '@angular/forms';

export interface IAutocompleteData<T = string> {
    id: T;
    label: string;
}

function isAutocompleteOption(value: IAutocompleteData): boolean {
    return value?.id !== null;
}

export function containsIdValidation(control: AbstractControl): ValidationErrors {
    return isAutocompleteOption(control.value) ? null : { required: true };
}
