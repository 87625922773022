import { String } from '../helpers/string.helper';
import { SupportedCountryCode } from './backend/common';
import { IProspect, MarketingChannel, ProspectPortal, ProspectStatus } from './backend/prospects';

export class ProspectViewModel implements IProspect {
    id: string;
    personalIdentityNumber: string;
    advertId: string;
    countryCode: SupportedCountryCode;
    name: string;
    email: string;
    phone: string;
    message: string;
    dateOfReceipt: Date;
    portalName: ProspectPortal;
    status: ProspectStatus;
    preferredMoveInDate: string;
    netHouseholdIncome: string;
    householdSize: string;
    scheduledViewingEventDate: Date;
    scheduledViewingEventStartTime: number;
    marketingChannel: MarketingChannel;
    statusScore: number;
    marketingOptIn: boolean;

    static factory(prospect: IProspect): ProspectViewModel {
        const vm = new ProspectViewModel();
        vm.id = prospect.id;
        vm.personalIdentityNumber = prospect.personalIdentityNumber;
        vm.name = prospect.name;
        vm.email = prospect.email;
        vm.phone = prospect.phone;
        vm.message = prospect.message;
        vm.dateOfReceipt = new Date(prospect.dateOfReceipt);
        vm.portalName = prospect.portalName;
        vm.status = prospect.status;
        vm.preferredMoveInDate = prospect.preferredMoveInDate;
        vm.netHouseholdIncome = prospect.netHouseholdIncome;
        vm.householdSize = prospect.householdSize;
        vm.countryCode = prospect.countryCode;
        vm.scheduledViewingEventDate = prospect.scheduledViewingDate;
        vm.scheduledViewingEventStartTime = prospect.scheduledViewingStartTime;
        vm.marketingChannel = prospect.marketingChannel;
        vm.statusScore = prospect.statusScore;
        vm.marketingOptIn = prospect.marketingOptIn;
        return vm;
    }

    get hasMessage(): boolean {
        return !String.isEmpty(this.message);
    }
}
