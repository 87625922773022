import { IKeyfact, IKeyfactLibrary } from '@models/backend/keyfacts';

export function getSectionKeyfacts(keyfacts: IKeyfactLibrary, sectionId: string): IKeyfact[] {
    const section = keyfacts.sections.find((s) => s.id.includes(sectionId));
    const allKeyfactsFromSection = section.subsections.reduce((acc: IKeyfact[], ss) => acc.concat(...ss.keyfacts), []);

    return allKeyfactsFromSection;
}

export function getValueOrUndefined<T>(keyfacts: IKeyfact[], keyfactId: string): T | undefined {
    const keyfact = keyfacts.find((kf) => kf.id.includes(keyfactId));
    return keyfact ? (keyfact.value as T) : undefined;
}
