import {
    Category,
    CategorySetup,
    CategoryValueType,
    ICategoryContext,
    OperationType,
} from '@components/dashboard/sections/adverts/types';
import { AdvertType } from '@models/backend/advert';
import { SupportedCountryCode } from '@models/backend/common';

export class DashboardHelper {
    static getCategoryBy(counrtyCode: SupportedCountryCode): CategorySetup {
        const defaultAdvertContexts: CategorySetup = [
            [Category.Living, 'SHARED_COMPONENT.PURPOSE_LIVING_PLURAL'],
            [Category.Commercial, 'SHARED_COMPONENT.PURPOSE_COMMERCIAL_PLURAL'],
            [Category.Parking, 'SHARED_COMPONENT.PURPOSE_PARKING_PLURAL'],
        ];

        switch (counrtyCode) {
            case 'SE':
                return [[Category.HuntingArea, 'SHARED_COMPONENT.PURPOSE_HUNTING_AREA_PLURAL']];
            case 'DE':
                return [
                    ...defaultAdvertContexts,
                    [Category.ApartmentForSale, 'SHARED_COMPONENT.PURPOSE_APARTMENT_SALES_PLURAL'],
                ];
            case 'US':
            case 'CA':
            case 'FR':
            case 'CY':
            case 'GB':
                return defaultAdvertContexts;
        }
    }

    static updateContext<T extends { id }>(contexts: ICategoryContext<T>[], item: T, operation: OperationType): void {
        const contextIndex = contexts.findIndex((context) => context.items.some((a) => a.id === item.id));
        contexts[contextIndex].items = this.updateItem(contexts[contextIndex].items, item, operation);
    }

    static updateItem<T extends { id }>(items: T[], item: T, operation: OperationType): T[] {
        const index = items.findIndex((obj) => obj.id === item.id);
        if (index !== -1) {
            switch (operation) {
                case 'update':
                    items[index] = item;
                    break;
                case 'remove':
                    items.splice(index, 1);
                    break;
            }
        }
        return items;
    }

    static isAdvertType(type: CategoryValueType): type is AdvertType {
        const supportedAdvertTypes = Object.values(AdvertType);
        return supportedAdvertTypes.includes(type as AdvertType);
    }
}
