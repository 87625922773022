import moment from 'moment';

export function getInitialStartTime(now: Date): number {
    const hours = now.getHours();

    if (hours === 23) {
        return hours * 60;
    }

    return (hours + 1) * 60;
}

export function getInitialEndTime(now: Date): number {
    return getInitialStartTime(now) + 15;
}

export function areOnSameDay(dateOne: Date, dateTwo: Date): boolean {
    return moment(dateOne).date() === moment(dateTwo).date();
}
