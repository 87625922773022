import { IBaseEvent } from '@models/backend/advert';

export function authorMessage(event: IBaseEvent): string {
    const { firstName, lastName, author } = event;
    const { type } = author;

    let authorMessageValue = '';
    switch (type) {
        case 'legacy':
            authorMessageValue = '-';
            break;
        case 'machine':
            authorMessageValue = 'SHARED_COMPONENT.SYSTEM';
            break;
        case 'user':
            authorMessageValue =
                type === 'user' && isUserKnown(event) ? `${firstName} ${lastName}` : 'SHARED_COMPONENT.UNKNOWN';
            break;
    }

    return authorMessageValue;
}

export function isUserDeleted(event: IBaseEvent): boolean {
    const { author, isUserDeleted: isUserDeletedValue } = event;
    const { type } = author;

    return type === 'user' && isUserKnown(event) && isUserDeletedValue;
}

export function isUserKnown(event: IBaseEvent): boolean {
    const { firstName, lastName } = event;

    return !!(firstName && lastName);
}

export function isTranslationKey(translationKey: string): boolean {
    return translationKey.includes('SHARED_COMPONENT');
}
