import { Component, Input, computed, inject } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '@services/country.service';

@Component({
    selector: 'advert-state',
    templateUrl: './advert-state.component.html',
})
export class AdvertStateComponent {
    private countryService = inject(CountryService);
    private translateService = inject(TranslateService);

    @Input()
    advert: AdvertViewModel;

    isVacancyStatusEnabled: boolean = this.countryService.getCurrentCountry() === 'FR';

    translatedDaysSincePublished = computed(() => {
        return this.translateService.instant(this.advert.publishedMessage, { days: this.advert.daysSincePublished });
    });

    translatedDaysSinceReserved = computed(() => {
        return this.translateService.instant(this.advert.reservedMessage, { days: this.advert.daysSinceReserved });
    });

    upcomingViewingsMessage = computed(() => {
        const label =
            this.advert.numberOfUpcomingViewings === 1
                ? 'SHARED_COMPONENT.UPCOMING_VIEWINGS'
                : 'SHARED_COMPONENT.UPCOMING_VIEWINGS_PLURAL';

        return `${this.advert.numberOfUpcomingViewings} ${this.translateService.instant(label)}`;
    });
}
