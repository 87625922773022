import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

export class ExpandableElement extends UnSubscriptionDirective {
    isExpanded: boolean = false;

    get carretIcon() {
        return this.isExpanded ? 'expand_less' : 'expand_more';
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }
}
