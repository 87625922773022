import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    Signal,
    TemplateRef,
    ViewChild,
    computed,
    inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/shared';
import { CopyService } from '@components/shared/advert-copy/copy.service';
import { IPartialAdvert } from '@components/types';
import { loadAdvertsFromStore, mapToTableData } from '@helpers/advert.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { MarketingChannel, ProspectPortal, ProspectStatus } from '@models/backend/prospects';
import { Selectable } from '@models/common/selectable';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import { AdvertService } from '@services/advert.service';
import { AdvertStoreService } from '@services/advert.store';
import { AnalyticsService } from '@services/analytics.service';
import { ProspectsService } from '@services/prospects.service';
import { EMPTY, catchError, finalize, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { ProspectCopyModalArgs } from './types';

export type PropsectCopyViewTemplate =
    | 'prospectCreatedTemplate'
    | 'prospectCreationErrorTemplate'
    | 'prospectCreationWarningTemplate'
    | 'prospectCreationConfirmationTemplate';

@Component({
    selector: 'prospect-copy',
    templateUrl: './prospect-copy.component.html',
    styleUrls: ['./prospect-copy.component.less'],
})
export class ProspectCopyComponent extends UnSubscriptionDirective implements OnInit {
    private copyService = inject(CopyService);
    private advertService = inject(AdvertService);
    private prospectsService = inject(ProspectsService);
    private advertStoreService = inject(AdvertStoreService);
    private element = inject(ElementRef);
    private renderer = inject(Renderer2);
    private gaService = inject(AnalyticsService);

    args = inject<ProspectCopyModalArgs>(MAT_DIALOG_DATA);
    dialogRef = inject<MatDialogRef<ProspectCopyComponent>>(MatDialogRef);

    @ViewChild(LoadingIndicatorComponent, { static: true })
    loadingIndicator: LoadingIndicatorComponent;

    @ViewChild('prospectCreatedTemplate') prospectCreatedTemplate: TemplateRef<unknown>;
    @ViewChild('prospectCreationErrorTemplate') prospectCreationErrorTemplate: TemplateRef<unknown>;
    @ViewChild('prospectCreationWarningTemplate') prospectCreationWarningTemplate: TemplateRef<unknown>;
    @ViewChild('prospectCreationConfirmationTemplate') prospectCreationConfirmationTemplate: TemplateRef<unknown>;

    currentView: string = '';

    selectedFilterOptions: ITeaserFilterOptions = {
        streetName: null,
        searchQuery: null,
    };

    partialAdverts: Selectable<IPartialAdvert>[] = [];

    page = 1;
    isLast: boolean = false;

    showAdvertList: boolean = true;

    prospect: ProspectViewModel;

    selectedTemplate: TemplateRef<unknown>;

    isConfirmButtonEnabled: Signal<boolean> = computed(() => !this.copyService.advertIds().length);

    advertId: string;

    async ngOnInit(): Promise<void> {
        this.loadingIndicator.show();

        await loadAdvertsFromStore(
            this.advertService,
            this.advertStoreService.advertsSubject,
            this.unsubscribe$,
            (adverts) => {
                this.advertStoreService.updateAdverts(adverts);
                this.loadingIndicator.hide();
                this.partialAdverts = adverts.map((advert) => mapToTableData(advert));
            },
        );

        this.prospect = this.args.prospect;
    }

    onScroll(): void {
        if (!this.isLast) {
            this.page = this.page + 1;
            this.loadAdverts();
        }
    }

    filterChanged(selectedFilterOptions: ITeaserFilterOptions) {
        this.partialAdverts = [];
        this.isLast = false;
        this.page = 0;
        this.selectedFilterOptions = selectedFilterOptions;
        this.loadAdverts();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    copy(): void {
        this.advertId = this.copyService.advertIds()[0];
        this.loadingIndicator.show();

        const preparedCopiedProspect = this.preparedProspects(this.advertId);

        this.prospectsService
            .createProspect(this.advertId, preparedCopiedProspect)
            .pipe(
                catchError((errors: HttpErrorResponse[]) => {
                    if (errors[0].status === 409) {
                        this.selectTemplate('prospectCreationWarningTemplate');
                        this.loadingIndicator.hide();
                        return EMPTY;
                    }
                    this.selectTemplate('prospectCreationErrorTemplate');
                    this.loadingIndicator.hide();
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.gaService.event(GoogleAnalyticsEvents.ProspectCreated);
                this.selectTemplate('prospectCreatedTemplate');
                this.loadingIndicator.hide();
            });
    }

    confirm(): void {
        this.showAdvertList = false;
        this.selectTemplate('prospectCreationConfirmationTemplate');
    }

    byAdvertId(_index: number, advert: IPartialAdvert): string {
        return advert.advertId;
    }

    loadAdverts(): void {
        this.loadingIndicator.show();
        this.advertService
            .getAdvertTeasers(this.selectedFilterOptions, this.page)
            .pipe(
                finalize(() => {
                    this.loadingIndicator.hide();
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((response) => {
                const { data, isLast } = response;
                const advertsData = data.map((advert) => mapToTableData(AdvertViewModel.factory(advert)));
                this.partialAdverts.push(...advertsData);
                this.isLast = isLast;
            });
    }

    backToAdvertList(): void {
        this.showAdvertList = true;
        this.selectTemplate(null);
    }

    private selectTemplate(template: PropsectCopyViewTemplate): void {
        this.renderer.setStyle(this.element.nativeElement, 'min-height', 'auto');

        this.currentView = template;
        this.selectedTemplate = this[template];
    }

    private preparedProspects(advertId: string) {
        const copiedProspect = structuredClone(this.prospect);

        Reflect.deleteProperty(copiedProspect, 'id');

        copiedProspect.advertId = advertId;
        copiedProspect.status = ProspectStatus.New;
        copiedProspect.marketingChannel = MarketingChannel.Other;
        copiedProspect.portalName = ProspectPortal.LettingApp;

        return copiedProspect;
    }
}
