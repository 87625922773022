<div class="app-update let-mat-dialog">
    <section class="head mat-dialog-title">
        <article class="subtitle">{{ title | translate }}</article>
        <button mat-icon-button clickAndKeydown (actionTriggered)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="info">
        <warning-box text="{{ infoMessage | translate }}"></warning-box>
    </section>

    <section class="actions">
        <button mat-flat-button clickAndKeydown (actionTriggered)="close()">
            {{ 'SHARED_COMPONENT.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()">
            {{ 'PWA.UPDATE' | translate }}
        </button>
    </section>
</div>
