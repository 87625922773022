<article>
    <span *ngIf="advert.showNumberOfRooms">
        {{ advert.numberOfRooms }}
        {{ advert.numberOfRoomsLabel | translate }},
    </span>
    <span *ngIf="advert.showMarketingNumberOfRooms">
        {{ advert.marketingNumberOfRooms }}
        {{ advert.numberOfRoomsLabel | translate }},
    </span>

    <span *ngIf="advert.showNumberOfBedrooms">
        {{ advert.numberOfBedrooms }}
        {{ advert.numberOfBedroomsLabel | translate }},
    </span>
    <span *ngIf="advert.showMarketingNumberOfBedrooms">
        {{ advert.marketingNumberOfBedrooms }}
        {{ advert.numberOfBedroomsLabel | translate }},
    </span>

    <span *ngIf="advert.showUnitSize"> {{ advert.size | numeral }} {{ advert.areaSizeUnit }} </span>
    <span *ngIf="advert.showMarketingUnitSize"> {{ advert.marketingSize | numeral }} {{ advert.areaSizeUnit }} </span>
</article>
