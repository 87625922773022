import { String } from '../helpers/string.helper';
import { SupportedCountryCode } from './backend/common';
import { GeneralInquiryStatus, IGeneralInquiry } from './backend/general-inquiry';

export class GeneralInquiryViewModel implements IGeneralInquiry {
    id: string;
    type: 'generalInquiry';
    countryCode: SupportedCountryCode;
    tenantId: string;
    dateOfReceipt: Date;
    email: string | null;
    message: string | null;
    name: string;
    phone: string | null;
    portalName: 'CallCenter';
    region: string | null;
    status: GeneralInquiryStatus;
    sortingScore: number;

    static factory(generalInquiry: IGeneralInquiry): GeneralInquiryViewModel {
        const vm = new GeneralInquiryViewModel();
        vm.id = generalInquiry.id;
        vm.type = 'generalInquiry'; // only in ViewModel available
        vm.countryCode = generalInquiry.countryCode;
        vm.tenantId = generalInquiry.tenantId;
        vm.dateOfReceipt = new Date(generalInquiry.dateOfReceipt);
        vm.email = generalInquiry.email;
        vm.message = generalInquiry.message;
        vm.name = generalInquiry.name;
        vm.phone = generalInquiry.phone;
        vm.portalName = generalInquiry.portalName;
        vm.region = generalInquiry.region;
        vm.status = generalInquiry.status;
        vm.sortingScore = generalInquiry.sortingScore;
        return vm;
    }

    get hasMessage(): boolean {
        return !String.isEmpty(this.message);
    }
}
