import { ISmsSendingResult } from '@models/backend/responses';
import { TranslateService } from '@ngx-translate/core';

export class SendSmsErrorMessageHelper {
    static generateSmsSendingErrorMessage(
        smsSendingResults: ISmsSendingResult[],
        translateService: TranslateService
    ): string {
        const invalidPhoneResults = smsSendingResults.filter((r) => r.type === 'invalid-phone-number');
        const failedResults = smsSendingResults.filter((r) => r.type !== 'ok');

        if (invalidPhoneResults.length && invalidPhoneResults.length === failedResults.length) {
            const prospects = invalidPhoneResults.map((r) => `${r.data.name} ${r.data.phone}`).join(', ');
            return translateService.instant('SHARED_COMPONENT.VIEWING_CONFIRMATION_INVALID_PHONE_ERROR', {
                prospects,
            });
        }

        const failedProspects = failedResults.map((r) => `${r.data.name} ${r.data.phone}`).join(', ');
        return translateService.instant('SHARED_COMPONENT.VIEWING_CONFIRMATION_GENERAL_ERROR', { failedProspects });
    }
}
