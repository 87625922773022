<div class="advert-preview" *ngIf="advert">
    <advert-details-header [isPreview]="true"></advert-details-header>

    <article class="title">{{ 'ADVERT.PREVIEW_BUTTON' | translate }}</article>

    <key-fact-section
        *ngFor="let section of advert.keyfacts.sections; trackBy: byIndex"
        [countryCode]="advert.keyfacts.countryCode"
        [region]="advert.keyfacts.region"
        [section]="section"
        [isPreview]="true"
        [advertId]="advert.id"
    >
    </key-fact-section>

    <contact-details-section [isPreview]="true" [advert]="advert"></contact-details-section>

    <images-section [isPreview]="true" [advert]="advert"></images-section>

    <virtual-viewing-section [isPreview]="true" [advert]="advert"></virtual-viewing-section>

    <publish-bar [isPreview]="true" [advert]="advert" (published)="publish()"></publish-bar>
</div>
<loading-indicator></loading-indicator>
