<div class="invitee-state">
    <ng-container [ngSwitch]="invitee.response">
        <span *ngSwitchCase="'declined'"
            ><mat-icon>cancel_filled</mat-icon> {{ 'VIEWINGS_VIEW.STATUS.declined' | translate }}</span
        >
        <span *ngSwitchCase="'confirmed'"
            ><mat-icon>check_circle_filled</mat-icon> {{ 'SHARED_COMPONENT.CONFIRM' | translate }}</span
        >
        <span *ngSwitchDefault
            ><mat-icon>warning_filled</mat-icon> {{ 'VIEWINGS_VIEW.STATUS.pending' | translate }}</span
        >
    </ng-container>
    <ng-container *ngIf="shouldShowInviteeStatus">
        <span *ngIf="!invitee.hasAttended"
            ><mat-icon>cancel_filled</mat-icon>
            {{ 'VIEWINGS_VIEW.STATUS.absent' | translate }}
        </span>
        <span *ngIf="invitee.hasAttended"
            ><mat-icon>check_circle_filled</mat-icon> {{ 'VIEWINGS_VIEW.STATUS.attendee' | translate }}</span
        >
    </ng-container>
</div>
