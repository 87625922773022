import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    ActivatedRoute,
    Data,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LoadingIndicatorComponent } from '@components/shared';
import { AnalyticsService } from '@services/analytics.service';
import { CountryService } from '@services/country.service';
import { EnvironmentService } from '@services/environment.service';
import { UserRoleService } from '@services/user-role.service';
import { UserSettingsService } from '@services/user-settings.service';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    private authService = inject(AuthService);
    private userSettingsService = inject(UserSettingsService);
    private gaService = inject(AnalyticsService);
    private userRoleService = inject(UserRoleService);
    private countryService = inject(CountryService);
    private titleService = inject(Title);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private environmentService = inject(EnvironmentService);
    private window = inject(Window);

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    ngOnInit(): void {
        // we show loading spinner during the bootstrap phase of angular
        this.showLoadingSpinner();

        this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
            if (isAuthenticated) {
                combineLatest([
                    this.userSettingsService.getHashedUserId(),
                    this.userRoleService.getUserRole(),
                ]).subscribe((result) => {
                    const hashedUserId = result[0];
                    const userRoleResponse = result[1];
                    const role = userRoleResponse.data;
                    localStorage.setItem('responsibilityId', role);
                    this.gaService.set(
                        hashedUserId,
                        role,
                        this.countryService.getCurrentCultureInfo(),
                        this.countryService.getCurrentCountry(),
                    );
                });

                return;
            }

            const authorizationParams = {
                authorizationParams: {
                    connection: this.environmentService.getEnvinronment().auth.connection,
                },
            };

            this.authService.loginWithRedirect(authorizationParams);
        });

        this.setTitle();
    }

    private showLoadingSpinner(): void {
        this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationStart ||
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel ||
                        event instanceof NavigationError,
                ),
            )
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.loadingIndicator.show();
                    return;
                }
                this.loadingIndicator.hide();
            });
    }

    private setTitle(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
            )
            .subscribe((event: ActivatedRoute) => {
                const data: Data = event.snapshot.data;
                const currentPagetitle: string = `Lettings Application-${data['pageTitle']}`;
                this.titleService.setTitle(currentPagetitle);
                const url = this.window.location.href;
                this.gaService.pageView(currentPagetitle, url);
            });
    }
}
