import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IPartialAdvert } from '@components/types';
import { Selectable } from '@models/common/selectable';
import { CopyService } from '../copy.service';

@Component({
    selector: 'copy-advert-list',
    templateUrl: './copy-advert-list.component.html',
    styleUrls: ['./copy-advert-list.component.less'],
})
export class CopyAdvertListComponent implements OnChanges {
    private copyService = inject(CopyService);

    @Input({ required: false }) reset: boolean;
    @Input({ required: true }) partialAdvertList: Selectable<IPartialAdvert>[];
    @Output() scrollEventTriggered = new EventEmitter<void>();
    @Output() advertSelected = new EventEmitter<string>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.reset && changes.reset.currentValue === true) {
            this.resetAdvertsSelection();
        }
    }

    isDisabled(partialAdvert: Selectable<IPartialAdvert>): boolean {
        if (partialAdvert.isSelected) {
            return false;
        }

        return this.partialAdvertList.some((d) => d.isSelected);
    }

    onCheckboxChange(event: MatCheckboxChange, index: number): void {
        const { checked } = event;
        this.partialAdvertList[index].isSelected = checked;
        if (checked) {
            const { advertId } = this.partialAdvertList[index].context;
            this.copyService.advertIds.set([advertId]);
            this.advertSelected.emit(advertId);
            return;
        }

        this.advertSelected.emit('');
        this.copyService.advertIds.set([]);
    }

    resetAdvertsSelection(): void {
        for (const partialAdvert of this.partialAdvertList) {
            partialAdvert.isSelected = false;
        }
        this.advertSelected.emit('');
        this.copyService.advertIds.set([]);
    }

    onScroll(isLast: boolean): void {
        if (isLast) {
            this.scrollEventTriggered.emit();
        }
    }

    byAdvertId(_index: number, advert: IPartialAdvert): string {
        return advert.advertId;
    }
}
