import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { LoadingIndicatorComponent } from '@components/shared';
import { IRegion } from '@models/backend/common';
import { IContactDetails } from '@models/backend/contacts';
import { ICostCenterAssignments } from '@models/backend/rotation';
import { AdvertService } from '@services/advert.service';
import { ContactsService } from '@services/contacts.service';
import { RotationService } from '@services/rotation.service';
import { Observable, finalize, of, switchMap, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IRefreshAssignmentsData, IRotationTeaserFilter } from '../types';

@Component({
    selector: 'rotation-teaser',
    templateUrl: './rotation-teaser.component.html',
    styleUrls: ['./rotation-teaser.component.less'],
})
export class RotationTeaserComponent extends UnSubscriptionDirective implements OnInit {
    private rotationService = inject(RotationService);
    private advertService = inject(AdvertService);
    private contactsService = inject(ContactsService);

    @ViewChild(LoadingIndicatorComponent, { static: true })
    loadingIndicator: LoadingIndicatorComponent;

    costCentersAssignments: ICostCenterAssignments[] = [];
    costCentersAssignments$: Observable<ICostCenterAssignments[]>;

    filter: IRotationTeaserFilter = {
        region: undefined,
        costCenterCode: undefined,
    };

    regions: IRegion[];
    contacts: IContactDetails[];

    page = 0;
    isLast: boolean = false;

    ngOnInit(): void {
        this.getRegionsAndContacts();
        this.getCostCentersAssignmentsList(this.filter.region, this.filter.costCenterCode);
    }

    refresh(event: IRefreshAssignmentsData): void {
        const { costCenterCode, selectedLm } = event;

        this.costCentersAssignments = this.costCentersAssignments
            .filter((costCentersAssignment) => costCenterCode.includes(costCentersAssignment.costCenterCode))
            .map((costCentersAssignment) => {
                const { contactFirstName, contactLastName } = selectedLm;
                return { ...costCentersAssignment, contactFirstName, contactLastName };
            });

        this.costCentersAssignments$ = of(this.costCentersAssignments);
    }

    onRotationTeaserFilterChange(filter: IRotationTeaserFilter): void {
        this.filter = filter;
        this.resetCurrentPage();
        this.getCostCentersAssignmentsList(filter.region, filter.costCenterCode);
        this.getRegionsAndContacts();
    }

    resetCurrentPage(): void {
        this.isLast = false;
        this.page = 0;
        this.costCentersAssignments = [];
        this.costCentersAssignments$ = of([]);
    }

    onScroll(): void {
        if (!this.isLast) {
            this.page = this.page + 1;
            this.getCostCentersAssignmentsList(this.filter.region, this.filter.costCenterCode);
        }
    }

    getRegionsAndContacts(): void {
        this.advertService
            .getTeaserFilterData()
            ?.pipe(
                takeUntil(this.unsubscribe$),
                switchMap((response) => {
                    this.regions = response.data.regions;

                    const regions = this.filter.region
                        ? [this.filter.region]
                        : response.data.regions.map((region) => region.name);

                    return this.contactsService.getContacts(regions);
                }),
            )
            .subscribe((contacts) => {
                this.contacts = contacts.data;
            });
    }

    getCostCentersAssignmentsList(region?: string, codeCenterCode?: string): void {
        this.loadingIndicator.show();
        this.rotationService
            .getCostCentersAssignments(this.page, region, codeCenterCode)
            ?.pipe(
                finalize(() => {
                    this.loadingIndicator.hide();
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((response) => {
                const costCentersAssignmentsEntries = response.data?.entries;

                if (this.isLast) {
                    return;
                }

                if (costCentersAssignmentsEntries?.length > 0) {
                    const { entries } = response.data;

                    this.costCentersAssignments.push(...entries);
                    this.costCentersAssignments$ = of(this.costCentersAssignments);
                }

                this.isLast = response.data.isLast;
            });
    }
}
