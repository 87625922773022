import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdvertViewModel } from '@models/advert/advert';
import { BrowserWindowService } from '@services/browser-window.service';
import { UserSettingsService } from '@services/user-settings.service';

@Component({
    selector: 'published-portals',
    templateUrl: 'published-portals.component.html',
    styleUrls: ['published-portals.component.less'],
})
export class PublishedPortalsComponent implements OnInit {
    private browserWindowService = inject(BrowserWindowService);
    private userSettingsService = inject(UserSettingsService);
    dialogRef = inject<MatDialogRef<PublishedPortalsComponent>>(MatDialogRef);
    args = inject<AdvertViewModel>(MAT_DIALOG_DATA);

    isMobileSafariBrowser: boolean = false;

    ngOnInit(): void {
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();
    }

    close(): void {
        this.dialogRef.close();
    }

    openExternalPortal(url: string): void {
        this.browserWindowService.openExternalLink(url, true);
    }

    byIndex(index: number): number {
        return index;
    }
}
