<div class="dashboard">
    @if (isDataAvailable) {
        <section class="header">
            <article class="subtitle hello">{{ 'SHARED_COMPONENT.WELCOME_BACK' | translate }} {{ userName }}</article>
            <button mat-flat-button color="primary" class="all" routerLink="/teasers">
                {{ 'SHARED_COMPONENT.UNIT_LIST' | translate }}
            </button>
        </section>

        <section class="tabs" *featureToggle="'general-inquiry'">
            <aside
                class="tab-item"
                routerLinkActive="selected"
                routerLink="/"
                [routerLinkActiveOptions]="{ exact: true }"
            >
                {{ 'SHARED_COMPONENT.ADVERTS' | translate }}
            </aside>

            <aside class="tab-item" routerLinkActive="selected" routerLink="/general-inquiries">
                {{ 'SHARED_COMPONENT.PURPOSE_GENERAL_INQUIRY' | translate }}
                <span> {{ generalInquiriesTotal }}</span>
            </aside>
        </section>
    }

    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<loading-indicator></loading-indicator>
