import { SupportedCountryCode } from '@models/backend/common';
import { GeneralInquiryStatus } from '@models/backend/general-inquiry';
import { IDropdownItem } from '@models/common/dropdown-item';
import { EnumHelper } from './enum.helper';

export class GeneralInquiryHelper {
    static getStatusOptions(countryCode: SupportedCountryCode): GeneralInquiryStatus[] {
        const allOptions = EnumHelper.toArray<GeneralInquiryStatus>(GeneralInquiryStatus);

        switch (countryCode) {
            case 'GB':
            case 'CY':
            case 'DE':
            case 'SE':
            case 'CA':
            case 'FR':
            case 'US':
                return allOptions;
        }
    }

    static getGeneralInquiryStatusItems(
        supportedCountryCode: SupportedCountryCode,
    ): IDropdownItem<GeneralInquiryStatus>[] {
        const allOptions = GeneralInquiryHelper.getStatusOptions(supportedCountryCode);
        return allOptions.map((s) => {
            const item: IDropdownItem<GeneralInquiryStatus> = {
                context: s,
                name: `GENERAL_INQUIRIES_VIEW.STATUS.${s}`,
            };
            return item;
        });
    }
}
