<td>
    {{ event.timestampUtc | convertDate }}
    <span class="timestamp"> - {{ timestampLabel }}</span>
</td>
<td>{{ 'EVENTS.PUBLICATION_TYPE.' + event.eventType | translate }}</td>
<td>
    <ng-container *ngIf="showStatsForViewingCarriedOut">
        {{ event.data.invitees.length }} {{ invitees | translate }}
        <ng-container *ngIf="event.data.invitees.length > 0">
            (<mat-icon matTooltip="{{ 'PROSPECTS.SCHEDULED' | translate }}" *ngIf="showConfirmed"
                >check_circle_filled</mat-icon
            >
            {{ numberOfConfirmed() }}
            <mat-icon matTooltip="{{ 'PROSPECTS.PENDING' | translate }}" *ngIf="showUnresponded"
                >warning_filled</mat-icon
            >
            {{ numberOfUnresponded() }}
            <mat-icon matTooltip="{{ 'PROSPECTS.DECLINED' | translate }}" *ngIf="showDeclined">cancel_filled</mat-icon>
            {{ numberOfDeclined() }}),
        </ng-container>
        <ng-container>{{ numberOfConfirmedAttendees }} {{ 'VIEWINGS_VIEW.STATUS.attendee' | translate }}</ng-container>
    </ng-container>
</td>

<!-- we should keep this to have a table rows with equal width -->
<td class="event-details-column"></td>
<td class="event-details-column"></td>

<td
    class="event-details-column"
    *ngIf="authorMessage() | translate : hasTranslationKey(authorMessage()) as translatedText"
>
    {{ translatedText }}
    <ng-container *ngIf="isUserNotAvailable"> ({{ 'SHARED_COMPONENT.DEACTIVATED' | translate }})</ng-container>
</td>
