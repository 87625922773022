import { Component, Input, inject } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';
import { CountryService } from '@services/index';

@Component({
    selector: 'apartment-stats',
    templateUrl: './apartment-stats.component.html',
})
export class ApartmentStatsComponent {
    private countryService = inject(CountryService);

    isLettableDateEnabled: boolean = this.countryService.getCurrentCountry() === 'FR';

    @Input() advert: AdvertViewModel;
}
