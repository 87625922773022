<td>
    {{ event.timestampUtc | convertDate }}
    <span class="timestamp"> - {{ timestampLabel }}</span>
</td>
<td>{{ 'EVENTS.PUBLICATION_TYPE.' + event.eventType | translate }}</td>
<td class="event-details-column">
    <ng-container *ngIf="shouldShowReasonColumn">{{ reasonLabel | translate }}</ng-container>
</td>
<td class="event-details-column">
    <ng-container *ngIf="shouldShowRentColumn">
        <ng-container *ngIf="isUnitApartmentForSale; else baseRentTranslation">
            {{ 'KEY_FACTS.cost' | translate }} {{ costLabel }}
        </ng-container>
        <ng-container #costTranslation> {{ 'EVENTS.BASERENT' | translate }} {{ baseRentLabel }} </ng-container>

        <ng-container *ngIf="shouldShowCommaBetweenRents">,</ng-container>
        {{ event | totalRent }}
    </ng-container>
</td>
<td>
    <span class="event-details-column" *ngIf="shouldShowProspectsCountColumn">
        {{ 'PROSPECTS.PROSPECTS' | translate }}, {{ event.numberOfProspects }}
    </span>
</td>
<td
    class="event-details-column"
    *ngIf="authorMessage() | translate : hasTranslationKey(authorMessage()) as translatedText"
>
    {{ translatedText }}
    <ng-container *ngIf="isUserNotAvailable"> ({{ 'SHARED_COMPONENT.DEACTIVATED' | translate }})</ng-container>
</td>
