import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class UnSubscriptionDirective implements OnDestroy {
    unsubscribe$: Subject<boolean> = new Subject<boolean>();

    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }
}
