import { Component, Input } from '@angular/core';
import { ViewingViewModel } from '@models/viewing/viewing';

@Component({
    selector: 'invitee-stats',
    templateUrl: './invitee-stats.component.html',
    styleUrls: ['./invitee-stats.component.less'],
})
export class InviteeStatsComponent {
    @Input() viewing: ViewingViewModel;

    get isViewingCarriedOutOrdHasAttendees(): boolean {
        return this.viewing.isViewingCarriedOut || this.viewing.hasConfirmedAttendees;
    }

    get showDeclined(): boolean {
        return this.viewing.invitees.some((i) => i.response === 'declined') && !this.isViewingCarriedOutOrdHasAttendees;
    }

    get showUnresponded(): boolean {
        const [, , unresponded] = this.getInviteeResponseStatistics();

        return unresponded !== 0 && !this.isViewingCarriedOutOrdHasAttendees;
    }

    get showConfirmed(): boolean {
        return (
            this.viewing.invitees.some((i) => i.response === 'confirmed') && !this.isViewingCarriedOutOrdHasAttendees
        );
    }

    get numberOfConfirmedAttendees(): number {
        return this.viewing.invitees.filter((i) => i.hasAttended && this.isViewingCarriedOutOrdHasAttendees).length;
    }

    numberOfDeclined(): string {
        const number = this.viewing.invitees.filter((i) => i.response === 'declined').length;

        if (number === 0) {
            return '';
        }

        return number.toString();
    }

    numberOfUnresponded(): string {
        const [, , unresponded] = this.getInviteeResponseStatistics();

        if (unresponded === 0) {
            return '';
        }

        return unresponded.toString();
    }

    numberOfConfirmed(): string {
        const [confirmed, ,] = this.getInviteeResponseStatistics();

        if (confirmed === 0) {
            return '';
        }

        return confirmed.toString();
    }

    private getInviteeResponseStatistics(): number[] {
        const { invitees } = this.viewing;
        const total = invitees.length;
        const confirmed = invitees.filter((i) => i.response === 'confirmed').length;
        const declined = invitees.filter((i) => i.response === 'declined').length;
        const unresponded = total - confirmed - declined;

        return [confirmed, declined, unresponded];
    }
}
