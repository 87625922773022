import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdvertType } from '@models/backend/advert';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '@services/user-settings.service';
import { IProspectModalArgs } from '../advert-prospects.component';

@Component({
    selector: 'prospect-rejection',
    templateUrl: './prospect-rejection.component.html',
    styleUrls: ['./prospect-rejection.component.less'],
})
export class ProspectRejectionComponent implements OnInit {
    private translateService = inject(TranslateService);
    dialogRef = inject<MatDialogRef<ProspectRejectionComponent>>(MatDialogRef);
    private userSettingsService = inject(UserSettingsService);
    args = inject<IProspectModalArgs>(MAT_DIALOG_DATA);

    shouldDelete: boolean = false;
    hasOffer: boolean = true;
    isReserved: boolean = true;
    currentView: 'reason' | 'loading' | 'success' | 'error' = 'reason';

    isMobileSafariBrowser: boolean = false;

    get isLoading(): boolean {
        return this.currentView === 'loading';
    }

    get sentMessage(): string {
        return this.translateService.instant('PROSPECTS.REJECTION_EMAIL_SENT');
    }

    get title(): string {
        let prefix = this.translateService.instant('PROSPECTS.REJECT_PROSPECT');

        if (this.args.advertType === AdvertType.ApartmentForSale || this.args.countryCode === 'CA') {
            const suffix = this.translateService.instant('SHARED_COMPONENT.RESERVATION');
            prefix += `, ${suffix}`;
        }

        return prefix;
    }

    get hasRejectionReasons(): boolean {
        if (this.args.countryCode === 'CA') {
            return false;
        }

        return this.args.advertType !== AdvertType.ApartmentForSale;
    }

    ngOnInit(): void {
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();
    }

    reasonChanged(isReserved: boolean): void {
        this.isReserved = isReserved;
        this.shouldDelete = !isReserved;
    }

    save(): void {
        this.dialogRef.close({ shouldDelete: this.shouldDelete, hasOffer: this.hasOffer, isReserved: this.isReserved });
    }

    close(): void {
        this.dialogRef.close();
    }
}
