import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AdvertService } from '@services/advert.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

export interface IReservationModalArgs {
    isReserved: boolean;
    advertId: string;
}

@Component({
    selector: 'reservation',
    templateUrl: 'reservation.component.html',
    styleUrls: ['reservation.component.less'],
})
export class ReservationComponent extends UnSubscriptionDirective implements OnInit {
    private advertService = inject(AdvertService);
    private translateService = inject(TranslateService);
    dialogRef = inject<MatDialogRef<ReservationComponent>>(MatDialogRef);
    args = inject<IReservationModalArgs>(MAT_DIALOG_DATA);

    currentViewType: 'loading' | 'finish' = 'loading';
    isSuccess: boolean = false;

    get isLoading(): boolean {
        return this.currentViewType === 'loading';
    }

    get message(): string {
        const tag = this.isSuccess
            ? this.args.isReserved
                ? 'SHARED_COMPONENT.RESERVATION_CANCELLED_SUCCESS'
                : 'SHARED_COMPONENT.RESERVATION_SUCCESS'
            : 'SHARED_COMPONENT.RESERVATION_ERROR';
        return this.translateService.instant(tag);
    }

    get warningBoxType() {
        return this.isSuccess ? 'success' : 'error';
    }

    ngOnInit(): void {
        this.isSuccess = false;
        this.advertService
            .patchAdvertFragment(this.args.advertId, { isReserved: !this.args.isReserved })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => this.reservationSucess(),
                () => this.reservationError(),
            );
    }

    private reservationSucess(): void {
        this.currentViewType = 'finish';
        this.isSuccess = true;
    }

    private reservationError(): void {
        this.currentViewType = 'finish';
    }
}
