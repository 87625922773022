import { IPartialAdvert } from '@components/types';
import { AdvertViewModel } from '@models/advert/advert';
import { Selectable } from '@models/common/selectable';
import { AdvertService } from '@services/advert.service';
import { BehaviorSubject, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

export function mapToTableData(advert: AdvertViewModel): Selectable<IPartialAdvert> {
    const {
        id,
        totalRent,
        numberOfBedrooms,
        numberOfBathrooms,
        streetName,
        localization: { currency },
    } = advert;
    const isSelected = false;

    return new Selectable(
        {
            advertId: id,
            streetName,
            numberOfBedrooms,
            numberOfBathrooms,
            totalRent: totalRent ? `${totalRent} ${currency}` : null,
        },
        isSelected,
    );
}

export async function loadAdvertsFromStore(
    advertService: AdvertService,
    advertsData$: BehaviorSubject<AdvertViewModel[]>,
    unsubscribe$: Subject<boolean>,
    updateStoreCallback: (adverts: AdvertViewModel[]) => void,
): Promise<void> {
    advertsData$
        .pipe(
            switchMap((advertsData) => {
                if (advertsData) {
                    return of(advertsData);
                }

                return advertService
                    .getAdvertTeasers({ streetName: null, searchQuery: null }, 0)
                    .pipe(
                        map((advertsResponseBody) =>
                            advertsResponseBody.data.map((advert) => AdvertViewModel.factory(advert)),
                        ),
                    );
            }),
            tap(updateStoreCallback),
            takeUntil(unsubscribe$),
        )
        .subscribe();
}
