import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { areOnSameDay, getInitialStartTime } from './common';

export function descriptionRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.parent.get('descriptionEn').value) {
        return Validators.required(formControl);
    }

    return null;
}

export function descriptionEnRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.parent.get('description').value) {
        return Validators.required(formControl);
    }

    return null;
}

export function endTimeRequiredValidator(formControl: AbstractControl): ValidationErrors {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.parent.get('startTime').value >= formControl.parent.get('endTime').value) {
        return { valid: false };
    }

    return null;
}

export function startTimeRequiredGroupValidator(formGroup: UntypedFormGroup): ValidationErrors {
    const now = new Date();
    const date: Date = formGroup.get('date').value;
    const startTimeContorl = formGroup.get('startTime');
    const startTime = startTimeContorl.value;

    if (areOnSameDay(date, now) && startTime < getInitialStartTime(now)) {
        startTimeContorl.setErrors({ incorrect: true });
    } else {
        startTimeContorl.setErrors(null);
    }

    return null;
}
