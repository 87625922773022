<div
    class="prospect-editor let-mat-dialog flex-footer"
    [ngClass]="{
        safariMobileHeight: isMobileSafariBrowser,
    }"
    *ngIf="prospect"
>
    <section class="head mat-dialog-title">
        <article class="subtitle">{{ title | translate }}</article>
        <button mat-icon-button clickAndKeydown (actionTriggered)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <prospect-form [prospect]="prospect" (formChange)="prospectChanged($event)"></prospect-form>

    <section class="actions">
        <button mat-flat-button clickAndKeydown (actionTriggered)="close()">
            {{ cancelButtonText | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()" [disabled]="!isValid">
            {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
        </button>
    </section>
</div>
