{
    "author": "Team Lettings Application",
    "name": "akelius-lettingapp-frontend",
    "version": "0.0.0",
    "repository": {
        "type": "git",
        "url": "git@github.com:Akelius/let-frontend.git"
    },
    "engines": {
        "node": "20.x",
        "npm": "10.x"
    },
    "scripts": {
        "setup-dev": "echo 'Nothing to set up'",
        "ng": "ng",
        "start": "NG_PERSISTENT_BUILD_CACHE=1 ng serve --configuration=local",
        "remote": "NG_PERSISTENT_BUILD_CACHE=1 ng serve",
        "shared": "NG_PERSISTENT_BUILD_CACHE=1 ng serve --disable-host-check --host 0.0.0.0",
        "show": "NG_PERSISTENT_BUILD_CACHE=1 ng serve --disable-host-check --host 0.0.0.0 --configuration dev",
        "dev": "NG_PERSISTENT_BUILD_CACHE=1 ng serve --configuration dev",
        "build": "grunt build",
        "build-dev": "grunt build:dev",
        "build-watch": "NG_PERSISTENT_BUILD_CACHE=1 ng build --watch",
        "test": "ng test --watch  --browsers=MyChromeWithoutSearchSelect",
        "test-ci": "grunt test:ci",
        "test-headless": "ng test --watch --browsers=ChromeHeadless_without_sandbox",
        "lint": "grunt lint",
        "package": "grunt package",
        "deploy": "grunt deploy",
        "release": "grunt release",
        "bundle-report": "webpack-bundle-analyzer dist/stats.json"
    },
    "private": true,
    "dependencies": {
        "@akelius/material-theme": "^18.0.0",
        "@angular/animations": "^18.2.2",
        "@angular/cdk": "^18.2.2",
        "@angular/common": "^18.2.2",
        "@angular/compiler": "^18.2.2",
        "@angular/core": "^18.2.2",
        "@angular/forms": "^18.2.2",
        "@angular/material": "^18.2.2",
        "@angular/material-moment-adapter": "^18.2.2",
        "@angular/platform-browser": "^18.2.2",
        "@angular/platform-browser-dynamic": "^18.2.2",
        "@angular/router": "^18.2.2",
        "@angular/service-worker": "^18.2.2",
        "@aspnet/signalr": "^1.0.27",
        "@auth0/auth0-angular": "^2.2.3",
        "@iplab/ngx-file-upload": "^18.0.0",
        "@ngx-translate/core": "^15.0.0",
        "@ngx-translate/http-loader": "^8.0.0",
        "@sentry/browser": "^7.109.0",
        "eslint-config-prettier": "^9.1.0",
        "moment": "^2.30.1",
        "ngx-google-analytics": "^14.0.1",
        "numeral": "^2.0.6",
        "rxjs": "^7.8.1",
        "ts-md5": "^1.3.1",
        "tslib": "^2.6.3",
        "uuid": "^9.0.1",
        "zone.js": "~0.14.10"
    },
    "devDependencies": {
        "@akelius-let/build-tasks": "^17.0.0",
        "@angular-devkit/architect": "^0.1802.2",
        "@angular-devkit/build-angular": "^18.2.2",
        "@angular-devkit/core": "^18.2.2",
        "@angular-eslint/builder": "^18.3.0",
        "@angular-eslint/eslint-plugin": "^18.3.0",
        "@angular-eslint/eslint-plugin-template": "^18.3.0",
        "@angular-eslint/schematics": "^18.3.0",
        "@angular-eslint/template-parser": "^18.3.0",
        "@angular/cli": "^18.2.2",
        "@angular/compiler-cli": "^18.2.2",
        "@commitlint/cli": "^19.4.1",
        "@commitlint/config-conventional": "^19.4.1",
        "@sentry/cli": "^2.34.1",
        "@types/fancy-log": "^2.0.2",
        "@types/grunt": "^0.4.31",
        "@types/jasmine": "^5.1.4",
        "@types/node": "^20.16.4",
        "@types/numeral": "2.0.5",
        "@types/uuid": "9.0.8",
        "@typescript-eslint/eslint-plugin": "^8.4.0",
        "@typescript-eslint/parser": "^8.4.0",
        "ansi-colors": "^4.1.3",
        "eslint": "^8.57.0",
        "eslint-plugin-import": "^2.30.0",
        "eslint-plugin-prefer-arrow": "1.2.3",
        "eslint-plugin-react": "^7.35.2",
        "fancy-log": "^2.0.0",
        "grunt": "^1.6.1",
        "grunt-contrib-compress": "^2.0.0",
        "grunt-contrib-copy": "^1.0.0",
        "husky": "^9.1.5",
        "jasmine-core": "~5.2.0",
        "jasmine-spec-reporter": "^7.0.0",
        "karma": "^6.4.4",
        "karma-chrome-launcher": "~3.2.0",
        "karma-cli": "~2.0.0",
        "karma-coverage": "^2.2.1",
        "karma-intl-shim": "^1.0.3",
        "karma-jasmine": "~5.1.0",
        "karma-jasmine-html-reporter": "^2.1.0",
        "lint-staged": "^15.2.10",
        "prettier": "^3.3.3",
        "rimraf": "^6.0.1",
        "sonarqube-scanner": "^4.2.2",
        "ts-node": "^10.9.2",
        "typescript": "^5.5.4",
        "webpack-bundle-analyzer": "^4.10.2"
    },
    "resolutions": {
        "webpack": "^5.0.0"
    }
}
