<div
    class="prospect-rejection let-mat-dialog option-modal flex-footer"
    [ngClass]="{
        safariMobileHeight: isMobileSafariBrowser,
    }"
    [ngSwitch]="currentView"
>
    <section class="head">
        <article class="subtitle">{{ title }}</article>
        <button mat-icon-button mat-dialog-close [disabled]="isLoading">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="options" *ngSwitchCase="'reason'">
        <aside class="option" *ngIf="hasRejectionReasons">
            <article class="heading">{{ 'SHARED_COMPONENT.REJECTION_REASON' | translate }}</article>
            <mat-radio-group [ngModel]="isReserved" (ngModelChange)="reasonChanged($event)">
                <mat-radio-button [value]="true">{{
                    'SHARED_COMPONENT.UNIT_IS_RESERVED' | translate
                }}</mat-radio-button>
                <mat-radio-button [value]="false">{{ 'SHARED_COMPONENT.UNIT_IS_RENTED' | translate }}</mat-radio-button>
            </mat-radio-group>
        </aside>

        <aside class="option">
            <article class="heading">{{ 'PROSPECTS.REFER_PROSPECT' | translate }}</article>
            <mat-radio-group [(ngModel)]="hasOffer">
                <mat-radio-button [value]="true">{{ 'SHARED_COMPONENT.YES' | translate }}</mat-radio-button>
                <mat-radio-button [value]="false">{{ 'SHARED_COMPONENT.NO' | translate }}</mat-radio-button>
            </mat-radio-group>
        </aside>

        <aside class="option">
            <article class="heading">{{ 'PROSPECTS.DELETE_PROSPECT' | translate }}</article>
            <mat-radio-group [(ngModel)]="shouldDelete">
                <mat-radio-button [value]="true">{{ 'SHARED_COMPONENT.YES' | translate }}</mat-radio-button>
                <mat-radio-button [value]="false">{{ 'SHARED_COMPONENT.NO' | translate }}</mat-radio-button>
            </mat-radio-group>
        </aside>
    </section>

    <section class="loading" *ngSwitchCase="'loading'">
        <loading-indicator [isPermanent]="true" [isEmbedded]="true"></loading-indicator>
    </section>

    <section class="finish" *ngSwitchCase="'success'">
        <warning-box [type]="'success'" [text]="sentMessage"></warning-box>
    </section>

    <section class="actions" [class.disabled]="isLoading">
        <button mat-flat-button mat-dialog-close clickAndKeydown (actionTriggered)="close()">
            {{ 'SHARED_COMPONENT.CANCEL' | translate }}
        </button>
        <button
            mat-flat-button
            color="primary"
            clickAndKeydown
            (actionTriggered)="save()"
            *ngIf="currentView === 'reason'"
        >
            {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
        </button>
    </section>
</div>
