import { AdvertViewModel } from '@models/advert/advert';
import { IAdvert } from '@models/backend/advert';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { AnalyticsService } from '@services/analytics.service';

export function generateReservationEvent(advert: IAdvert | AdvertViewModel, gaService: AnalyticsService): void {
    if (!advert.isReserved) {
        gaService.event(GoogleAnalyticsEvents.AdvertReserved, 'data', `advert-id:${advert.id}`);
    } else {
        gaService.event(GoogleAnalyticsEvents.AdvertReservationCancelled, 'data', `advert-id:${advert.id}`);
    }
}
