import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IMessageBoxArgs {
    translatedTitleText: string;
    translatedMessageText: string;
}

@Component({
    selector: 'message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.less'],
})
export class MessageBoxComponent {
    dialogRef = inject<MatDialogRef<MessageBoxComponent>>(MatDialogRef);
    args = inject<IMessageBoxArgs>(MAT_DIALOG_DATA);


    ok(): void {
        this.dialogRef.close(true);
    }
}
