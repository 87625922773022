import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralInquiryHelper } from '@helpers/general-inquiry.helper';
import { GeneralInquiryStatus } from '@models/backend/general-inquiry';
import { IDropdownItem } from '@models/common/dropdown-item';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '@services/country.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IGeneralInquiryModalArgs } from '../types';

@Component({
    selector: 'general-inquiry-status-after-email',
    templateUrl: 'general-inquiry-status-after-email.component.html',
    styleUrls: ['general-inquiry-status-after-email.component.less'],
})
export class GeneralInquiryStatusAfterEmailComponent extends UnSubscriptionDirective implements OnInit {
    private countryService = inject(CountryService);
    private translateService = inject(TranslateService);

    dialogRef = inject<MatDialogRef<GeneralInquiryStatusAfterEmailComponent>>(MatDialogRef);
    args = inject<IGeneralInquiryModalArgs>(MAT_DIALOG_DATA);

    options: IDropdownItem<GeneralInquiryStatus>[];
    selectedStatus: GeneralInquiryStatus;

    get successMessage(): string {
        return this.translateService.instant('GENERAL_INQUIRIES_VIEW.EDIT_SUCCESS_MESSAGE');
    }

    ngOnInit(): void {
        const country = this.countryService.getCurrentCountry();
        this.options = GeneralInquiryHelper.getGeneralInquiryStatusItems(country);
        this.selectedStatus =
            this.options.find((s) => s.context === GeneralInquiryStatus.MessageAnswered).context ||
            this.options[0].context;
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.dialogRef.close());
    }

    save(): void {
        this.dialogRef.close(this.selectedStatus);
    }

    byIndex(index: number): number {
        return index;
    }
}
