<div class="option-modal" [ngSwitch]="currentViewType">
    <section class="head">
        <article class="subtitle">{{ 'PROSPECTS.DELETE_PROSPECT' | translate }}</article>
        <button mat-icon-button mat-dialog-close [disabled]="isLoading">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="options" *ngSwitchCase="0">
        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.REJECTION_REASON' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedReason">
                <mat-option *ngFor="let item of deletionReasons; trackBy: byIndex" [value]="item.context">{{
                    item.name | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <section class="loading" *ngSwitchCase="1">
        <loading-indicator [isPermanent]="true" [isEmbedded]="true"></loading-indicator>
    </section>

    <section class="actions" *ngIf="currentViewType !== 1">
        <button mat-flat-button mat-dialog-close>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()">
            {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
        </button>
    </section>
</div>
