import { IViewing } from '@models/viewing/viewing';
import {
    ILocalization,
    IPortal,
    PublicationPortal,
    SupportedCountryCode,
    UnpublishReason,
    VacancyStatus,
} from './common';
import { IContactDetails } from './contacts';
import { IImage } from './images';
import { IKeyfactLibrary } from './keyfacts';
import { IProspectSource } from './prospects';

export interface IBaseAdvert {
    id: string;
    internalId: string;
    costCenterCode: string;
    countryCode: SupportedCountryCode;
    unitCode: string;
    localization: ILocalization;
    type: AdvertType;
    buildingOrGroundAreaCode: string;
    lastPublishedDate?: Date | null;
    lastUnpublishedDate?: Date | null;
    lastRepublishedDate?: Date | null;
    lastUnpublishedReason?: UnpublishReason | null;
    lastPublicationResults?: IAdvertPublicationResult[];
    contactDetails?: IContactDetails;
    assignedContactFirstName: string | null;
    assignedContactLastName: string | null;
    assignedContactPhoneNumber: string | null;
    assignedContactEmail: string | null;
    isPublished: boolean;
    numberOfNewProspects: number;
    numberOfUpcomingViewings: number;
    numberOfViewingsWithConfirmedAttendees: number;
    numberOfOpenViewings: number;
    numberOfProspects: number;
    teaserImageUrl?: string;
    direction?: string;
    comment?: string;
    address: IPostalAddress;
    documents: IImage[];
    contract?: IContract;
    vacancyStatus: VacancyStatus | null;
    lettableDate: Date | null;
    virtualTourUrl?: string;
    isReserved?: boolean;
    lastReservationDate?: Date;
    useServiceCenterPhoneNumber?: boolean;
    isPhoneNumberShownOnPortal?: boolean;
    prospectLimit: number | null;
}

export interface IAdvert extends IBaseAdvert {
    keyfacts: IKeyfactLibrary;
}

export interface IFlattenedAdvert extends IBaseAdvert {
    keyfacts: IFlatKeyfacts;
}

type KeyfactValueType = string | number | Date | boolean | null | undefined;

export type IFlatKeyfacts = {
    [key: string]: KeyfactValueType;
};

export enum AdvertType {
    ApartmentForSale = 'apartmentForSale',
    Commercial = 'commercial',
    Living = 'living',
    Parking = 'parking',
    HuntingArea = 'huntingArea',
    SummerHouse = 'summerHouse',
    AgriculturalArea = 'agriculturalArea',
}

export interface IAdvertPublicationResult {
    isSuccess: boolean;
    statusCode: number;
    portalName: PublicationPortal;
    reason?: string;
    url?: string;
}

export interface IPostalAddress {
    streetName?: string;
    houseNumber?: string;
    postalCode?: string;
    borough?: string;
    branch?: string;
    city?: string;
    state?: string;
    latitude?: number;
    longitude?: number;
    area?: string;
    countryCode?: string;
    region?: string;
}

export interface IContract {
    baseRent?: number;
    contractEndDate?: Date | null;
    contractStartDate?: Date | null;
    moveOutDate: Date | null;
}

export interface IBaseEvent {
    id: string;
    internalId: string;
    eventType: AdvertEventType;
    countryCode: string;
    advertId: string;
    data: IFlattenedAdvert | IViewing;
    timestampUtc: Date;
    author: Author;
    lastName?: string;
    firstName?: string;
    isUserDeleted?: boolean;
}
export interface IAdvertEvent extends IBaseEvent {
    advertType: AdvertType;
    costCenterCode: string;
    unitCode: string;
    data: IFlattenedAdvert;
    numberOfProspects?: number;
    prospectSource?: IProspectSource[];
    unpublishReason?: UnpublishReason;
    portals: IPortal[];
}

export interface IViewingEvent extends IBaseEvent {
    advertId: string;
    advertType: AdvertType;
    data: IViewing;
}

export type AdvertEventType =
    | 'AdvertPublished'
    | 'AdvertRepublished'
    | 'AdvertUnpublished'
    | 'ReservationCancelled'
    | 'ReservationCreated'
    | 'ViewingDeclined'
    | 'ViewingCarriedOut'
    | 'ViewingScheduled';

export enum AdvertFilterStatus {
    Bookmarked = 'bookmarked',
    HasProspects = 'hasProspects',
    Published = 'published',
    Reserved = 'reserved',
    Unpublished = 'unpublished',
}

type UserAuthor = {
    type: 'user';
    userId: string;
};
type MachineAuthor = {
    type: 'machine';
};
type LegacyAuthor = {
    type: 'legacy';
};

export type Author = UserAuthor | MachineAuthor | LegacyAuthor;
export interface IEvent<TData> {
    id: string;
    advertId: string;
    eventType: string;
    countryCode: string;
    timestampUtc: Date;
    data: TData;
    author: Author;
    ttl?: number;
}
