import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'copy-filter',
    templateUrl: './copy-filter.component.html',
    styleUrls: ['./copy-filter.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyFilterComponent extends UnSubscriptionDirective implements OnInit {
    @Output() advertsFilterEvent = new EventEmitter<ITeaserFilterOptions>();

    form: UntypedFormGroup;
    freeTextSearchForm: FormControl<string> = new FormControl();
    streetSearchForm: UntypedFormControl = new UntypedFormControl();

    showStreetSearchLoader: boolean = false;
    selectedFilterOptions: ITeaserFilterOptions = {
        streetName: null,
        searchQuery: null,
    };

    ngOnInit(): void {
        this.freeTextSearchForm.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.unsubscribe$))
            .subscribe((search) => {
                this.selectedFilterOptions.searchQuery = search;
                this.advertsFilterEvent.emit(this.selectedFilterOptions);
            });
    }

    clearUnitSearchForm(): void {
        if (!this.freeTextSearchForm) {
            return;
        }

        this.freeTextSearchForm.setValue(null);
        this.advertsFilterEvent.emit(this.selectedFilterOptions);
    }

    onStreetSearchCleaned() {
        const streetData: IAutocompleteData = {
            id: null,
            label: null,
        };

        this.streetSearchForm.setValue(streetData);
        this.selectedFilterOptions.streetName = null;
        this.advertsFilterEvent.emit(this.selectedFilterOptions);
    }

    onStreetSelection(id: string) {
        const streetData: IAutocompleteData = {
            id,
            label: id,
        };

        this.streetSearchForm.setValue(streetData);
        this.selectedFilterOptions.streetName = streetData.id;
        this.advertsFilterEvent.emit(this.selectedFilterOptions);
    }
}
