/* eslint-disable brace-style */
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NgControl,
    UntypedFormControl,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { areAllObjectValuesNotNull } from '@helpers/are-object-property-empty';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IAutocompleteData, containsIdValidation } from './autocomplete';

@Component({
    selector: 'autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<T>
    extends UnSubscriptionDirective
    implements OnInit, ControlValueAccessor, OnChanges
{
    private controlDir = inject(NgControl, { optional: true, self: true });
    private translateService = inject(TranslateService);

    @Input() placeholder = '';

    @Input() options: IAutocompleteData<T>[];

    @Input() clearSelection: boolean = true;

    @Input() disable: boolean = false;

    @Input() isRequired: boolean = true;

    @Input() translateLabels: boolean = true;

    @Input() showLoading: boolean = false;

    @Input() useHttp: boolean = false;

    @Output() optionSelected = new EventEmitter<IAutocompleteData<T>>();

    @Output() resetClicked = new EventEmitter();

    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
    inputAutoComplete: MatAutocompleteTrigger;

    options$: Observable<IAutocompleteData<T>[]>;

    // Inner form control to link input text changes to mat autocomplete
    searchControl: UntypedFormControl = new UntypedFormControl('', this.validators);

    control: AbstractControl;

    noResults = false;

    notSelected = {
        id: null,
        label: this.translateService.instant('SHARED_COMPONENT.NOT_SELECTED'),
    };

    get isEmptySearch(): boolean {
        if (!areAllObjectValuesNotNull(this.searchControl.value)) {
            return true;
        }

        return this.searchControl.value ? Object.keys(this.searchControl.value).length === 0 : true;
    }

    get doesNotExist(): boolean {
        if (this.isEmptySearch) {
            return false;
        }

        return this.options.some((option) => option.label.includes(this.searchControl.value.label));
    }

    get showCloseBtn(): boolean {
        return this.clearSelection && !this.isEmptySearch;
    }

    prepareValue(option: IAutocompleteData<T>): IAutocompleteData<T> {
        const preparedOption = { ...option };
        if (this.translateLabels) {
            return { ...preparedOption, label: this.translateService.instant(option.label) };
        }

        return preparedOption;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options'] && this.useHttp) {
            this.options = changes['options'].currentValue;
            this.options$ = of(this.options);
        }

        if (changes['showLoading']) {
            if (changes['showLoading'].currentValue) {
                this.showLoading = true;
            } else {
                this.showLoading = false;
            }
        }

        if (changes['disable']) {
            this.disable = changes['disable'].currentValue;
            this.setDisabledState();
        }
    }

    constructor() {
        super();
        if (this.controlDir) {
            this.controlDir.valueAccessor = this;
        }
    }

    ngOnInit() {
        if (this.controlDir) {
            this.control = this.controlDir.control;

            if (this.control.errors !== null) {
                const validators = this.control.validator
                    ? [this.control.validator, this.searchControl.validator]
                    : this.searchControl.validator;
                this.control.setValidators(validators);
            } else if (!this.control.value || !this.isRequired) {
                this.control.setValidators(null);
                this.searchControl.setValidators(null);
            }

            // to set default value please set value as object  with id and label
            if (this.control.value) {
                const prepareValue = this.prepareValue(this.control.value);
                this.searchControl.setValue(prepareValue);
            } else {
                this.searchControl.setValue(this.control.value);
            }

            this.control.updateValueAndValidity({ emitEvent: false });
        }

        this.searchControl.markAsTouched();

        this.setDisabledState();

        if (!this.useHttp) {
            this.search();
        } else {
            this.options$ = of(this.options);
        }
    }

    search(): void {
        this.options$ = this.searchControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            switchMap((search) => {
                // force user to select item from the list
                if (search && this.isRequired) {
                    const isSelectedOption = this.options.find((option) => option.id === search.id);
                    if (!isSelectedOption) {
                        this.searchControl.setErrors({ required: true });
                        // update parent validator
                        this.control.setErrors({ required: true });
                    }
                }

                if (search === null) {
                    timer(0)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(() => {
                            this.inputAutoComplete.openPanel();
                        });

                    return of(this.options);
                }

                if (typeof search === 'string') {
                    return of(this.options).pipe(
                        map((response) =>
                            response.filter((p) => p.label.includes(search) || p.label.toLowerCase().includes(search)),
                        ),
                    );
                }

                return of([]);
            }),
            takeUntil(this.unsubscribe$),
        );
    }

    writeValue(obj: unknown): void {
        return obj && this.searchControl.setValue(obj);
    }

    registerOnChange(fn: () => NonNullable<unknown>): void {
        this.onChange = fn;
        this.searchControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(fn);
    }

    registerOnTouched(fn: () => NonNullable<unknown>): void {
        this.onTouched = fn;
    }

    setDisabledState?(): void {
        return this.disable ? this.searchControl.disable() : this.searchControl.enable();
    }

    onTouched() {}

    onChange() {}

    displayFn(result: IAutocompleteData): string | undefined {
        return result ? result.label : undefined;
    }

    reset(): void {
        if (this.useHttp) {
            this.options = [];
            this.options$ = of([]);
        }

        this.searchControl.reset();
        this.searchControl.markAsTouched();
        this.resetClicked.emit();
    }

    onOptionSelected(option: IAutocompleteData<T>): void {
        this.optionSelected.emit(option);
    }

    byOptionId(_index: number, option: IAutocompleteData<T>): T {
        return option.id;
    }

    private get validators(): ValidatorFn[] {
        return [Validators.required, containsIdValidation];
    }
}
