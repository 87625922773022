import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { COUNTRY_CODE_PHONE_REGEX, EMAIL_REGEX, PHONE_REGEX } from '@validators/regex';

export function emailOrPhoneValidator(formGroup: FormGroup) {
    const emailControl = formGroup.get('email');
    const phoneControl = formGroup.get('phone');

    const isValidEmail = EMAIL_REGEX.test(emailControl.value);
    const isValidCountryCodePhone = COUNTRY_CODE_PHONE_REGEX.test(phoneControl.value);

    resetValidators(emailControl, EMAIL_REGEX);
    resetValidators(phoneControl, PHONE_REGEX);

    if (emailControl && phoneControl) {
        if (emailControl.valid) {
            clearValidation(phoneControl);
        } else if (phoneControl.valid) {
            clearValidation(emailControl);
        }
    }

    if (!isValidCountryCodePhone && phoneControl.value) {
        resetValidators(phoneControl, PHONE_REGEX);
    }

    if (!isValidEmail && emailControl.value) {
        resetValidators(emailControl, EMAIL_REGEX);
    }

    return null;
}

function resetValidators(control: AbstractControl, pattern: RegExp): void {
    control.setValidators([Validators.required, Validators.pattern(pattern)]);
    control.updateValueAndValidity({ onlySelf: true });
}

function clearValidation(control: AbstractControl): void {
    control.clearValidators();
    control.updateValueAndValidity({ onlySelf: true });
}
