import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { UrlQueryParams } from '@components/teaser-filter/url-param-query';
import { IIdJwtToken } from '@models/auth/id-token';
import { SupportedCountryCode } from '@models/backend/common';
import { IUserDataResponseBody } from '@models/backend/responses';
import { IUserSettings } from '@models/backend/user-settings';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { TenantName } from '@models/shared';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { AnalyticsService } from './analytics.service';
import { BrowserWindowService } from './browser-window.service';
import { CountryService } from './country.service';

export const SupportedTenants = ['Akelius', 'Heimstaden'] as const;
export type Tenant = (typeof SupportedTenants)[number];
export type DevicePlatform = 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
    private httpClient = inject(HttpClient);
    private authService = inject(AuthService);
    private browserWindowService = inject(BrowserWindowService);
    private gaService = inject(AnalyticsService);
    private countryService = inject(CountryService);

    private readonly localStorageKeys = {
        bookmarks: 'bookmarks',
        bookmarkedProspects: 'bookmarkedProspects',
        countryCode: 'countryCode',
        userCultureInfo: 'userCultureInfo',
        teaserFilter: 'teaserFilter',
    };

    getDecodedToken(): Observable<User> {
        return this.authService.user$;
    }

    getCurrentTenantBy(countryCode: SupportedCountryCode): Tenant {
        const heimstadenCountries: SupportedCountryCode[] = ['DE'];
        const tenant: Tenant = heimstadenCountries.includes(countryCode) ? 'Heimstaden' : 'Akelius';

        return tenant;
    }

    replaceUserEmailByTenantName(token: IIdJwtToken): string {
        const countryCode = this.countryService.getCurrentCountry();
        const tenantNames = token['https://akelius.com/claims/tenant-names'];
        const tenant: Tenant = this.getCurrentTenantBy(countryCode);
        let email = token.email;

        if (tenant === 'Heimstaden' && tenantNames.includes('heimstaden')) {
            const akeliusDomainRegex = /akelius.[de]+/;

            switch (countryCode) {
                case 'DE':
                    email = email.replace(akeliusDomainRegex, 'heimstaden.de');
                    break;
            }
        }
        return email;
    }

    getUserName(): Observable<string> {
        return this.getDecodedToken().pipe(
            map((token: IIdJwtToken) => {
                if (!token) {
                    return '';
                }

                return token.given_name && token.family_name
                    ? `${token.given_name} ${token.family_name}`
                    : this.replaceUserEmailByTenantName(token);
            }),
        );
    }

    getUserEmail(): Observable<string> {
        return this.getDecodedToken().pipe(
            map((token: IIdJwtToken) => {
                if (!token) {
                    return '';
                }

                return token.email ? this.replaceUserEmailByTenantName(token).toLocaleLowerCase() : '';
            }),
        );
    }

    getTenantNames(): Observable<TenantName[]> {
        return this.getDecodedToken().pipe(
            map((token: IIdJwtToken) => {
                if (!token) {
                    return '';
                }
                const tenantNames = token['https://akelius.com/claims/tenant-names'];
                return tenantNames;
            }),
        );
    }

    getHashedUserId(): Observable<string> {
        return this.getDecodedToken().pipe(
            map((token: IIdJwtToken) => {
                const userId = token['https://akelius.com/claims/userid'];
                return Md5.hashStr(userId) as string;
            }),
        );
    }

    getUserSettings(): Observable<IUserDataResponseBody> {
        return this.getHashedUserId().pipe(
            switchMap((userHash) => {
                return this.httpClient.get<IUserDataResponseBody>(`users/${userHash}/settings`);
            }),
        );
    }

    saveUserSettings(settings: IUserSettings): Observable<IUserSettings> {
        return this.getHashedUserId().pipe(
            switchMap((userHash) => {
                return this.httpClient.put<IUserSettings>(`users/${userHash}/settings`, settings);
            }),
        );
    }

    updateBookmarks(): Observable<IUserSettings> {
        return this.getHashedUserId().pipe(
            switchMap((userHash) => {
                const bookmarks = JSON.parse(localStorage.getItem(this.localStorageKeys.bookmarks)) as string[];
                return this.httpClient.patch<IUserSettings>(`users/${userHash}/settings`, {
                    bookmarks: bookmarks,
                });
            }),
        );
    }

    updateBookmarkedProspects(): Observable<object> {
        return this.getHashedUserId().pipe(
            switchMap((userHash) => {
                const bookmarkedProspects = JSON.parse(
                    localStorage.getItem(this.localStorageKeys.bookmarkedProspects),
                ) as string[];
                return this.httpClient.patch(`users/${userHash}/settings`, {
                    bookmarkedProspects,
                });
            }),
        );
    }

    isAdvertBookmarked(advertId: string): boolean {
        const bookmarkedAdverts = JSON.parse(localStorage.getItem(this.localStorageKeys.bookmarks)) as string[];
        return bookmarkedAdverts ? bookmarkedAdverts.includes(advertId) : false;
    }

    removeBookmark(advertId: string): void {
        this.gaService.event(GoogleAnalyticsEvents.AdvertBookmarkRemoved, 'data', `advert_id:${advertId}`);

        const bookmarkedAdverts = JSON.parse(localStorage.getItem(this.localStorageKeys.bookmarks)) as string[];
        const indexToRemove = bookmarkedAdverts.indexOf(advertId);
        if (indexToRemove > -1) {
            bookmarkedAdverts.splice(indexToRemove, 1);
        }
        localStorage.setItem(this.localStorageKeys.bookmarks, JSON.stringify(bookmarkedAdverts));
    }

    addBookmark(advertId: string): void {
        this.gaService.event(GoogleAnalyticsEvents.AdvertBookmarkAdded, 'data', `advert_id:${advertId}`);

        const bookmarkedAdverts = JSON.parse(localStorage.getItem(this.localStorageKeys.bookmarks)) as string[];
        if (bookmarkedAdverts.includes(advertId)) {
            return;
        }

        bookmarkedAdverts.push(advertId);
        localStorage.setItem(this.localStorageKeys.bookmarks, JSON.stringify(bookmarkedAdverts));
    }

    isProspectBookmarked(prospectId: string): boolean {
        const bookmarkedProspects = JSON.parse(
            localStorage.getItem(this.localStorageKeys.bookmarkedProspects),
        ) as string[];
        return bookmarkedProspects ? bookmarkedProspects.includes(prospectId) : false;
    }

    removeProspectBookmark(prospectId: string): void {
        const bookmarkedProspects = JSON.parse(
            localStorage.getItem(this.localStorageKeys.bookmarkedProspects),
        ) as string[];
        const indexToRemove = bookmarkedProspects.indexOf(prospectId);
        if (indexToRemove > -1) {
            bookmarkedProspects.splice(indexToRemove, 1);
        }
        localStorage.setItem(this.localStorageKeys.bookmarkedProspects, JSON.stringify(bookmarkedProspects));
    }

    addProspectBookmark(advertId: string): void {
        const bookmarkedProspects = JSON.parse(
            localStorage.getItem(this.localStorageKeys.bookmarkedProspects),
        ) as string[];
        if (bookmarkedProspects.includes(advertId)) {
            return;
        }

        bookmarkedProspects.push(advertId);
        localStorage.setItem(this.localStorageKeys.bookmarkedProspects, JSON.stringify(bookmarkedProspects));
    }

    getOperatingSystem(): DevicePlatform {
        // https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
        const browserWindow = this.browserWindowService.getWindowObject();

        const userAgent = browserWindow.navigator.userAgent;
        const platform = browserWindow.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    isMobileDevice(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    clearTeaserFilter(): void {
        const teaserFilterValue = localStorage.getItem(this.localStorageKeys.teaserFilter);
        if (teaserFilterValue) {
            localStorage.removeItem(this.localStorageKeys.teaserFilter);
        }
    }

    saveTeaserFilter(newfilter: ITeaserFilterOptions | UrlQueryParams): void {
        const current = this.getTeaserFilter();
        const result = { ...current, ...newfilter };

        // clear search query since it's not meant to be persisted
        result.searchQuery = '';
        localStorage.setItem(this.localStorageKeys.teaserFilter, JSON.stringify(result));
    }

    hasTeaserFilter(): boolean {
        const value = localStorage.getItem(this.localStorageKeys.teaserFilter);
        return !!value;
    }

    isStandaloneDisplayMode(): boolean {
        return this.browserWindowService.matchMedia('(display-mode: standalone)');
    }

    isMobileSafariBrowser(): boolean {
        const { userAgent } = this.browserWindowService.getWindowObject().navigator;
        const isIphone = /(iPhone|iPad)/i.test(userAgent);
        const isSafariBrowser = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') <= -1;

        return (isSafariBrowser && isIphone) || this.isStandaloneDisplayMode();
    }

    getTeaserFilter(): ITeaserFilterOptions {
        const value = localStorage.getItem(this.localStorageKeys.teaserFilter);

        if (value) {
            return JSON.parse(value);
        } else {
            return {
                advertType: null,
                assignedContactFilter: null,
                vacancyStatuses: null,
                searchQuery: '',
                status: null,
                sortBy: 'moveoutdate',
                sortDirection: 'ASC',
                region: null,
                streetName: null,
                unitCode: null,
                branch: null,
            };
        }
    }
}
