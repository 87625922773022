<div class="advert-details" *ngIf="advert">
    <key-fact-section
        *ngFor="let section of advert.keyfacts.sections; trackBy: byIndex"
        [section]="section"
        [countryCode]="advert.keyfacts.countryCode"
        [region]="advert.keyfacts.region"
        [advertType]="advert.keyfacts.advertType"
        [advertId]="advert.id"
        (sectionChanged)="sectionChanged($event)"
    >
    </key-fact-section>

    <section class="required-hint">{{ 'SHARED_COMPONENT.REQUIRED_HINT' | translate }}</section>

    <contact-details-section *ngIf="contacts" [advert]="advert" [contacts]="contacts"></contact-details-section>

    <images-section id="imageSection" [advert]="advert"></images-section>

    <virtual-viewing-section [advert]="advert"></virtual-viewing-section>

    <publish-bar
        [advert]="advert"
        (unpublished)="unpublish()"
        (reservationChanged)="reloadAfterReservationChanged()"
    ></publish-bar>
</div>
