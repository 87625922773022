<div #scroller>
    <div class="header">
        <div>{{ 'SHARED_COMPONENT.COPY_TO_ADVERT.UNIT_ID' | translate }}</div>
        <div>{{ 'SHARED_COMPONENT.COPY_TO_ADVERT.STREET' | translate }}</div>
        <div>{{ 'SHARED_COMPONENT.COPY_TO_ADVERT.DETAILS' | translate }}</div>
        <div>{{ 'SHARED_COMPONENT.COPY_TO_ADVERT.RENT' | translate }}</div>
    </div>
    <div
        *ngFor="let item of partialAdvertList; let last = last; let index = index; trackBy: byAdvertId"
        class="row"
        [class.highlighted]="item.isSelected"
        infinityScrolling
        (elementVisible)="onScroll(last)"
        [isTargetElement]="last"
    >
        <div>
            <mat-checkbox
                [checked]="item.isSelected"
                (change)="onCheckboxChange($event, index)"
                [disabled]="isDisabled(item)"
            ></mat-checkbox>
            {{ item.context.advertId }}
        </div>
        <div>
            {{ item.context.streetName }}
        </div>
        <br />
        <div>
            <ng-container *ngIf="item.context.numberOfBedrooms; else noBedrooms"
                >{{ item.context.numberOfBedrooms }}
                {{ 'SHARED_COMPONENT.COPY_TO_ADVERT.BED' | translate }}</ng-container
            >
            <ng-container *ngIf="item.context.numberOfBathrooms; else noBathrooms"
                >, {{ item.context.numberOfBathrooms }}
                {{ 'SHARED_COMPONENT.COPY_TO_ADVERT.BATH' | translate }}</ng-container
            >
            <ng-template #noBedrooms>-</ng-template>
            <ng-template #noBathrooms></ng-template>
        </div>
        <div>
            {{ item.context.totalRent || '-' }}
        </div>
    </div>
</div>
