import { sortByAscendingForCharacters } from '@helpers/string.helper';
import { ISelect } from '@models/shared';

export const DETAILS_OPTION: ISelect[] = [
    {
        label: 'amenities',
        id: 'amenities',
    },
    {
        label: 'apartment-door',
        id: 'apartment_door',
    },
    {
        label: 'attic',
        id: 'attic',
    },
    {
        label: 'balcony',
        id: 'balcony',
    },
    {
        label: 'basement',
        id: 'basement',
    },
    {
        label: 'bathroom',
        id: 'bathroom',
    },
    {
        label: 'bedroom',
        id: 'bedroom',
    },
    {
        label: 'business-space',
        id: 'business_space',
    },
    {
        label: 'ceiling',
        id: 'ceiling',
    },
    {
        label: 'closet',
        id: 'closet',
    },
    {
        label: 'courtyard',
        id: 'courtyard',
    },
    {
        label: 'detail',
        id: 'detail',
    },
    {
        label: 'detector',
        id: 'detector',
    },
    {
        label: 'dining-room',
        id: 'dining_room',
    },
    {
        label: 'room',
        id: 'room',
    },
    {
        label: 'doors',
        id: 'doors',
    },
    {
        label: 'electrical-device',
        id: 'electrical_device',
    },
    {
        label: 'elevator',
        id: 'elevator',
    },
    {
        label: 'energy-certificate',
        id: 'energy_certificate',
    },
    {
        label: 'entrance',
        id: 'entrance',
    },
    {
        label: 'entrance-area',
        id: 'entrance_area',
    },
    {
        label: 'entrance-door',
        id: 'entrance_door',
    },
    {
        label: 'facade',
        id: 'facade',
    },
    {
        label: 'floorplan',
        id: 'floorplan',
    },
    {
        label: 'front-desk',
        id: 'front_desk',
    },
    {
        label: 'garage',
        id: 'garage',
    },
    {
        label: 'garbage-area',
        id: 'garbage_area',
    },
    {
        label: 'garbage-room',
        id: 'garbage_room',
    },
    {
        label: 'garden',
        id: 'garden',
    },
    {
        label: 'guest-toilet',
        id: 'guest_toilet',
    },
    {
        label: 'gym',
        id: 'gym',
    },
    {
        label: 'hall',
        id: 'hall',
    },
    {
        label: 'in-unit-laundry',
        id: 'in_unit_laundry',
    },
    {
        label: 'hallway',
        id: 'hallway',
    },
    {
        label: 'kitchen',
        id: 'kitchen',
    },
    {
        label: 'laundry-room',
        id: 'laundry_room',
    },
    {
        label: 'lighting',
        id: 'lighting',
    },
    {
        label: 'living-room',
        id: 'living_room',
    },
    {
        label: 'lobby',
        id: 'lobby',
    },
    {
        label: 'lounge',
        id: 'lounge',
    },
    {
        label: 'mailboxes',
        id: 'mailboxes',
    },
    {
        label: 'meter',
        id: 'meter',
    },
    {
        label: 'neighboring-property',
        id: 'neighboring_property',
    },
    {
        label: 'park',
        id: 'park',
    },
    {
        label: 'parking',
        id: 'parking',
    },
    {
        label: 'playground',
        id: 'playground',
    },
    {
        label: 'pool',
        id: 'pool',
    },
    {
        label: 'promotion',
        id: 'promotion',
    },
    {
        label: 'sauna',
        id: 'sauna',
    },
    {
        label: 'shopping',
        id: 'shopping',
    },
    {
        label: 'square',
        id: 'square',
    },
    {
        label: 'stairwell',
        id: 'stairwell',
    },
    {
        label: 'storage-room',
        id: 'storage_room',
    },
    {
        label: 'terrace',
        id: 'terrace',
    },
    {
        label: 'transportation',
        id: 'transportation',
    },
    {
        label: 'view',
        id: 'view',
    },
    {
        label: 'walk-in-closet',
        id: 'walk_in_closet',
    },
    {
        label: 'wardrobe',
        id: 'wardrobe',
    },
    {
        label: 'water',
        id: 'water',
    },
    {
        label: 'window',
        id: 'window',
    },
    {
        label: 'den',
        id: 'den',
    },
    {
        label: 'foyer',
        id: 'foyer',
    },
    {
        label: 'patio',
        id: 'patio',
    },
].sort((a, b) => sortByAscendingForCharacters(a.label, b.label));

export const FOREST_DETAILS_OPTION: ISelect[] = [
    {
        label: 'wood',
        id: 'wood',
    },
    {
        label: 'lake',
        id: 'lake',
    },
    {
        label: 'road',
        id: 'road',
    },
    {
        label: 'fence',
        id: 'fence',
    },
    {
        label: 'lodge',
        id: 'lodge',
    },
    {
        label: 'tower',
        id: 'tower',
    },
    {
        label: 'game',
        id: 'game',
    },
    {
        label: 'mire',
        id: 'mire',
    },
    {
        label: 'grassland',
        id: 'grassland',
    },
    {
        label: 'field',
        id: 'field',
    },
].sort((a, b) => sortByAscendingForCharacters(a.label, b.label));

export const TAGS_OPTION: ISelect[] = [
    {
        id: 'exterior',
        label: 'IMAGE_TAGS.exterior',
    },
    {
        id: 'interior',
        label: 'IMAGE_TAGS.interior',
    },
    {
        id: 'surroundings',
        label: 'IMAGE_TAGS.surroundings',
    },
];
