import { SupportedCountryCode } from '@models/backend/common';
import { ProspectStatus } from '@models/backend/prospects';
import { EnumHelper } from './enum.helper';

export class ProspectHelper {
    static getStatusOptions(countryCode: SupportedCountryCode): ProspectStatus[] {
        const allOptions = EnumHelper.toArray<ProspectStatus>(ProspectStatus);

        switch (countryCode) {
            case 'GB':
            case 'CY':
                return allOptions;
            case 'DE':
                const notAllowed: ProspectStatus[] = [
                    ProspectStatus.HoldingDepositPaid,
                    ProspectStatus.InReferencing,
                    ProspectStatus.UnderSigning,
                ];
                return allOptions.filter((_) => !notAllowed.includes(_));
            case 'SE':
                const notAllowViewingOptions: ProspectStatus[] = [
                    ProspectStatus.ViewingAttended,
                    ProspectStatus.ViewingDeclined,
                    ProspectStatus.AbsentOnViewing,
                    ProspectStatus.InvitedToViewing,
                    ProspectStatus.ViewingScheduled,
                ];
                return allOptions.filter((_) => !notAllowViewingOptions.includes(_));
            default:
                return allOptions.filter((_) => _ !== 'holdingDepositPaid');
        }
    }
}
