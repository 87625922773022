import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeHelper } from '@helpers/time.helper';
import { AdvertType } from '@models/backend/advert';
import { SupportedCountryCode } from '@models/backend/common';
import { IKeyfact, IKeyfactSection } from '@models/backend/keyfacts';
import { IKeyfactSectionUpdatedResponseBody } from '@models/backend/responses';
import { ValidatedSection } from '@models/common/validated-section';
import { TranslateService } from '@ngx-translate/core';
import { ConvertDatePipe } from '@pipes/convert-date.pipe';
import { NumeralPipe } from '@pipes/numeral.pipe';
import { AdvertService } from '@services/advert.service';
import { CustomValidators } from '@validators/custom-validator';
import moment from 'moment';
import Numeral from 'numeral';
import { Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { TimePickerDialogComponent } from '../../shared';

type ShowFirstDayOfMonthList = 'construction-work-on-facade-until';

@Component({
    selector: 'key-fact-section',
    templateUrl: 'key-fact-section.component.html',
})
export class KeyFactSectionComponent extends ValidatedSection implements OnInit, OnDestroy {
    private advertService = inject(AdvertService);
    private convertDatePipe = inject(ConvertDatePipe);
    private translateService = inject(TranslateService);
    private router = inject(Router);
    private dialog = inject(MatDialog);

    @Input() section: IKeyfactSection;
    @Input() countryCode: SupportedCountryCode;
    @Input() region: string | null;
    @Input() advertType: AdvertType;

    @Input() isPreview: boolean = false;
    @Input() advertId: string;

    @Output()
    sectionChanged = new EventEmitter<IKeyfactSection>();

    form: UntypedFormGroup;
    isLoading: boolean = false;
    schemaName: string;
    hintTranslationKey: string;

    private mappedKeyfacts: IKeyfact[] = [];
    private keyFactChangesSubscriptions: Subscription[] = [];

    numeralPipe: NumeralPipe = new NumeralPipe();

    get isValid(): boolean {
        return this.form.valid;
    }

    get validationIconClass(): string {
        return this.isValid ? 'valid' : 'invalid';
    }

    get hasAllEmptyValues(): boolean {
        return this.mappedKeyfacts.every((kf) => !this.isPreviewKeyfactVisible(kf)) && this.isValid;
    }

    ngOnInit(): void {
        if (!this.section || !this.advertId) {
            console.error('Cannot render generic section without the section object or the advert ID.');
            return;
        }

        if (!this.isPreview) {
            this.schemaName = this.generateSchemaNameByCountry();
        }

        this.setUpFormControls();
    }

    ngOnDestroy(): void {
        this.cleanUpSubscriptions();
    }

    private setUpFormControls(): void {
        this.cleanUpSubscriptions();

        this.section.subsections.forEach((ss) => {
            this.mappedKeyfacts = [...this.mappedKeyfacts, ...ss.keyfacts];
        });

        this.form = new UntypedFormGroup(this.generateFormControls());
        this.mappedKeyfacts.forEach((kf) => {
            const sub = this.form
                .get(kf.id)
                .valueChanges.pipe(
                    distinctUntilChanged(),
                    map((val) => {
                        if (this.isDate(kf) && this.shouldRoundToFirstDayOfMonth(kf.id)) {
                            const startDayOfMonth = moment(val).startOf('month');
                            return startDayOfMonth;
                        }

                        return val;
                    }),
                    takeUntil(this.unsubscribe$),
                )
                .subscribe((val) => this.updateKeyfact(kf, val));
            this.keyFactChangesSubscriptions.push(sub);
        });

        this.form.markAllAsTouched();
    }

    private cleanUpSubscriptions(): void {
        this.keyFactChangesSubscriptions.forEach((s) => s.unsubscribe());
        this.mappedKeyfacts.splice(0, this.mappedKeyfacts.length);
        this.keyFactChangesSubscriptions.splice(0, this.keyFactChangesSubscriptions.length);
    }

    private updateKeyfact(kf: IKeyfact, newValue: unknown): void {
        if (kf.isReadOnly || kf.value === newValue) {
            return;
        }

        kf.value = this.isNumeric(kf) ? Numeral(newValue).value() : newValue;
        this.isLoading = true;
        this.advertService
            .saveKeyFact(kf, this.advertId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.saveSuccess(res, kf.id),
                () => this.saveError(),
            );
    }

    private saveSuccess(res: IKeyfactSectionUpdatedResponseBody, lastKeyfactId: string): void {
        this.isLoading = false;
        this.section = res.data;
        this.sectionChanged.emit(res.data);

        this.setUpFormControls();

        timer(0)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.focusNextKeyfact(lastKeyfactId);
            });
    }

    private focusNextKeyfact(lastKeyfactId: string): void {
        const kf = this.mappedKeyfacts.find((mkf) => mkf.id === lastKeyfactId);
        const currentIndex = this.mappedKeyfacts.indexOf(kf);
        if (currentIndex === this.mappedKeyfacts.length - 1) {
            // last keyfact
            return;
        }

        const isNextEditableKeyfactPredicate = (_: IKeyfact) =>
            this.mappedKeyfacts.indexOf(_) > currentIndex && !_.isReadOnly;
        const nextKeyfact = this.mappedKeyfacts.find((mkf) => isNextEditableKeyfactPredicate(mkf));
        if (!nextKeyfact) {
            // there is no editable next keyfact to focus
            return;
        }
        const domId = this.getDomId(nextKeyfact);
        const elem = document.querySelector(`[data-test="${domId}"]`) as HTMLElement;

        if (elem) {
            elem.focus();
        }
    }

    private saveError(): void {
        this.isLoading = false;
    }

    isBoolean(kf: IKeyfact): boolean {
        return kf.type === 'boolean';
    }

    isText(kf: IKeyfact): boolean {
        return kf.type === 'text';
    }

    isInteger(kf: IKeyfact): boolean {
        return kf.type === 'integer';
    }

    isDecimal(kf: IKeyfact): boolean {
        return kf.type === 'decimal';
    }

    isNumeric(kf: IKeyfact) {
        return this.isInteger(kf) || this.isDecimal(kf);
    }

    isEnum(kf: IKeyfact): boolean {
        return kf.type === 'enum';
    }

    isDate(kf: IKeyfact): boolean {
        return kf.type === 'date';
    }

    shouldRoundToFirstDayOfMonth(id: string): boolean {
        const showFirstDayOfMonthList: ShowFirstDayOfMonthList[] = ['construction-work-on-facade-until'];
        return showFirstDayOfMonthList.includes(id as ShowFirstDayOfMonthList);
    }

    isTime(kf: IKeyfact): boolean {
        return kf.type === 'time';
    }

    isPreviewKeyfactVisible(kf: IKeyfact): boolean {
        if (!this.isPreview) {
            return true;
        }

        switch (kf.type) {
            case 'boolean':
                return !!kf.value;
            case 'date':
                return kf.value !== null;
            case 'enum':
                return kf.value !== 'notSelected';
            case 'integer':
            case 'time':
            case 'decimal':
                return kf.value !== undefined && kf.value !== null;
            case 'text':
                return !!kf.value;
            default:
                return true;
        }
    }

    generateSchemaNameByCountry(): string {
        const schemaName = `KEY_FACT_HINTS.${this.countryCode}.${this.advertType}`;

        switch (this.countryCode) {
            case 'CA':
                return `${schemaName}.${this.region}`;
            default:
                return schemaName;
        }
    }

    getHint(translationKey: string): string {
        this.hintTranslationKey = `${this.schemaName}.${translationKey}`;
        const result = this.translateService.instant(this.hintTranslationKey);
        return this.hintTranslationKey !== result ? result : '';
    }

    getPreviewValue(kf: IKeyfact): string {
        switch (kf.type) {
            case 'boolean':
                return 'check';
            case 'date':
                return this.convertDatePipe.transform(kf.value as Date);
            case 'enum':
                return kf.value === 'notSelected'
                    ? 'SHARED_COMPONENT.NOT_SELECTED'
                    : `KEY_FACT_ENUMS.${kf.translationKey}.${kf.value}`;
            case 'decimal':
                return this.numeralPipe.transform(kf.value);
            case 'time':
                return TimeHelper.getInputFormattedValue(kf.value as number);
            default:
                return kf.value != null ? kf.value.toString() : '';
        }
    }

    getNumberUnit(kf: IKeyfact): string {
        return !!kf.unit ? `${kf.unit}` : undefined;
    }

    getInputTabIndex(kf: IKeyfact): number {
        return kf.isReadOnly ? -1 : 0;
    }

    getDomId(kf: IKeyfact): string {
        return `${this.section.id}.${kf.id}`;
    }

    getTranslatedErrorMessage(kf: IKeyfact): Observable<string> {
        switch (kf.validationError) {
            case 'DATE_MAX_DATE_EXCEEDED':
                return this.translateService.get(`VALIDATION_MESSAGES.DATE_MAX_DATE_EXCEEDED`, { date: kf.maxDate });
            case 'DATE_MIN_DATE_NOT_REACHED':
                return this.translateService.get(`VALIDATION_MESSAGES.DATE_MIN_DATE_NOT_REACHED`, { date: kf.minDate });
            case 'ENUM_NO_OPTION_SELECTED':
                return this.translateService.get(`VALIDATION_MESSAGES.ENUM_NO_OPTION_SELECTED`);
            case 'ENUM_UNSUPPORTED_OPTION_SELECTED':
                return this.translateService.get(`VALIDATION_MESSAGES.ENUM_UNSUPPORTED_OPTION_SELECTED`);
            case 'NUMBER_MAX_VALUE_EXCEEDED':
                return this.translateService.get(`VALIDATION_MESSAGES.NUMBER_MAX_VALUE_EXCEEDED`, { max: kf.maxValue });
            case 'TIME_START_INVALID_SELECTION':
                return this.translateService.get(`VALIDATION_MESSAGES.TIME_START_INVALID_SELECTION`);
            case 'TIME_END_INVALID_SELECTION':
                return this.translateService.get(`VALIDATION_MESSAGES.TIME_END_INVALID_SELECTION`);
            case 'NUMBER_MIN_VALUE_NOT_REACHED':
                return this.translateService.get(`VALIDATION_MESSAGES.NUMBER_MIN_VALUE_NOT_REACHED`, {
                    min: kf.minValue,
                });
            case 'REQUIRED_FIELD_NOT_GIVEN':
                return this.translateService.get(`VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN`);
            case 'TEXT_MAX_LENGTH_EXCEEDED':
                return this.translateService.get(`VALIDATION_MESSAGES.TEXT_MAX_LENGTH_EXCEEDED`, { max: kf.maxLength });
            case 'TEXT_MIN_LENGTH_NOT_REACHED':
                return this.translateService.get(`VALIDATION_MESSAGES.TEXT_MIN_LENGTH_NOT_REACHED`, {
                    min: kf.maxLength,
                });
            case 'BATHROOM_NUMBER_INVALID':
                return this.translateService.get(`VALIDATION_MESSAGES.BATHROOM_NUMBER_INVALID`);
            case 'MULTIPLE_RENOVATION_STATE_SELECTED':
                return this.translateService.get(`VALIDATION_MESSAGES.MULTIPLE_RENOVATION_STATE_SELECTED`);
            case 'INVALID_POSTAL_CODE':
                return this.translateService.get(`VALIDATION_MESSAGES.INVALID_POSTAL_CODE`);
            default:
                return this.translateService.get(`VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN`);
        }
    }

    navigateToAdvert(): void {
        this.router.navigate([`/advert/${this.advertId}`], { queryParams: { sectionId: this.section.id } });
    }

    updateTimeKeyfact(kf: IKeyfact): void {
        const dialog = this.dialog.open(TimePickerDialogComponent, { data: kf.value });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((newValue) => {
                if (newValue) {
                    this.updateKeyfact(kf, newValue);
                }
            });
    }

    private generateFormControls() {
        return this.mappedKeyfacts.reduce((acc, kf) => {
            const value = this.formatKeyfactValue(kf);
            const updateOn = this.getFormUpdateFunctionality(kf);
            const validators = this.generateValidators(kf);

            acc[kf.id] = new UntypedFormControl(value, { validators: validators, updateOn: updateOn });
            if (kf.isReadOnly) {
                (acc[kf.id] as UntypedFormControl).disable();
            }
            return acc;
        }, {});
    }

    private getFormUpdateFunctionality(kf: IKeyfact): 'change' | 'blur' {
        switch (kf.type) {
            case 'boolean':
            case 'enum':
            case 'time':
                return 'change';
            case 'integer':
            case 'decimal':
            case 'text':
            case 'date':
                return 'blur';
        }
    }

    private formatKeyfactValue(kf: IKeyfact): unknown {
        if (this.isDecimal(kf) && kf.value !== undefined && kf.value !== null) {
            return this.numeralPipe.transform(kf.value);
        }
        return kf.value;
    }

    private generateValidators(kf: IKeyfact): ValidatorFn[] {
        const validators = [
            kf.isRequired ? (this.isEnum(kf) ? CustomValidators.requiredForDropdown : Validators.required) : undefined,
            kf.maxLength ? Validators.maxLength(kf.maxLength) : undefined,
            kf.maxValue ? Validators.max(kf.maxValue) : undefined,
            kf.minLength ? Validators.minLength(kf.minLength) : undefined,
            kf.minValue ? Validators.min(kf.minValue) : undefined,
            kf.isValid ? undefined : CustomValidators.backendError(),
        ];
        return validators.filter((v) => v !== undefined);
    }

    byIndex(index: number): number {
        return index;
    }
}
