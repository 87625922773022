import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageUploadType } from '@models/shared';
import { UploadImageService } from '@services/upload-image.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'advert-image-upload',
    templateUrl: './advert-image-upload.component.html',
    styleUrls: ['./advert-image-upload.component.less'],
})
export class AdvertImageUploadComponent extends UnSubscriptionDirective implements OnInit {
    private router = inject(ActivatedRoute);
    private uploadImageService = inject(UploadImageService);

    unitId: string;
    type: ImageUploadType;

    get imageTypeTitle(): string {
        if (this.type === 'property/building') {
            return 'IMAGE_UPLOAD_TITLE.property_building';
        }

        if (this.type === 'hunting_area') {
            return 'IMAGE_UPLOAD_TITLE.hunting_area';
        }

        return `IMAGE_UPLOAD_TITLE.${this.type}`;
    }

    ngOnInit(): void {
        this.unitId = this.router.snapshot.params.unitId;
        this.router.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            this.type = params.type;
        });
    }

    uploadImagesOneByOne(): void {
        this.uploadImageService.startUploading$.next(true);
    }
}
