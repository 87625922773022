import { IInviteeViewModel, InviteeEventResponseStatus } from './viewing/viewing';

export class InviteeViewModel implements IInviteeViewModel {
    id: string;
    name: string;
    email: string;
    phone: string;
    response: InviteeEventResponseStatus;
    hasAttended: boolean;

    static factory(invitee: IInviteeViewModel): InviteeViewModel {
        const vm = new InviteeViewModel();
        vm.id = invitee.id;
        vm.name = invitee.name;
        vm.email = invitee.email;
        vm.phone = invitee.phone;
        vm.response = invitee.response;
        vm.hasAttended = invitee.hasAttended;
        return vm;
    }
}
