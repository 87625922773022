<div class="modal-base">
    <section class="head">
        <article class="subtitle">{{ 'SHARED_COMPONENT.CONFIRMATION' | translate }}</article>
        <button mat-icon-button matDialogClose clickAndKeydown (actionTriggered)="close(true)">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="warning-dialog">
        {{ 'IMAGE_UPLOAD_RESPONSE.exitPage' | translate }}
    </section>

    <section class="actions">
        <button mat-flat-button matDialogClose clickAndKeydown (actionTriggered)="close()">
            {{ 'SHARED_COMPONENT.BACK_TO_ADVERT' | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="close(true)">
            {{ 'SHARED_COMPONENT.BACK_TO_UPLOAD' | translate }}
        </button>
    </section>
</div>
