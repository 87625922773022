<ng-container *ngIf="enableViewingOptions">
    <button mat-menu-item clickAndKeydown (actionTriggered)="viewingInvitation()">
        <mat-icon fontSet="material-icons-outlined" class="material-icons">event</mat-icon>
        {{ 'PROSPECTS.VIEWING_INVITATION' | translate }}
    </button>
    <button mat-menu-item clickAndKeydown (actionTriggered)="confirmation()">
        <mat-icon class="material-icons">check_circle_outline</mat-icon>
        {{ 'PROSPECTS.VIEWING_CONFIRMATION' | translate }}
    </button>
</ng-container>
