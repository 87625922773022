import { Pipe, PipeTransform } from '@angular/core';

export type FileSizeUnit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB';

const units: FileSizeUnit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

@Pipe({
    name: 'formatFileSize',
})
export class BytesPipe implements PipeTransform {
    transform(values: string): string {
        let unit = 0;
        let size = parseInt(values, 10) || 0;

        while (size >= 1024 && ++unit) {
            size = size / 1024;
        }

        return size.toFixed(size < 10 && unit > 0 ? 1 : 0) + ' ' + units[unit];
    }
}
