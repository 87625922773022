<div
    class="viewing-dialog let-mat-dialog auto flex-footer"
    [ngClass]="{
        'fixed-height': currentView !== 'Success',
        'min-height': showTabs,
        safariMobileHeight: isMobileSafariBrowser,
    }"
    [ngSwitch]="currentView"
>
    <section class="head">
        <article class="subtitle ellipsis-title">{{ title }}</article>
        <button mat-icon-button [disabled]="isLoading" clickAndKeydown (actionTriggered)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <aside class="loader">
        <loading-indicator [isEmbedded]="true"></loading-indicator>
    </aside>

    <section class="success" *ngSwitchCase="'Success'">
        <warning-box [type]="'success'" [text]="successMessage"></warning-box>
        <aside class="actions">
            <button mat-flat-button clickAndKeydown (actionTriggered)="cancel()">
                {{ 'SHARED_COMPONENT.CLOSE' | translate }}
            </button>
            <button
                mat-flat-button
                clickAndKeydown
                (actionTriggered)="downloadViewingCalenderFile()"
                *ngIf="showDownloadIcsButton"
            >
                <mat-icon>save_alt</mat-icon>
                {{ 'SHARED_COMPONENT.ADD_TO_CALENDER' | translate }}
            </button>
        </aside>
    </section>

    <section class="error" *ngSwitchCase="'Error'">
        <warning-box [text]="errorText" [type]="'error'"></warning-box>
        <aside class="actions">
            <button mat-flat-button mat-dialog-close>{{ 'SHARED_COMPONENT.CLOSE' | translate }}</button>
        </aside>
    </section>

    <section class="error" *ngSwitchCase="'Warning'">
        <warning-box [type]="'warning'" [text]="errorText"></warning-box>
        <aside class="actions">
            <button mat-flat-button mat-dialog-close clickAndKeydown (actionTriggered)="cancel()">
                {{ 'SHARED_COMPONENT.CLOSE' | translate }}
            </button>
        </aside>
    </section>

    <mat-tab-group
        *ngIf="showTabs"
        mat-align-tabs="start"
        [@.disabled]="true"
        [selectedIndex]="tabIndex"
        animationDuration="0ms"
        (selectedTabChange)="updateTabIndex($event)"
    >
        <mat-tab [label]="upcomingViewingsLabel" [disabled]="isEmpty">
            <div class="tab-content">
                <div class="empty" *ngIf="isEmpty">
                    <warning-box [text]="emptyText"></warning-box>
                </div>

                <section class="confirmation" *ngSwitchCase="'Confirmation'">
                    <section
                        class="viewing-item"
                        [class.last-viewing]="last"
                        *ngFor="let futureViewing of futureViewings; trackBy: byViewingId; let last = last"
                    >
                        <section class="header" [class.disabled]="isAlreadyConfirmedViewing(futureViewing)">
                            <mat-checkbox
                                [ngModel]="futureViewing.isSelected"
                                [disabled]="isDisabled(futureViewing)"
                                (ngModelChange)="toggleSelection(futureViewing, $event)"
                            >
                                <span class="heading">{{ displayViewingDate(futureViewing) }}</span>
                            </mat-checkbox>
                        </section>
                        <aside class="row">
                            <article
                                class="invitees"
                                *ngIf="futureViewing.context.hasInvitees && !isAlreadyConfirmedViewing(futureViewing)"
                            >
                                {{ inviteesLabel(futureViewing) }}
                                <invitee-stats [viewing]="futureViewing.context"></invitee-stats>
                            </article>
                            <p class="isAlreadyConfirmed" *ngIf="isAlreadyConfirmedViewing(futureViewing)">
                                {{ 'VIEWINGS_VIEW.PROSPECT_ALREADY_CONFIRMED_VIEWING' | translate }}
                            </p>
                        </aside>
                    </section>
                </section>

                <section class="viewing-invitation" *ngSwitchCase="'ViewingInvitation'">
                    <section
                        class="viewing-item"
                        [class.last-event]="last"
                        *ngFor="let futureViewing of futureViewings; trackBy: byViewingId; let last = last"
                    >
                        <section class="header">
                            <mat-checkbox
                                [ngModel]="futureViewing.isSelected"
                                (ngModelChange)="toggleSelection(futureViewing, $event)"
                            >
                                <span class="heading">{{ displayViewingDate(futureViewing) }}</span>
                            </mat-checkbox>
                        </section>
                        <aside class="row">
                            <article class="invitees" *ngIf="futureViewing.context.hasInvitees">
                                {{ inviteesLabel(futureViewing) }}
                                <invitee-stats [viewing]="futureViewing.context"></invitee-stats>
                            </article>
                        </aside>
                    </section>
                </section>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'VIEWINGS_VIEW.CREATE_VIEWING' | translate }}">
            <div class="tab-content">
                <section class="wait" *ngSwitchCase="'Loading'"></section>
                <section class="options form" [formGroup]="form">
                    <aside class="row">
                        <div class="property">
                            <mat-form-field>
                                <mat-label>{{ 'SHARED_COMPONENT.DATE' | translate }}</mat-label>
                                <input
                                    formControlName="date"
                                    matInput
                                    [min]="today"
                                    [matDatepicker]="datePicker"
                                    autocomplete="off"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker></mat-datepicker>
                                <mat-error *ngIf="!form.get('date').valid">{{
                                    'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                                }}</mat-error>
                            </mat-form-field>
                        </div>
                    </aside>
                    <aside class="row" [class.display-block]="isAmPmFormat">
                        <div class="property start-time-picker">
                            <article [ngClass]="startTimeIsValid ? 'label' : 'error-label'">
                                {{ 'VIEWINGS_VIEW.START_TIME' | translate }}
                            </article>
                            <time-picker
                                formControlName="startTime"
                                [isShortForm]="true"
                                timeFormat="two-digit"
                            ></time-picker>
                            <mat-error class="time-error" *ngIf="!startTimeIsValid">{{
                                'VALIDATION_MESSAGES.START_TIME_IS_NOT_VALID' | translate
                            }}</mat-error>
                        </div>
                        <div class="property">
                            <article [ngClass]="endTimeIsValid ? 'label' : 'error-label'">
                                {{ 'VIEWINGS_VIEW.END_TIME' | translate }}
                            </article>
                            <time-picker
                                formControlName="endTime"
                                [isShortForm]="true"
                                timeFormat="two-digit"
                            ></time-picker>
                            <mat-error class="time-error" *ngIf="!form.get('endTime').valid">{{
                                'VALIDATION_MESSAGES.TIME_END_INVALID_SELECTION' | translate
                            }}</mat-error>
                        </div>
                    </aside>
                    <aside class="row">
                        <div class="property">
                            <mat-form-field>
                                <mat-label>{{ 'VIEWINGS_VIEW.MEETING_POINT' | translate }}</mat-label>
                                <mat-select formControlName="meetingPoint">
                                    <mat-option [value]="'mainEntrance'">{{
                                        'VIEWINGS_VIEW.MAIN_ENTRANCE' | translate
                                    }}</mat-option>
                                    <mat-option [value]="'rearEntrance'">{{
                                        'VIEWINGS_VIEW.REAR_ENTRANCE' | translate
                                    }}</mat-option>
                                    <mat-option [value]="'apartmentDoor'">{{
                                        'VIEWINGS_VIEW.APARTMENT_DOOR' | translate
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </aside>
                    <h4 class="h4-header">{{ 'VIEWINGS_VIEW.ADDITIONAL_TITLE' | translate }}</h4>
                    <aside class="row">
                        <div class="property">
                            <mat-form-field>
                                <textarea
                                    placeholder="{{
                                        'VIEWINGS_VIEW.ADDITIONAL'
                                            | additionalInfoByCountry: advert.countryCode : advert.region
                                    }}"
                                    formControlName="description"
                                    matInput
                                    rows="3"
                                ></textarea>
                                <mat-error *ngIf="!form.get('description').valid">{{
                                    'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                                }}</mat-error>
                            </mat-form-field>
                        </div>
                    </aside>
                    <aside class="row" *ngIf="isAdditionalInfoEngAvailableFor">
                        <div class="property">
                            <mat-form-field>
                                <textarea
                                    placeholder="{{ 'VIEWINGS_VIEW.ADDITIONAL_EN' | translate }}"
                                    formControlName="descriptionEn"
                                    matInput
                                    rows="3"
                                ></textarea>
                                <mat-error *ngIf="!form.get('descriptionEn').valid">{{
                                    'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                                }}</mat-error>
                            </mat-form-field>
                        </div>
                    </aside>
                </section>
            </div>
        </mat-tab>
    </mat-tab-group>

    <section class="actions" *ngIf="showViewingActionButtons">
        <button mat-flat-button matDialogClose>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()" [disabled]="canSave">
            {{ createViewingActionLabel }}
        </button>
    </section>
</div>

<download-ics></download-ics>
