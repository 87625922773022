<div class="option-modal" [ngSwitch]="currentViewType">
    <section class="head">
        <article class="subtitle">{{ 'GENERAL_INQUIRIES_VIEW.DELETE_GENERAL_INQUIRY' | translate }}</article>
        <button mat-icon-button mat-dialog-close [disabled]="isLoading">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="options" *ngSwitchCase="0">
        {{ 'GENERAL_INQUIRIES_VIEW.DELETE_GENERAL_INQUIRY_MESSAGE' | translate }}
    </section>

    <section class="loading" *ngSwitchCase="1">
        <loading-indicator [isPermanent]="true" [isEmbedded]="true"></loading-indicator>
    </section>

    <section class="actions" *ngIf="currentViewType !== 1">
        <button mat-flat-button mat-dialog-close>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()">
            {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
        </button>
    </section>
</div>
