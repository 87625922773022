import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { cultureInfoHelper } from '@helpers/culture-info-helper';
import { SupportedCountryCode, SupportedCultureInfo } from '@models/backend/common';
import { IUserSettings } from '@models/backend/user-settings';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { NotificationViewModel } from '@models/notification';
import { TenantName } from '@models/shared';
import { AnalyticsService } from '@services/analytics.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { CountryService } from '@services/country.service';
import { UserRoleService } from '@services/user-role.service';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

export interface ICountry {
    code: SupportedCountryCode;
    name: string;
}
export interface ICultrueInfo {
    cultureInfo: SupportedCultureInfo;
    name: string;
    description: string;
}

@Component({
    selector: 'navigation',
    templateUrl: 'navigation.component.html',
    styleUrls: ['navigation.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends UnSubscriptionDirective implements OnInit {
    private authService = inject(AuthService);
    private router = inject(Router);
    private userSettingsService = inject(UserSettingsService);
    private countryService = inject(CountryService);
    private browserWindowService = inject(BrowserWindowService);
    private dateAdapter = inject<DateAdapter<SupportedCultureInfo>>(DateAdapter);
    private gaService = inject(AnalyticsService);
    private userRoleService = inject(UserRoleService);

    isNavigationExpanded: boolean = false;
    selectedCountry: SupportedCountryCode;
    selectedCultureInfo: SupportedCultureInfo;
    notifications: NotificationViewModel[] = [];
    isRotationFeatureAvailable: boolean = false;

    oldCountry: SupportedCountryCode;

    countries: ICountry[] = [
        { code: 'CY', name: 'SHARED_COMPONENT.COUNTRY_CY' },
        { code: 'US', name: 'SHARED_COMPONENT.COUNTRY_US' },
        { code: 'CA', name: 'SHARED_COMPONENT.COUNTRY_CA' },
        { code: 'FR', name: 'SHARED_COMPONENT.COUNTRY_FR' },
        { code: 'DE', name: 'SHARED_COMPONENT.COUNTRY_DE' },
        { code: 'GB', name: 'SHARED_COMPONENT.COUNTRY_GB' },
        { code: 'SE', name: 'SHARED_COMPONENT.COUNTRY_SE' },
    ];

    cultureInfos: ICultrueInfo[] = [
        { cultureInfo: 'en-US', name: 'SHARED_COMPONENT.LANG_EN', description: '' },
        { cultureInfo: 'en-GB', name: 'SHARED_COMPONENT.LANG_EN', description: 'SHARED_COMPONENT.UNITED_KINGDOM' },
        { cultureInfo: 'fr-FR', name: 'SHARED_COMPONENT.LANG_FR', description: '' },
        { cultureInfo: 'de-DE', name: 'SHARED_COMPONENT.LANG_DE', description: '' },
        { cultureInfo: 'sv-SE', name: 'SHARED_COMPONENT.LANG_SV', description: '' },
    ];

    private readonly akeliusSupportUrl =
        'https://akelius.atlassian.net/servicedesk/customer/portal/7/group/22/create/56';

    get isDashboardRoute(): boolean {
        return this.router.url === '/';
    }

    get isTeasersRoute(): boolean {
        return this.router.url === '/teasers';
    }

    get isRotationRoute(): boolean {
        return this.router.url === '/rotation';
    }

    ngOnInit(): void {
        const cultureInfo = this.countryService.getCurrentCultureInfo();
        this.selectedCultureInfo = cultureInfo;
        this.dateAdapter.setLocale(cultureInfo);

        this.selectedCountry = this.countryService.getCurrentCountry();
        this.oldCountry = this.countryService.getCurrentCountry();
        const allowedCountryCodes: SupportedCountryCode[] = ['FR', 'GB', 'CA', 'SE', 'US', 'CY'];

        this.userSettingsService
            .getTenantNames()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((tenantName: TenantName[]) => {
                const isHeimstaden = tenantName.includes('heimstaden');
                const isAkelius = tenantName.includes('akelius-residential');

                if (isHeimstaden && isAkelius) {
                    return;
                }

                if (isAkelius) {
                    this.countries = this.countries.filter((c) => allowedCountryCodes.includes(c.code));
                } else if (isHeimstaden) {
                    this.countries = this.countries.filter((c) => ['DE'].includes(c.code));
                }
            });

        this.showRotationFeature();
    }

    isSelectedCountry(countryCode: SupportedCountryCode): boolean {
        return countryCode === this.selectedCountry;
    }

    isSelectedCultureInfo(cultureInfo: SupportedCultureInfo): boolean {
        return cultureInfo === this.selectedCultureInfo;
    }

    setCultureInfo(cultureInfo: SupportedCultureInfo): void {
        this.gaService.event(
            GoogleAnalyticsEvents.LanguageChanged,
            'data',
            `old_language:${this.selectedCultureInfo},new_language:${cultureInfo}`,
        );

        cultureInfo = cultureInfoHelper(cultureInfo);

        this.selectedCultureInfo = cultureInfo;

        localStorage.setItem('userCultureInfo', cultureInfo);

        const settings: IUserSettings = {
            selectedCountry: localStorage.getItem('countryCode') as SupportedCountryCode,
            selectedCultureInfo: cultureInfo as SupportedCultureInfo,
        };
        this.userSettingsService
            .saveUserSettings(settings)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.browserWindowService.reloadWindow();
            });
    }

    logout(): void {
        this.gaService.event(GoogleAnalyticsEvents.UserLoggedOut);

        const logoutOptions = {
            logoutParams: {
                returnTo: this.browserWindowService.getWindowObject().location.origin,
            },
        };

        this.authService.logout(logoutOptions);
    }

    navigateToHome(): void {
        this.isNavigationExpanded = false;
        this.router.navigate(['']);
    }

    navigateToTeaserList(): void {
        this.isNavigationExpanded = false;
        this.router.navigate(['/teasers']);
    }

    navigateToRotationTeaser(): void {
        this.isNavigationExpanded = false;
        this.router.navigate(['/rotation']);
    }

    openSupportPage(): void {
        this.browserWindowService.openExternalLink(this.akeliusSupportUrl, true);
    }

    private showRotationFeature(): void {
        this.userRoleService
            .isRotationFeatureAvailable()
            ?.pipe(takeUntil(this.unsubscribe$))
            .subscribe((isRotationFeatureAvailable) => {
                this.isRotationFeatureAvailable = isRotationFeatureAvailable;
            });
    }

    setCountry(selectedCountry: SupportedCountryCode): void {
        this.selectedCountry = selectedCountry;

        this.gaService.event(
            GoogleAnalyticsEvents.CountryChanged,
            'data',
            `old_country:${this.oldCountry},new_country:${this.selectedCountry}`,
        );

        const settings: IUserSettings = {
            selectedCountry: this.selectedCountry,
            selectedCultureInfo: localStorage.getItem('userCultureInfo') as SupportedCultureInfo,
        };

        this.userSettingsService
            .saveUserSettings(settings)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.browserWindowService.navigateWithPageReload('/');
            });

        // clear saved filter
        this.userSettingsService.clearTeaserFilter();
    }
}
