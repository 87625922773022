import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'additionalInfoByCountry',
})
export class AdditionalInfoByCountryPipe implements PipeTransform {
    private translateService = inject(TranslateService);


    transform(value: string, ...args: string[]): string {
        let countryCode = args[0];
        const region = args[1];

        if (countryCode.includes('GB') || (countryCode === 'CA' && region === 'Toronto')) {
            return this.translateService.instant(value);
        }

        const regionsManagedByMontrealOffice = ['Montreal', 'Ottawa', 'Quebec City'];
        const isRegionManagedByMontrealOffice = regionsManagedByMontrealOffice.includes(region);

        if (countryCode === 'CA' && isRegionManagedByMontrealOffice) {
            countryCode = 'FR';
        }

        return `${this.translateService.instant(value)} ${this.translateService.instant(value + '_' + countryCode)}`;
    }
}
