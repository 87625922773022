<div
    class="published-portals let-mat-dialog"
    [ngClass]="{
        safariMobileHeight: isMobileSafariBrowser,
    }"
>
    <section class="head">
        <article class="subtitle">{{ 'SHARED_COMPONENT.PORTALS' | translate }}</article>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <article>{{ 'SHARED_COMPONENT.MODAL_PUBLISH_SUCCESS_MESSAGE' | translate }}</article>

    <section class="portals">
        <div class="portal" *ngFor="let portal of args.publicationResults; trackBy: byIndex">
            <span *ngIf="!portal.url">- {{ 'PORTALS.' + portal.portalName | translate }}</span>
            <span *ngIf="portal.url" clickAndKeydown (actionTriggered)="openExternalPortal(portal.url)">
                - <a class="link">{{ 'PORTALS.' + portal.portalName | translate }}</a>
            </span>
            <i class="material-icons {{ portal.isSuccess ? 'success' : 'error' }}">{{
                portal.isSuccess ? 'check_circle' : 'error'
            }}</i>
        </div>
    </section>

    <button mat-flat-button clickAndKeydown (actionTriggered)="close()">
        {{ 'SHARED_COMPONENT.CLOSE' | translate }}
    </button>
</div>
