import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BrowserWindowService {
    getWindowObject() {
        return window;
    }

    reloadWindow(): void {
        window.location.reload();
    }

    openExternalLink(url: string, isNewTab?: boolean): void {
        window.open(url, isNewTab ? '_blank' : '_self');
    }

    navigateWithPageReload(url: string): void {
        window.location.href = url;
    }

    scrollTo(horizontalValue: number, verticalValue: number): void {
        window.scrollTo(horizontalValue, verticalValue);
    }

    getURLConstructor() {
        return window.URL;
    }

    matchMedia(displayMode: string): boolean {
        return window.window.matchMedia(displayMode).matches;
    }
}
