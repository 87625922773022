export class String {
    // https://gist.github.com/thevangelist/8ff91bac947018c9f3bfaad6487fa149
    static toKebabCase(str: string): string {
        return str
            .replace(/([a-z])([A-Z])/g, '$1-$2')
            .replace(/[\s_]+/g, '-')
            .toLowerCase();
    }

    static isEmpty(str: string): boolean {
        return str === '' || str === null || str === undefined;
    }

    static isValidDateString(dateString: string): boolean {
        const timestamp = Date.parse(dateString);
        return !isNaN(timestamp);
    }
}

export function sortByAscendingForCharacters(a: string, b: string): number {
    return a.localeCompare(b);
}
