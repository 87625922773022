import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'warning-box',
    templateUrl: 'warning-box.component.html',
    styleUrls: ['warning-box.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningBoxComponent {
    @Input()
    text: string;
    @Input()
    type: 'info' | 'warning' | 'error' | 'success' = 'info';

    @Input()
    link: string;
    @Input()
    linkText: string;

    get icon(): string {
        switch (this.type) {
            case 'warning':
                return 'warning';
            case 'error':
                return 'cancel';
            case 'success':
                return 'check_circle';
            case 'info':
            default:
                return 'info';
        }
    }
}
