import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdvertViewModel } from '@models/advert/advert';
import { ImageViewModel } from '@models/advert/image';
import { AdvertType } from '@models/backend/advert';
import { IImage } from '@models/backend/images';
import { ValidatedSection } from '@models/common/validated-section';
import { ISelect } from '@models/shared';
import { AdvertService } from '@services/advert.service';
import { AdvertStoreService } from '@services/advert.store';
import { BrowserWindowService } from '@services/browser-window.service';
import { IconType, ValidationMessage } from '@validators/types';
import { takeUntil } from 'rxjs';
import { IntranetGalleryComponent } from '../../intranet-gallery/intranet-gallery.component';

@Component({
    selector: 'images-section',
    templateUrl: 'images-section.component.html',
    styleUrls: ['images-section.component.less'],
})
export class ImagesSectionComponent extends ValidatedSection implements OnInit, AfterViewChecked {
    private advertService = inject(AdvertService);
    private advertStore = inject(AdvertStoreService);
    private router = inject(Router);
    private dialog = inject(MatDialog);
    private browserWindowService = inject(BrowserWindowService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @Input() advert: AdvertViewModel;

    currentZoomImage: ImageViewModel;
    isZoomImageOpen: boolean = false;
    isLoading: boolean = false;
    isImageNotExist: boolean = false;
    dragOrientation: string;
    validationMessages: ValidationMessage[] = [];

    uploadImageMenuItems: ISelect[] = [
        { id: 'apartment', label: 'IMAGE_TAGS.apartment' },
        { id: 'property/building', label: 'IMAGE_TAGS.property_building' },
        { id: 'examples', label: 'IMAGE_TAGS.examples' },
    ];

    get isValid(): boolean {
        const isValid =
            this.advert.hasImages &&
            !this.isTitleImagePortraitOrFloorPlan &&
            !this.hasManyImages &&
            !this.hasMultiplePromotionImages &&
            !this.isImageNotExist;

        return this.isFloorPlanMandatory ? isValid && this.hasFloorPlan : isValid;
    }

    get isFloorPlanMandatory(): boolean {
        const isMandatoryForCountry =
            this.advert.isFrenchUnit ||
            this.advert.isCanadianUnit ||
            this.advert.isBritishUnit ||
            this.advert.isUnitedStatesUnit ||
            this.advert.isCyprusUnit;

        const isMandatoryForAdvertType = this.advert.type !== AdvertType.Parking;

        return isMandatoryForCountry && isMandatoryForAdvertType;
    }

    get isMultipleMessageBoxVisible(): boolean {
        if (this.isPreview) {
            return false;
        }

        return !this.isValid || this.hasPromotion;
    }

    get hasPromotion(): boolean {
        if (this.advert.images.some((image) => image.pictureDetail === 'promotion')) {
            return true;
        }
        return false;
    }

    get isTitleImagePortraitOrFloorPlan(): boolean {
        if (!this.advert.hasImages) {
            return false;
        }

        if (this.advert.images[0].isPortrait || this.advert.images[0].isFloorplan) {
            return true;
        }

        return false;
    }

    get hasFloorPlan(): boolean {
        return this.advert.images.some((i) => i.isFloorplan);
    }

    get hasManyImages(): boolean {
        if (this.advert.hasImages && this.advert.images.length >= this.maxImageSize) {
            return true;
        }
        return false;
    }

    get hasMultiplePromotionImages(): boolean {
        if (this.advert.hasImages) {
            const promotionImages = this.advert.images.filter((image) => image.pictureDetail === 'promotion');
            if (promotionImages.length > 1) {
                return true;
            }
        }
        return false;
    }

    get hasZoomNavigation(): boolean {
        return this.advert.images.length > 1;
    }

    get maxImageSize(): number {
        switch (this.advert.countryCode) {
            case 'CA':
            case 'FR':
                return 20;
            default:
                return 200;
        }
    }

    get getIndexOfCurrentZoomImage(): number {
        return this.advert.images.indexOf(this.currentZoomImage);
    }

    private get imageValidationRules(): {
        iconType: string;
        showMessage: boolean;
        message: string;
        suffix: number;
    }[] {
        const validationRules = [
            {
                iconType: 'cancel',
                showMessage: !this.advert.hasImages,
                message: 'SHARED_COMPONENT.NO_IMAGE',
                suffix: null,
            },
            {
                iconType: 'cancel',
                showMessage: this.isTitleImagePortraitOrFloorPlan,
                message: 'SHARED_COMPONENT.LANDSCAPE_ERROR',
                suffix: null,
            },
            {
                iconType: 'cancel',
                showMessage: this.hasManyImages,
                message: 'SHARED_COMPONENT.MAX_NUMBER_OF_IMAGES',
                suffix: this.maxImageSize,
            },
            {
                iconType: 'cancel',
                showMessage: this.hasMultiplePromotionImages,
                message: 'SHARED_COMPONENT.PROMOTION_IMAGE_ERROR',
                suffix: null,
            },
            {
                iconType: 'cancel',
                showMessage: this.isImageNotExist,
                message: 'SHARED_COMPONENT.NOT_FOUND_IMAGE_ERROR',
                suffix: null,
            },
            {
                iconType: 'info',
                showMessage: this.hasPromotion,
                message: 'SHARED_COMPONENT.PROMOTION_IMAGE_INFO',
                suffix: null,
            },
        ];

        if (this.isFloorPlanMandatory) {
            validationRules.push({
                iconType: 'cancel',
                showMessage: !this.hasFloorPlan,
                message: 'SHARED_COMPONENT.FLOORPLAN_ERROR',
                suffix: null,
            });
        }

        return validationRules;
    }

    ngAfterViewChecked(): void {
        this.checkImageValidation();
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        this.updateUploadImagesMenuTitle();
        this.dragOrientation =
            this.browserWindowService.getWindowObject().screen.width < 768 ? 'vertical' : 'horizontal';
    }

    updateUploadImagesMenuTitle(): void {
        switch (this.advert.type) {
            case AdvertType.Commercial:
                this.uploadImageMenuItems[0].label = 'IMAGE_TAGS.commercial';
                this.uploadImageMenuItems[0].id = 'commercial';
                break;
            case AdvertType.Parking:
                this.uploadImageMenuItems[0].label = 'IMAGE_TAGS.parking';
                this.uploadImageMenuItems[0].id = 'parking';
                break;
            case AdvertType.HuntingArea:
                this.uploadImageMenuItems[0].label = 'IMAGE_TAGS.hunting_area';
                this.uploadImageMenuItems[0].id = 'hunting_area';
                break;
            default:
                this.uploadImageMenuItems[0].label = 'IMAGE_TAGS.apartment';
                this.uploadImageMenuItems[0].id = 'apartment';
        }
    }

    showGallery(): void {
        const dialog = this.dialog.open(IntranetGalleryComponent, {
            data: this.advert,
            maxWidth: '90vw',
            maxHeight: '90vh',
            panelClass: 'unconstrained-dialog',
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((images: ImageViewModel[]) => {
                if (images) {
                    this.advert.images = images;
                    this.saveImages();
                }
            });
    }

    private saveImages(): void {
        this.isLoading = true;

        const result = this.advert.images.map((image, index) => {
            const doc: IImage = {
                id: image.id,
                name: image.name,
                assetName: image.assetName,
                mimeType: image.mimeType,
                isTitleImage: index === 0, // first image is always the title image
                isFloorplan: image.isFloorplan,
                isExample: image.isExample,
                pictureType: image.pictureType,
                pictureDetail: image.pictureDetail,
                index: index,
                isPortrait: image.isPortrait,
                originalImageUrl: image.originalImageUrl,
                thumbnailImageUrl: image.thumbnailImageUrl,
                smallUrl: image.smallUrl,
                mediumUrl: image.mediumUrl,
                largeUrl: image.largeUrl,
            };
            return doc;
        });

        this.advertService
            .saveAdvertImages(this.advert.id, result)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                error: () => this.saveComplete(),
                complete: () => this.saveComplete(),
            });
    }

    private saveComplete(): void {
        this.isLoading = false;
    }

    removeImage(image: ImageViewModel) {
        this.advert.images.splice(this.advert.images.indexOf(image), 1);
        this.saveImages();
    }

    zoomInSelectedImage(image: ImageViewModel): void {
        if (this.isPreview) {
            return;
        }

        this.currentZoomImage = image;
        this.isZoomImageOpen = true;
    }

    previousImage(): void {
        if (this.getIndexOfCurrentZoomImage === 0) {
            this.currentZoomImage = this.advert.images[this.advert.images.length - 1];
        } else {
            this.currentZoomImage = this.advert.images[this.getIndexOfCurrentZoomImage - 1];
        }
    }

    nextImage(): void {
        if (this.getIndexOfCurrentZoomImage === this.advert.images.length - 1) {
            this.currentZoomImage = this.advert.images[0];
        } else {
            this.currentZoomImage = this.advert.images[this.getIndexOfCurrentZoomImage + 1];
        }
    }

    navigateToAdvert(): void {
        this.router.navigate([`/advert/${this.advert.id}`], { queryParams: { sectionId: 'images-section' } });
    }

    uploadImages(type: string): void {
        const { unitCode, id } = this.advert;
        this.router.navigate([`${id}/image-upload`], {
            queryParams: { unitCode, type },
        });
    }

    imageDropped(): void {
        this.saveImages();
    }

    imageEntered(e: CdkDragEnter): void {
        moveItemInArray(this.advert.images, e.item.data, e.container.data);
    }

    refreshAdvert(): void {
        this.advertStore.refreshed$.next(true);
    }

    byAdvertId(_index: number, advert: AdvertViewModel): string {
        return advert.id;
    }

    byIndex(index: string): string {
        return index;
    }

    checkImageValidation(): void {
        const validationMessages: ValidationMessage[] = [];
        const validateImageRules = this.imageValidationRules;

        for (const validateImageRule of validateImageRules) {
            const { showMessage, message, iconType, suffix } = validateImageRule;
            if (showMessage) {
                validationMessages.push({
                    iconType: iconType as IconType,
                    message,
                    suffix,
                });
            }
        }

        this.validationMessages = validationMessages;
    }
}
