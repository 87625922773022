<div
    class="general-inquiry-editor let-mat-dialog flex-footer"
    [ngClass]="{
        safariMobileHeight: isMobileSafariBrowser,
    }"
    *ngIf="generalInquiry"
>
    <section class="head mat-dialog-title">
        <article class="subtitle">{{ title | translate }}</article>
        <button mat-icon-button clickAndKeydown (actionTriggered)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="edit" [formGroup]="generalInquiryForm">
        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'GENERAL_INQUIRIES_VIEW.NAME' | translate }}</mat-label>
                <input matInput required formControlName="name" type="text" autocomplete="off" />
                <mat-error *ngIf="!generalInquiryForm.get('name').valid">{{
                    'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'KEY_FACTS.email' | translate }}</mat-label>
                <input matInput type="email" formControlName="email" autocomplete="off" />
                <mat-error *ngIf="emailFieldError?.required">{{
                    'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
                }}</mat-error>

                <mat-error *ngIf="emailFieldError?.pattern">{{ 'VALIDATION_MESSAGES.EMAIL' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'KEY_FACTS.phoneNumber' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="phone"
                    (input)="phoneChangedEvent($event)"
                    [placeholder]="phoneFieldPlaceholder"
                    autocomplete="off"
                />
                <mat-error *ngIf="!generalInquiryForm.get('phone').valid">
                    <ng-container *ngIf="phoneFieldError?.pattern">
                        {{ 'VALIDATION_MESSAGES.PHONE_IS_NOT_VALID' | translate }}
                    </ng-container>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'SHARED_COMPONENT.STATUS' | translate }}</mat-label>
                <mat-select required formControlName="status">
                    <mat-option *ngFor="let status of statusOptions" [value]="status">{{
                        'KEY_FACT_ENUMS.prospectStatus.' + status | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'GENERAL_INQUIRIES_VIEW.MOVE_IN_DATE' | translate }}</mat-label>
                <input
                    matInput
                    [matDatepicker]="datePicker"
                    [min]="today"
                    autocomplete="off"
                    formControlName="preferredMoveInDate"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="row full">
            <mat-form-field>
                <mat-label>{{ 'SHARED_COMPONENT.MESSAGE' | translate }}</mat-label>
                <textarea matInput formControlName="message" rows="5"></textarea>
            </mat-form-field>
        </div>
    </section>

    <section class="actions">
        <button mat-flat-button clickAndKeydown (actionTriggered)="close()">
            {{ cancelButtonText | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()" [disabled]="!canContinue">
            {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
        </button>
    </section>
</div>
