import { Component, inject } from '@angular/core';
import { BrowserWindowService } from '@services/browser-window.service';

@Component({
    selector: 'system-error',
    templateUrl: 'system-error.component.html',
    styleUrls: ['system-error.component.less'],
})
export class SystemErrorComponent {
    private browserWindowService = inject(BrowserWindowService);


    contactTeam(): void {
        this.browserWindowService.openExternalLink('mailto:lettingapp-team@akelius.com');
    }

    reloadPage(): void {
        this.browserWindowService.navigateWithPageReload('/');
    }
}
