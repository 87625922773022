<section class="stats">
    <div class="stat-group">
        <article>{{ advert.streetNameAndHouseNumber }}</article>
        <article>{{ advert.postalCodeAndCity }}</article>
        <article>&nbsp;</article>
    </div>
    <div class="teaser-stats-group">
        <article class="item">
            {{ 'SHARED_COMPONENT.MOVE_OUT' | translate }} {{ advert.moveOutDate | convertDate }}
        </article>
        <article class="item">
            <span *ngIf="advert.totalRent"
                >{{ 'KEY_FACTS.totalRent' | translate }} {{ advert.totalRent | numeral }}
                {{ advert.localization.currency }}</span
            >
            <span *ngIf="!advert.totalRent">{{ 'KEY_FACTS.totalRent' | translate }} -</span>
        </article>
        <article>&nbsp;</article>
    </div>
    <div class="teaser-contact">
        <article>
            {{ advert.hasNoContactAssigned ? ('SHARED_COMPONENT.NO_CONTACT' | translate) : advert.contactDisplay }}
        </article>
        <article *ngIf="advert.hasSubstituteContact">
            , {{ advert.substituteContact.firstName }} {{ advert.substituteContact.lastName }}
        </article>
        <article *ngIf="!advert.hasSubstituteContact">&nbsp;</article>
    </div>
</section>
