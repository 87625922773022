import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TimeHelper } from '@helpers/time.helper';
import { ITimePickerValue, ITwoDigitTimePickerValue, TimeFormat } from '@models/common/time';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TimePickerComponent),
        },
    ],
})
export class TimePickerComponent extends UnSubscriptionDirective implements ControlValueAccessor, OnDestroy, OnInit {
    private changeDetectorRef = inject(ChangeDetectorRef);

    @Input() disabled = false;
    @Input() isShortForm = false;
    @Input() timeFormat: TimeFormat = 'one-digit';

    form: UntypedFormGroup;
    hours: string[] | number[];
    minutes: string[] | number[];

    // eslint-disable-next-line
    onChange = (_val: number) => {};
    onTouched = () => {};

    get isAmPm(): boolean {
        return TimeHelper.isAmPmFormat();
    }

    ngOnInit() {
        this.hours = TimeHelper.getAvailableHours(this.timeFormat) as string[];
        this.minutes = TimeHelper.getAvailableMinutes(this.timeFormat) as string[];

        this.form = new UntypedFormGroup({
            hour: new UntypedFormControl(0),
            minute: new UntypedFormControl(0),
            timeFormat: new UntypedFormControl('am'),
        });

        if (this.timeFormat === 'two-digit') {
            this.form.valueChanges
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((val) => this.formTwoDigitValueChanged(val));
        } else {
            this.form.valueChanges
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((val) => this.formOneDigitValueChanged(val));
        }
    }

    private formTwoDigitValueChanged(value: ITwoDigitTimePickerValue): void {
        if (value.hour === '00') {
            this.form.patchValue({ timeFormat: 'am' }, { emitEvent: false });
            value.timeFormat = 'am';
        }

        if (value.hour === '12') {
            this.form.patchValue({ timeFormat: 'pm' }, { emitEvent: false });
            value.timeFormat = 'pm';
        }

        this.onChange(
            TimeHelper.getCombinedValue(parseInt(value.hour, 10), parseInt(value.minute, 10), value.timeFormat),
        );
        this.onTouched();
    }

    private formOneDigitValueChanged(value: ITimePickerValue): void {
        if (value.hour === 0) {
            this.form.patchValue({ timeFormat: 'am' }, { emitEvent: false });
            value.timeFormat = 'am';
        }
        if (value.hour === 12) {
            this.form.patchValue({ timeFormat: 'pm' }, { emitEvent: false });
            value.timeFormat = 'pm';
        }

        this.onChange(TimeHelper.getCombinedValue(value.hour, value.minute, value.timeFormat));
        this.onTouched();
    }

    private readTime(val: number): { hour: number | string; minute: number | string } {
        const hourRaw = TimeHelper.getHourFromCombinedValue(val);
        const minuteRaw = TimeHelper.getMinuteFromCombinedValue(val);

        if (this.timeFormat === 'two-digit') {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const hour = val ? TimeHelper.pad(hourRaw) : '00';
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const minute = val ? TimeHelper.pad(minuteRaw) : '00';
            return { hour, minute };
        }

        const hour = val ? hourRaw : 0;
        const minute = val ? minuteRaw : 0;

        return { hour, minute };
    }

    ngOnDestroy(): void {
        this.changeDetectorRef.detach();
    }

    writeValue(val: number): void {
        const { hour, minute } = this.readTime(val);
        const timeFormat = val ? TimeHelper.getFormatFromCombinedValue(val) : 'am';
        this.form.setValue({ hour, minute, timeFormat }, { emitEvent: false });
        this.changeDetectorRef.detectChanges();
    }

    registerOnChange(fn: () => NonNullable<unknown>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => NonNullable<unknown>): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    byIndex(index: number): number {
        return index;
    }
}
