import { Component, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IAdvertPublicationResult } from '@models/backend/advert';
import { takeUntil } from 'rxjs';
import { ContactDetailsSectionComponent } from '../advert-sections/contact-details-section/contact-details-section.component';
import { ImagesSectionComponent } from '../advert-sections/images-section/images-section.component';
import { KeyFactSectionComponent } from '../advert-sections/key-fact-section/key-fact-section.component';
import { AdvertComponent } from '../advert/advert.component';
import { IPublishModalArgs, PublishComponent } from '../shared';

@Component({
    selector: 'advert-preview',
    templateUrl: 'advert-preview.component.html',
    styleUrls: ['advert-preview.component.less'],
})
export class AdvertPreviewComponent extends AdvertComponent implements OnInit {
    private dialog = inject(MatDialog);

    @ViewChildren(ContactDetailsSectionComponent)
    private contactDetailsSection: QueryList<ContactDetailsSectionComponent>;

    @ViewChildren(ImagesSectionComponent)
    private imagesSection: QueryList<ImagesSectionComponent>;

    @ViewChildren(KeyFactSectionComponent)
    private genericSectionComponents: QueryList<KeyFactSectionComponent>;

    ngOnInit(): void {
        super.ngOnInit();
    }

    publish(): void {
        this.genericSectionComponents.forEach((cmp) => {
            if (!cmp.isValid) {
                document.getElementById(cmp.section.id).scrollIntoView({ block: 'end' });
            }
        });

        if (!this.contactDetailsSection.first.isValid) {
            document.getElementById('contact-details-section').scrollIntoView({ block: 'end' });
            return;
        }
        if (!this.imagesSection.first.isValid) {
            document.getElementById('images-section').scrollIntoView({ block: 'end' });
            return;
        }

        const isValid =
            this.contactDetailsSection.first.isValid &&
            this.imagesSection.first.isValid &&
            this.genericSectionComponents.toArray().every((c) => c.isValid);

        if (isValid) {
            this.openPublishDialog();
        }
    }

    private openPublishDialog(): void {
        const args: IPublishModalArgs = {
            advert: this.advert,
            mode: 'publish',
        };
        const dialog = this.dialog.open(PublishComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((results: IAdvertPublicationResult[]) => {
                const isSuccess = results.some((r) => r.isSuccess);
                if (isSuccess) {
                    this.ngOnInit();
                }
            });
    }

    byIndex(index: number): number {
        return index;
    }
}
