export enum GoogleAnalyticsEvents {
    AdvertBookmarkAdded = 'advert_bookmark_added',
    AdvertBookmarkRemoved = 'advert_bookmark_removed',
    AdvertPublished = 'advert_published',
    AdvertReservationCancelled = 'advert_reservation_cancelled',
    AdvertReserved = 'advert_reserved',
    AdvertsSearched = 'adverts_searched',
    AdvertsSorted = 'adverts_sorted',
    AdvertUnpublished = 'advert_unpublished',
    CountryChanged = 'country_changed',
    ExposeDownloaded = 'expose_downloaded',
    GeneralInquiryAnswered = 'general_inquiry_answered',
    GeneralInquiryCreated = 'general_inquiry_created',
    GeneralInquiryEdited = 'general_inquiry_edited',
    GeneralInquiryDeleted = 'general_inquiry_deleted',
    LanguageChanged = 'language_changed',
    ProspectAnswered = 'prospect_answered',
    ProspectCreated = 'prospects_created',
    ProspectDeleted = 'prospect_deleted',
    ProspectEdited = 'prospect_edited',
    ProspectRejected = 'prospects_rejected',
    ProspectsInBulkDeleted = 'prospects_in_bulk_deleted',
    ProspectsInBulkRejected = 'prospects_in_bulk_rejected',
    ProspectsSearched = 'prospects_searched',
    UserLoggedOut = 'user_logged_out',
    ViewingConfirmationSent = 'viewing_confirmation_sent',
    ViewingEventAttendeeConfirmed = 'viewing_event_attendee_confirmed',
    ViewingEventCarriedOut = 'viewing_event_carried_out',
    ViewingEventConfirmed = 'viewing_event_invitee_confirmed',
    ViewingEventCreated = 'viewing_event_created',
    ViewingEventDeclined = 'viewing_event_invitee_declined',
    ViewingEventDeleted = 'viewing_event_deleted',
    ViewingEventDownloaded = 'viewing_event_downloaded',
    ViewingEventFiltered = 'viewing_event_filetered',
    ViewingEventViewingInviteeList = 'viewing_event_invitee_list_viewed',
    ViewingInvitationSent = 'viewing_invitation_sent',
}
