import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'leave-page-dialog',
    templateUrl: './leave-page-dialog.component.html',
    styleUrls: ['./leave-page-dialog.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeavePageDialogComponent {
    dialogRef = inject<MatDialogRef<LeavePageDialogComponent>>(MatDialogRef);


    close(backToPage: boolean = false): void {
        this.dialogRef.close(backToPage);
    }
}
