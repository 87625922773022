import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IStreetNamesResponseBody } from '@models/backend/responses';
import { AdvertService } from '@services/advert.service';
import { debounceTime, filter, finalize, of, switchMap, tap } from 'rxjs';
import { IAutocompleteData } from '../autocomplete/autocomplete';

@Component({
    selector: 'street-search',
    templateUrl: './street-search.component.html',
    styleUrls: ['./street-search.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StreetSearchComponent),
            multi: true,
        },
    ],
})
export class StreetSearchComponent implements ControlValueAccessor {
    private advertService = inject(AdvertService);
    private cdr = inject(ChangeDetectorRef);

    @Output() streetSelectionEvent = new EventEmitter<string>();
    @Output() streetSelectionCleardEvent = new EventEmitter<void>();

    streetNames: IAutocompleteData[] = [];

    showStreetSearchLoader: boolean = false;

    // eslint-disable-next-line
    value: any = { id: null, label: null };

    // eslint-disable-next-line
    writeValue(value: any): void {
        this.value = value;
        this.onChange(value);
    }

    // eslint-disable-next-line
    onChange: any = () => {};

    // eslint-disable-next-line
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line
    onTouched: any = () => {};

    // eslint-disable-next-line
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onInput(event: Event): void {
        this.value = (event.target as HTMLInputElement).value;
        if (this.value < 3) {
            return;
        }

        this.streetNamesSearch();

        this.onChange(this.value);
    }

    onStreetSelection(event: MatAutocompleteSelectedEvent): void {
        this.value = event.option.value.id;

        this.onChange(this.value);
        this.streetSelectionEvent.emit(this.value);
    }

    onStreetSearchCleaned(): void {
        this.streetSelectionCleardEvent.emit();
    }

    private streetNamesSearch(): void {
        of(this.value)
            .pipe(
                debounceTime(500),
                tap((search) => {
                    if (search?.length < 3) {
                        this.streetNames = null;
                    }
                }),
                filter((search) => search?.length >= 3),
                tap(() => {
                    this.showStreetSearchLoader = true;
                }),
                switchMap((searchString: string) => {
                    return this.advertService.getStreetNames(searchString).pipe(
                        finalize(() => {
                            this.showStreetSearchLoader = false;
                        }),
                    );
                }),
            )
            .subscribe((response: IStreetNamesResponseBody) => {
                this.showStreetSearchLoader = false;
                const streetNames = response.data.map((item: string) => {
                    return {
                        id: item,
                        label: item,
                    };
                });
                this.streetNames = streetNames;
                this.cdr.detectChanges();
            });
    }
}
