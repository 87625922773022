import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, NgZone, OnInit, inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { areAllObjectValuesNotNull } from '@helpers/are-object-property-empty';

@Directive({
    selector: '[highlightActiveFormControl]',
})
export class HighlightActiveFormControlDirective implements OnInit {
    private formControl = inject(NgControl);
    private elementRef = inject(ElementRef);
    private ngZone = inject(NgZone);
    private document = inject<Document>(DOCUMENT);

    @HostListener('focus', ['$event.target'])
    onFocus(target: HTMLElement) {
        if (!(target instanceof HTMLInputElement)) {
            target.blur();
        }
    }

    ngOnInit(): void {
        // should run outside angular change detection zone
        this.ngZone.runOutsideAngular(() => {
            this.toggleActiveClass();
            this.formControl.valueChanges.subscribe(() => {
                this.toggleActiveClass();
            });
        });
    }

    private toggleActiveClass(): void {
        const elementRef = this.elementRef.nativeElement;
        const formControlValue = this.formControl.value;

        const matFormField =
            this.findTargetElementParent(elementRef, 'mat-form-field') ||
            this.findTargetElementParent(elementRef, 'autocomplete');

        const isRegionControl = this.formControl.name === 'region';
        const condition = formControlValue && areAllObjectValuesNotNull(formControlValue);

        if (matFormField) {
            this.addActiveClassToElementByCondition(condition, matFormField);
        }

        if (isRegionControl) {
            this.removeActiveClassFromBranchSelect();
        }
    }

    private addActiveClassToElementByCondition(condition: boolean, target: Element): void {
        if (condition) {
            target.classList.add('active');
            return;
        }

        target.classList.remove('active');
    }

    private removeActiveClassFromBranchSelect(): void {
        const branchSelect = this.document.querySelector('[formControlName="branch"]');
        const branch = this.findTargetElementParent(branchSelect, 'mat-form-field');
        if (branch) {
            branch.classList.remove('active');
        }
    }

    private findTargetElementParent(element: Element, parent: string): Element | null {
        if (!element) return null;

        if (element.tagName.toLowerCase() === parent) {
            return element;
        }

        return this.findTargetElementParent(element.parentElement, parent);
    }
}
