import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InviteeViewModel } from '@models/invitee';

@Component({
    selector: 'preview-invitee-state',
    templateUrl: './preview-invitee-state.component.html',
    styleUrls: ['./preview-invitee-state.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewInviteeStateComponent {
    @Input() invitee: InviteeViewModel;
    @Input() shouldShowInviteeStatus: boolean = false;
}
