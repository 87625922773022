import { IPostalAddress } from './backend/advert';
import {
    IAdvertNotificationData,
    INotification,
    IProspectStatusChangedData,
    NotificationType,
} from './backend/notifications';

export class NotificationViewModel implements INotification {
    id: string;
    type: NotificationType;
    recipientId: string;
    createDate: Date;
    readDate: Date;
    isRead: boolean;
    ttl: number;
    data: IAdvertNotificationData | IProspectStatusChangedData;

    static factory(notification: INotification): NotificationViewModel {
        const vm = new NotificationViewModel();
        vm.id = notification.id;
        vm.type = notification.type;
        vm.recipientId = notification.recipientId;
        vm.createDate = new Date(notification.createDate);
        vm.readDate = new Date(notification.readDate);
        vm.isRead = notification.isRead;
        vm.ttl = notification.ttl;
        vm.data = notification.data;
        return vm;
    }

    get isAdvertContactNotification(): boolean {
        return this.type === 'newAdvertContact';
    }

    get isProspectStatusChangedNotification(): boolean {
        return this.type === 'newProspectsForAdvert';
    }

    get address(): IPostalAddress {
        return (this.data as IAdvertNotificationData).address;
    }
}
