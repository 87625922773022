<div class="time-picker-dialog">
    <section class="head">
        <article class="subtitle">{{ 'SHARED_COMPONENT.SELECT_TIME' | translate }}</article>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="time">
        <time-picker [formControl]="form"></time-picker>
    </section>
    <section class="actions">
        <button mat-flat-button mat-dialog-close>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()">
            {{ 'SHARED_COMPONENT.SAVE' | translate }}
        </button>
    </section>
</div>
