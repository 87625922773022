<div class="phone-number" *ngIf="phoneNumber">
    <aside>
        <section class="part">
            <mat-form-field>
                <input
                    data-test="phone-country-code"
                    matInput
                    disabled
                    class="country"
                    tabindex="-1"
                    type="text"
                    [value]="countryCodeTranslationKey | translate"
                />
                <mat-label>{{ 'SHARED_COMPONENT.COUNTRY_DIALING_CODE' | translate }}</mat-label>
            </mat-form-field>
        </section>
    </aside>
    <aside [formGroup]="phoneForm">
        <section class="part area">
            <mat-form-field>
                <input
                    data-test="phone-area-code"
                    matInput
                    tabindex="-1"
                    class="area-code"
                    type="text"
                    formControlName="phoneAreaCode"
                />
                <mat-label>{{ 'SHARED_COMPONENT.AREA_CODE' | translate }}</mat-label>
            </mat-form-field>
        </section>
        <section class="part phone">
            <mat-form-field>
                <input data-test="phone-main-number" matInput tabindex="-1" type="text" formControlName="mainNumber" />
                <mat-label>{{ 'KEY_FACTS.phoneNumber' | translate }}</mat-label>
            </mat-form-field>
        </section>
    </aside>
</div>
