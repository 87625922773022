import { Component, Input } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';

@Component({
    selector: 'parking-space-stats',
    templateUrl: './parking-space-stats.component.html',
})
export class ParkingSpaceStatsComponent {
    @Input() advert: AdvertViewModel;
}
