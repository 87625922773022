import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { AdvertViewModel } from '@models/advert/advert';
import { IContact } from '@models/advert/contact';
import { extractContacts } from '@models/advert/extractors/contacts-extractor';
import { IAdvertFoundResponseBody } from '@models/backend/responses';
import { ValidatedSection } from '@models/common/validated-section';
import { TranslateService } from '@ngx-translate/core';
import { ContactsService } from '@services/contacts.service';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'contact-details-section',
    templateUrl: 'contact-details-section.component.html',
})
export class ContactDetailsSectionComponent extends ValidatedSection implements OnInit {
    private contactsService = inject(ContactsService);
    private router = inject(Router);
    private translateService = inject(TranslateService);

    @Input()
    advert: AdvertViewModel;
    @Input()
    contacts: IContact[];

    isSubstituteContactEnabled: boolean = false;
    isLoading: boolean = false;
    substituteContactAutocompleteItems: IAutocompleteData[];
    substituteContactForm: UntypedFormControl = new UntypedFormControl();

    get hasServiceCenterOption(): boolean {
        return this.advert.isManagedByMontrealOffice;
    }

    get selectedContact(): IContact {
        return this.advert.hasSubstituteContact ? this.advert.substituteContact : this.advert.mainContact;
    }

    get isValid(): boolean {
        return this.advert.hasSubstituteContact ? this.advert.isSubstituteContactValid : this.advert.isMainContactValid;
    }

    get isPhoneNumberVisible(): boolean {
        return (
            this.advert.isGermanUnit ||
            this.advert.isFrenchUnit ||
            this.advert.isCanadianUnit ||
            this.advert.isSwedishUnit
        );
    }

    get warningMessage(): string {
        return this.translateService.instant('SHARED_COMPONENT.INCOMPLETE_CONTACT_WARNING');
    }

    ngOnInit(): void {
        if (!this.contacts) {
            return;
        }

        this.substituteContactAutocompleteItems = this.getSubstituteContactAutocompleteItems();

        if (this.cannotFindSubstituteContact()) {
            this.advert.substituteContact = undefined;
        }

        this.isSubstituteContactEnabled = this.advert.hasSubstituteContact;
        if (this.isSubstituteContactEnabled) {
            const { email, firstName, lastName } = this.advert.substituteContact;
            this.substituteContactForm.setValue({ id: email, label: `${firstName} ${lastName}` });
        }
    }

    useServiceCenterPhoneNumberChanged(newValue: boolean): void {
        this.advert.useServiceCenterPhoneNumber = newValue;
        this.isLoading = true;
        this.contactsService
            .updateWithServiceCenterNumber(this.advert.id, newValue)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.advertMetadataUpdated(res));
    }

    isPhoneNumberShownOnPortalChanged(newValue: boolean): void {
        this.advert.isPhoneNumberShownOnPortal = newValue;
        this.isLoading = true;
        this.contactsService
            .updatePhoneNumberShowOnPortal(this.advert.id, newValue)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.advertMetadataUpdated(res));
    }

    private advertMetadataUpdated(res: IAdvertFoundResponseBody): void {
        this.isLoading = false;
        this.advert = AdvertViewModel.factory(res.data);
    }

    toggleSubstituteContact(isEnabled: boolean): void {
        this.isSubstituteContactEnabled = isEnabled;
        if (!isEnabled) {
            this.advert.substituteContact = undefined;
            this.substituteContactForm.setValue({ id: null, label: '' });
            this.saveContact();
        }
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const contact = this.contacts.find((c) => c.email === event.option.value.id);
        this.advert.substituteContact = contact;
        this.saveContact();
    }

    getDomId(field: string): string {
        return `${this.advert.countryCode.toLowerCase()}-${this.advert.type}-contact-details-section.${field}`;
    }

    private cannotFindSubstituteContact(): boolean {
        return this.advert.hasSubstituteContact && this.hasNotContactsSubstituteContact();
    }

    private hasNotContactsSubstituteContact(): boolean {
        return !this.contacts.find((c) => c.email === this.advert.substituteContact.email);
    }

    private saveContact(): void {
        this.isLoading = true;
        const contactDetails = extractContacts(this.advert);
        this.contactsService
            .saveContact(this.advert.id, contactDetails)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.saveCompleted(res));
    }

    private saveCompleted(res: IAdvertFoundResponseBody): void {
        this.isLoading = false;
        this.advert = AdvertViewModel.factory(res.data);
    }

    private getSubstituteContactAutocompleteItems(): IAutocompleteData[] {
        return this.contacts
            .filter((c) => c.email !== this.advert.mainContact.email)
            .map((c) => ({
                id: c.email,
                label: `${c.firstName} ${c.lastName}`,
            }));
    }

    navigateToAdvert(): void {
        this.router.navigate([`/advert/${this.advert.id}`], { queryParams: { sectionId: 'images-section' } });
    }

    byIndex(index: number): number {
        return index;
    }
}
