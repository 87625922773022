<mat-form-field>
    <input
        matInput
        type="text"
        [matAutocomplete]="auto"
        #inputAutoComplete
        matAutocompletePosition="below"
        [formControl]="searchControl"
        (blur)="onTouched()"
    />
    <mat-label>{{ placeholder }}</mat-label>

    <mat-icon [class.disabled]="disable" *ngIf="isEmptySearch && !showLoading" matSuffix> search </mat-icon>
    <mat-icon *ngIf="showCloseBtn && !showLoading" matSuffix clickAndKeydown (actionTriggered)="reset()">
        close
    </mat-icon>

    <mat-spinner matSuffix class="spinner" *ngIf="showLoading" [diameter]="24"></mat-spinner>

    <mat-error *ngIf="searchControl.errors?.required">
        {{ 'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate }}
    </mat-error>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="displayFn">
    <ng-container *ngIf="options$ | async as optionsList">
        <mat-option
            *ngFor="let option of optionsList; trackBy: byOptionId"
            [value]="prepareValue(option)"
            class="provided"
        >
            <ng-container *ngIf="translateLabels">{{ option.label | translate }}</ng-container>
            <ng-container *ngIf="!translateLabels">{{ option.label }}</ng-container>
        </mat-option>
        <mat-option
            *ngIf="optionsList.length === 0 && !doesNotExist && searchControl.value?.id !== null"
            value=""
            disabled="true"
        >
            {{ 'SHARED_COMPONENT.NO_RESULT' | translate }}
        </mat-option>
    </ng-container>
</mat-autocomplete>
