@if (!_isEditing) {
  <span class="mat-mdc-chip-focus-overlay"></span>
}

<span class="mdc-evolution-chip__cell mdc-evolution-chip__cell--primary" role="gridcell"
    matChipAction
    [disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    [attr.aria-describedby]="_ariaDescriptionId">
  @if (leadingIcon) {
    <span class="mdc-evolution-chip__graphic mat-mdc-chip-graphic">
      <ng-content select="mat-chip-avatar, [matChipAvatar]"></ng-content>
    </span>
  }

  <span class="mdc-evolution-chip__text-label mat-mdc-chip-action-label">
    @if (_isEditing) {
      @if (contentEditInput) {
        <ng-content select="[matChipEditInput]"></ng-content>
      } @else {
        <span matChipEditInput></span>
      }
    } @else {
      <ng-content></ng-content>
    }

    <span class="mat-mdc-chip-primary-focus-indicator mat-mdc-focus-indicator" aria-hidden="true"></span>
  </span>
</span>

@if (_hasTrailingIcon()) {
  <span
    class="mdc-evolution-chip__cell mdc-evolution-chip__cell--trailing"
    role="gridcell">
    <ng-content select="mat-chip-trailing-icon,[matChipRemove],[matChipTrailingIcon]"></ng-content>
  </span>
}

<span class="cdk-visually-hidden" [id]="_ariaDescriptionId">{{ariaDescription}}</span>
