import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralInquiryHelper } from '@helpers/general-inquiry.helper';
import { GeneralInquiryStatus } from '@models/backend/general-inquiry';
import { IDropdownItem } from '@models/common/dropdown-item';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '@services/country.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IGeneralInquiryModalArgs } from '../types';

@Component({
    selector: 'general-inquiry-status',
    templateUrl: 'general-inquiry-status.component.html',
    styleUrls: ['general-inquiry-status.component.less'],
})
export class GeneralInquiryStatusComponent extends UnSubscriptionDirective implements OnInit {
    private countryService = inject(CountryService);
    private translateService = inject(TranslateService);

    dialogRef = inject<MatDialogRef<GeneralInquiryStatusComponent>>(MatDialogRef);
    args = inject<IGeneralInquiryModalArgs>(MAT_DIALOG_DATA);

    options: IDropdownItem<GeneralInquiryStatus>[];
    selectedStatus: GeneralInquiryStatus;
    viewType: 'status' | 'loading' | 'finish';

    get isLoading(): boolean {
        return this.viewType === 'loading';
    }

    get successMessage(): string {
        return this.translateService.instant('GENERAL_INQUIRIES_VIEW.EDIT_SUCCESS_MESSAGE');
    }

    ngOnInit(): void {
        const country = this.countryService.getCurrentCountry();
        this.options = GeneralInquiryHelper.getGeneralInquiryStatusItems(country);
        this.selectedStatus = this.options[0].context;
        this.viewType = 'status';
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.cancel());
    }

    save(): void {
        this.dialogRef.close(this.selectedStatus);
    }

    cancel(): void {
        const result = this.viewType === 'finish' ? this.selectedStatus : null;
        this.dialogRef.close(result);
    }

    byIndex(index: number): number {
        return index;
    }
}
