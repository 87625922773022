import { Injectable } from '@angular/core';
import { captureEvent, captureException, captureMessage, Event, SeverityLevel } from '@sentry/browser';
import { INITIALIZER, OPTIONS } from './config';

/**
 * this service used to set custom or manual exception
 * Usage: this.sentryService.captureException(new Error(''))
 */

@Injectable({
    providedIn: 'root',
    deps: [OPTIONS, INITIALIZER],
})
export class SentryService {
    public captureMessage(message: string, level: SeverityLevel): string {
        return captureMessage(message, level);
    }

    public captureException(exception: unknown): string {
        return captureException(exception);
    }

    public captureEvent(event: Event): string {
        return captureEvent(event);
    }
}
