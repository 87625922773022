import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IProspectModalArgs } from '@components/advert-prospects/advert-prospects.component';
import { ProspectEditorComponent } from '@components/advert-prospects/prospect-editor/prospect-editor.component';
import { ProspectEditDialogResult } from '@components/advert-prospects/types';
import { TimeHelper } from '@helpers/time.helper';
import { IProspect, ProspectStatus } from '@models/backend/prospects';
import { IViewingInviteeResponseResponseBody } from '@models/backend/responses';
import { DateFormat } from '@models/common/date-format';
import { ProspectViewModel } from '@models/prospect';
import { IInviteeViewModel, IViewingInviteeResponseModel, ViewingViewModel } from '@models/viewing/viewing';
import { TranslateService } from '@ngx-translate/core';
import { ProspectsService } from '@services/prospects.service';
import { SnackBarService } from '@services/snack-bar.service';
import { ViewingsService } from '@services/viewings.service';
import moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IMessageBoxArgs, MessageBoxComponent } from '../../shared';

@Component({
    selector: 'viewing-item',
    templateUrl: './viewing-item.component.html',
    styleUrls: ['./viewing-item.component.less'],
})
export class ViewingItemComponent extends UnSubscriptionDirective {
    private translateService = inject(TranslateService);
    private viewingService = inject(ViewingsService);
    private dialog = inject(MatDialog);
    private prospectsService = inject(ProspectsService);
    private snackBarService = inject(SnackBarService);

    @Input() viewing: ViewingViewModel;
    @Input() advertId: string;
    @Output() viewingDeleted = new EventEmitter();
    @Output() viewingCopied = new EventEmitter();
    @Output() viewingEdited = new EventEmitter();
    @Output() viewingShowInvitees = new EventEmitter();
    @Output() viewingCalenderFile = new EventEmitter();
    @Output() refreshViewings = new EventEmitter();

    get dateTitle(): string {
        const day = this.viewing.date.getDay();
        const dayLabel = TimeHelper.geDayLabel(day);
        const translatedDay = this.translateService.instant(dayLabel);

        const dateFormat = DateFormat[this.translateService.currentLang];
        const date = moment(this.viewing.date).format(dateFormat);

        const startTime = TimeHelper.getInputFormattedValue(this.viewing.startTime);
        const endTime = TimeHelper.getInputFormattedValue(this.viewing.endTime);

        return `${translatedDay}, ${date}, ${startTime} - ${endTime}`;
    }

    get inviteesLabel(): string {
        const label = this.viewing.totalNumberOfInvitees === 1 ? 'VIEWINGS_VIEW.INVITEE' : 'VIEWINGS_VIEW.INVITEES';
        const translated = this.translateService.instant(label);
        return `${this.viewing.totalNumberOfInvitees} ${translated}`;
    }

    get statusLabel(): string {
        return this.translateService.instant(`VIEWINGS_VIEW.STATUS.${this.viewing.status}`);
    }

    get showCalendarEventIcon(): boolean {
        return this.viewing.isScheduled || this.viewing.isDeclined || this.viewing.isPending || this.viewing.isDraft;
    }

    get showDeleteAction(): boolean {
        return this.viewing.isDraft || this.viewing.isDeclined || this.viewing.isArchived;
    }

    delete(): void {
        const translatedMessageText = this.translateService.instant('SHARED_COMPONENT.DELETE_CONFIRMATION');
        const translatedTitleText = this.translateService.instant('SHARED_COMPONENT.CONFIRMATION');
        const args: IMessageBoxArgs = {
            translatedMessageText,
            translatedTitleText,
        };

        const dialog = this.dialog.open(MessageBoxComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isConfirmed) => {
                if (isConfirmed) {
                    this.viewingDeleted.emit();
                }
            });
    }

    edit(): void {
        this.viewingEdited.emit();
    }

    copy(): void {
        this.viewingCopied.emit();
    }

    showInvitees(): void {
        this.viewingShowInvitees.emit();
    }

    generateCalendarEventFile(): void {
        this.viewingCalenderFile.emit();
    }

    createProspect(): void {
        const args: IProspectModalArgs = {
            advertId: null,
            prospects: null,
            advertType: null,
            defaulProspecttStatus: ProspectStatus.ViewingAttended,
        };

        this.dialog
            .open(ProspectEditorComponent, { data: args, panelClass: 'unconstrained-dialog' })
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((dialogResult: ProspectEditDialogResult) => {
                const { prospect } = dialogResult;
                if (prospect) {
                    const prospectData = ProspectViewModel.factory(prospect);
                    this.handleProspectCreate(prospectData);
                }
            });
    }

    private handleProspectCreate(prospect: ProspectViewModel): void {
        this.prospectsService
            .createProspect(this.advertId, prospect)
            .pipe(
                catchError((error) => {
                    this.handleProspectCreateError(error);
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((response: { data: IProspect }) => {
                const createdProspect: IProspect = response.data;

                if (createdProspect) {
                    this.handleProspectEvent(createdProspect).subscribe(() => {
                        this.refreshViewings.emit();
                    });
                    this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.PROSPECT_CREATE_SUCCESS');
                }
            });
    }

    private handleProspectCreateError(error): void {
        console.error('Error occurred while creating prospect:', error);
        this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.ERROR', 9000);
    }

    private confirmAttendanceForViewing(
        data: IViewingInviteeResponseModel,
        advertId: string,
    ): Observable<IViewingInviteeResponseResponseBody> {
        return this.viewingService.replyToInvitation(data, advertId).pipe(
            catchError((error) => {
                console.error('Error occurred while inviting to viewing:', error);
                return EMPTY;
            }),
            takeUntil(this.unsubscribe$),
        );
    }

    private handleProspectEvent(response: IProspect): Observable<IProspect> {
        const invitee = this.getInvitee(ProspectViewModel.factory(response));

        const data: IViewingInviteeResponseModel = {
            operationId: 'confirm',
            inviteeIds: [invitee.id],
            viewingId: this.viewing.id,
        };

        return this.confirmAttendanceForViewing(data, this.advertId).pipe(
            switchMap(() => this.updateProspectStatus(response)),
        );
    }

    private updateProspectStatus(prospect: IProspect): Observable<IProspect> {
        return this.prospectsService
            .updateProspect(this.advertId, ProspectViewModel.factory(prospect))
            .pipe(takeUntil(this.unsubscribe$));
    }

    private getInvitee(prospect: ProspectViewModel): IInviteeViewModel {
        const { id, name, email, phone } = prospect;
        return { id, name, email, phone, response: 'confirmed', hasAttended: true };
    }
}
