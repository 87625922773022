import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IRegion } from '@models/backend/common';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IRotationTeaserFilter } from '../types';

@Component({
    selector: 'rotation-filter',
    templateUrl: './rotation-filter.component.html',
    styleUrls: ['./rotation-filter.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RotationFilterComponent extends UnSubscriptionDirective implements OnInit {
    @Input() regions: IRegion[];
    @Output() rotationTeaserFilterChanged = new EventEmitter<IRotationTeaserFilter>();

    get costCenterCodeIcon(): 'search' | 'close' {
        const costCenterCode = this.form.get('costCenterCode').value;

        if (costCenterCode) {
            return 'close';
        }

        return 'search';
    }

    form: FormGroup = new FormGroup({
        region: new FormControl<IRegion>(null),
        costCenterCode: new FormControl<string>(''),
    });

    ngOnInit(): void {
        this.onFilterChanged();
    }

    resetCostCenterCode(): void {
        this.form.get('costCenterCode').reset();
    }

    onFilterChanged(): void {
        this.form.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.unsubscribe$))
            .subscribe((filter) => {
                const filterOptions: IRotationTeaserFilter = {
                    region: filter.region,
                    costCenterCode: filter.costCenterCode,
                };

                this.rotationTeaserFilterChanged.emit(filterOptions);
            });
    }

    clearFilters(): void {
        if (this.form.value.region || this.form.value.costCenterCode) {
            this.form.reset();
        }
    }
}
