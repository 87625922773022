import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IUpdateNotification {
    text: string;
    title?: string;
}

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.less'],
})
export class NotificationComponent implements OnInit {
    private dialogRef = inject<MatDialogRef<NotificationComponent>>(MatDialogRef);
    args = inject<IUpdateNotification>(MAT_DIALOG_DATA);

    title: string;
    infoMessage: string;

    ngOnInit(): void {
        this.title = this.args.title;
        this.infoMessage = this.args.text;
    }

    save(): void {
        this.dialogRef.close(true);
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
