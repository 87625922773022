import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { sortByAscendingForCharacters } from '@helpers/string.helper';
import { MarketingChannel } from '@models/backend/prospects';

export function getSourceOptions(countryCode: string): IAutocompleteData<MarketingChannel>[] {
    let options = [
        MarketingChannel.AkeliusWeb,
        MarketingChannel.Other,
        MarketingChannel.Referral,
        MarketingChannel.WordOfMouth,
        MarketingChannel.SearchEngine,
        MarketingChannel.Flyer,
        MarketingChannel.BuildingSign,
        MarketingChannel.PhoneCall,
        MarketingChannel.Chatbot,
    ];

    if (countryCode === 'DE') {
        options = [
            ...options,
            MarketingChannel.ImmoScout24,
            MarketingChannel.Immowelt,
            MarketingChannel.Facebook,
            MarketingChannel.GoogleAds,
        ];
    }

    if (countryCode === 'FR') {
        options = [...options, MarketingChannel.SeLoger, MarketingChannel.GoogleAds];
    }

    if (countryCode === 'GB') {
        options = [...options, MarketingChannel.Rightmove, MarketingChannel.Zoopla, MarketingChannel.GoogleAds];
    }

    if (countryCode === 'CA') {
        options = [
            MarketingChannel.GoogleAds,
            MarketingChannel.AkeliusWeb,
            MarketingChannel.ListingSite,
            MarketingChannel.BillboardsAndOtherSigns,
            MarketingChannel.SocialMedia,
            MarketingChannel.SignAtAnAkeliusProperty,
            MarketingChannel.ReferredByAFriendOrWordOfMouth,
            MarketingChannel.PreviouslyOrCurrentlyATenantWithAkelius,
            MarketingChannel.GoogleSearch,
            MarketingChannel.FlyerInTheMail,
            MarketingChannel.NewspaperOrMagazineAd,
            MarketingChannel.Other,
        ];
    }

    if (countryCode === 'US') {
        options = [...options, MarketingChannel.StreetEasy];
    }

    return mapMarketingChannel(options).sort((a, b) => sortByAscendingForCharacters(a.id, b.id));
}

function mapMarketingChannel(marketingChannels: MarketingChannel[]): IAutocompleteData<MarketingChannel>[] {
    return marketingChannels.map(
        (channel): IAutocompleteData<MarketingChannel> => ({
            id: channel,
            label: `PROSPECTS.MARKETING_CHANNEL.${channel}`,
        }),
    );
}
