import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ExpandableElement } from '@models/common/expandable-element';
import { GeneralInquiryViewModel } from '@models/general-inquiry';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { AnalyticsService } from '@services/analytics.service';
import { EMAIL_REGEX } from '@validators/regex';

@Component({
    selector: 'general-inquiry-item',
    templateUrl: 'general-inquiry-item.component.html',
    styleUrls: ['general-inquiry-item.component.less'],
})
export class GeneralInquiryItemComponent extends ExpandableElement {
    private gaService = inject(AnalyticsService);

    @Input()
    generalInquiry: GeneralInquiryViewModel;

    @Output()
    emailSelected = new EventEmitter<GeneralInquiryViewModel>();

    @Output()
    editClicked = new EventEmitter<GeneralInquiryViewModel>();

    @Output()
    deleteSelected = new EventEmitter<GeneralInquiryViewModel>();

    @Output()
    statusChanged = new EventEmitter<GeneralInquiryViewModel>();

    @Output()
    createSelected = new EventEmitter<GeneralInquiryViewModel>();

    get isValidEmail(): boolean {
        const { email, phone } = this.generalInquiry;
        if (!email && phone) {
            return true;
        }

        return EMAIL_REGEX.test(email);
    }

    writeEmail(): void {
        this.gaService.event(GoogleAnalyticsEvents.GeneralInquiryAnswered);
        this.emailSelected.emit(this.generalInquiry);
    }

    edit(): void {
        this.editClicked.emit(this.generalInquiry);
    }

    delete(): void {
        this.deleteSelected.emit(this.generalInquiry);
    }

    setStatus(): void {
        this.statusChanged.emit(this.generalInquiry);
    }

    copy(): void {
        this.createSelected.emit(this.generalInquiry);
    }
}
