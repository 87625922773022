import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralInquiryHelper } from '@helpers/general-inquiry.helper';
import { PhoneNumberCountryCodes, PhoneNumberExamples } from '@helpers/phone-number.helper';
import { SupportedCountryCode } from '@models/backend/common';
import { GeneralInquiryStatus } from '@models/backend/general-inquiry';
import { GeneralInquiryViewModel } from '@models/general-inquiry';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';
import { CountryService } from '@services/country.service';
import { UserSettingsService } from '@services/user-settings.service';
import { emailOrPhoneValidator } from '@validators/email-phone-custom-validation';
import { EMAIL_REGEX, PHONE_REGEX } from '@validators/regex';
import moment, { Moment } from 'moment';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IGeneralInquiryModalArgs } from '../types';

@Component({
    selector: 'general-inquiry-editor',
    templateUrl: 'general-inquiry-editor.component.html',
    styleUrls: ['general-inquiry-editor.component.less'],
})
export class GeneralInquiryEditorComponent extends UnSubscriptionDirective implements OnInit {
    private countryService = inject(CountryService);
    private translateService = inject(TranslateService);
    private gaService = inject(AnalyticsService);

    private userSettingsService = inject(UserSettingsService);
    dialogRef = inject<MatDialogRef<GeneralInquiryEditorComponent>>(MatDialogRef);
    args = inject<IGeneralInquiryModalArgs>(MAT_DIALOG_DATA);

    generalInquiry: GeneralInquiryViewModel;
    isEditMode: boolean = false;
    statusOptions: string[];

    generalInquiryForm: UntypedFormGroup;
    isMobileSafariBrowser: boolean = false;

    countryCode: SupportedCountryCode;

    defaulGeneralInquiryStatus: GeneralInquiryStatus;

    get title(): string {
        return this.isEditMode
            ? 'GENERAL_INQUIRIES_VIEW.EDIT_GENERAL_INQUIRY'
            : 'GENERAL_INQUIRIES_VIEW.CREATE_GENERAL_INQUIRY';
    }

    get cancelButtonText(): string {
        return 'SHARED_COMPONENT.CANCEL';
    }

    get successMessage(): string {
        const tag = this.isEditMode
            ? 'GENERAL_INQUIRIES_VIEW.EDIT_SUCCESS_MESSAGE'
            : 'GENERAL_INQUIRIES_VIEW.CREATE_SUCCESS_MESSAGE';
        return this.translateService.instant(tag);
    }

    get errorMessage(): string {
        return this.translateService.instant('GENERAL_INQUIRIES_VIEW.EDIT_ERROR_MESSAGE');
    }

    get canContinue(): boolean {
        return this.generalInquiryForm.valid;
    }

    get emailFieldError(): ValidationErrors | null {
        return this.generalInquiryForm.get('email').errors;
    }

    get phoneFieldError(): ValidationErrors | null {
        return this.generalInquiryForm.get('phone').errors;
    }
    get phoneFieldPlaceholder(): string {
        const forExempleText = this.translateService.instant('SHARED_COMPONENT.FOR_EXEMPLE');

        const phoneNumberExample = PhoneNumberExamples[this.countryCode];

        return `${forExempleText} ${phoneNumberExample}`;
    }

    get today(): Moment {
        return moment();
    }
    ngOnInit(): void {
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();
        this.generalInquiry = structuredClone(this.args.generalInquiry);
        this.isEditMode = this.args.generalInquiry !== undefined;
        this.countryCode = this.countryService.getCurrentCountry();

        this.defaulGeneralInquiryStatus = this.args.defaultStatus;

        this.statusOptions = this.getStatusOptions();

        this.generalInquiryForm = new FormGroup(
            {
                name: new FormControl(this.generalInquiry.name, Validators.required),
                email: new FormControl(this.generalInquiry.email, [
                    Validators.required,
                    Validators.pattern(EMAIL_REGEX),
                ]),
                phone: new FormControl(this.resolvePhoneNumber(), [
                    Validators.required,
                    Validators.pattern(PHONE_REGEX),
                ]),
                status: new FormControl(this.getGeneralInquiryStatus(), Validators.required),
                message: new FormControl(this.generalInquiry.message),
            },
            { validators: emailOrPhoneValidator },
        );

        this.generalInquiryForm.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.generalInquiryFormChanged(val);
            });

        this.generalInquiryForm.markAllAsTouched();
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.close());
    }

    save(): void {
        const event = this.isEditMode
            ? GoogleAnalyticsEvents.GeneralInquiryEdited
            : GoogleAnalyticsEvents.GeneralInquiryCreated;
        const mode = this.isEditMode ? 'edit' : 'create';
        this.gaService.event(event);
        this.dialogRef.close({ generalInquiry: this.generalInquiry, mode });
    }

    close(): void {
        this.dialogRef.close();
    }

    phoneChangedEvent(): void {
        const phoneControl = this.generalInquiryForm.get('phone');
        const sanitizedPhone = phoneControl.value.replace(/[^+0-9]+/g, '');

        phoneControl.setValue(sanitizedPhone);
    }

    private resolvePhoneNumber(): string {
        return this.generalInquiry.phone?.replace(/[^+0-9]+/g, '') || PhoneNumberCountryCodes[this.countryCode];
    }
    private generalInquiryFormChanged(value: GeneralInquiryViewModel): void {
        this.generalInquiry.name = value.name;
        this.generalInquiry.email = value.email;
        this.generalInquiry.phone = value.phone;
        this.generalInquiry.status = value.status;
        this.generalInquiry.message = value.message;
    }

    private getStatusOptions(): string[] {
        return GeneralInquiryHelper.getStatusOptions(this.countryCode);
    }

    private getGeneralInquiryStatus(): GeneralInquiryStatus {
        if (!this.isEditMode) {
            return this.args.status || this.generalInquiry.status;
        }

        if (this.defaulGeneralInquiryStatus) {
            this.generalInquiry.status = this.defaulGeneralInquiryStatus;
        }

        return this.generalInquiry.status;
    }
}
