@if (hasItems) {
    <div class="category">
        <article class="subtitle">{{ categoryTitle() }}</article>

        <aside class="tabs">
            <ng-container>
                @for (context of category.contexts; track $index) {
                    <ng-container
                        *ngTemplateOutlet="
                            Tabs;
                            context: { context, id: category.id, currentFilter: category.currentFilter }
                        "
                    ></ng-container>
                }
            </ng-container>
        </aside>

        @for (context of category.contexts; track $index) {
            <ng-container
                *ngTemplateOutlet="List; context: { currentFilter: category.currentFilter, context }"
            ></ng-container>
        }
    </div>

    <ng-template
        #Tabs
        let-currentFilter="currentFilter"
        let-type="context.type"
        let-items="context.items"
        let-translationKey="context.translationKey"
    >
        <div
            class="tab-item"
            [ngClass]="{ selected: currentFilter === type, disabled: items.length === 0 }"
            clickAndKeydown
            (actionTriggered)="updateSelected(type)"
            (onkeydown)="updateSelected(type)"
        >
            {{ translationKey | translate }} {{ items.length }}
        </div>
    </ng-template>

    <ng-template
        #List
        let-items="context.items"
        let-display="context.display"
        let-isBookmarksMode="context.isBookmarksMode"
    >
        @if (display && items.length > 0) {
            <advert-status
                [adverts]="items"
                [isBookmarksMode]="isBookmarksMode"
                [display]="display"
                (advertBookmarkChanged)="updateBookmarkedList($event)"
                (advertPublishedChanged)="updateTopPublishedAdverts($event)"
                (advertReservationChanged)="updateReservedAdverts($event)"
            ></advert-status>
        }
    </ng-template>
}
