import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewingViewModel } from '@models/viewing/viewing';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { getInitialEndTime, getInitialStartTime } from './common';
import {
    descriptionEnRequiredValidator,
    descriptionRequiredValidator,
    endTimeRequiredValidator,
    startTimeRequiredGroupValidator,
} from './validators';

export function setupViewingForm(
    isAdditionalInfoEngAvailable: boolean,
    unsubscribe$: Subject<boolean>,
    data: ViewingViewModel = getInitialViewing(),
): UntypedFormGroup {
    const { date, startTime, endTime, meetingPoint, description, descriptionEn } = data;
    const viewingDate = date ? moment(date) : null;

    const form = new UntypedFormGroup(
        {
            date: new UntypedFormControl(viewingDate, Validators.required),
            startTime: new UntypedFormControl(startTime),
            endTime: new UntypedFormControl(endTime, endTimeRequiredValidator),
            meetingPoint: new UntypedFormControl(meetingPoint, Validators.required),
            description: new UntypedFormControl(
                description,
                isAdditionalInfoEngAvailable ? descriptionRequiredValidator : undefined,
            ),
            descriptionEn: new UntypedFormControl(
                descriptionEn,
                isAdditionalInfoEngAvailable ? descriptionEnRequiredValidator : undefined,
            ),
        },
        startTimeRequiredGroupValidator,
    );

    form.markAllAsTouched();

    form.get('startTime')
        .valueChanges.pipe(takeUntil(unsubscribe$))
        .subscribe((val) => startTimeChanged(form, val));

    form.get('description')
        .valueChanges.pipe(takeUntil(unsubscribe$))
        .subscribe(() => form.get('descriptionEn').updateValueAndValidity({ emitEvent: false }));

    form.get('descriptionEn')
        .valueChanges.pipe(takeUntil(unsubscribe$))
        .subscribe(() => form.get('description').updateValueAndValidity({ emitEvent: false }));

    return form;
}

export function getInitialViewing() {
    const now = moment().toDate();
    return ViewingViewModel.factory({
        date: null,
        description: null,
        descriptionEn: null,
        endTime: getInitialEndTime(now),
        id: null,
        invitees: [],
        meetingPoint: 'mainEntrance',
        numberOfDeletedInvitees: 1,
        totalNumberOfInvitees: 1,
        startTime: getInitialStartTime(now),
    });
}

function startTimeChanged(form: UntypedFormGroup, startTime: number): void {
    // max allowed value ist 24h - 15 mins
    const maxStartValue = 1425;

    const endTime = startTime >= maxStartValue ? startTime : startTime + 15;
    form.patchValue({ endTime }, { emitEvent: false });
}
