import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserRoleService } from '@services/user-role.service';
import { Observable, takeUntil, tap } from 'rxjs';
import { UnSubscriptionDirective } from '../directives/unsubscribe.directive';

@Injectable({
    providedIn: 'root',
})
export class RotationGuard extends UnSubscriptionDirective implements CanActivate {
    private userRoleService = inject(UserRoleService);
    private router = inject(Router);

    canActivate(): Observable<boolean> {
        return this.userRoleService.isRotationFeatureAvailable().pipe(
            tap((isRotationFeatureAvailable) => {
                if (!isRotationFeatureAvailable) {
                    this.router.navigate(['/not-found']);
                }
                return true;
            }),
            takeUntil(this.unsubscribe$),
        );
    }
}
