import { Component, Input } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';

@Component({
    selector: 'parking-space-header-stats',
    templateUrl: './parking-space-header-stats.component.html',
})
export class ParkingSpaceHeaderStatsComponent {
    @Input() advert: AdvertViewModel;
}
