import { AdvertViewModel } from '@models/advert/advert';
import { TranslateService } from '@ngx-translate/core';

export function buildEmailSubject(advert: AdvertViewModel, translateService: TranslateService): string {
    const { id, streetName, houseNumber, city, postalCode, direction } = advert;
    const prefix = translateService.instant('SHARED_COMPONENT.MAILTO_LINK_PREFIX');
    const maybeDirection = direction ? `${direction} ` : '';
    const maybeHouseNumber = houseNumber ? ` ${houseNumber}` : '';

    switch (advert.countryCode) {
        case 'DE':
        case 'SE':
        case 'FR':
            return `${prefix} ${id}, ${streetName}${maybeHouseNumber}, ${maybeDirection}${postalCode} ${city}`;
        case 'GB':
        case 'CA':
        case 'US':
        case 'CY':
            return `${prefix} ${id},${maybeHouseNumber} ${streetName}, ${maybeDirection}${postalCode} ${city}`;
        default:
            return '';
    }
}
