import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnumHelper } from '@helpers/enum.helper';
import { ProspectDeletionReason } from '@models/backend/prospects';
import { IDropdownItem } from '@models/common/dropdown-item';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import { AnalyticsService } from '@services/analytics.service';
import { ProspectsService } from '@services/prospects.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IProspectModalArgs } from '../advert-prospects.component';

export enum ProspectDeletionReasonViewType {
    Edit,
    Loading,
}

@Component({
    selector: 'prospect-deletion-reason',
    templateUrl: 'prospect-deletion-reason.component.html',
    styleUrls: ['./prospect-deletion-reason.component.less'],
})
export class ProspectDeletionReasonComponent extends UnSubscriptionDirective implements OnInit {
    private prospectsService = inject(ProspectsService);
    private router = inject(Router);
    private gaService = inject(AnalyticsService);

    dialogRef = inject<MatDialogRef<ProspectDeletionReasonComponent>>(MatDialogRef);
    args = inject<IProspectModalArgs>(MAT_DIALOG_DATA);

    deletionReasons: IDropdownItem<ProspectDeletionReason>[];
    currentViewType: ProspectDeletionReasonViewType = ProspectDeletionReasonViewType.Edit;
    selectedReason: ProspectDeletionReason;

    private prospects: ProspectViewModel[];

    get isLoading(): boolean {
        return this.currentViewType === ProspectDeletionReasonViewType.Loading;
    }

    private get isBulkDelete(): boolean {
        return this.prospects && this.prospects.length > 1;
    }

    ngOnInit(): void {
        this.deletionReasons = EnumHelper.toTranslatedArray<ProspectDeletionReason>(
            ProspectDeletionReason,
            'prospectRejectionReason',
        );
        this.selectedReason = this.deletionReasons[0].context;
        this.prospects = this.args.prospects;
    }

    save(): void {
        this.currentViewType = ProspectDeletionReasonViewType.Loading;
        this.dialogRef.disableClose = true;

        if (this.isBulkDelete) {
            this.gaService.event(GoogleAnalyticsEvents.ProspectsInBulkDeleted, 'data', `reason:${this.selectedReason}`);

            this.prospectsService
                .bulkDeleteProspects(this.args.advertId, this.selectedReason, this.prospects)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    () => this.deletedSuccess(),
                    () => this.requestFail(),
                );
        } else {
            this.gaService.event(GoogleAnalyticsEvents.ProspectDeleted, 'data', `reason:${this.selectedReason}`);

            this.prospectsService
                .deleteProspect(this.args.advertId, this.prospects[0], this.selectedReason)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    () => this.deletedSuccess(),
                    () => this.requestFail(),
                );
        }
    }

    private deletedSuccess(): void {
        this.dialogRef.close(true);
    }

    private requestFail(): void {
        this.router.navigate(['/404']);
    }

    byIndex(index: number): number {
        return index;
    }
}
