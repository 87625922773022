import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { UserSettingsService } from '@services/user-settings.service';

@Component({
    selector: 'phone-number-menu',
    templateUrl: './phone-number-menu.component.html',
    styleUrls: ['./phone-number-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberMenuComponent {
    private userSettingsService = inject(UserSettingsService);

    @Input() phoneNumber: string;

    get isMobileDevice(): boolean {
        return this.userSettingsService.isMobileDevice();
    }
}
