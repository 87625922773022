import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { EnvironmentService } from '@services/environment.service';

@Component({
    selector: 'view-unit-intranet',
    templateUrl: './view-unit-intranet.component.html',
    styleUrls: ['./view-unit-intranet.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewUnitIntranetComponent {
    private environmentService = inject(EnvironmentService);

    @Input() internalId: string;

    get viewUnitIntranetUrl(): string {
        return `${this.environmentService.getEnvinronment().intranetViewingUnitUrl}/${this.internalId}`;
    }
}
