import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdvertViewModel } from '@models/advert/advert';
import { SupportedCultureInfo } from '@models/backend/common';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { TranslateService } from '@ngx-translate/core';
import { AdvertService } from '@services/advert.service';
import { AnalyticsService } from '@services/analytics.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { CountryService } from '@services/country.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

enum DownloadExposeViewType {
    Language,
    Loading,
    Success,
    Error,
}

@Component({
    selector: 'download-expose',
    templateUrl: 'download-expose.component.html',
    styleUrls: ['download-expose.component.less'],
})
export class DownloadExposeComponent extends UnSubscriptionDirective implements OnInit {
    private advertService = inject(AdvertService);
    private countryService = inject(CountryService);
    private translateService = inject(TranslateService);
    private browserWindowService = inject(BrowserWindowService);
    private gaService = inject(AnalyticsService);
    dialogRef = inject<MatDialogRef<DownloadExposeComponent>>(MatDialogRef);
    args = inject<AdvertViewModel>(MAT_DIALOG_DATA);

    currentViewType: DownloadExposeViewType = DownloadExposeViewType.Language;
    selectedCultureInfo: SupportedCultureInfo;

    @ViewChild('downloadLink', { static: true }) private downloadLinkElement: ElementRef;

    get isLoading(): boolean {
        return this.currentViewType === DownloadExposeViewType.Loading;
    }

    get successMessage(): string {
        return this.translateService.instant('SHARED_COMPONENT.EXPOSE_DOWNLOAD_SUCCESS');
    }

    get errorMessage(): string {
        return this.translateService.instant('SHARED_COMPONENT.EXPOSE_DOWNLOAD_ERROR');
    }

    ngOnInit(): void {
        this.selectedCultureInfo = this.countryService.getCurrentCultureInfo();
    }

    languageSelected(): void {
        this.gaService.event(GoogleAnalyticsEvents.ExposeDownloaded, 'data', `language:${this.selectedCultureInfo}`);

        this.currentViewType = DownloadExposeViewType.Loading;
        this.dialogRef.disableClose = true;
        this.advertService
            .getExpose(this.args.id, this.selectedCultureInfo)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.gotExpose(res),
                () => this.requestError(),
            );
    }

    private gotExpose(blob: Blob): void {
        this.dialogRef.disableClose = false;
        this.currentViewType = DownloadExposeViewType.Success;
        const url = this.browserWindowService.getURLConstructor().createObjectURL(blob);
        const anchor = this.downloadLinkElement.nativeElement as HTMLAnchorElement;
        anchor.href = url;
        anchor.download = `Expose_${this.args.id}.pdf`;
        anchor.click();
    }

    private requestError(): void {
        this.dialogRef.disableClose = false;
        this.currentViewType = DownloadExposeViewType.Error;
    }
}
