import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AdvertViewModel } from '@models/advert/advert';
import { ValidatedSection } from '@models/common/validated-section';
import { AdvertService } from '@services/advert.service';
import { takeUntil } from 'rxjs';

type SupportedVirtualViewingPortals = 'youtube' | 'matterport' | 'zillow';

@Component({
    selector: 'virtual-viewing-section',
    templateUrl: 'virtual-viewing-section.component.html',
    styleUrls: ['virtual-viewing-section.component.less'],
})
export class VirtualViewingSectionComponent extends ValidatedSection implements OnInit {
    private advertService = inject(AdvertService);
    private router = inject(Router);
    private sanitizer = inject(DomSanitizer);

    @Input()
    advert: AdvertViewModel;

    urlForm: UntypedFormControl;
    isLoading: boolean = false;
    iFrameUrl: SafeUrl;

    get isValid(): boolean {
        return this.urlForm.valid;
    }

    get domId(): string {
        return `${this.advert.countryCode.toLowerCase()}-${this.advert.type}-virtual-viewing-section.virtualTourUrl`;
    }

    private get supportedPortal(): SupportedVirtualViewingPortals | undefined {
        if (!this.advert.virtualTourUrl) {
            return undefined;
        }

        const portalIdentifiers = new Map<string, SupportedVirtualViewingPortals>([
            ['youtu', 'youtube'],
            ['matterport', 'matterport'],
            ['zillow', 'zillow'],
        ]);

        for (const [portalsIdentifier, supportedPortal] of portalIdentifiers.entries()) {
            if (this.advert.virtualTourUrl.includes(portalsIdentifier)) {
                return supportedPortal;
            }
        }

        return undefined;
    }

    ngOnInit(): void {
        // https://github.com/angular/angular.js/commit/ffb6b2fb56d9ffcb051284965dd538629ea9687a
        const urlPattern = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

        const videoUrl = this.advert.virtualTourUrl || null;
        this.urlForm = new UntypedFormControl(videoUrl, {
            validators: Validators.pattern(urlPattern),
            updateOn: 'blur',
        });
        this.urlForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => this.urlChanged(val));

        this.iFrameUrl = this.getIframeSource();
    }

    navigateToAdvert(): void {
        this.router.navigate([`/advert/${this.advert.id}`], { queryParams: { sectionId: 'virtual-viewing-section' } });
    }

    private urlChanged(newUrl: string): void {
        this.isLoading = true;
        this.advertService
            .patchAdvertFragment(this.advert.id, { virtualTourUrl: newUrl })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => this.saveCompleted(),
                () => this.saveCompleted(),
            );
    }

    private saveCompleted(): void {
        this.isLoading = false;
    }

    private getIdFromYoutubeUrl(url: string): string {
        // https://gist.github.com/takien/4077195
        let result = '';
        const params = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (params[2] !== undefined) {
            result = params[2].split(/[^0-9a-z_\-]/i)[0];
        } else {
            result = params[0];
        }
        return result;
    }

    private getIframeSource(): SafeUrl {
        const supportedPortalLink = this.supportedPortal;
        let result = '';

        switch (supportedPortalLink) {
            case 'zillow':
            case 'matterport':
                result = this.advert.virtualTourUrl;
                break;
            case 'youtube':
                result = `https://www.youtube.com/embed/${this.getIdFromYoutubeUrl(this.advert.virtualTourUrl)}`;
                break;
        }

        // TODO(LA-5876): check here if this could be done without bypassing security (XSS issue)
        return this.sanitizer.bypassSecurityTrustResourceUrl(result); // NOSONAR
    }
}
