import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { AssetType, ImageUploadType, Orientation } from '@models/shared';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

interface IUploadImageFormData {
    assetType: 'property';
    orientation: Orientation;
    pictureDetail: IAutocompleteData;
    isExample: boolean;
    pictureType: ImageUploadType;
}

interface IImageData {
    assetType: AssetType;
    assetname: string;
    orientation: Orientation;
    pictureDetail: string;
    isExample: boolean;
}

interface IUnitImageData extends IImageData {
    assetType: 'unit';
}

interface IPropertyImageData extends IImageData {
    assetType: 'property';
    pictureType: ImageUploadType;
}

@Injectable({
    providedIn: 'root',
})
export class UploadImageService {
    private httpClient = inject(HttpClient);

    startUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    uploadImage(
        image: File,
        advertId: string,
        unitCode: string,
        form: IUploadImageFormData,
        assetType: AssetType,
        type: ImageUploadType
    ): Observable<HttpEvent<IUploadImageFormData>> {
        const formData: FormData = new FormData();
        const headers: HttpHeaders = new HttpHeaders({ enctype: 'multipart/form-data' });

        const isExampleUploadPage = type === 'examples';
        const formDataRequest = this.buildRequestBody(form, unitCode, assetType, isExampleUploadPage);

        formData.append('file', image);
        formData.append(
            'meta',
            new Blob([JSON.stringify(formDataRequest)], {
                type: 'application/json',
            }),
            'meta'
        );

        const req = new HttpRequest('POST', `units/${advertId}/images`, formData, {
            reportProgress: true,
            headers,
        });

        return this.httpClient.request(req);
    }

    private buildRequestBody(
        form: IUploadImageFormData,
        unitCode: string,
        assetType: AssetType,
        isExamplePropertyImageUploadPage: boolean
    ): IUnitImageData | IPropertyImageData {
        switch (assetType) {
            case 'unit':
                return {
                    assetType: 'unit',
                    assetname: unitCode,
                    isExample: form.isExample,
                    pictureDetail: form.pictureDetail.id,
                    orientation: form.orientation,
                };
            case 'property':
                return {
                    assetType: 'property',
                    assetname: unitCode,
                    isExample: isExamplePropertyImageUploadPage,
                    pictureDetail: form.pictureDetail.id,
                    pictureType: form.pictureType,
                    orientation: form.orientation,
                };
        }
    }
}
