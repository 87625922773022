import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IContactDetails } from '@models/backend/contacts';
import { IAdvertFoundResponseBody, IContactDetailsResponseBody } from '@models/backend/responses';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContactsService {
    private httpClient = inject(HttpClient);


    getContacts(regions?: string[]): Observable<IContactDetailsResponseBody> {
        const regionsParams = {};

        if (regions) {
            regionsParams['regions'] = regions.toString();
        }

        const params = new HttpParams({
            fromObject: regionsParams,
        });

        return this.httpClient.get<IContactDetailsResponseBody>('contacts', { params });
    }

    saveContact(advertId: string, contact: IContactDetails): Observable<IAdvertFoundResponseBody> {
        return this.httpClient.patch<IAdvertFoundResponseBody>(`units/${advertId}/contact`, contact);
    }

    updateWithServiceCenterNumber(
        advertId: string,
        useServiceCenterPhoneNumber: boolean
    ): Observable<IAdvertFoundResponseBody> {
        return this.httpClient.patch<IAdvertFoundResponseBody>(`units/${advertId}/useServiceCenterPhoneNumber`, {
            useServiceCenterPhoneNumber: useServiceCenterPhoneNumber,
        });
    }

    updatePhoneNumberShowOnPortal(
        advertId: string,
        isPhoneNumberShownOnPortal: boolean
    ): Observable<IAdvertFoundResponseBody> {
        return this.httpClient.patch<IAdvertFoundResponseBody>(`units/${advertId}/isPhoneNumberShownOnPortal`, {
            isPhoneNumberShownOnPortal: isPhoneNumberShownOnPortal,
        });
    }
}
