import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { captureMessage } from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SentryErrorInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request.clone()).pipe<HttpEvent<unknown>>(
            catchError((response: HttpErrorResponse) => {
                if (new HttpErrorResponse(response) instanceof HttpErrorResponse) {
                    captureMessage(this.getMessage(request, response));
                }

                // re-throw error
                return throwError(response);
            }),
        );
    }

    private getMessage(request: HttpRequest<unknown>, response: HttpErrorResponse): string {
        const defaultMessage = 'Http request failed. ({method}, {status}, {url})';
        const replace = (msg: string) => {
            const map = {
                method: request.method,
                url: request.url,
                status: response[0].status.toString(),
                message: response.message,
            };

            // replace all keys with their values
            Object.keys(map).forEach((key) => {
                msg = msg.replace(new RegExp(`{${key}}`, 'g'), map[key]);
            });

            return msg;
        };

        // use default message
        return replace(defaultMessage);
    }
}
