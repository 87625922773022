import { NumeralJSLocale } from 'numeral';

export const NUMBER_LOCALE_GB: NumeralJSLocale = {
    delimiters: {
        decimal: '.',
        thousands: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: () => '',
    currency: {
        symbol: 'GBP',
    },
};

export const NUMBER_LOCALE_US: NumeralJSLocale = {
    delimiters: {
        decimal: '.',
        thousands: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: () => '',
    currency: {
        symbol: 'USD',
    },
};

export const NUMBER_LOCALE_DE: NumeralJSLocale = {
    delimiters: {
        decimal: ',',
        thousands: '.',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: () => '',
    currency: {
        symbol: '€',
    },
};

export const NUMBER_LOCALE_FR: NumeralJSLocale = {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: () => '',
    currency: {
        symbol: '€',
    },
};

export const NUMBER_LOCALE_SE: NumeralJSLocale = {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: () => '',
    currency: {
        symbol: 'SEK',
    },
};
