import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-indicator',
    templateUrl: 'loading-indicator.component.html',
    styleUrls: ['loading-indicator.component.less'],
})
export class LoadingIndicatorComponent {
    @Input() isEmbedded: boolean = false;
    @Input() isPermanent: boolean = false;
    @Input() isSmall: boolean = false;

    private _isOpen: boolean = false;
    get isOpen() {
        return this._isOpen;
    }

    show(): void {
        this._isOpen = true;
    }

    hide(): void {
        this._isOpen = false;
    }
}
