import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/shared';
import { String } from '@helpers/string.helper';
import { SupportedCountryCode } from '@models/backend/common';
import { GeneralInquiryStatus } from '@models/backend/general-inquiry';
import { IGeneralInquiryPageResponseBody } from '@models/backend/responses';
import { GeneralInquiryViewModel } from '@models/general-inquiry';
import { TranslateService } from '@ngx-translate/core';
import { BrowserWindowService } from '@services/browser-window.service';
import { CountryService } from '@services/country.service';
import { DashboardItems, DashboardService } from '@services/dashboard.service';
import { GeneralInquiryService, GeneralInquiryUpdateURequestBody } from '@services/general-inquiries.service';
import { SnackBarService } from '@services/snack-bar.service';
import { UserSettingsService } from '@services/user-settings.service';
import { EMAIL_REGEX } from '@validators/regex';
import { EMPTY, catchError, debounceTime, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { GeneralInquiryCopyComponent } from './general-inquiry-copy/general-inquiry-copy.component';
import { GeneralInquiryCopyModalArgs } from './general-inquiry-copy/types';
import { GeneralInquiryDeletionComponent } from './general-inquiry-deletion/general-inquiry-deletion.component';
import { GeneralInquiryEditorComponent } from './general-inquiry-editor/general-inquiry-editor.component';
import { GeneralInquiryStatusAfterEmailComponent } from './general-inquiry-status-after-email/general-inquiry-status-after-email.component';
import { GeneralInquiryStatusComponent } from './general-inquiry-status/general-inquiry-status.component';
import { GeneralInquiryEditDialogResult, IGeneralInquiryModalArgs } from './types';
import { compareByStatusAndDateOfReceipt } from './utils';

type Filter = { searchForm: FormControl<string>; status: FormControl<GeneralInquiryStatus> };

interface StatusFilter {
    label: string;
    value: string | null;
}

@Component({
    selector: 'general-inquiries',
    templateUrl: 'general-inquiries.component.html',
    styleUrls: ['general-inquiries.component.less'],
})
export class GeneralInquiriesComponent extends UnSubscriptionDirective implements OnInit {
    private dashboardService = inject(DashboardService);
    private translateService = inject(TranslateService);
    private generalInquiryService = inject(GeneralInquiryService);
    private userSettingsService = inject(UserSettingsService);
    private countryService = inject(CountryService);
    private browserWindowService = inject(BrowserWindowService);
    private snackBarService = inject(SnackBarService);

    dialog = inject(MatDialog);

    generalInquiries: GeneralInquiryViewModel[] = [];
    isLoading = true;
    hasInquiries: boolean = false;

    @Output() scrollEventTriggered = new EventEmitter<void>();

    statuses: StatusFilter[] = [
        { label: 'SHARED_COMPONENT.ALL', value: null },
        { label: 'GENERAL_INQUIRIES_VIEW.STATUS.new', value: 'new' },
        { label: 'GENERAL_INQUIRIES_VIEW.STATUS.messageAnswered', value: 'messageAnswered' },
    ];

    searchAndFilter: FormGroup<Filter>;

    @Output() itemCountChange = new EventEmitter<number>();

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    page = 0;
    searchForm: string;
    status: GeneralInquiryStatus;
    lastCallWasEmpty: boolean = false;
    countryCode: SupportedCountryCode;

    ngOnInit(): void {
        this.loadingIndicator.show();

        this.countryCode = this.countryService.getCurrentCountry();
        this.dashboardService.dashboardData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ generalInquiryResponse }: DashboardItems) => {
                this.gotInquiryData(generalInquiryResponse);
                this.isLoading = false;
            });

        this.searchAndFilter = new FormGroup({
            searchForm: new FormControl<string>(''),
            status: new FormControl(null),
        });

        this.searchAndFilter.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe$)).subscribe((val) => {
            this.generalInquiries = [];
            this.searchForm = val.searchForm;
            this.status = val.status;
            this.page = 0;
            this.lastCallWasEmpty = false;
            this.callSearchAndFilter(this.searchForm, this.status);
        });
    }

    private callSearchAndFilter(searchForm?: string, status?: GeneralInquiryStatus): void {
        this.loadingIndicator.show();
        this.generalInquiryService
            .getData(searchForm, status, this.page)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.gotInquiryData(res));
    }

    private gotInquiryData(res: IGeneralInquiryPageResponseBody) {
        this.loadingIndicator.hide();

        const { data, totalElements } = res;
        this.hasInquiries = totalElements > 0;
        this.itemCountChange.emit(totalElements);

        if (!data.length) {
            this.lastCallWasEmpty = true;
        }

        this.generalInquiries = [...this.generalInquiries, ...data.map((g) => GeneralInquiryViewModel.factory(g))];
    }

    email(generalInquiry: GeneralInquiryViewModel) {
        const generalInquiries = [generalInquiry];

        const subjectSegment = '';

        const isMobile = this.userSettingsService.isMobileDevice();
        const delimiter = isMobile ? ',' : ';';
        const emailAddressSegment = `${generalInquiries.map((p) => p.email).join(`${delimiter}`)}`.replace('&', '%26');

        const link = `mailto:${emailAddressSegment}?subject=${subjectSegment}&body=${this.createBodySegment(generalInquiries)}`;

        this.browserWindowService.openExternalLink(link);

        const args: IGeneralInquiryModalArgs = {
            generalInquiry,
            status: generalInquiry.status,
            defaultStatus: GeneralInquiryStatus.New,
        };

        const dialog = this.dialog.open(GeneralInquiryStatusAfterEmailComponent, { data: args });

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((status: GeneralInquiryStatus | undefined) => {
                if (!status) {
                    return;
                }

                this.handleOptimisticStatusUpdate([generalInquiry], status);
            });
    }

    edit(generalInquiry: GeneralInquiryViewModel) {
        const args: IGeneralInquiryModalArgs = {
            generalInquiry,
            status: generalInquiry.status,
        };
        this.openEditGeneralInquiryDialog(args);
    }

    delete(generalInquiry: GeneralInquiryViewModel) {
        const args: IGeneralInquiryModalArgs = {
            generalInquiry,
            status: generalInquiry.status,
            defaultStatus: GeneralInquiryStatus.New,
        };

        const dialog = this.dialog.open(GeneralInquiryDeletionComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isSuccess) => {
                if (!isSuccess) {
                    return;
                }

                const generalInquiriesCount = this.generalInquiries.length - 1;
                this.itemCountChange.emit(generalInquiriesCount);
                this.generalInquiries = this.generalInquiries.filter((p) => p.id !== generalInquiry.id);
            });
    }

    setStatus(generalInquiry: GeneralInquiryViewModel) {
        const args: IGeneralInquiryModalArgs = {
            generalInquiry,
            status: generalInquiry.status,
            defaultStatus: GeneralInquiryStatus.New,
        };

        const dialog = this.dialog.open(GeneralInquiryStatusComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((status: GeneralInquiryStatus | null) => {
                if (status) {
                    this.handleOptimisticStatusUpdate([generalInquiry], status);
                }
            });
    }

    onScroll(isLast: boolean): void {
        if (isLast && !this.lastCallWasEmpty) {
            this.page++;
            this.callSearchAndFilter(this.searchForm, this.status);
        }
    }

    convertGeneralInquiryToProspect(generalInquiry: GeneralInquiryViewModel): void {
        const args: GeneralInquiryCopyModalArgs = {
            generalInquiry,
        };
        const dialog = this.dialog.open(GeneralInquiryCopyComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((generalInquiryId) => {
                if (!generalInquiryId) {
                    return;
                }

                this.generalInquiries = [...this.generalInquiries.filter((p) => p.id !== generalInquiryId)];
                this.itemCountChange.emit(this.generalInquiries.length);
            });
    }

    private createBodySegment(generalInquiries: GeneralInquiryViewModel[]): string {
        const useNameSubstitute = String.isEmpty(generalInquiries[0].name);

        const greeting = useNameSubstitute
            ? this.translateService.instant('PROSPECTS.GREETING')
            : this.translateService.instant('PROSPECTS.HELLO', { name: generalInquiries[0].name });

        const thankYou = this.translateService.instant('PROSPECTS.THANK_EN', { streetName: '' });

        return `${greeting}${thankYou}`.replace('&', '%26'); // when the text contains & it means for the url its a mail parameter and it will not generate the email link
    }

    private handleOptimisticStatusUpdate(
        generalInquiries: GeneralInquiryViewModel[],
        status: GeneralInquiryStatus,
    ): void {
        const oldStatusesMap = new Map<string, GeneralInquiryStatus>();

        generalInquiries.forEach((generalInquiry) => {
            oldStatusesMap.set(generalInquiry.id, generalInquiry.status);
            generalInquiry.status = status;
        });

        this.generalInquiries.sort(compareByStatusAndDateOfReceipt).reverse();

        this.generalInquiryService
            .updateGeneralInquiryStatus(status, generalInquiries[0].id)
            .pipe(
                catchError(() => {
                    this.generalInquiries.forEach((generalInquiry) => {
                        if (oldStatusesMap.has(generalInquiry.id)) {
                            generalInquiry.status = oldStatusesMap.get(generalInquiry.id);
                        }
                    });

                    this.snackBarService.showSnackbar('GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.ERROR', 9000);
                    this.selectAllChanged();

                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.snackBarService.showSnackbar('GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.STATUS_UPDATE_SUCCESS');
                this.selectAllChanged();
            });
    }

    private openEditGeneralInquiryDialog(args: IGeneralInquiryModalArgs): void {
        const dialog = this.dialog.open(GeneralInquiryEditorComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((dialogResult: GeneralInquiryEditDialogResult) => {
                const { generalInquiry } = dialogResult;

                if (!generalInquiry) {
                    return;
                }

                this.handleOptimisticEditUpdate(generalInquiry);
            });
    }

    private handleOptimisticEditUpdate(updatedGeneralInquiry: GeneralInquiryViewModel): void {
        const { id, name, email, phone, status, message } = updatedGeneralInquiry;

        const generalInquiryToUpdate = this.generalInquiries.find((p) => p.id === id);
        const map = new Map<string, GeneralInquiryViewModel>();

        this.generalInquiries.forEach((generalInquiry) => {
            map.set(generalInquiry.id, generalInquiry);
        });

        this.generalInquiries = this.generalInquiries.map((inquiry) =>
            inquiry.id === updatedGeneralInquiry.id ? updatedGeneralInquiry : inquiry,
        );

        const generalInquiryRequestBody: GeneralInquiryUpdateURequestBody = {
            name,
            email,
            phone,
            status,
            message,
        };

        const hasStatusChanged = generalInquiryToUpdate.status !== status;
        if (hasStatusChanged) {
            this.generalInquiries.sort(compareByStatusAndDateOfReceipt).reverse();
        }

        this.generalInquiryService
            .updateGeneralInquiry(id, generalInquiryRequestBody)
            .pipe(
                catchError(() => {
                    this.generalInquiries = this.generalInquiries.map((generalInquiry) => map.get(generalInquiry.id));

                    this.generalInquiries.sort(compareByStatusAndDateOfReceipt).reverse();
                    this.snackBarService.showSnackbar('GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.ERROR', 9000);

                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.snackBarService.showSnackbar(
                    'GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.GENERAL_INQUIRY_UPDATE_SUCCESS',
                );
            });
    }

    selectAllChanged(): void {
        this.generalInquiries.forEach((p) => EMAIL_REGEX.test(p.email));
    }
}
