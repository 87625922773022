import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ISentryConfiguration } from '@environments/environment-model';
import { init } from '@sentry/browser';
import { INITIALIZER, OPTIONS } from './config';
import { SentryErrorHandler } from './sentry.handler';
import { SentryErrorInterceptor } from './sentry.interceptor';

export function initializer(options: ISentryConfiguration): void {
    // configure sentry's browser library
    if (options.enabled) {
        init(options);
    }
}

@NgModule({
    declarations: [],
    imports: [CommonModule],
})
export class SentryModule {
    public static forRoot(options: ISentryConfiguration): ModuleWithProviders<SentryModule> {
        return {
            ngModule: SentryModule,
            providers: [
                {
                    provide: OPTIONS,
                    useValue: options,
                },
                {
                    provide: INITIALIZER,
                    useFactory: initializer,
                    deps: [OPTIONS],
                },
                {
                    provide: ErrorHandler,
                    useClass: SentryErrorHandler,
                    deps: [OPTIONS, INITIALIZER],
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: SentryErrorInterceptor,
                    deps: [OPTIONS, INITIALIZER],
                    multi: true,
                },
            ],
        };
    }
}
