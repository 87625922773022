import { Component, Input } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';

@Component({
    selector: 'hunting-area-stats',
    templateUrl: './hunting-area-stats.component.html',
})
export class HuntingAreaStatsComponent {
    @Input() advert: AdvertViewModel;
}
