import { GeneralInquiryStatus } from '@models/backend/general-inquiry';
import { GeneralInquiryViewModel } from '@models/general-inquiry';

type ComparisonResult = -1 | 0 | 1;

const statusScoreMap = new Map<GeneralInquiryStatus, number>([
    [GeneralInquiryStatus.New, 100],
    [GeneralInquiryStatus.MessageAnswered, 99],
]);

export function compareByStatusAndDateOfReceipt(
    left: GeneralInquiryViewModel,
    right: GeneralInquiryViewModel,
): ComparisonResult {
    const { status: leftStatus, dateOfReceipt: leftDateOfReceipt } = left;
    const { status: rightStatus, dateOfReceipt: rightDateOfReceipt } = right;

    const comparisonByStatusResult = compareByStatus(leftStatus, rightStatus);
    if (comparisonByStatusResult !== 0) {
        return comparisonByStatusResult;
    }

    const result = compareByDateOfReceipt(leftDateOfReceipt, rightDateOfReceipt);

    return result;
}

function compareByDateOfReceipt(leftDateOfReceipt: Date, rightDateOfReceipt: Date): ComparisonResult {
    if (leftDateOfReceipt.getTime() <= rightDateOfReceipt.getTime()) {
        return -1;
    } else {
        return 1;
    }
}

function compareByStatus(leftStatus: GeneralInquiryStatus, rightStatus: GeneralInquiryStatus): ComparisonResult {
    const leftScore = statusScoreMap.get(leftStatus) || 0;
    const rightScore = statusScoreMap.get(rightStatus) || 0;
    if (leftScore < rightScore) {
        return -1;
    } else if (leftScore === rightScore) {
        return 0;
    } else {
        return 1;
    }
}
