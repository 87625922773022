export interface IPhoneNumber {
    phoneCountryCode: string;
    phoneAreaCode: string;
    mainNumber: string;
}

export function getPhoneNumberFromCombinedString(phoneNumber: string): IPhoneNumber {
    if (!phoneNumber) {
        return {
            phoneCountryCode: '',
            phoneAreaCode: '',
            mainNumber: '',
        };
    }

    const splitPhoneNumber = phoneNumber ? phoneNumber.split(' ') : [''];
    if (splitPhoneNumber.length === 3) {
        return {
            phoneCountryCode: splitPhoneNumber[0],
            phoneAreaCode: splitPhoneNumber[1],
            mainNumber: splitPhoneNumber[2],
        };
    }

    const countryCode = splitPhoneNumber.find((n) => n.startsWith('+')) || '';
    const phoneAreaCode = splitPhoneNumber.length > 1 ? splitPhoneNumber[1] : '';
    return {
        phoneCountryCode: countryCode,
        phoneAreaCode: phoneAreaCode,
        mainNumber: splitPhoneNumber.filter((n) => !n.startsWith('+') && n !== phoneAreaCode).join(' ') || '',
    };
}
