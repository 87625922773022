import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { SupportedCountryCode } from '@models/backend/common';
import { CountryService } from '@services/country.service';
import { EnvironmentService } from '@services/environment.service';

@Component({
    selector: 'view-unit-acm',
    templateUrl: './view-unit-acm.component.html',
    styleUrls: ['./view-unit-acm.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewUnitAcmComponent implements OnInit {
    private environmentService = inject(EnvironmentService);
    private countryCodeService = inject(CountryService);

    @Input({ required: true }) internalId: string;

    viewUnitOnACMUrl: string;
    countryCode: SupportedCountryCode;

    ngOnInit(): void {
        this.countryCode = this.countryCodeService.getCurrentCountry();
        this.viewUnitOnACMUrl = this.getViewUnitOnACMUrl();
    }

    private getViewUnitOnACMUrl(): string {
        const acmBaseUrl = this.environmentService.getEnvinronment().acmUrl;
        const acmUrlTemplate = `${acmBaseUrl}/jump_to/latest_apartment_project/${this.internalId}`;
        const lowercaseCountryCode = this.countryCode.toLowerCase();

        const acmCountryCode = lowercaseCountryCode === 'gb' ? 'uk' : lowercaseCountryCode;
        const topLevelDomain = lowercaseCountryCode === 'gb' ? 'co.uk' : lowercaseCountryCode;

        const acmUrl = acmUrlTemplate
            .replace('{countryCode}', acmCountryCode)
            .replace('{topLevelDomain}', topLevelDomain);

        return acmUrl;
    }
}
