import { IPostalAddress } from '@models/backend/advert';
import { SupportedCountryCode } from '@models/backend/common';

interface IAdvertisementSectionBuilderResult {
    streetName: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    district: string;
    countryCode: SupportedCountryCode;
    region: string | undefined;
}

export function buildAdvertisementSection(address: IPostalAddress): IAdvertisementSectionBuilderResult {
    return {
        streetName: address.streetName || '',
        houseNumber: address.houseNumber || '',
        postalCode: address.postalCode || '',
        city: address.city || '',
        district: address.borough || '',
        countryCode: (address.countryCode as SupportedCountryCode) || 'DE',
        region: address.region,
    };
}
