<div class="intranet-gallery">
    <loading-indicator [isEmbedded]="true"></loading-indicator>
    <section class="header">
        <article class="subtitle">{{ 'SHARED_COMPONENT.SOURCE_POOL' | translate }}</article>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <div class="filter-option" *ngIf="hasImages">
        <mat-form-field class="detail">
            <mat-label>{{ 'SHARED_COMPONENT.DETAIL' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedDetails" multiple (valueChange)="changeDetailFilter($event)">
                <mat-option *ngFor="let detail of detailsOptions" [value]="detail.id">{{
                    'IMAGE_LABELS.' + detail.label | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <section class="filter">
            <mat-checkbox [ngModel]="isSamplesView" (ngModelChange)="onSamplesViewChanged()">{{
                'SHARED_COMPONENT.SHOW_EXAMPLES' | translate
            }}</mat-checkbox>
            <article>{{ 'SHARED_COMPONENT.SELECTED_IMAGES' | translate }} {{ selectedCount }}</article>
        </section>
    </div>

    <section class="images" *ngIf="hasImages" #imageContainer>
        <div class="image" *ngFor="let image of images; trackBy: byIndex">
            <section class="preview" [loadImage]="image.context.smallUrl" [isPortrait]="image.context.isPortrait">
                <mat-checkbox [ngModel]="image.isSelected" (ngModelChange)="imageSelected(image)"></mat-checkbox>
                <div class="overlay" clickAndKeydown (actionTriggered)="imageSelected(image)"></div>
            </section>
            <mat-chip-set>
                <mat-chip *ngIf="image.context.hasUnitCodeForExampleImage">{{ image.context.belongsToUnit }}</mat-chip>
                <mat-chip color="primary" *ngIf="image.context.isExample">{{
                    'SHARED_COMPONENT.EXAMPLE_IMAGE' | translate
                }}</mat-chip>
                <mat-chip
                    ><span class="trim">{{ image.context.tagText | translate }}</span></mat-chip
                >
            </mat-chip-set>
        </div>
    </section>

    <aside class="no-images" *ngIf="isIntranetDown">
        <article class="heading">{{ 'SHARED_COMPONENT.GALLERY_ERROR_TITLE' | translate }}</article>
        <article>{{ 'SHARED_COMPONENT.GALLERY_ERROR_SUBTITLE' | translate }}</article>
    </aside>

    <section class="actions" *ngIf="!isLoading">
        <button mat-flat-button mat-dialog-close>{{ 'SHARED_COMPONENT.CLOSE' | translate }}</button>
        <button mat-flat-button *ngIf="!isIntranetDown" color="primary" clickAndKeydown (actionTriggered)="save()">
            {{ 'SHARED_COMPONENT.SAVE' | translate }}
        </button>
    </section>
</div>
