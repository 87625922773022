import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ICostCenterAssignmentsResponseBody } from '@models/backend/rotation';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RotationService {
    private httpClient = inject(HttpClient);


    getCostCentersAssignments(
        page: number,
        region?: string,
        costCenterCode?: string
    ): Observable<ICostCenterAssignmentsResponseBody> {
        const paramsQueryFilter = { pageSize: 50 };

        if (region) {
            paramsQueryFilter['region'] = region;
        }

        if (costCenterCode) {
            paramsQueryFilter['costCenterCode'] = costCenterCode;
        }

        paramsQueryFilter['page'] = page;

        const params = new HttpParams({
            fromObject: paramsQueryFilter,
        });

        return this.httpClient.get<ICostCenterAssignmentsResponseBody>('view/cost-center-assignments', { params });
    }

    assignLMToCostCenters(userId?: string, costCenterCodes?: string[]): Observable<ICostCenterAssignmentsResponseBody> {
        const body = {};

        if (userId) {
            body['userId'] = userId;
        }

        if (costCenterCodes.length) {
            body['costCenterCodes'] = costCenterCodes;
        }

        return this.httpClient.patch<ICostCenterAssignmentsResponseBody>(`handle/assign-cost-centers`, body);
    }
}
