import { DOCUMENT } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProspectHelper } from '@helpers/prospect.helper';
import { ProspectStatus } from '@models/backend/prospects';
import { IDropdownItem } from '@models/common/dropdown-item';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '@services/country.service';
import { takeUntil, timer } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IProspectModalArgs } from '../advert-prospects.component';
import { ProspectStatusAfterEmailComponent } from '../prospect-status-after-email/prospect-status-after-email.component';

@Component({
    selector: 'prospect-status-after-sms',
    templateUrl: './prospect-status-after-sms.component.html',
    styleUrls: ['./prospect-status-after-sms.component.less'],
})
export class ProspectStatusAfterSmsComponent extends UnSubscriptionDirective implements OnInit {
    private countryService = inject(CountryService);
    private translateService = inject(TranslateService);
    private document = inject<Document>(DOCUMENT);

    dialogRef = inject<MatDialogRef<ProspectStatusAfterEmailComponent>>(MatDialogRef);
    args = inject<IProspectModalArgs>(MAT_DIALOG_DATA);

    options: IDropdownItem<ProspectStatus>[];
    selectedStatus: ProspectStatus;

    prospectPhone: string;

    get successMessage(): string {
        return this.translateService.instant('PROSPECTS.EDIT_SUCCESS_MESSAGE');
    }

    ngOnInit(): void {
        this.options = this.getProspectStatusItems();
        this.prospectPhone = this.args.prospects[0].phone;

        this.triggerSmsApp();

        this.selectedStatus =
            this.options.find((s) => s.context === ProspectStatus.MessageAnswered).context || this.options[0].context;
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.dialogRef.close());
    }

    save(): void {
        this.dialogRef.close(this.selectedStatus);
    }

    private triggerSmsApp(): void {
        timer(300)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                const element: HTMLElement = this.document.getElementById('open-sms-app') as HTMLElement;
                element.click();
            });
    }

    private getProspectStatusItems(): IDropdownItem<ProspectStatus>[] {
        const country = this.countryService.getCurrentCountry();
        const allOptions = ProspectHelper.getStatusOptions(country);
        return allOptions.map((s) => {
            const item: IDropdownItem<ProspectStatus> = {
                context: s,
                name: `KEY_FACT_ENUMS.prospectStatus.${s}`,
            };
            return item;
        });
    }

    byIndex(index: number): number {
        return index;
    }
}
