<div
    class="advert-details-section virtual-viewing"
    *ngIf="advert"
    id="virtual-viewing-section"
    [class.disabled]="isLoading"
>
    <section class="header">
        <span class="subtitle">{{ 'SHARED_COMPONENT.VIRTUAL_VIEWING' | translate }}</span>
        <i class="material-icons validation-icon" [ngClass]="isValid ? 'valid' : 'invalid'">{{ validationIcon }}</i>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="navigateToAdvert()"
            *ngIf="isPreview"
            matTooltip="{{ 'SHARED_COMPONENT.EDIT' | translate }}"
        >
            <mat-icon fontSet="material-icons-outlined">create</mat-icon>
        </button>
        <div class="loader" *ngIf="isLoading">
            <loading-indicator [isPermanent]="true" [isEmbedded]="true" [isSmall]="true"></loading-indicator>
        </div>
    </section>

    <div class="form" [class.preview]="isPreview">
        <section class="properties">
            <div class="property">
                <mat-form-field>
                    <mat-label>{{ 'KEY_FACTS.virtualTourUrl' | translate }}</mat-label>
                    <input type="url" matInput [formControl]="urlForm" [attr.data-test]="domId" />
                    <mat-error *ngIf="isValid === false">{{
                        'VALIDATION_MESSAGES.VIDEO_URL_INVALID' | translate
                    }}</mat-error>
                </mat-form-field>
            </div>
        </section>
    </div>

    <iframe
        [src]="iFrameUrl"
        frameborder="0"
        [title]="iFrameUrl"
        *ngIf="isPreview && urlForm.value"
        allowfullscreen
    ></iframe>
</div>
