import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import {
    GoogleAnalyticsInitializer,
    GoogleAnalyticsService,
    GtagFn,
    NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
    NGX_GTAG_FN,
} from 'ngx-google-analytics';
import { IGoogleAnalyticsSettings } from 'ngx-google-analytics/lib/interfaces/i-google-analytics-settings';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
    private googleAnalyticsSettingsToken = inject<IGoogleAnalyticsSettings>(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN);
    private googleTagFunction = inject<GtagFn>(NGX_GTAG_FN);
    private document = inject<Document>(DOCUMENT);
    googleAnalyticsService = inject(GoogleAnalyticsService);
    private environmentService = inject(EnvironmentService);

    private isInitialized: boolean;
    private userRole: string;
    private userLanguage: string;
    private userCountry: string;

    initializeGoogleAnalytics() {
        this.environmentService.getEnvinronment();
        if (!this.isInitialized) {
            this.googleAnalyticsSettingsToken.trackingCode =
                this.environmentService.getEnvinronment().googleAnaliticsKey;
            Promise.all([
                GoogleAnalyticsInitializer(this.googleAnalyticsSettingsToken, this.googleTagFunction, this.document)(),
            ])
                .then(() => {
                    this.isInitialized = true;
                })
                .catch(() => {
                    this.isInitialized = false;
                });
        }
    }

    set(userId: string, userRole: string, userLanguage: string, userCountry: string): void {
        const measurementId = this.googleAnalyticsSettingsToken.trackingCode;
        this.googleAnalyticsService.set({
            user_id: userId,
        });

        this.googleAnalyticsService.gtag('config', measurementId, {
            custom_map: {
                dimension1: 'user_role',
                dimension2: 'user_language',
                dimension3: 'user_country',
            },
        });

        this.userRole = userRole;
        this.userLanguage = userLanguage;
        this.userCountry = userCountry;
    }

    event(action: GoogleAnalyticsEvents, category?: string, label?: string): void {
        this.googleAnalyticsService.gtag('event', action, {
            event_category: category,
            event_label: label,
            user_role: this.userRole,
            user_language: this.userLanguage,
            user_country: this.userCountry,
        });
    }

    pageView(title: string, location: string): void {
        this.googleAnalyticsService.gtag('event', 'page_view', {
            page_title: title,
            page_location: location,

            custom_map: {
                dimension1: 'user_role',
                dimension2: 'user_language',
                dimension3: 'user_country',
            },

            user_role: this.userRole,
            user_language: this.userLanguage,
            user_country: this.userCountry,
        });
    }
}
