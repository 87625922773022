<section class="edit" [formGroup]="prospectForm">
    <div class="row">
        <mat-form-field>
            <mat-label>{{ 'PROSPECTS.NAME' | translate }}</mat-label>
            <input matInput required formControlName="name" type="text" autocomplete="off" />
            <mat-error *ngIf="!prospectForm.get('name').valid">{{
                'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
            }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'KEY_FACTS.email' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" autocomplete="off" />
            <mat-error *ngIf="emailFieldError?.required">{{
                'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate
            }}</mat-error>

            <mat-error *ngIf="emailFieldError?.pattern">{{ 'VALIDATION_MESSAGES.EMAIL' | translate }}</mat-error>
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field>
            <mat-label>{{ 'KEY_FACTS.phoneNumber' | translate }}</mat-label>
            <input
                matInput
                type="text"
                formControlName="phone"
                (input)="phoneChangedEvent()"
                [placeholder]="phoneFieldPlaceholder"
                autocomplete="off"
            />
            <mat-error *ngIf="!prospectForm.get('phone').valid">
                <ng-container *ngIf="phoneFieldError?.pattern; else elseBlock">
                    {{ 'VALIDATION_MESSAGES.PHONE_IS_NOT_VALID' | translate }}
                </ng-container>
                <ng-template #elseBlock>
                    {{ pinErrorMessage | translate }}
                </ng-template>
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'PROSPECTS.STATUS' | translate }}</mat-label>
            <mat-select required formControlName="status">
                <mat-option *ngFor="let status of statusOptions" [value]="status">{{
                    'KEY_FACT_ENUMS.prospectStatus.' + status | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row">
        <autocomplete
            class="autocomplete"
            formControlName="marketingChannel"
            placeholder="{{ 'PROSPECTS.TITLE' | translate }}"
            [options]="marketingChannelSources"
        >
        </autocomplete>

        <mat-form-field>
            <mat-label>{{ 'PROSPECTS.MOVE_IN_DATE' | translate }}</mat-label>
            <input
                matInput
                [matDatepicker]="datePicker"
                [min]="today"
                autocomplete="off"
                formControlName="preferredMoveInDate"
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="row full" *ngIf="isPinFieldVisible">
        <mat-form-field>
            <mat-label>{{ 'PROSPECTS.PIN' | translate }}</mat-label>
            <input matInput required type="text" formControlName="personalIdentityNumber" autocomplete="off" />
            <mat-error *ngIf="!prospectForm.get('personalIdentityNumber').valid">{{
                pinErrorMessage | translate
            }}</mat-error>
        </mat-form-field>
    </div>
    <div class="row full">
        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.MESSAGE' | translate }}</mat-label>
            <textarea matInput formControlName="message" rows="5"></textarea>
        </mat-form-field>
    </div>
</section>
