<div class="advert-details-header" *ngIf="advert">
    <section class="head">
        <span class="title">{{ advert.id }}</span>
        <button
            class="bookmark"
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="toggleBookmark()"
            matTooltip="{{ bookmarkTooltipMessage | translate }}"
        >
            <i class="material-icons">{{ isAdvertBookmarked ? 'star' : 'star_border' }}</i>
        </button>
        <button
            *ngIf="advert.hasExpose"
            class="expose"
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="openExposeDialog()"
            matTooltip="{{ 'SHARED_COMPONENT.EXPOSE' | translate }}"
        >
            <mat-icon fontSet="material-icons-outlined">description</mat-icon>
        </button>
        <button
            *ngIf="canConvertApartment"
            mat-icon-button
            matTooltip="{{ conversionTooltipMessage | translate }}"
            clickAndKeydown
            (actionTriggered)="convertApartment()"
        >
            <mat-icon fontSet="material-icons-outlined">sync_alt</mat-icon>
        </button>
    </section>

    <section class="pills">
        <advert-state [advert]="advert"></advert-state>
    </section>

    <section class="info">
        <aside class="image-note">
            <div class="image" [ngStyle]="thumbnailImageStyle()"></div>

            <div class="comment">
                <mat-form-field *ngIf="!isPreview">
                    <mat-label>{{ 'SHARED_COMPONENT.TEASER_COMMENT_PLACEHOLDER' | translate }}</mat-label>
                    <textarea
                        matInput
                        rows="5"
                        maxlength="100"
                        [formControl]="commentForm"
                        [class.disabled]="isSaving"
                    ></textarea>
                    <!-- prettier-ignore -->
                    <mat-hint *ngIf="commentForm.value">{{ commentForm.value.length }} / 100</mat-hint>
                </mat-form-field>
                <article class="comment" *ngIf="isPreview && advert.comment">{{ advert.comment }}</article>
            </div>
        </aside>

        <aside class="stats" [ngSwitch]="advert.type">
            <apartment-header-stats *ngSwitchCase="'living'" [advert]="advert"></apartment-header-stats>

            <apartment-header-stats *ngSwitchCase="'commercial'" [advert]="advert"></apartment-header-stats>

            <apartment-sale-header-stats
                *ngSwitchCase="'apartmentForSale'"
                [advert]="advert"
            ></apartment-sale-header-stats>

            <parking-space-header-stats *ngSwitchCase="'parking'" [advert]="advert"></parking-space-header-stats>

            <parking-space-header-stats *ngSwitchCase="'huntingArea'" [advert]="advert"></parking-space-header-stats>

            <div class="portals" *ngIf="!isPreview && advert.isPublished && advert.hasPublicationResults">
                <a class="link" clickAndKeydown (actionTriggered)="showPublishedPortals()">{{
                    'SHARED_COMPONENT.PUBLISHED_ON_PORTALS' | translate
                }}</a>
            </div>
        </aside>
    </section>

    <section id="tabs" class="tabs" *ngIf="!isPreview">
        <aside
            class="tab-item"
            routerLinkActive="selected"
            routerLink="{{ detailsRoute }}"
            [routerLinkActiveOptions]="{ exact: true }"
        >
            {{ 'SHARED_COMPONENT.ADVERT' | translate }}
        </aside>
        <aside
            class="tab-item"
            [class.disable]="isUsAdvertOnProdEnvironment"
            routerLinkActive="selected"
            routerLink="{{ prospectsRoute }}"
        >
            {{ 'PROSPECTS.PROSPECTS' | translate }}
            <span *ngIf="advert.numberOfProspectMessages && !isUsAdvertOnProdEnvironment">{{
                advert.numberOfProspectMessages
            }}</span>
        </aside>
        <aside
            *ngIf="enableViewingFeature"
            class="tab-item"
            routerLinkActive="selected"
            [class.disable]="isUsAdvertOnProdEnvironment"
            routerLink="{{ viewingsRoute }}"
        >
            {{ 'VIEWINGS_VIEW.VIEWINGS' | translate }}
            <span *ngIf="advert.numberOfOpenViewings && !isUsAdvertOnProdEnvironment">{{
                advert.numberOfOpenViewings
            }}</span>
        </aside>
        <aside class="tab-item" routerLinkActive="selected" routerLink="{{ historyRoute }}">
            {{ 'EVENTS.HISTORY' | translate }}
        </aside>
    </section>
</div>
