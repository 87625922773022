import { Orientation } from '@models/shared';

export function getOrientation(image: HTMLImageElement): Orientation {
    if (!image.height || !image.width) {
        return 'landscape';
    }

    return getOrientationNaiveFromDimensions(image);
}

function getOrientationNaiveFromDimensions(image: HTMLImageElement): Orientation {
    const { width, height } = image;
    return width >= height ? 'landscape' : 'portrait';
}
