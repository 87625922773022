import { IDropdownItem } from '@models/common/dropdown-item';
import { Selectable } from '@models/common/selectable';

export class EnumHelper {
    static toTranslatedDropdownItems<T>(
        obj: unknown,
        translationNamespace: string,
        defaultItemTranslation?: string
    ): IDropdownItem<T>[] {
        const defaultTranslated = defaultItemTranslation || 'SHARED_COMPONENT.NOT_SELECTED';
        return Object.keys(obj).map((key, i) => {
            return {
                name: i === 0 ? defaultTranslated : `KEY_FACT_ENUMS.${translationNamespace}.${obj[key]}`,
                context: obj[key],
            };
        });
    }

    static toTranslatedSelectableItem<T>(
        obj: unknown,
        translationNamespace: string,
        currentSelectedItem: unknown
    ): Selectable<T>[] {
        return Object.keys(obj).map<Selectable<T>>((key) => {
            const res = new Selectable(obj[key], obj[key] === currentSelectedItem);
            res.displayName = `KEY_FACT_ENUMS.${translationNamespace}.${obj[key]}`;
            return res;
        });
    }

    static toTranslatedArray<T>(obj: unknown, translationNamespace: string): IDropdownItem<T>[] {
        return Object.keys(obj).map<IDropdownItem<T>>((key) => {
            return {
                name: `KEY_FACT_ENUMS.${translationNamespace}.${obj[key]}`,
                context: obj[key],
            };
        });
    }

    static toArray<T>(obj: unknown): T[] {
        return Object.keys(obj).map<T>((key) => obj[key]);
    }
}
