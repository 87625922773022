<div class="upload-text">
    @if (control.valueChanges | async) {
        <ng-container *ngTemplateOutlet="placeholderRef ? placeholderRef : defaultPlaceholderTemplate; context: { $implicit: control.value, control: control, files: control.value }" />
    }

    <ng-template #defaultPlaceholderTemplate let-files="files">
        @for (file of files; track trackByFn(i, file); let i = $index;) {
            @if (i > 0) { <span>,&nbsp;</span> } <span class="file-name">{{ file.name }}</span>
        } @empty {
            Select a file...
        }
    </ng-template>
</div>

<label #labelRef class="upload-button" tabindex="0" (keydown)="onKeyDown($event)">
    <ng-container *ngTemplateOutlet="buttonRef ? buttonRef : defaultButtonTemplate; context: { control: control }"></ng-container>

    <ng-template #defaultButtonTemplate>
        <div class="button">
            <div class="icon">
                <svg viewBox="0 0 96 96">
                    <g>
                        <path d="M62.8,68.1c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6s-1.1,0.2-1.5,0.6
                            c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6s1.1-0.2,1.5-0.6S62.8,68.7,62.8,68.1z M71.3,68.1
                            c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6C67.2,67,67,67.5,67,68.1c0,0.6,0.2,1.1,0.6,1.5
                            s0.9,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6C71.1,69.2,71.3,68.7,71.3,68.1z M75.5,60.7v10.6c0,0.9-0.3,1.6-0.9,2.2
                            c-0.6,0.6-1.4,0.9-2.2,0.9H23.7c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.2V60.7c0-0.9,0.3-1.6,0.9-2.2
                            c0.6-0.6,1.4-0.9,2.2-0.9h14.1c0.5,1.2,1.2,2.2,2.3,3c1.1,0.8,2.3,1.2,3.7,1.2h8.5c1.3,0,2.6-0.4,3.7-1.2c1.1-0.8,1.9-1.8,2.3-3
                            h14.1c0.9,0,1.6,0.3,2.2,0.9C75.2,59.1,75.5,59.8,75.5,60.7z M64.8,39.3c-0.4,0.9-1,1.3-2,1.3h-8.5v14.8c0,0.6-0.2,1.1-0.6,1.5
                            c-0.4,0.4-0.9,0.6-1.5,0.6h-8.5c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5V40.6h-8.5c-0.9,0-1.6-0.4-2-1.3
                            c-0.4-0.9-0.2-1.6,0.5-2.3l14.8-14.8c0.4-0.4,0.9-0.6,1.5-0.6s1.1,0.2,1.5,0.6L64.3,37C65,37.7,65.1,38.4,64.8,39.3z"/>
                    </g>
                </svg>
            </div>
            
            <span class="button-text">Browse</span>
        </div>
    </ng-template>

    <input #inputRef type="file" class="files-input" tabindex="-1" (change)="onInputChange($event)">
</label>