import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@helpers/date.helper';

@Pipe({
    name: 'convertTime',
    pure: false,
})
export class ConvertTimePipe implements PipeTransform {
    transform(dateValue: string | Date): string {
        if (!DateHelper.isValidDate(dateValue)) {
            return '-';
        }

        const date = new Date(dateValue);

        const addZero = (i: number): string => {
            return i < 10 ? `0${i}` : i.toString();
        };
        return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
    }
}
