<div class="stat-group">
    <article>{{ advert.streetNameAndHouseNumber }}</article>
    <article>{{ advert.direction }}</article>
    <article>{{ advert.postalCodeAndCity }}</article>
</div>

<div class="stat-group">
    <advert-rooms-label [advert]="advert"></advert-rooms-label>
    <view-unit-intranet [internalId]="advert.internalId" />
    <view-unit-acm [internalId]="advert.internalId" />
</div>

<div class="stat-group">
    <article>
        {{ advert.hasNoContactAssigned ? ('SHARED_COMPONENT.NO_CONTACT' | translate) : advert.contactDisplay }}
    </article>
    <article *ngIf="advert.hasSubstituteContact">
        {{ advert.substituteContact.firstName }} {{ advert.substituteContact.lastName }}
    </article>
</div>

<div class="stat-group">
    <article>{{ 'KEY_FACTS.availableFromDate' | translate }}</article>
    <article *ngIf="advert.isAvailabeUponAgreement">{{ 'SHARED_COMPONENT.UPON_AGREEMENT' | translate }}</article>
    <article *ngIf="!advert.isAvailabeUponAgreement">
        {{
            advert.isAvailableFromNowOn
                ? ('KEY_FACTS.availableFromNowOnTeaser' | translate)
                : (advert.availabeFrom | convertDate)
        }}
    </article>
</div>

<div class="stat-group">
    <article>{{ 'KEY_FACTS.cost' | translate }}</article>
    <article *ngIf="advert.cost">
        <span> {{ advert.cost | numeral }} {{ advert.localization.currency }}</span>
        <span> | {{ advert.costPerSize | numeral }}</span>
        <span> {{ advert.localization.currency }}/{{ advert.areaSizeUnit }}</span>
    </article>
    <article *ngIf="!advert.cost">-</article>
</div>
