<div
    class="advert-details-section"
    *ngIf="section"
    id="{{ section.id }}"
    [ngClass]="{ disabled: isLoading, empty: hasAllEmptyValues }"
>
    <section class="header">
        <span class="subtitle">{{ 'ADVERT_SECTIONS.' + section.translationKey | translate }}</span>
        <i class="material-icons {{ validationIconClass }} validation-icon">{{ validationIcon }}</i>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="navigateToAdvert()"
            *ngIf="isPreview"
            matTooltip="{{ 'SHARED_COMPONENT.EDIT' | translate }}"
        >
            <mat-icon fontSet="material-icons-outlined">create</mat-icon>
        </button>
        <div class="loader" *ngIf="isLoading">
            <loading-indicator [isPermanent]="true" [isEmbedded]="true" [isSmall]="true"></loading-indicator>
        </div>
    </section>

    <div class="form" [class.preview]="isPreview" [formGroup]="form">
        <section class="properties" *ngFor="let subsection of section.subsections; trackBy: byIndex">
            <div
                class="property"
                *ngFor="let kf of subsection.keyfacts; trackBy: byIndex"
                [ngClass]="{
                    single: kf.isStandalone,
                    mandatory: kf.isRequired,
                    hidden: !isPreviewKeyfactVisible(kf),
                    bool: isBoolean(kf),
                }"
            >
                <span class="preview-label">{{ 'KEY_FACTS.' + kf.translationKey | translate }}</span>
                <span class="value" *ngIf="!isBoolean(kf) && !isEnum(kf)">{{ getPreviewValue(kf) }}</span>
                <span class="value" *ngIf="isEnum(kf)">{{ getPreviewValue(kf) | translate }}</span>
                <i class="material-icons value" *ngIf="isBoolean(kf)">check</i>

                <mat-form-field *ngIf="isText(kf) || isNumeric(kf)">
                    <mat-label>{{ 'KEY_FACTS.' + kf.translationKey | translate }} {{ getNumberUnit(kf) }}</mat-label>
                    <input
                        matInput
                        [required]="kf.isRequired"
                        type="text"
                        autocomplete="off"
                        tabindex="{{ getInputTabIndex(kf) }}"
                        [formControl]="form.controls[kf.id]"
                        [attr.data-test]="getDomId(kf)"
                    />
                    <!-- prettier-ignore -->
                    <mat-hint *ngIf="kf.hasHint && !isPreview">{{ getHint(kf.translationKey) }}</mat-hint>
                    <mat-error *ngIf="kf.isValid === false">{{ getTranslatedErrorMessage(kf) | async }}</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="isDate(kf)">
                    <input
                        matInput
                        [required]="kf.isRequired"
                        [matDatepicker]="datePicker"
                        [formControl]="form.controls[kf.id]"
                        autocomplete="off"
                        [attr.data-test]="getDomId(kf)"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-label>{{ 'KEY_FACTS.' + kf.translationKey | translate }}</mat-label>
                    <!-- prettier-ignore -->
                    <mat-hint *ngIf="kf.hasHint && !isPreview">{{ getHint(kf.translationKey) }}</mat-hint>
                    <mat-error *ngIf="kf.isValid === false">{{ getTranslatedErrorMessage(kf) | async }}</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="isEnum(kf)">
                    <mat-label>{{ 'KEY_FACTS.' + kf.translationKey | translate }}</mat-label>
                    <mat-select
                        [formControl]="form.controls[kf.id]"
                        [attr.data-test]="getDomId(kf)"
                        [required]="kf.isRequired"
                    >
                        <mat-option *ngFor="let value of kf.values; trackBy: byIndex; let i = index" [value]="value">
                            {{
                                i === 0
                                    ? ('SHARED_COMPONENT.NOT_SELECTED' | translate)
                                    : ('KEY_FACT_ENUMS.' + kf.translationKey + '.' + value | translate)
                            }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="kf.isValid === false">{{ getTranslatedErrorMessage(kf) | async }}</mat-error>
                </mat-form-field>

                <mat-checkbox *ngIf="isBoolean(kf)" [formControl]="form.controls[kf.id]" [attr.data-test]="getDomId(kf)"
                    >{{ 'KEY_FACTS.' + kf.translationKey | translate }}
                    <mat-error *ngIf="kf.isValid === false">{{ getTranslatedErrorMessage(kf) | async }}</mat-error>
                </mat-checkbox>

                <mat-form-field *ngIf="isTime(kf)">
                    <mat-label>{{ 'KEY_FACTS.' + kf.translationKey | translate }} {{ getNumberUnit(kf) }}</mat-label>
                    <input
                        matInput
                        [required]="kf.isRequired"
                        type="text"
                        readonly
                        [formatTime]="kf"
                        [value]="getPreviewValue(kf)"
                        autocomplete="off"
                        tabindex="{{ getInputTabIndex(kf) }}"
                        [formControl]="form.controls[kf.id]"
                        [attr.data-test]="getDomId(kf)"
                        clickAndKeydown
                        (actionTriggered)="updateTimeKeyfact(kf)"
                    />
                    <!-- prettier-ignore -->
                    <mat-hint *ngIf="kf.hasHint && !isPreview"> {{ getHint(kf.translationKey) }} </mat-hint>
                    <mat-error *ngIf="kf.isValid === false">{{ getTranslatedErrorMessage(kf) | async }}</mat-error>
                </mat-form-field>
            </div>
        </section>
    </div>
</div>
