import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[clickAndKeydown]',
    standalone: true,
})
export class ClickAndKeydownDirective {
    @Output() actionTriggered = new EventEmitter<Event>();
    @HostListener('click', ['$event'])
    @HostListener('keydown.enter', ['$event'])
    @HostListener('keydown.space', ['$event'])
    handleEvent(event: Event): void {
        this.actionTriggered.emit(event);
    }
}
