import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdvertViewModel } from '@models/advert/advert';
import { IViewingsResponseBody } from '@models/backend/responses';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { IViewingViewModel, ViewingStatus, ViewingViewModel } from '@models/viewing/viewing';
import { TranslateService } from '@ngx-translate/core';
import { AdvertStoreService } from '@services/advert.store';
import { AnalyticsService } from '@services/analytics.service';
import { ViewingsService } from '@services/viewings.service';
import { Moment } from 'moment';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';
import { DownloadIcsComponent } from '../shared/download-ics/download-ics.component';
import { IViewingEditorArgs, ViewingEditorComponent } from './viewing-editor/viewing-editor.component';
import { ViewingInviteesComponent } from './viewing-invitees/viewing-invitees.component';

interface StatusFilter {
    label: string;
    value: string | null;
}

@Component({
    selector: 'advert-viewings',
    templateUrl: './advert-viewings.component.html',
    styleUrls: ['./advert-viewings.component.less'],
})
export class AdvertViewingsComponent extends UnSubscriptionDirective implements OnInit {
    private viewingsService = inject(ViewingsService);
    private dialog = inject(MatDialog);
    private advertStoreService = inject(AdvertStoreService);
    private translateService = inject(TranslateService);
    private route = inject(ActivatedRoute);
    private gaService = inject(AnalyticsService);

    viewings: ViewingViewModel[];
    advert: AdvertViewModel;
    isForbidden: boolean = false;
    isArchiveExpanded: boolean = false;
    filterForm: UntypedFormGroup;
    statuses: StatusFilter[] = [
        { label: 'SHARED_COMPONENT.ALL', value: null },
        { label: 'VIEWINGS_VIEW.STATUS.draft', value: 'draft' },
        { label: 'VIEWINGS_VIEW.STATUS.scheduled', value: 'scheduled' },
        { label: 'VIEWINGS_VIEW.STATUS.declined', value: 'declined' },
        { label: 'VIEWINGS_VIEW.STATUS.pending', value: 'pending' },
        { label: 'VIEWINGS_VIEW.STATUS.archived', value: 'archived' },
        { label: 'VIEWINGS_VIEW.STATUS.attendee-confirmation', value: 'attendee-confirmation' },
    ];

    private viewingId: string;

    get isEmpty(): boolean {
        return this.viewings !== undefined && this.viewings.length === 0;
    }

    get hasArchivedViewings(): boolean {
        return this.viewings.some((e) => e.isArchived || e.isViewingCarriedOut);
    }

    get archivedViewings(): ViewingViewModel[] {
        return this.viewings.filter((e) => this.isArchivedViewing(e)).sort(this.sortByDateDescending);
    }

    get futureViewings(): ViewingViewModel[] {
        return this.viewings.filter((e) => this.isFutureViewing(e)).sort(this.sortByDateDescending);
    }

    get notAllowedErrorMessage(): string {
        return this.translateService.instant('EVENTS.NOT_ALLOWED');
    }

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    @ViewChild(DownloadIcsComponent, { static: true }) private downloadIcsElement: DownloadIcsComponent;

    ngOnInit(): void {
        this.filterForm = new UntypedFormGroup({
            date: new UntypedFormControl(null, { updateOn: 'blur' }),
            status: new UntypedFormControl(null),
        });
        this.filterForm.disable();

        this.filterForm
            .get('date')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((value) => {
                if (value) {
                    this.gaService.event(GoogleAnalyticsEvents.ViewingEventFiltered, 'data', `date:${value}`);
                }
            });

        this.filterForm
            .get('status')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((value) => {
                if (value) {
                    this.gaService.event(GoogleAnalyticsEvents.ViewingEventFiltered, 'status', `status:${value}`);
                }
            });

        this.loadingIndicator.show();
        this.advertStoreService.advertSubject
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((advert) => this.advertLoaded(advert));

        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            const filterby = params['filterBy'];
            if (filterby) {
                this.filterForm.get('status').setValue(filterby);
            }

            // TODO: eventId comes from the ics file which is generated by BE
            this.viewingId = params['eventId'];
        });
    }

    private advertLoaded(advert: AdvertViewModel): void {
        this.advert = advert;
        this.viewingsService
            .getViewings(this.advert.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.gotViewings(res),
                (errors) => this.responseEror(errors[0]),
            );
    }

    private gotViewings(res: IViewingsResponseBody): void {
        this.viewings = res.data.map((e) => ViewingViewModel.factory(e));
        this.loadingIndicator.hide();
        if (this.viewings.length > 0) {
            this.filterForm.enable();
        }

        const viewing = this.viewings.find((e) => e.id === this.viewingId);
        if (viewing?.hasInvitees) {
            this.showInvitees(viewing);
        }
    }

    private requestError(): void {
        this.loadingIndicator.hide();
        this.viewings = [];
    }

    private readonly sortByDateDescending = (a: ViewingViewModel, b: ViewingViewModel) => {
        if (a.date > b.date) {
            return -1;
        }
        if (b.date > a.date) {
            return 1;
        }
        return 0;
    };

    private isArchivedViewing(viewing: ViewingViewModel): boolean {
        return (
            (viewing.isArchived || viewing.isViewingCarriedOut) &&
            this.hasDateFilter(viewing) &&
            this.hasStatusFilter(viewing)
        );
    }

    private isFutureViewing(viewing: ViewingViewModel): boolean {
        return (
            !viewing.isArchived &&
            !viewing.isViewingCarriedOut &&
            this.hasDateFilter(viewing) &&
            this.hasStatusFilter(viewing)
        );
    }

    private hasStatusFilter(viewing: ViewingViewModel): boolean {
        const selectedStatus = this.filterForm.get('status').value as ViewingStatus;
        return selectedStatus === null || selectedStatus === viewing.status;
    }

    private hasDateFilter(viewing: ViewingViewModel): boolean {
        const selectedFormDate = this.filterForm.get('date').value;

        if (selectedFormDate === null) {
            return true;
        }

        const selectedDate = (selectedFormDate as Moment).toDate();
        const areEventDatesEqual = (d1: Date, d2: Date) =>
            d1.getMonth() === d2.getMonth() && d1.getDay() === d2.getDay();

        return selectedDate === null || areEventDatesEqual(selectedDate, viewing.date);
    }

    updateViewingsCount(): void {
        this.advert.numberOfOpenViewings = this.futureViewings.length;
    }
    updateUpcomingViewingsCount(): void {
        this.advert.numberOfUpcomingViewings++;
    }

    deleted(viewing: ViewingViewModel): void {
        this.gaService.event(GoogleAnalyticsEvents.ViewingEventDeleted);

        const index = this.viewings.findIndex((e) => e.id === viewing.id);
        this.viewings.splice(index, 1);

        if (this.viewings.length === 0) {
            this.filterForm.disable();
        }

        this.viewingsService
            .deleteViewing(this.advert.id, viewing.id, viewing.status)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.updateViewingsCount();
                if (!this.isArchivedViewing(viewing)) {
                    this.advert.numberOfUpcomingViewings--;
                }
            });
    }

    createViewing(): void {
        this.gaService.event(GoogleAnalyticsEvents.ViewingEventCreated);

        const args: IViewingEditorArgs = {
            mode: 'create',
            unitId: this.advert.id,
        };
        const dialog = this.dialog.open(ViewingEditorComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((e?) => {
                this.viewingCreated(e);
                this.updateViewingsCount();
                this.updateUpcomingViewingsCount();
            });
    }

    copyViewing(viewing: ViewingViewModel): void {
        this.gaService.event(GoogleAnalyticsEvents.ViewingEventCreated);

        const copiedViewing = ViewingViewModel.factory(viewing.extract());
        copiedViewing.invitees = [];

        if (copiedViewing.isArchived) {
            copiedViewing.date = null;
        }

        const args: IViewingEditorArgs = {
            mode: 'copy',
            viewing: copiedViewing,
            unitId: this.advert.id,
        };
        const dialog = this.dialog.open(ViewingEditorComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((e?) => {
                this.viewingCreated(e);
                this.updateViewingsCount();
                this.updateUpcomingViewingsCount();
            });
    }

    editViewing(viewing: ViewingViewModel): void {
        const args: IViewingEditorArgs = {
            mode: 'edit',
            viewing,
            unitId: this.advert.id,
        };
        const dialog = this.dialog.open(ViewingEditorComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((updatedViewing?: IViewingViewModel) => this.viewingEdited(updatedViewing));
    }

    showInvitees(viewing: ViewingViewModel): void {
        this.gaService.event(GoogleAnalyticsEvents.ViewingEventViewingInviteeList);

        const dialog = this.dialog.open(ViewingInviteesComponent, {
            panelClass: 'unconstrained-dialog',
            data: {
                viewing,
                advert: this.advert,
            },
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.getViewings());
    }

    downloadEventCalenderFile(viewing: ViewingViewModel): void {
        this.downloadIcsElement.downloadEventCalenderFile(this.advert.id, viewing.id);
    }

    expandArchived(): void {
        this.isArchiveExpanded = !this.isArchiveExpanded;
    }

    private responseEror(error: { status: number }): void {
        this.loadingIndicator.hide();
        this.isForbidden = error.status === 403;
    }

    private viewingEdited(updatedViewing?: IViewingViewModel): void {
        if (updatedViewing) {
            this.getViewings();
        }
    }

    private getViewings(): void {
        this.viewingsService
            .getViewings(this.advert.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.gotViewings(res),
                () => this.requestError(),
            );
    }

    private viewingCreated(viewing?: IViewingViewModel): void {
        if (!viewing) {
            return;
        }

        this.filterForm.enable();
        const newViewingModel = ViewingViewModel.factory(viewing);
        const lastOlder = this.viewings.find((e) => e.date < newViewingModel.date);

        if (lastOlder) {
            this.viewings.splice(this.viewings.indexOf(lastOlder), 0, newViewingModel);
        } else {
            this.viewings.unshift(newViewingModel);
        }
    }

    byViewingsId(_index: number, viewing: ViewingViewModel): string {
        return viewing.id;
    }

    refreshViewings(): void {
        this.ngOnInit();
    }
}
