import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { DateHelper } from '@helpers/date.helper';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DateFormat } from '../models/common/date-format';

@Pipe({
    name: 'convertDate',
    pure: false,
})
@Injectable()
export class ConvertDatePipe implements PipeTransform {
    private translateService = inject(TranslateService);


    transform(date: string | Date): string {
        if (!DateHelper.isValidDate(date)) {
            return '-';
        }

        const dateFormat = DateFormat[this.translateService.currentLang];

        return moment.utc(date).format(dateFormat);
    }
}
