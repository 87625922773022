import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { String } from '@helpers/string.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { ImageViewModel } from '@models/advert/image';
import { IAdvertImageRequestParams, IAdvertImagesPageResponseBody } from '@models/backend/responses';
import { Selectable } from '@models/common/selectable';
import { ISelect } from '@models/shared';
import { DETAILS_OPTION, FOREST_DETAILS_OPTION } from '@modules/image-upload/preview-uploaded-image/options';
import { AdvertService } from '@services/advert.service';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';

@Component({
    selector: 'intranet-gallery',
    templateUrl: 'intranet-gallery.component.html',
    styleUrls: ['intranet-gallery.component.less'],
})
export class IntranetGalleryComponent extends UnSubscriptionDirective implements OnInit {
    private readonly advertService = inject(AdvertService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    readonly dialogRef = inject<MatDialogRef<IntranetGalleryComponent>>(MatDialogRef);
    readonly advert = inject<AdvertViewModel>(MAT_DIALOG_DATA);

    @Output()
    uploadToIntranet = new EventEmitter();

    images: Selectable<ImageViewModel>[];
    imagesBackup: Selectable<ImageViewModel>[];

    detailFilter: string[];

    isSamplesView: boolean = false;
    isIntranetDown: boolean = false;
    isLoading: boolean = false;

    private lastScrollPosition = 0;
    private selectedImages: ImageViewModel[] = [];

    private continuationToken: string;
    private isLastPage: boolean = false;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    @ViewChild('imageContainer', { static: false })
    private imageContainer: ElementRef;

    get selectedCount(): number {
        return this.images.filter((i) => i.isSelected).length;
    }

    get hasImages(): boolean {
        return !this.isIntranetDown && this.images !== undefined;
    }

    private get requestParams(): IAdvertImageRequestParams {
        return {
            unitId: this.advert.id,
            isExamplesVisible: this.isSamplesView,
            continuationToken: this.continuationToken,
        };
    }

    detailsOptions: ISelect[] = DETAILS_OPTION;
    detailsOptionsBackup: ISelect[] = DETAILS_OPTION;
    selectedDetails: ISelect;

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
        this.loadingIndicator.show();
        this.isLoading = true;

        this.advertService
            .getAdvertImages(this.requestParams)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.gotImages(res),
                (errors) => this.requestError(errors[0]),
            );
    }

    changeDetailFilter(detailOption: string[]): void {
        this.detailFilter = detailOption;

        if (detailOption.length === 0) {
            this.images = this.imagesBackup;
            this.detailFilter = [];
            return;
        }

        this.images = this.imagesBackup.filter((image) => detailOption.includes(image.context.pictureDetail));
    }

    private gotImages(res: IAdvertImagesPageResponseBody): void {
        this.loadingIndicator.hide();
        this.isLoading = false;

        this.isLastPage = res.isLast;
        this.continuationToken = res.continuationToken;

        this.images = res.data.map((i) => {
            const vm = ImageViewModel.factory(i);
            const selectable = new Selectable<ImageViewModel>(vm);
            selectable.isSelected = this.advert.images?.some((si) => si.id === vm.id);
            return selectable;
        });
        this.selectedImages = this.advert.images;

        this.imagesBackup = this.images;

        if (this.detailFilter?.length > 0) {
            this.images = this.images.filter((image) => this.detailFilter.includes(image.context.pictureDetail));
        }

        const pictureDetails = this.images.map((image) => image.context.pictureDetail);
        this.detailsOptionsBackup = this.advert.type === 'huntingArea' ? FOREST_DETAILS_OPTION : DETAILS_OPTION;
        this.detailsOptions = this.detailsOptionsBackup.filter((image) => pictureDetails.includes(image.id));

        this.changeDetectorRef.detectChanges();
        this.setScrollingBehavior();
    }

    private setScrollingBehavior(): void {
        if (!this.imageContainer) {
            return;
        }

        fromEvent(this.imageContainer.nativeElement, 'scroll')
            .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
            .subscribe(() => this.imagesScrolled());
    }

    private imagesScrolled(): void {
        const imagePanel = this.imageContainer.nativeElement as HTMLElement;
        const delta = imagePanel.scrollTop - this.lastScrollPosition;
        this.lastScrollPosition = imagePanel.scrollTop;
        this.loadNextImagesPage(delta);
    }

    private loadNextImagesPage(delta: number): void {
        if (delta <= 0 || this.isLastPage) {
            return;
        }

        this.loadingIndicator.show();

        this.advertService
            .getAdvertImages(this.requestParams)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.appendImages(res),
                () => (this.isIntranetDown = true),
            );
    }

    private appendImages(res: IAdvertImagesPageResponseBody): void {
        this.loadingIndicator.hide();

        this.isLastPage = res.isLast;
        this.continuationToken = res.continuationToken;

        const result = res.data.map((i) => {
            const vm = ImageViewModel.factory(i);
            const selectable = new Selectable<ImageViewModel>(vm);
            selectable.isSelected = this.advert.images?.some((si) => si.id === vm.id);
            return selectable;
        });

        this.images = [...this.images, ...result];
    }

    private requestError(error: { status: number }): void {
        this.loadingIndicator.hide();
        this.isLoading = false;
        this.isIntranetDown = error.status === 502;
    }

    onSamplesViewChanged(): void {
        this.isSamplesView = !this.isSamplesView;
        this.loadingIndicator.show();
        this.detailFilter = [];
        this.selectedDetails = null;
        this.continuationToken = null;

        this.advertService
            .getAdvertImages(this.requestParams)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.gotImages(res),
                (errors) => this.requestError(errors[0]),
            );
    }

    getKebabCaseDetail(image: Selectable<ImageViewModel>): string {
        return String.toKebabCase(image.context.pictureDetail);
    }

    imageSelected(image: Selectable<ImageViewModel>): void {
        if (!image.isSelected) {
            this.selectedImages.push(image.context);
        } else {
            this.selectedImages = this.selectedImages.filter((i) => i.id !== image.context.id);
        }

        image.isSelected = !image.isSelected;
    }

    save(): void {
        this.dialogRef.close(this.selectedImages);
    }

    byIndex(index: number): number {
        return index;
    }
}
