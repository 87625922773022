<div class="files-row" *ngIf="uploadedImages$">
    <div class="file-list" [formGroup]="imageUploadform">
        <div formArrayName="items" *ngFor="let item of uploadedImages; let i = index">
            <div [formGroupName]="i">
                <div class="file-item">
                    <div class="image-details">
                        <img class="{{ item.orientation }}" [src]="item.image" alt="image-{{ i }}" />

                        <div class="file-details">
                            <span class="name" title="{{ item.name }}" [ngClass]="{ ellipse: !isStartUploading }">{{
                                item.name
                            }}</span>
                            <span>{{ item.size | formatFileSize }}</span>
                        </div>
                    </div>

                    <div class="container" [class.end]="isStartUploading">
                        <ng-container *ngIf="!isStartUploading">
                            <section class="item-width" *ngIf="isPropertyBuilding">
                                <mat-form-field>
                                    <mat-label>{{ 'SHARED_COMPONENT.TYPE_LABEL' | translate }}</mat-label>
                                    <mat-select formControlName="pictureType">
                                        <mat-option *ngFor="let tagType of imageTagsList" [value]="tagType.id">
                                            {{ tagType.label | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-error
                                    *ngIf="
                                        imageUploadformControls.controls[i].controls.pictureType.hasError('required')
                                    "
                                    >{{ 'VALIDATION_MESSAGES.REQUIRED_FIELD_NOT_GIVEN' | translate }}</mat-error
                                >
                            </section>

                            <section class="item-width">
                                <mat-form-field>
                                    <mat-label>{{ 'IMAGE_UPLOAD_ORIENTATION.orientation' | translate }}</mat-label>
                                    <mat-select formControlName="orientation">
                                        <mat-option *ngFor="let orientation of orientations" [value]="orientation.id">
                                            {{ orientation.label | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </section>

                            <section class="item-width">
                                <autocomplete
                                    formControlName="pictureDetail"
                                    placeholder="{{ 'SHARED_COMPONENT.DETAIL' | translate }}"
                                    [options]="detailsOptions"
                                >
                                </autocomplete>
                            </section>

                            <section class="example" *ngIf="showExample">
                                <mat-checkbox formControlName="isExample">{{
                                    'IMAGE_TAGS.examples' | translate
                                }}</mat-checkbox>
                            </section>

                            <mat-icon class="material-icons-outlined" clickAndKeydown (actionTriggered)="remove(i)"
                                >delete</mat-icon
                            >
                        </ng-container>

                        <section class="item-width txt-right" *ngIf="isStartUploading">
                            <ng-container [ngSwitch]="imageUploadResults[i].uploadStatus">
                                <mat-spinner *ngSwitchCase="'inProgress'"></mat-spinner>
                                <mat-icon class="success" *ngSwitchCase="'success'">check_circle</mat-icon>
                                <mat-icon class="failed" *ngSwitchCase="'failed'">cancel</mat-icon>
                            </ng-container>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="actions">
    <button mat-flat-button clickAndKeydown (actionTriggered)="backToAdvert()">
        {{ 'SHARED_COMPONENT.BACK_TO_ADVERT' | translate }}
    </button>

    <button
        mat-flat-button
        color="primary"
        *ngIf="!isUploadComplete"
        [disabled]="IsUploadValid"
        clickAndKeydown
        (actionTriggered)="uploadImagesOneByOne()"
    >
        {{ 'SHARED_COMPONENT.UPLOAD_BUTTON' | translate }}
    </button>
    <button
        class="backToUpload"
        mat-flat-button
        color="primary"
        *ngIf="isUploadComplete"
        clickAndKeydown
        (actionTriggered)="backToUpload()"
    >
        {{ 'SHARED_COMPONENT.BACK_TO_UPLOAD' | translate }}
    </button>
</div>
