import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sanitazedPhoneNumber',
    pure: false,
})
export class SanitazedPhoneNumberPipe implements PipeTransform {
    transform(value: string): string | null {
        return value ? value.replace(/[^+0-9]+/g, '') : null;
    }
}
