import { Directive, Input } from '@angular/core';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Directive()
export abstract class ValidatedSection extends UnSubscriptionDirective {
    @Input() isPreview = false;

    abstract get isValid(): boolean;

    get validationIcon(): string {
        return this.isValid ? 'check_circle' : 'error';
    }
}
