export class Selectable<T> {
	context: T;
	isSelected: boolean;
	displayName: string;

	constructor(item: T, isSelected?: boolean) {
		this.context = item;
		this.isSelected = isSelected || false;
	}
}
