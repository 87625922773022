import { TimeHelper } from '@helpers/time.helper';
import moment from 'moment';
import { InviteeViewModel } from '../invitee';

export interface IViewingViewModel {
    id?: string;
    date: Date | string;
    startTime: number;
    endTime: number;
    description?: string;
    descriptionEn?: string;
    meetingPoint: 'mainEntrance' | 'rearEntrance' | 'apartmentDoor';
    invitees: IInviteeViewModel[];
    numberOfDeletedInvitees: number;
    totalNumberOfInvitees: number;
    status?: ViewingStatus;
}

export interface IViewingInviteeResponseModel {
    operationId: InviteeEventResponse;
    inviteeIds: string[];
    viewingId: string;
}

// TODO: This type is only use for API requests
export interface IViewingRequest {
    id: string;
    date: Date | string;
    startTime: number;
    endTime: number;
    description?: string;
    descriptionEn?: string;
    meetingPoint: 'mainEntrance' | 'rearEntrance' | 'apartmentDoor';
    numberOfDeletedInvitees: number; // TODO: is this really a part from IViewing?
    totalNumberOfInvitees: number; // TODO: is this really a part from IViewing?
    inviteeIds: string[];
}

// Note: This type represents the raw viewing from the database.
export interface IViewing {
    id: string;
    date: Date | string;
    startTime: number;
    endTime: number;
    description?: string;
    descriptionEn?: string;
    meetingPoint: 'mainEntrance' | 'rearEntrance' | 'apartmentDoor';
    invitees: IInvitee[];
    author: string;
}

export interface IInvitee {
    hasAttended: boolean;
    id: string;
    notifications: INotification[];
    response: InviteeResponse;
}

export interface INotification {
    type: 'sms' | 'email';
    sentAt: string;
}

export type InviteeResponse = 'confirmed' | 'declined' | null;

export interface IInviteeViewModel {
    id: string;
    name: string;
    email: string;
    phone: string;
    response: InviteeEventResponseStatus;
    hasAttended: boolean;
}

export type ViewingStatus =
    | 'draft'
    | 'scheduled'
    | 'declined'
    | 'pending'
    | 'archived'
    | 'attendee-confirmation'
    | 'viewing-carried-out'
    | 'absentOnViewing'
    | 'viewingAttended';

export type InviteeEventResponse = 'confirm' | 'decline';
export type InviteeEventResponseStatus = 'confirmed' | 'declined' | null;

export class ViewingViewModel {
    id: string;
    date: Date;
    endTime: number;
    description: string;
    descriptionEn: string;
    meetingPoint: 'mainEntrance' | 'rearEntrance' | 'apartmentDoor';
    status: ViewingStatus;
    numberOfDeletedInvitees: number;
    totalNumberOfInvitees: number;
    invitees: InviteeViewModel[];

    private _startTime: number;
    get startTime(): number {
        return this._startTime;
    }
    set startTime(val: number) {
        this._startTime = val;

        if (!this.date) {
            return;
        }

        const hours = TimeHelper.getHourFromCombinedValue(val);
        const minutes = TimeHelper.getMinuteFromCombinedValue(val);
        this.date.setHours(hours);
        this.date.setMinutes(minutes);
    }

    static factory(viewing: IViewingViewModel): ViewingViewModel {
        const vm = new ViewingViewModel();
        vm.id = viewing.id;
        vm.date = typeof viewing.date === 'string' ? moment(viewing.date).utc().toDate() : viewing.date;
        vm.startTime = viewing.startTime;
        vm.endTime = viewing.endTime;
        vm.description = viewing.description;
        vm.descriptionEn = viewing.descriptionEn;
        vm.meetingPoint = viewing.meetingPoint;
        vm.numberOfDeletedInvitees = viewing.numberOfDeletedInvitees;
        vm.totalNumberOfInvitees = viewing.totalNumberOfInvitees;
        vm.status = viewing.status;
        vm.invitees = viewing.invitees ? viewing.invitees.map((i) => InviteeViewModel.factory(i)) : [];
        return vm;
    }

    get isScheduled(): boolean {
        return this.status === 'scheduled';
    }

    get isDeclined(): boolean {
        return this.status === 'declined';
    }

    get isPending(): boolean {
        return this.status === 'pending';
    }

    get isDraft(): boolean {
        return this.status === 'draft';
    }

    get isArchived(): boolean {
        return this.status === 'archived';
    }

    get hasConfirmedAttendees(): boolean {
        return this.status === 'attendee-confirmation';
    }

    get isAbsentOnViewing(): boolean {
        return this.status === 'absentOnViewing';
    }

    get isViewingAttended(): boolean {
        return this.status === 'viewingAttended';
    }

    get isViewingCarriedOut(): boolean {
        return this.status === 'viewing-carried-out';
    }

    get hasInvitees(): boolean {
        return this.invitees?.length > 0;
    }

    extract(): IViewingViewModel {
        return {
            date: this.date,
            endTime: this.endTime,
            invitees: this.invitees,
            meetingPoint: this.meetingPoint,
            startTime: this._startTime,
            description: this.description,
            descriptionEn: this.descriptionEn,
            id: this.id,
            numberOfDeletedInvitees: this.numberOfDeletedInvitees,
            totalNumberOfInvitees: this.totalNumberOfInvitees,
            status: this.status,
        };
    }
}
