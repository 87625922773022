import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PublicationPortal, UnpublishReason } from '@models/backend/common';
import { IAdvertPublicationResultResponseBody } from '@models/backend/responses';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PublishService {
    private httpClient = inject(HttpClient);


    publishAdvert(
        advertId: string,
        prospectLimit: number,
        portals?: PublicationPortal[]
    ): Observable<IAdvertPublicationResultResponseBody> {
        return this.httpClient.put<IAdvertPublicationResultResponseBody>(`units/${advertId}/publication`, {
            portals: portals,
            prospectLimit: prospectLimit,
        });
    }

    unpublishAdvert(
        unpublishReason: UnpublishReason,
        advertId: string,
        countryCode: string
    ): Observable<IAdvertPublicationResultResponseBody> {
        const params = new HttpParams().set('unpublishReason', unpublishReason).set('countryCode', countryCode);
        return this.httpClient.delete<IAdvertPublicationResultResponseBody>(`units/${advertId}/publication`, {
            params: params,
        });
    }
}
