import { UntypedFormControl } from '@angular/forms';

export class CustomValidators {
    static requiredForDropdown(control: UntypedFormControl) {
        return control.value === 'notSelected' ? { required: true } : null;
    }
    static backendError() {
        return () => ({ isValid: false });
    }
}
