import { Component, Input, computed } from '@angular/core';
import { IViewingEvent } from '@models/backend/advert';
import { authorMessage, isTranslationKey, isUserDeleted } from '../common';

@Component({
    selector: 'tr[viewing-event-item]',
    templateUrl: 'viewing-event-item.component.html',
    styleUrls: ['viewing-event-item.component.less'],
})
export class ViewingEventItemComponent {
    @Input() event: IViewingEvent;

    authorMessage = computed(() => authorMessage(this.event));

    get isUserNotAvailable(): boolean {
        return isUserDeleted(this.event);
    }

    get timestampLabel(): string {
        const date = new Date(this.event.timestampUtc);
        const minutes = date.getMinutes();
        const minutesLabel = minutes < 10 ? `0${minutes}` : minutes;

        return `${date.getHours()}:${minutesLabel}`;
    }

    get invitees(): string {
        const inviteesTranlastionKey =
            this.event.data.invitees.length === 1 ? 'VIEWINGS_VIEW.INVITEE' : 'VIEWINGS_VIEW.INVITEES';

        return inviteesTranlastionKey;
    }

    get numberOfConfirmedAttendees(): number {
        return this.event.data.invitees.filter((e) => e.hasAttended).length;
    }

    get showDeclined(): boolean {
        return this.event.data.invitees.filter((i) => i.response === 'declined').length !== 0;
    }

    get showUnresponded(): boolean {
        const [, , unresponded] = this.getInviteeResponseStatistics();

        return unresponded !== 0;
    }

    get showConfirmed(): boolean {
        return this.event.data.invitees.filter((i) => i.response === 'confirmed').length !== 0;
    }

    get showStatsForViewingCarriedOut(): boolean {
        return this.event.eventType === 'ViewingCarriedOut';
    }

    hasTranslationKey(translationKey: string): boolean {
        return isTranslationKey(translationKey);
    }

    numberOfDeclined(): string {
        const number = this.event.data.invitees.filter((i) => i.response === 'declined').length;

        if (number === 0) {
            return '';
        }

        return number.toString();
    }

    numberOfUnresponded(): string {
        const [, declined, unresponded] = this.getInviteeResponseStatistics();

        if (unresponded === 0) {
            return '';
        }

        if (declined > 0) {
            return `${unresponded},`;
        }

        return unresponded.toString();
    }

    numberOfConfirmed(): string {
        const [confirmed, declined, unresponded] = this.getInviteeResponseStatistics();

        if (confirmed === 0) {
            return '';
        }

        if (declined > 0 || unresponded > 0) {
            return `${confirmed},`;
        }

        return confirmed.toString();
    }

    private getInviteeResponseStatistics(): number[] {
        const { invitees } = this.event.data;
        const total = invitees.length;
        const confirmed = invitees.filter((i) => i.response === 'confirmed').length;
        const declined = invitees.filter((i) => i.response === 'declined').length;
        const unresponded = total - confirmed - declined;

        return [confirmed, declined, unresponded];
    }
}
