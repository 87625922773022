import { Component, EventEmitter, Output, inject } from '@angular/core';
import { CountryService } from '@services/country.service';

@Component({
    selector: 'prospect-viewing-actions',
    templateUrl: './prospect-viewing-actions.component.html',
})
export class ProspectViewingActionsComponent {
    private countryService = inject(CountryService);

    @Output()
    setViewingInvitation = new EventEmitter();

    @Output()
    setConfirmation = new EventEmitter();

    get enableViewingOptions(): boolean {
        return this.countryService.getCurrentCountry() !== 'SE';
    }

    viewingInvitation(): void {
        this.setViewingInvitation.emit();
    }

    confirmation(): void {
        this.setConfirmation.emit();
    }
}
