import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IContactDetails } from '@models/backend/contacts';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

type IRotationDialogArgs = IContactDetails & {
    status$: Observable<string>;
};

@Component({
    selector: 'rotation-confirmation-dialog',
    templateUrl: './rotation-confirmation-dialog.component.html',
    styleUrls: ['./rotation-confirmation-dialog.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RotationConfirmationDialogComponent implements OnInit {
    dialogRef = inject<MatDialogRef<RotationConfirmationDialogComponent>>(MatDialogRef);
    private translateService = inject(TranslateService);
    args = inject<IRotationDialogArgs>(MAT_DIALOG_DATA);

    lettingManagerName: string;
    status$: Observable<string>;

    ngOnInit(): void {
        this.lettingManagerName = `${this.args.contactFirstName} ${this.args.contactLastName}`;
        this.status$ = this.args.status$;
    }

    getText(status: string): string {
        switch (status) {
            case 'warning':
                return this.translateService.instant('ROTATION.WARNING', {
                    lettingManagerName: this.lettingManagerName,
                });
            case 'success':
                return this.translateService.instant('ROTATION.SUCCESS', {
                    lettingManagerName: this.lettingManagerName,
                });
            case 'error':
                return this.translateService.instant('SHARED_COMPONENT.VIEWING_INVITATION_CONFIRMATION_ERROR');
        }
    }

    close(confirmed: boolean = false): void {
        this.dialogRef.close(confirmed);
    }
}
