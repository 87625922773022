import { SupportedCountryCode } from './common';

export interface IProspect {
    advertId: string;
    countryCode: SupportedCountryCode;
    dateOfReceipt: Date;
    email?: string;
    hash?: string;
    householdSize?: string;
    id: string;
    isDeleted?: boolean;
    isRejected?: boolean;
    marketingChannel: MarketingChannel;
    marketingOptIn: boolean | null;
    message?: string;
    name?: string;
    netHouseholdIncome?: string;
    personalIdentityNumber?: string;
    phone?: string;
    portalName: ProspectPortal;
    preferredMoveInDate?: string;
    rejectionReason?: ProspectDeletionReason | null;
    scheduledViewingDate?: Date | null;
    scheduledViewingStartTime?: number | null;
    status: ProspectStatus;
    statusScore: number;
    url?: string;
}

export interface IProspectSource {
    name: ProspectPortal;
    numberOfProspects?: number;
}

export enum ProspectStatus {
    New = 'new',
    MessageAnswered = 'messageAnswered',
    NotAvailable = 'notAvailable',
    ViewingScheduled = 'viewingScheduled',
    HoldingDepositPaid = 'holdingDepositPaid',
    InReferencing = 'inReferencing',
    InvitedToViewing = 'invitedToViewing',
    UnderSigning = 'underSigning',
    Tenant = 'tenant',
    Rejected = 'rejected',
    ViewingDeclined = 'viewingDeclined',
    RejectedReservation = 'rejectedReservation',
    ViewingAttended = 'viewingAttended',
    AbsentOnViewing = 'absentOnViewing',
}

export enum ProspectDeletionReason {
    Duplicate = 'duplicate',
    NotChosen = 'notChosen',
    NotInterestedAnymore = 'notInterestedAnymore',
    Spam = 'spam',
}

export enum ProspectRejectionReason {
    UnitIsRented = 'unitIsRented',
    UnitIsReserved = 'unitIsReserved',
}

export enum ProspectPortal {
    AkeliusWeb = 'AkeliusWeb',
    ApartmentsDotCom = 'ApartmentsDotCom',
    CallCenter = 'CallCenter',
    ImmoScout24 = 'ImmoScout24',
    Immowelt = 'Immowelt',
    Kijiji = 'Kijiji',
    LettingApp = 'LettingApp',
    ListNet = 'ListNet',
    Louer = 'Louer',
    PadMapper = 'PadMapper',
    PhoneCall = 'PhoneCall',
    RealEstateWire = 'RealEstateWire',
    Rentals = 'Rentals',
    RentBoard = 'RentBoard',
    RentCanada = 'RentCanada',
    RentFaster = 'RentFaster',
    Rentsync = 'Rentsync',
    Rightmove = 'Rightmove',
    SeLoger = 'SeLoger',
    SeLogerPlus = 'SeLogerPlus',
    SeLogerPro = 'SeLogerPro',
    SeLogerProLouervite = 'SeLogerProLouervite',
    TorontoRentals = 'TorontoRentals',
    Zoopla = 'Zoopla',
    Zumper = 'Zumper',
    Chatbot = 'Chatbot',
}

export enum MarketingChannel {
    AkeliusWeb = 'AkeliusWeb',
    ApartmentsDotCom = 'ApartmentsDotCom',
    BillboardsAndOtherSigns = 'BillboardsAndOtherSigns',
    BuildingSign = 'BuildingSign',
    Facebook = 'Facebook',
    FlashSale = 'FlashSale',
    Flyer = 'Flyer',
    GoogleAds = 'GoogleAds',
    ImmoScout24 = 'ImmoScout24',
    Immowelt = 'Immowelt',
    Kijiji = 'Kijiji',
    ListNet = 'ListNet',
    Louer = 'Louer',
    MlsRealtor = 'MlsRealtor',
    Newspaper = 'Newspaper',
    OpenHouse = 'OpenHouse',
    Other = 'Other',
    PadMapper = 'PadMapper',
    PhoneCall = 'PhoneCall',
    RealEstateWire = 'RealEstateWire',
    Referral = 'Referral',
    Rentals = 'Rentals',
    RentBoard = 'RentBoard',
    RentCanada = 'RentCanada',
    RentFaster = 'RentFaster',
    RentSeeker = 'RentSeeker',
    Rightmove = 'Rightmove',
    SearchEngine = 'SearchEngine',
    SeLoger = 'SeLoger',
    TorontoRentals = 'TorontoRentals',
    WordOfMouth = 'WordOfMouth',
    Zoopla = 'Zoopla',
    Zumper = 'Zumper',
    Chatbot = 'Chatbot',
    ListingSite = 'ListingSite',
    SocialMedia = 'SocialMedia',
    SignAtAnAkeliusProperty = 'SignAtAnAkeliusProperty',
    StreetEasy = 'StreetEasy',
    ReferredByAFriendOrWordOfMouth = 'ReferredByAFriendOrWordOfMouth',
    PreviouslyOrCurrentlyATenantWithAkelius = 'PreviouslyOrCurrentlyATenantWithAkelius',
    FlyerInTheMail = 'FlyerInTheMail',
    NewspaperOrMagazineAd = 'NewspaperOrMagazineAd',
    GoogleSearch = 'GoogleSearch',
}
