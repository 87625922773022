import { Component, OnInit, inject } from '@angular/core';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil, timer } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.less'],
})
export class SplashScreenComponent extends UnSubscriptionDirective implements OnInit {
    private userSettingsService = inject(UserSettingsService);

    windowWidth: string;
    showSplash = true;
    isMobile = false;

    ngOnInit(): void {
        this.isMobile = this.userSettingsService.isMobileDevice();

        timer(200)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.windowWidth = '-' + window.innerWidth + 'px';

                timer(500)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.showSplash = !this.showSplash;
                    });
            });
    }
}
