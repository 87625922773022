import { Injectable, inject } from '@angular/core';
import { SentryService } from '@modules/sentry/sentry.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { EnvironmentService } from '@services/environment.service';

@Injectable({ providedIn: 'root' })
export class AppMissingTranslationHandler implements MissingTranslationHandler {
    private environmentService = inject(EnvironmentService);
    private sentryService = inject(SentryService);

    public handle(params: MissingTranslationHandlerParams): string {
        // Send Sentry message if translation key could not be found
        // need to be activate only for prod
        const isProduction = this.environmentService.getEnvinronment().isProduction;

        if (isProduction && params) {
            this.sentryService.captureMessage('missing translation: ' + params.key, 'error');
        }

        return params.key;
    }
}
