import { Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';

@Directive({
    selector: '[loadImage]',
})
export class LoadImageDirective implements OnInit {
    private el = inject(ElementRef);
    private renderer = inject(Renderer2);

    @Input({ required: true, alias: 'loadImage' }) imageUrl: string;
    @Input() fallbackUrl: string = '/assets/image/fallback-image.png';
    @Input() isPortrait: boolean;

    async ngOnInit() {
        try {
            await this.loadImage(this.imageUrl);
            this.setStyles(this.imageUrl);
        } catch {
            this.setStyles(this.fallbackUrl);
        }
    }

    private setStyles(url: string) {
        const styles = {
            'background-image': `url('${url}')`,
            'background-position': 'center',
            'background-repeat': 'no-repeat',
            'background-size': this.isPortrait ? 'contain' : 'cover',
        };

        for (const [key, value] of Object.entries(styles)) {
            this.renderer.setStyle(this.el.nativeElement, key, value);
        }
    }

    private loadImage(url: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve();
            img.onerror = () => reject(new Error(`The image with the url: ${url} is not available.`));
        });
    }
}
