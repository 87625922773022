import { String } from './string.helper';

export class DateHelper {
    static isValidDate(date: string | Date): boolean {
        const isDateAvailable = date instanceof Date || String.isValidDateString(date);
        if (!isDateAvailable) {
            return false;
        }

        return true;
    }
}
