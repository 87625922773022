import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { AdvertFilterStatus } from '@models/backend/advert';
import { VacancyStatus } from '@models/backend/common';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';

export class UrlQueryParams {
    public advertType: string;
    public assignedContactFilter: IAutocompleteData | string;
    public branch: string;
    public vacancyStatuses: VacancyStatus[];
    public region: string;
    public searchQuery: string;
    public sortBy: string;
    public sortDirection: string;
    public status: AdvertFilterStatus;
    public streetName: string;
    public unitCode: string;
    constructor(query: ITeaserFilterOptions) {
        this.advertType = query.advertType;
        this.assignedContactFilter = query.assignedContactFilter;
        this.branch = query.branch;
        this.vacancyStatuses = query.vacancyStatuses;
        this.region = query.region;
        this.searchQuery = query.searchQuery;
        this.sortBy = query.sortBy;
        this.sortDirection = query.sortDirection;
        this.status = query.status;
        this.streetName = query.streetName;
        this.unitCode = query.unitCode;
    }
}
