import { IPostalAddress } from '@models/backend/advert';
import { SupportedCountryCode } from '@models/backend/common';

export function buildPostalCodeAndCity(countryCode: SupportedCountryCode, address: IPostalAddress): string {
    let postalCodeAndCity;
    const { city, state, postalCode } = address;
    const cityValue = city || '';
    const stateValue = state || '';
    const postalCodeValue = postalCode || '';

    if (countryCode === 'CA') {
        postalCodeAndCity = state
            ? `${cityValue}, ${stateValue}, ${postalCodeValue}`
            : `${cityValue}, ${postalCodeValue}`;
    } else {
        postalCodeAndCity = `${postalCodeValue} ${cityValue}`;
    }

    return postalCodeAndCity.trim();
}

export function buildStreetNameAndHouseNumber(address: IPostalAddress, direction?: string): string {
    const { streetName, houseNumber } = address;
    const streetNameValue = streetName || '';
    const houseNumberValue = houseNumber || '';
    const streetNameAndHouseNumberValue = `${streetNameValue} ${houseNumberValue}`.trim();
    const directionValue = direction || '';

    return streetNameAndHouseNumberValue || directionValue || '';
}
