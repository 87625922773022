import { Component, Input, inject } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';
import { CountryService } from '@services/country.service';

@Component({
    selector: 'apartment-header-stats',
    templateUrl: './apartment-header-stats.component.html',
})
export class ApartmentHeaderStatsComponent {
    private countryService = inject(CountryService);

    @Input() advert: AdvertViewModel;

    isLettableDateEnabled: boolean = this.countryService.getCurrentCountry() === 'FR';
}
