import { Component, Input } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';

@Component({
    selector: 'advert-rooms-label',
    templateUrl: './advert-rooms-label.component.html',
})
export class AdvertRoomsLabelComponent {
    @Input() advert: AdvertViewModel;
}
