export interface ILocalization {
    lengthUnit: 'm' | 'ft' | 'mi';
    areaUnit: 'sqm' | 'sqft';
    currency: string;
}

export type SupportedCountryCode = 'DE' | 'FR' | 'GB' | 'SE' | 'CA' | 'US' | 'CY';

export type SupportedCultureInfo = 'de-DE' | 'fr-FR' | 'en-GB' | 'sv-SE' | 'en-US' | 'en-CA';

export type VacancyStatus = 'occupied' | 'notice' | 'vacant';

export type PublicationPortal =
    | 'akelius-web'
    | 'apartments-com'
    | 'immobilienscout24'
    | 'immowelt'
    | 'kijiji'
    | 'rentsync'
    | 'rightmove'
    | 'seloger'
    | 'seloger-plus'
    | 'zoopla'
    | 'chatbot'
    | 'streeteasy';

export enum UnpublishReason {
    ApplicantFoundOrContractSigned = 'applicantFoundOrContractSigned',
    ConstructionProblems = 'constructionProblems',
    RentChangeOrAdvertUpdate = 'rentChangeOrAdvertUpdate',
    Absence = 'absence',
    TooManyInquiries = 'tooManyInquiries',
    TemporaryHousing = 'temporaryHousing',
}

export interface IPortal {
    name: PublicationPortal;
    trackingUrl?: string;
}

export interface IRegion {
    name: string;
    branches: { name: string }[];
}
