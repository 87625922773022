import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IHandleEventCreateInviteAndConfirmResponseBody,
    IViewingInviteeResponseResponseBody,
    IViewingResponseBody,
    IViewingsResponseBody,
} from '../models/backend/responses';
import {
    IInviteeViewModel,
    IViewingInviteeResponseModel,
    IViewingRequest,
    IViewingViewModel,
    ViewingStatus,
} from '../models/viewing/viewing';

@Injectable({ providedIn: 'root' })
export class ViewingsService {
    private httpClient = inject(HttpClient);


    getViewings(unitId: string): Observable<IViewingsResponseBody> {
        return this.httpClient.get<IViewingsResponseBody>(`view/units/${unitId}/viewings`);
    }

    deleteViewing(unitId: string, viewingId: string, status: ViewingStatus): Observable<unknown> {
        const params = new HttpParams().set('status', status);
        return this.httpClient.delete(`view/units/${unitId}/viewings/${viewingId}`, { params });
    }

    createViewing(unitId: string, viewing: IViewingViewModel): Observable<IViewingResponseBody> {
        return this.httpClient.post<IViewingResponseBody>(`view/units/${unitId}/viewings`, viewing);
    }

    createViewingAndInvite(
        unitId: string,
        viewing: IViewingRequest
    ): Observable<IHandleEventCreateInviteAndConfirmResponseBody> {
        return this.httpClient.post<IHandleEventCreateInviteAndConfirmResponseBody>(
            `units/${unitId}/handle/create-viewing-and-send-invitation`,
            viewing
        );
    }

    createViewingAndConfirm(
        unitId: string,
        viewing: IViewingRequest
    ): Observable<IHandleEventCreateInviteAndConfirmResponseBody> {
        return this.httpClient.post<IHandleEventCreateInviteAndConfirmResponseBody>(
            `units/${unitId}/handle/create-viewing-and-send-confirmation`,
            viewing
        );
    }

    updateViewing(unitId: string, viewing: IViewingViewModel, status: ViewingStatus): Observable<IViewingResponseBody> {
        const params = new HttpParams().set('status', status);
        return this.httpClient.put<IViewingResponseBody>(`view/units/${unitId}/viewings/${viewing.id}`, viewing, {
            params,
        });
    }

    getIcs(unitId: string, viewingId: string): Observable<Blob> {
        const url = `units/${unitId}/viewings/${viewingId}/ics`;
        return this.httpClient.get(url, { responseType: 'blob' });
    }

    inviteToViewing(advertId: string, viewingIds: string[], inviteeIds: string[]): Observable<IViewingResponseBody> {
        const viewingInvitations = viewingIds.map((viewingId) => ({ viewingId, inviteeIds }));
        return this.httpClient.post<IViewingResponseBody>(
            `units/${advertId}/handle/invite-prospects`,
            viewingInvitations
        );
    }

    confirmAttendence(advertId: string, invitee: IInviteeViewModel, viewingId: string): Observable<IInviteeViewModel> {
        return this.httpClient.patch<IInviteeViewModel>(
            `units/${advertId}/viewings/${viewingId}/invitees/${invitee.id}`,
            {
                hasAttended: invitee.hasAttended,
            }
        );
    }

    submitAndConfirmAttendence(advertId: string, viewingId: string): Observable<IInviteeViewModel> {
        const params = new HttpParams().set('viewingId', viewingId);

        return this.httpClient.post<IInviteeViewModel>(`units/${advertId}/handle/submit-attendance-for-viewing`, null, {
            params,
        });
    }

    replyToInvitation(
        data: IViewingInviteeResponseModel,
        advertId: string
    ): Observable<IViewingInviteeResponseResponseBody> {
        return this.httpClient.post<IViewingInviteeResponseResponseBody>(
            `units/${advertId}/handle/invitee-response`,
            data
        );
    }
}
