export type PhoneNumberCountryCodeList = {
    GB: '+44';
    FR: '+33';
    CA: '+1';
    US: '+1';
    CY: '+357';
    DE: '+49';
    SE: '+46';
};

export type PhoneNumberCountryCode = PhoneNumberCountryCodeList[keyof PhoneNumberCountryCodeList];

export const PhoneNumberCountryCodes: PhoneNumberCountryCodeList = {
    GB: '+44',
    FR: '+33',
    CA: '+1',
    US: '+1',
    CY: '+357',
    DE: '+49',
    SE: '+46',
};

export const PhoneNumberExamples = {
    DE: '+496912345678',
    CA: '+18191234567',
    US: '+19179720404',
    CY: '+35732002211',
    FR: '+33123456789',
    GB: '+447912345678',
    SE: '+46712345678',
};
