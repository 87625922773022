import { ExtraOptions, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AdvertImageUploadComponent } from '@components/advert-image-upload/advert-image-upload.component';
import { AdvertsComponent } from '@components/dashboard/sections/adverts/adverts.component';
import { GeneralInquiriesComponent } from '@components/dashboard/sections/general-inquiries/general-inquiries.component';
import { RotationTeaserComponent } from '@components/rotation/rotation-teaser/rotation-teaser.component';
import { AdvertDetailsComponent } from './components/advert-details/advert-details.component';
import { AdvertEventHistoryComponent } from './components/advert-event-history/advert-event-history.component';
import { AdvertPreviewComponent } from './components/advert-preview/advert-preview.component';
import { AdvertProspectsComponent } from './components/advert-prospects/advert-prospects.component';
import { AdvertViewingsComponent } from './components/advert-viewings/advert-viewings.component';
import { AdvertComponent } from './components/advert/advert.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SystemErrorComponent } from './components/system-error/system-error.component';
import { TeaserListComponent } from './components/teaser-list/teaser-list.component';
import { RotationGuard } from './guards/rotation.guard';

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
};

export const routeConfig = RouterModule.forRoot(
    [
        {
            path: 'error',
            component: SystemErrorComponent,
            data: {
                pageTitle: 'Error',
            },
        },

        {
            path: '',
            component: HomeComponent,
            data: {
                pageTitle: 'Lettings Application',
            },
            canActivate: [AuthGuard],
            children: [
                {
                    path: '',
                    component: DashboardComponent,
                    data: {
                        pageTitle: 'dashboard',
                    },
                    children: [
                        {
                            path: '',
                            component: AdvertsComponent,
                        },
                        {
                            path: 'general-inquiries',
                            component: GeneralInquiriesComponent,
                        },
                    ],
                },
                {
                    path: 'rotation',
                    component: RotationTeaserComponent,
                    canActivate: [RotationGuard],
                    data: {
                        pageTitle: 'rotation',
                    },
                },
                {
                    path: 'teasers',
                    component: TeaserListComponent,
                    data: {
                        pageTitle: 'teasers list',
                    },
                },
                {
                    path: 'advert/:unitId',
                    component: AdvertComponent,
                    children: [
                        {
                            path: '',
                            component: AdvertDetailsComponent,
                            data: {
                                pageTitle: 'advert details',
                            },
                        },
                        {
                            path: 'history',
                            component: AdvertEventHistoryComponent,
                            data: {
                                pageTitle: 'event history',
                            },
                        },
                        {
                            path: 'prospects',
                            component: AdvertProspectsComponent,
                            runGuardsAndResolvers: 'always',
                            data: {
                                pageTitle: 'prospects',
                            },
                        },
                        // TODO: to change the path, we have to adjust the route inside ics file which is generated by BE
                        {
                            path: 'events',
                            redirectTo: 'viewings',
                            data: {
                                pageTitle: 'events',
                            },
                        },
                        {
                            path: 'viewings',
                            component: AdvertViewingsComponent,
                            data: {
                                pageTitle: 'events',
                            },
                        },
                    ],
                },
                {
                    path: ':unitId/image-upload',
                    component: AdvertImageUploadComponent,
                    data: {
                        pageTitle: 'upload images',
                    },
                },
                {
                    path: 'preview/:unitId',
                    component: AdvertPreviewComponent,
                    data: {
                        pageTitle: 'preview advert',
                    },
                },
                {
                    path: '404',
                    component: NotFoundComponent,
                    data: {
                        pageTitle: 'Not found',
                    },
                },
                {
                    path: '**',
                    component: NotFoundComponent,
                    data: {
                        pageTitle: 'Not found',
                    },
                },
            ],
        },
    ],
    routingConfiguration,
);
