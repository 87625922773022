import { Injectable } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdvertStoreService {
    private _advertSubject: BehaviorSubject<AdvertViewModel>;
    private _advertsSubject: BehaviorSubject<AdvertViewModel[]>;
    refreshed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get advertSubject() {
        if (!this._advertSubject) {
            this._advertSubject = new BehaviorSubject(undefined);
            return this._advertSubject;
        }

        return this._advertSubject;
    }

    get advertsSubject() {
        if (!this._advertsSubject) {
            this._advertsSubject = new BehaviorSubject(undefined);
            return this._advertsSubject;
        }

        return this._advertsSubject;
    }

    update(advert: AdvertViewModel): void {
        this.advertSubject.next(advert);
    }

    updateAdverts(adverts: AdvertViewModel[]): void {
        this.advertsSubject.next(adverts);
    }
}
