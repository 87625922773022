import { ErrorHandler, Injectable, inject } from '@angular/core';
import { ISentryConfiguration } from '@environments/environment-model';
import { captureException } from '@sentry/browser';
import { OPTIONS } from './config';

type Errortype = {
    originialError: string;
};
@Injectable({
    providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
    private readonly options = inject<ISentryConfiguration>(OPTIONS);

    public handleError(error: unknown): void {
        if (this.options.environment === 'local') {
            throw error;
        }

        if (this.options.enabled) {
            const originalError = getOriginalError(error);
            captureException(originalError || error);
        }
    }
}

function getOriginalError(error: unknown): error is Errortype {
    if (!isObject(error)) {
        return false;
    }

    return (error as Errortype).originialError !== undefined;
}

function isObject(obj: unknown): boolean {
    return obj !== null && obj instanceof Object;
}
