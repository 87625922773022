import { AdvertType } from '@models/backend/advert';

export type CategorySetup = [CategoryValueType, string][];

export type CategoryItem = { type: CategoryValueType; id: string };

export const Category = {
    ...AdvertType,
} as const;

export type CategoryValueType = (typeof Category)[keyof typeof Category];

export type Identifier = 'bookmarked' | 'topPublished';

export interface ICategory<T> {
    id: Identifier;
    title: string;
    itemsCount: number;
    currentFilter: CategoryValueType;
    contexts: ICategoryContext<T>[];
}

export interface ICategoryContext<T> {
    translationKey: string;
    display: boolean;
    type: CategoryValueType;
    items: T[];
    isBookmarksMode: boolean;
}

export const Operation = {
    Update: 'update',
    Remove: 'remove',
} as const;

export type OperationType = (typeof Operation)[keyof typeof Operation];
