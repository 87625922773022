<autocomplete
    highlightActiveFormControl
    [ngModel]="value"
    [translateLabels]="false"
    [isRequired]="false"
    [showLoading]="showStreetSearchLoader"
    [useHttp]="true"
    placeholder="{{ 'KEY_FACTS.streetName' | translate }}"
    [options]="streetNames"
    (optionSelected)="onStreetSelection($event)"
    (input)="onInput($event)"
    (resetClicked)="onStreetSearchCleaned()"
>
</autocomplete>
