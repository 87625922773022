import { Component, Input, computed } from '@angular/core';
import { AdvertType, IAdvertEvent } from '@models/backend/advert';
import { authorMessage, isTranslationKey, isUserDeleted } from '../common';
@Component({
    selector: 'tr[advert-event-item]',
    templateUrl: 'advert-event-item.component.html',
    styleUrls: ['advert-event-item.component.less'],
})
export class AdvertEventItemComponent {
    @Input() event: IAdvertEvent;

    authorMessage = computed(() => authorMessage(this.event));

    get isUserNotAvailable(): boolean {
        return isUserDeleted(this.event);
    }

    get reasonLabel(): string {
        return this.event.data.lastUnpublishedReason
            ? `KEY_FACT_ENUMS.lastUnpublishedReason.${this.event.data.lastUnpublishedReason}`
            : 'KEY_FACT_ENUMS.apartmentType.undefined';
    }

    get timestampLabel(): string {
        const date = new Date(this.event.timestampUtc);
        const minutes = date.getMinutes();
        const minutesLabel = minutes < 10 ? `0${minutes}` : minutes;
        return `${date.getHours()}:${minutesLabel}`;
    }

    get isUnitApartmentForSale(): boolean {
        return AdvertType.ApartmentForSale === this.event.advertType;
    }

    get costLabel(): string {
        const advert = this.event.data;
        const cost = advert.keyfacts['cost'];
        return cost ? `${cost} ${advert.localization.currency}` : '-';
    }

    get baseRentLabel(): string {
        const advert = this.event.data;
        const baseRent = advert.keyfacts['base-rent'];
        return baseRent ? `${baseRent} ${advert.localization.currency}` : '-';
    }

    get hasProspects(): boolean {
        const { numberOfProspects } = this.event;
        return numberOfProspects !== undefined && numberOfProspects !== null && numberOfProspects > 0;
    }

    get shouldShowReasonColumn(): boolean {
        return this.event.eventType === 'AdvertUnpublished' && !this.event.data.isPublished;
    }

    get shouldShowRentColumn(): boolean {
        const expectedTypes = ['AdvertPublished', 'AdvertRepublished', 'AdvertUnpublished'];
        return expectedTypes.includes(this.event.eventType) && this.event.data.isPublished;
    }

    get shouldShowProspectsCountColumn(): boolean {
        return this.event.eventType === 'AdvertUnpublished' && this.hasProspects;
    }

    get shouldShowCommaBetweenRents(): boolean {
        const { keyfacts } = this.event.data;
        return keyfacts['total-rent'] && keyfacts['base-rent'] && keyfacts['base-rent'] !== keyfacts['total-rent'];
    }

    hasTranslationKey(translationKey: string): boolean {
        return isTranslationKey(translationKey);
    }
}
