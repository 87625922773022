import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnumHelper } from '@helpers/enum.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { IAdvertPublicationResult } from '@models/backend/advert';
import { PublicationPortal, UnpublishReason } from '@models/backend/common';
import { IAdvertPublicationResultResponseBody } from '@models/backend/responses';
import { IDropdownItem } from '@models/common/dropdown-item';
import { Selectable } from '@models/common/selectable';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';
import { PublishService } from '@services/publish.service';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';
import { PORTAL_DEFINITIONS, PortalDefinition } from './portal-definitions';

export enum PublishFlowViewType {
    Loading = 'Loading',
    SelectPortals = 'SelectPortals',
    Success = 'Success',
    SelectUnpublishReason = 'SelectUnpublishReason',
    Error = 'Error',
}

export interface IPublishModalArgs {
    advert: AdvertViewModel;
    mode: 'publish' | 'unpublish';
}

@Component({
    selector: 'publish',
    templateUrl: 'publish.component.html',
    styleUrls: ['publish.component.less'],
})
export class PublishComponent extends UnSubscriptionDirective implements OnInit {
    private router = inject(Router);
    private translateService = inject(TranslateService);
    private publishService = inject(PublishService);
    dialogRef = inject<MatDialogRef<PublishComponent>>(MatDialogRef);
    args = inject<IPublishModalArgs>(MAT_DIALOG_DATA);
    private gaService = inject(AnalyticsService);
    private userSettingsService = inject(UserSettingsService);

    currentView: PublishFlowViewType;
    selectedReason = UnpublishReason.ApplicantFoundOrContractSigned;
    unpublishReasonItems: IDropdownItem<UnpublishReason>[] = [];
    workflowResults: IAdvertPublicationResult[];
    publicationPortals: Selectable<PublicationPortal>[];

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    prospectLimitControl = new UntypedFormControl();

    isMobileSafariBrowser: boolean = false;

    get isLoading(): boolean {
        return this.currentView === PublishFlowViewType.Loading;
    }

    get isPublishing(): boolean {
        return this.args.mode === 'publish';
    }

    get title(): string {
        return this.isPublishing ? 'SHARED_COMPONENT.MODAL_PUBLISH_HEADER' : 'SHARED_COMPONENT.MODAL_UNPUBLISH_HEADER';
    }

    get loadingText(): string {
        return this.isPublishing
            ? 'SHARED_COMPONENT.MODAL_PUBLISH_LOADING_MESSAGE'
            : 'SHARED_COMPONENT.MODAL_UNPUBLISH_LOADING_MESSAGE';
    }

    get successText(): string {
        return this.isPublishing
            ? 'SHARED_COMPONENT.MODAL_PUBLISH_SUCCESS_MESSAGE'
            : 'SHARED_COMPONENT.MODAL_UNPUBLISH_SUCCESS_MESSAGE';
    }

    get errorText(): string {
        const tag = this.isPublishing
            ? 'SHARED_COMPONENT.MODAL_PUBLISH_ERROR_MESSAGE'
            : 'SHARED_COMPONENT.MODAL_UNPUBLISH_ERROR_MESSAGE';
        return this.translateService.instant(tag);
    }

    get canPublish(): boolean {
        return this.publicationPortals.some((p) => p.isSelected);
    }

    ngOnInit(): void {
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();

        this.unpublishReasonItems = this.getUnpublishReasons();
        this.currentView =
            this.args.mode === 'publish'
                ? PublishFlowViewType.SelectPortals
                : PublishFlowViewType.SelectUnpublishReason;
        this.workflowResults = [];

        const canPublishParkingSpaceToAkeliusWebsite = this.args.advert.canPublishParkingSpaceToAkeliusWebsite;
        this.publicationPortals = this.getPublicationPortalItems();

        if (!canPublishParkingSpaceToAkeliusWebsite) {
            this.publicationPortals = this.publicationPortals.filter((p) => p.context !== 'akelius-web');
        }

        this.dialogRef.disableClose = true;
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.backdropClicked());

        this.prospectLimitControl.setValue(this.args.advert.prospectLimit);
    }

    portalSelectionConfirmed(): void {
        this.currentView = PublishFlowViewType.Loading;
        this.loadingIndicator.show();

        const selectedPortals = this.publicationPortals.filter((p) => p.isSelected).map((p) => p.context);

        this.publishService
            .publishAdvert(this.args.advert.id, this.prospectLimitControl.value, selectedPortals)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => {
                    this.gaService.event(GoogleAnalyticsEvents.AdvertPublished, 'data', JSON.stringify(res.data));
                    return this.workflowSuccess(res);
                },
                () => this.workflowError(),
            );
    }

    unpublishReasonConfirmed(): void {
        this.gaService.event(GoogleAnalyticsEvents.AdvertUnpublished, 'data', `reason:${this.selectedReason}`);

        this.currentView = PublishFlowViewType.Loading;
        this.loadingIndicator.show();
        this.publishService
            .unpublishAdvert(this.selectedReason, this.args.advert.id, this.args.advert.countryCode)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.workflowSuccess(res),
                () => this.workflowError(),
            );
    }

    cancel(): void {
        this.dialogRef.close(this.workflowResults);
    }

    navigateToDashboard(): void {
        this.dialogRef.close(this.workflowResults);
        this.router.navigate(['']);
    }

    navigateToAdvert(): void {
        this.dialogRef.close(this.workflowResults);
        this.router.navigate([`/advert/${this.args.advert.id}`]);
    }

    private backdropClicked(): void {
        if (this.currentView === PublishFlowViewType.Loading) {
            return;
        }

        this.dialogRef.close(this.workflowResults);
    }

    private workflowSuccess(res: IAdvertPublicationResultResponseBody): void {
        this.loadingIndicator.hide();
        this.workflowResults = res.data;
        this.currentView = PublishFlowViewType.Success;
    }

    private workflowError(): void {
        this.loadingIndicator.hide();
        this.currentView = PublishFlowViewType.Error;
    }

    private getPublicationPortalItems(): Selectable<PublicationPortal>[] {
        const hasCountry = (p: PortalDefinition) => {
            return p.countryCodes === undefined || p.countryCodes.includes(this.args.advert.countryCode);
        };

        const hasType = (p: PortalDefinition) => {
            return p.advertTypes === undefined || p.advertTypes.includes(this.args.advert.type);
        };

        const hasRegion = (p: PortalDefinition) => {
            return p.regions === undefined || p.regions.includes(this.args.advert.region);
        };

        return PORTAL_DEFINITIONS.filter((pd) => hasCountry(pd) && hasType(pd) && hasRegion(pd)).map((pd) => {
            const isSelected = this.canPortalBeSelected(pd.portal);
            return new Selectable(pd.portal, isSelected);
        });
    }

    private getUnpublishReasons(): IDropdownItem<UnpublishReason>[] {
        const allItems = EnumHelper.toTranslatedArray<UnpublishReason>(UnpublishReason, 'lastUnpublishedReason');
        const { type, countryCode } = this.args.advert;

        switch (countryCode) {
            case 'CA':
            case 'DE':
            case 'FR':
            case 'GB':
            case 'US':
                return type === 'parking'
                    ? allItems.filter((i) => i.context !== UnpublishReason.TemporaryHousing)
                    : allItems;
            case 'SE':
            case 'CY':
                return allItems.filter(
                    (i) =>
                        i.context === UnpublishReason.ApplicantFoundOrContractSigned ||
                        i.context === UnpublishReason.RentChangeOrAdvertUpdate ||
                        i.context === UnpublishReason.TooManyInquiries,
                );
            default:
                return allItems;
        }
    }

    wasPublishedOnPortal(portal: PublicationPortal): boolean {
        return (
            this.args.advert.isPublished &&
            this.args.advert.publicationResults.some((pr) => pr.portalName === portal && pr.isSuccess)
        );
    }

    canPublishToAkeliusWebsite(): boolean {
        return this.args.advert.canBePublishedToAkeliusWebsite;
    }

    canPortalBeSelected(portal: PublicationPortal): boolean {
        if (portal === 'akelius-web') {
            return this.canPublishToAkeliusWebsite();
        }

        return true;
    }

    shouldDisablePortalSelector(portal: PublicationPortal): boolean {
        if (portal === 'akelius-web' && !this.canPublishToAkeliusWebsite()) {
            return true;
        }

        if (this.wasPublishedOnPortal(portal)) {
            return true;
        }

        return false;
    }

    byIndex(index: number): number {
        return index;
    }
}
