import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IEnvironment } from '@environments/environment-model';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
    getEnvinronment(): IEnvironment {
        return environment;
    }
}
