import { IContactDetails } from '@models/backend/contacts';

export type ResponsibilityId =
    | 'head-of-city'
    | 'head-of-area'
    | 'property-manager'
    | 'lettings-manager'
    | 'senior-lettings-manager'
    | 'construction-manager'
    | 'facilities-manager'
    | 'accounting-manager'
    | 'deposit-manager'
    | 'apartment-inspection'
    | 'claim-manager'
    | 'operational-cost'
    | 'operational-cost-group';

export const AuthorizedResponsibilities: ResponsibilityId[] = ['senior-lettings-manager'];

export interface IRotationTeaserFilter {
    region?: string;
    costCenterCode?: string;
}
export interface IRefreshAssignmentsData {
    costCenterCode: string[];
    selectedLm: IContactDetails;
}
