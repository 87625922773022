import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IPhoneNumber } from '@models/advert/phone-number';
import { SupportedCountryCode } from '@models/backend/common';

@Component({
    selector: 'phone-number',
    templateUrl: 'phone-number.component.html',
    styleUrls: ['phone-number.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberComponent implements OnChanges {
    @Input()
    phoneNumber: IPhoneNumber;
    @Input()
    isMandatory: boolean;
    @Input()
    countryCode: SupportedCountryCode;

    phoneForm: UntypedFormGroup;

    get isValid(): boolean {
        return this.phoneForm.valid;
    }

    get countryCodeTranslationKey(): string {
        switch (this.phoneNumber.phoneCountryCode) {
            case '+49':
                return 'SHARED_COMPONENT.COUNTRY_DE_PHONE';
            case '+33':
                return 'SHARED_COMPONENT.COUNTRY_FR_PHONE';
            case '+1':
                return this.countryCode === 'US'
                    ? 'SHARED_COMPONENT.COUNTRY_US_PHONE'
                    : 'SHARED_COMPONENT.COUNTRY_CA_PHONE';
            case '+357':
                return 'SHARED_COMPONENT.COUNTRY_CY_PHONE';
            case '+46':
                return 'SHARED_COMPONENT.COUNTRY_SE_PHONE';
            default:
                return 'SHARED_COMPONENT.COUNTRY_DE_PHONE';
        }
    }

    ngOnChanges(): void {
        const areaCode = this.phoneNumber ? this.phoneNumber.phoneAreaCode : undefined;
        const mainNumber = this.phoneNumber ? this.phoneNumber.mainNumber : undefined;

        this.phoneForm = new UntypedFormGroup({
            phoneAreaCode: new UntypedFormControl(areaCode, this.isMandatory ? Validators.required : undefined),
            mainNumber: new UntypedFormControl(mainNumber, this.isMandatory ? Validators.required : undefined),
        });
        this.phoneForm.markAllAsTouched();
        this.phoneForm.disable();
    }
}
