import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeHelper } from '@helpers/time.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { IViewingResponseBody } from '@models/backend/responses';
import { IViewingViewModel, ViewingViewModel } from '@models/viewing/viewing';
import { AdvertStoreService } from '@services/advert.store';
import { UserSettingsService } from '@services/user-settings.service';
import { ViewingsService } from '@services/viewings.service';
import moment, { Moment } from 'moment';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { areOnSameDay, getInitialStartTime } from '../viewing-helpers/common';
import { getInitialViewing, setupViewingForm } from '../viewing-helpers/viewing-helpers';

export interface IViewingEditorArgs {
    unitId: string;
    viewing?: ViewingViewModel;
    mode: 'create' | 'edit' | 'copy';
}

type ViewingEditorView = 'form' | 'loading';

@Component({
    selector: 'viewing-editor',
    templateUrl: './viewing-editor.component.html',
    styleUrls: ['./viewing-editor.component.less'],
})
export class ViewingEditorComponent extends UnSubscriptionDirective implements OnInit {
    private viewingsService = inject(ViewingsService);
    private advertStore = inject(AdvertStoreService);
    private userSettingsService = inject(UserSettingsService);
    private args = inject<IViewingEditorArgs>(MAT_DIALOG_DATA);

    dialogRef = inject<MatDialogRef<ViewingEditorComponent>>(MatDialogRef);

    form: UntypedFormGroup;
    currentView: ViewingEditorView;

    private advert: AdvertViewModel;

    isMobileSafariBrowser: boolean = false;

    get canSave(): boolean {
        return this.form.valid;
    }

    get startTimeIsValid(): boolean {
        const now = new Date();

        const date: Date = this.form.get('date').value;
        const startTime: number = this.form.get('startTime').value;

        if (areOnSameDay(date, now)) {
            return startTime >= getInitialStartTime(now);
        }

        return true;
    }

    get endTimeIsValid(): boolean {
        return this.form.get('startTime').value < this.form.get('endTime').value;
    }

    get today(): Moment {
        return moment();
    }

    get isLoading(): boolean {
        return this.currentView === 'loading';
    }

    get isAdditionalInfoEngAvailableFor(): boolean {
        return !(this.advert.isCanadianTorontoUnit || this.advert.isBritishUnit || this.advert.isUnitedStatesUnit);
    }

    get isAmPmFormat() {
        return TimeHelper.isAmPmFormat();
    }

    private get hasExistingViewing(): boolean {
        return this.args.mode === 'copy' || this.args.mode === 'edit';
    }

    ngOnInit(): void {
        this.currentView = 'form';
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();

        let data: ViewingViewModel;

        if (this.hasExistingViewing) {
            data = this.args.viewing;
        } else {
            data = getInitialViewing();
        }

        this.advertStore.advertSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((advert) => {
            this.advert = advert;
        });

        this.form = setupViewingForm(this.isAdditionalInfoEngAvailableFor, this.unsubscribe$, data);
    }

    save(): void {
        this.currentView = 'loading';
        this.dialogRef.disableClose = true;
        const dateValue = this.form.value.date;

        const result: IViewingViewModel = {
            date: dateValue.format('YYYY-MM-DD'),
            description: this.form.value.description,
            descriptionEn: this.form.value.descriptionEn,
            id: this.args.mode === 'edit' ? this.args.viewing.id : undefined,
            endTime: this.form.value.endTime,
            startTime: this.form.value.startTime,
            invitees: this.hasExistingViewing ? this.args.viewing.invitees : [],
            meetingPoint: this.form.value.meetingPoint,
            numberOfDeletedInvitees: this.form.value.numberOfDeletedInvitees,
            totalNumberOfInvitees: this.form.value.totalNumberOfInvitees,
        };

        const reqObs =
            this.args.mode === 'edit'
                ? this.viewingsService.updateViewing(this.args.unitId, result, this.args.viewing.status)
                : this.viewingsService.createViewing(this.args.unitId, result);
        reqObs.pipe(takeUntil(this.unsubscribe$)).subscribe(
            (updated: IViewingResponseBody) => this.saveSuccess(updated),
            () => this.saveError(),
        );
    }

    private saveSuccess(updated: IViewingResponseBody): void {
        this.currentView = 'form';
        this.dialogRef.close(updated.data);
    }

    private saveError(): void {
        this.dialogRef.close();
    }
}
