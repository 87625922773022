<div class="stat-group">
    <article>{{ advert.streetNameAndHouseNumber }}</article>
    <article>{{ advert.direction }}</article>
    <article>{{ advert.postalCodeAndCity }}</article>
</div>

<div class="stat-group">
    <advert-rooms-label [advert]="advert"></advert-rooms-label>
    <view-unit-intranet [internalId]="advert.internalId" />
    <view-unit-acm [internalId]="advert.internalId" />
</div>

<div class="stat-group">
    <article>
        {{ advert.hasNoContactAssigned ? ('SHARED_COMPONENT.NO_CONTACT' | translate) : advert.contactDisplay }}
    </article>
    <article *ngIf="advert.hasSubstituteContact">
        {{ advert.substituteContact.firstName }} {{ advert.substituteContact.lastName }}
    </article>
</div>

<div class="stat-group" *ngIf="isLettableDateEnabled">
    <article>{{ 'SHARED_COMPONENT.LETTABLE_DATE' | translate }}</article>
    <article>{{ advert.lettableDate | convertDate }}</article>
</div>

<div class="stat-group">
    <article>{{ 'SHARED_COMPONENT.MOVE_OUT' | translate }}</article>
    <article>{{ advert.moveOutDate | convertDate }}</article>
</div>

<div class="stat-group">
    <article>{{ 'KEY_FACTS.availableFromDate' | translate }}</article>
    <article>
        {{
            advert.isAvailableFromNowOn
                ? ('KEY_FACTS.availableFromNowOnTeaser' | translate)
                : (advert.availabeFrom | convertDate)
        }}
    </article>
</div>

<div class="stat-group rent">
    <article>{{ 'SHARED_COMPONENT.LAST_RENT' | translate }}</article>
    <article *ngIf="advert.lastRent">
        <span> {{ advert.lastRent | numeral }} {{ advert.localization.currency }}</span>
        <span> | {{ advert.lastRentPerSize | numeral }}</span>
        <span> {{ advert.localization.currency }}/{{ advert.areaSizeUnit }}</span>
    </article>
    <article *ngIf="!advert.lastRent">-</article>
</div>

<div class="stat-group rent">
    <article>{{ 'SHARED_COMPONENT.NEW_RENT' | translate }}</article>
    <article *ngIf="advert.newRent">
        <span> {{ advert.newRent | numeral }} {{ advert.localization.currency }}</span>
        <span> | {{ advert.newRentPerSize | numeral }}</span>
        <span> {{ advert.localization.currency }}/{{ advert.areaSizeUnit }}</span>
    </article>
    <article *ngIf="!advert.newRent">-</article>
</div>
