import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IContact } from '@models/advert/contact';
import { getPhoneNumberFromCombinedString } from '@models/advert/phone-number';
import { IAdvertPublicationResult } from '@models/backend/advert';
import { IKeyfactSection } from '@models/backend/keyfacts';
import { IContactDetailsResponseBody } from '@models/backend/responses';
import { AdvertStoreService } from '@services/advert.store';
import { BrowserWindowService } from '@services/browser-window.service';
import { takeUntil, timer } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { AdvertViewModel } from '../../models/advert/advert';
import { ContactsService } from '../../services/contacts.service';
import { IPublishModalArgs, PublishComponent } from '../shared';

@Component({
    selector: 'advert-details',
    templateUrl: 'advert-details.component.html',
    styleUrls: ['advert-details.component.less'],
})
export class AdvertDetailsComponent extends UnSubscriptionDirective implements OnInit, AfterViewInit {
    private contactsService = inject(ContactsService);
    private advertStore = inject(AdvertStoreService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private browserWindowService = inject(BrowserWindowService);
    private dialog = inject(MatDialog);
    private scroller = inject(ViewportScroller);

    advert: AdvertViewModel;
    contacts: IContact[];

    private lastSectionId: string;

    ngOnInit(): void {
        this.advertStore.advertSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((a) => this.gotAdvert(a));

        this.contactsService
            .getContacts()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.gotContacts(res));

        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: Params) => {
            this.lastSectionId = params['sectionId'];
            this.scrollToSection('imageSection', params);
        });
    }

    ngAfterViewInit(): void {
        if (!this.lastSectionId) {
            return;
        }

        const sectionElement = document.getElementById(this.lastSectionId);
        if (sectionElement) {
            const elementTop = sectionElement.offsetTop;
            const headerHeight = document.querySelector('nav').offsetHeight;
            this.browserWindowService.scrollTo(0, elementTop - headerHeight);
        }
    }

    private scrollToSection(section: string, params: Params): void {
        if (params['section'] === section) {
            timer(1000)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.router.navigate([], { fragment: section });
                    this.scroller.scrollToAnchor(section);
                });
        }
    }

    private gotAdvert(advert: AdvertViewModel): void {
        this.advert = advert;
    }

    unpublish(): void {
        const args: IPublishModalArgs = {
            advert: this.advert,
            mode: 'unpublish',
        };
        const dialog = this.dialog.open(PublishComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((results: IAdvertPublicationResult[]) => {
                if (results.length > 0) {
                    const wasUnpublishSuccessful = results.some((r) => r.isSuccess);
                    if (wasUnpublishSuccessful) {
                        this.advert.isPublished = false;
                        this.advert.isReserved = false;
                    }
                }
            });
    }

    private gotContacts(response: IContactDetailsResponseBody): void {
        this.contacts = response.data.map((c) => {
            return {
                email: c.email,
                firstName: c.contactFirstName,
                lastName: c.contactLastName,
                phoneNumber: getPhoneNumberFromCombinedString(c.phoneNumber),
            };
        });
    }

    sectionChanged(keyfactSection: IKeyfactSection): void {
        const sectionIndex = this.advert.keyfacts.sections.findIndex((section) => section.id === keyfactSection.id);
        this.advert.keyfacts.sections[sectionIndex] = keyfactSection;
        this.advertStore.update(this.advert);
    }

    reloadAfterReservationChanged(): void {
        this.browserWindowService.reloadWindow();
    }

    byIndex(index: number): number {
        return index;
    }
}
