<div class="filter-container">
    <div class="option">
        <unit-search
            [displayHint]="false"
            [formControl]="freeTextSearchForm"
            (clearUnitSearchFormEvent)="clearUnitSearchForm()"
        />
    </div>

    <div class="option mg-r">
        <street-search
            [formControl]="streetSearchForm"
            (streetSelectionEvent)="onStreetSelection($event)"
            (streetSelectionCleardEvent)="onStreetSearchCleaned()"
        />
    </div>
</div>
