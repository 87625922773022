import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GeneralInquiryViewModel } from '@models/general-inquiry';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { AnalyticsService } from '@services/analytics.service';
import { GeneralInquiryService } from '@services/general-inquiries.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IGeneralInquiryModalArgs } from '../types';

export enum GeneralInquiryDeletionViewType {
    Edit,
    Loading,
}

@Component({
    selector: 'general-inquiry-deletion',
    templateUrl: 'general-inquiry-deletion.component.html',
    styleUrls: ['./general-inquiry-deletion.component.less'],
})
export class GeneralInquiryDeletionComponent extends UnSubscriptionDirective {
    private generalInquiryService = inject(GeneralInquiryService);
    private router = inject(Router);
    private gaService = inject(AnalyticsService);

    dialogRef = inject<MatDialogRef<GeneralInquiryDeletionComponent>>(MatDialogRef);
    args = inject<IGeneralInquiryModalArgs>(MAT_DIALOG_DATA);

    currentViewType: GeneralInquiryDeletionViewType = GeneralInquiryDeletionViewType.Edit;

    private generalInquiry: GeneralInquiryViewModel = this.args.generalInquiry;

    get isLoading(): boolean {
        return this.currentViewType === GeneralInquiryDeletionViewType.Loading;
    }

    save(): void {
        this.currentViewType = GeneralInquiryDeletionViewType.Loading;
        this.dialogRef.disableClose = true;

        this.gaService.event(GoogleAnalyticsEvents.GeneralInquiryDeleted, 'data');

        this.generalInquiryService
            .deleteGeneralInquiry(this.generalInquiry.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => this.deletedSuccess(),
                () => this.requestFail(),
            );
    }

    private deletedSuccess(): void {
        this.dialogRef.close(true);
    }

    private requestFail(): void {
        this.router.navigate(['/404']);
    }

    byIndex(index: number): number {
        return index;
    }
}
