import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { AdvertViewModel } from '@models/advert/advert';
import { IBaseEvent } from '@models/backend/advert';
import { IAdvertEventsPageResponseBody } from '@models/backend/responses';
import { TranslateService } from '@ngx-translate/core';
import { AdvertService } from '@services/advert.service';
import { AdvertStoreService } from '@services/advert.store';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';

@Component({
    selector: 'advert-event-history',
    templateUrl: 'advert-event-history.component.html',
    styleUrls: ['advert-event-history.component.less'],
})
export class AdvertEventHistoryComponent extends UnSubscriptionDirective implements OnInit {
    private advertService = inject(AdvertService);
    private advertStore = inject(AdvertStoreService);
    private translateService = inject(TranslateService);

    events: IBaseEvent[] = [];

    private unitId: string;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    @Output() scrollEventTriggered = new EventEmitter<void>();

    page: number = 0;
    isEmpty: boolean = false;

    get errorMessage(): string {
        return this.translateService.instant('EVENTS.NO_EVENTS_FOUND');
    }

    ngOnInit(): void {
        this.advertStore.advertSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((advert) => {
            this.advertChanged(advert);
        });
    }

    private advertChanged(advert: AdvertViewModel): void {
        if (this.unitId) {
            return;
        }

        this.unitId = advert.id;
        this.loadingIndicator.show();
        this.getEvents();
    }

    onScroll(isLast: boolean): void {
        if (isLast) {
            this.page++;
            this.getEvents();
        }
    }

    isAdvertEvent(event: IBaseEvent): boolean {
        return [
            'AdvertPublished',
            'AdvertRepublished',
            'AdvertUnpublished',
            'ReservationCancelled',
            'ReservationCreated',
        ].includes(event.eventType);
    }

    isViewingEvent(event: IBaseEvent): boolean {
        return ['ViewingCarriedOut', 'ViewingDeclined', 'ViewingScheduled'].includes(event.eventType);
    }

    private getEvents() {
        this.loadingIndicator.show();
        this.advertService
            .getAdvertEvents(this.unitId, this.page)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.gotEvents(res));
    }

    private gotEvents(res: IAdvertEventsPageResponseBody): void {
        const events = res.data;
        if (!events.length) {
            this.loadingIndicator.hide();

            if (this.page === 0) {
                this.isEmpty = true;
            }

            return;
        }

        this.events = [...this.events, ...res.data];
        this.loadingIndicator.hide();
    }

    byEventId(_index: number, event: IBaseEvent): string {
        return event.id;
    }
}
