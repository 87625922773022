import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export type SnackbarMessageKey =
    | 'GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.STATUS_UPDATE_SUCCESS'
    | 'GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.GENERAL_INQUIRY_CREATE_SUCCESS'
    | 'GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.GENERAL_INQUIRY_UPDATE_SUCCESS'
    | 'GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.ERROR'
    | 'PROSPECTS.SNACK_BAR_MESSAGE.STATUS_UPDATE_SUCCESS'
    | 'PROSPECTS.SNACK_BAR_MESSAGE.PROSPECT_CREATE_SUCCESS'
    | 'PROSPECTS.SNACK_BAR_MESSAGE.PROSPECT_UPDATE_SUCCESS'
    | 'PROSPECTS.SNACK_BAR_MESSAGE.REJECTION_EMAIL_SEND_SUCCESS'
    | 'PROSPECTS.SNACK_BAR_MESSAGE.ERROR';
@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    private snackBar = inject(MatSnackBar);
    private translateService = inject(TranslateService);


    showSnackbar(messageKey: SnackbarMessageKey, duration: number = 3000): void {
        const message = this.translateService.instant(messageKey);
        this.snackBar.open(message, null, {
            duration,
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
        });
    }
}
