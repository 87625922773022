<div class="rotation-filter" [formGroup]="form">
    <aside class="filter-types">
        <div class="option">
            <mat-form-field>
                <input matInput type="text" formControlName="costCenterCode" autocomplete="off" />
                <mat-label>{{ 'SHARED_COMPONENT.PROFIT_CENTER' | translate }}</mat-label>
                <mat-icon matSuffix clickAndKeydown (actionTriggered)="resetCostCenterCode()">{{
                    costCenterCodeIcon
                }}</mat-icon>
            </mat-form-field>
        </div>
    </aside>

    <aside class="filter-types">
        <div class="option">
            <mat-form-field>
                <mat-label>{{ 'SHARED_COMPONENT.REGION' | translate }}</mat-label>
                <mat-select formControlName="region">
                    <mat-option [value]="null">{{ 'SHARED_COMPONENT.ALL' | translate }}</mat-option>
                    <mat-option *ngFor="let region of regions" [value]="region.name">{{ region.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </aside>
</div>
<aside class="filter-types full">
    <div class="option flex-end clear-btn">
        <button mat-flat-button clickAndKeydown (actionTriggered)="clearFilters()">
            {{ 'SHARED_COMPONENT.CLEAR_FILTER' | translate }}
        </button>
    </div>
</aside>
