import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProspectForm } from '@components/shared/prospect-form/prospect-form.component';
import { SupportedCountryCode } from '@models/backend/common';
import { ProspectPortal, ProspectStatus } from '@models/backend/prospects';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import { AnalyticsService } from '@services/analytics.service';
import { CountryService } from '@services/country.service';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { IProspectModalArgs } from '../advert-prospects.component';

@Component({
    selector: 'prospect-editor',
    templateUrl: 'prospect-editor.component.html',
    styleUrls: ['prospect-editor.component.less'],
})
export class ProspectEditorComponent extends UnSubscriptionDirective implements OnInit {
    private countryService = inject(CountryService);
    private gaService = inject(AnalyticsService);
    private userSettingsService = inject(UserSettingsService);
    private cdr = inject(ChangeDetectorRef);
    public dialogRef = inject(MatDialogRef<ProspectEditorComponent>);
    public args = inject(MAT_DIALOG_DATA) as IProspectModalArgs;

    prospect: ProspectViewModel;
    isEditMode: boolean = false;
    isValid: boolean = false;

    isMobileSafariBrowser: boolean = false;
    countryCode: SupportedCountryCode;

    get cancelButtonText(): string {
        return 'SHARED_COMPONENT.CANCEL';
    }

    get title(): string {
        return this.isEditMode ? 'PROSPECTS.EDIT_PROSPECT' : 'PROSPECTS.CREATE_PROSPECT';
    }

    ngOnInit(): void {
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();
        this.isEditMode = this.args.prospects !== undefined;
        this.prospect = this.prepareProspect();
        this.countryCode = this.countryService.getCurrentCountry();

        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.close());
    }

    save(): void {
        const event = this.isEditMode ? GoogleAnalyticsEvents.ProspectEdited : GoogleAnalyticsEvents.ProspectCreated;
        this.gaService.event(event);
        this.dialogRef.close({ prospect: this.prospect, mode: this.isEditMode ? 'edit' : 'create' });
    }

    close(): void {
        this.dialogRef.close();
    }

    prospectChanged(prospectForm: ProspectForm): void {
        this.prospect = prospectForm.data;
        this.isValid = prospectForm.isValid;
        this.cdr.detectChanges();
    }

    private prepareProspect(): ProspectViewModel {
        const prospect = this.args.prospects ? this.args.prospects[0] : this.createEmptyProspect();
        prospect.status = this.getProspectStatus();

        return prospect;
    }

    private createEmptyProspect(): ProspectViewModel {
        const vm = new ProspectViewModel();
        vm.dateOfReceipt = new Date();
        vm.status = ProspectStatus.New;
        vm.name = null;
        vm.email = null;
        vm.message = null;
        vm.preferredMoveInDate = null;
        vm.personalIdentityNumber = null;
        vm.portalName = ProspectPortal.LettingApp;
        return vm;
    }

    private getProspectStatus(): ProspectStatus {
        if (!this.isEditMode) {
            return this.args?.status || ProspectStatus.New;
        }
        const prospectStatus = this.args?.defaulProspecttStatus || this.args?.prospects[0]?.status;

        return prospectStatus;
    }
}
