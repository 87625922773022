<div class="option-modal" [ngSwitch]="currentViewType">
    <section class="head">
        <article class="subtitle">{{ 'SHARED_COMPONENT.RESERVATION' | translate }}</article>
        <button mat-icon-button mat-dialog-close [disabled]="isLoading">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="loading" *ngSwitchCase="'loading'">
        <loading-indicator [isPermanent]="true" [isEmbedded]="true"></loading-indicator>
    </section>

    <section class="finish" *ngSwitchCase="'finish'">
        <warning-box [type]="warningBoxType" [text]="message"></warning-box>
    </section>

    <section class="actions" *ngIf="currentViewType === 'finish'">
        <button mat-flat-button mat-dialog-close>{{ 'SHARED_COMPONENT.CLOSE' | translate }}</button>
    </section>
</div>
