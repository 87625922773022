import { Pipe, PipeTransform } from '@angular/core';
import Numeral from 'numeral';

@Pipe({
    name: 'numeral',
    pure: false,
})
export class NumeralPipe implements PipeTransform {
    transform(value: unknown): string | undefined {
        return Numeral(value).format('0.00');
    }
}
