<ng-container *ngIf="costCentersAssignments$ | async as costCentersAssignments">
    <div class="assigning-actions" *ngIf="isAutoCompleteDataReady">
        <autocomplete
            #autocomplete
            [disable]="disableAssignmentsSelection"
            [translateLabels]="false"
            [formControl]="assignedLMForm"
            [options]="contactsAutocompleteItems"
            [isRequired]="false"
            placeholder="{{ 'ROTATION.ASSIGN_TO' | translate }}"
            (optionSelected)="assignLettingManagerToCostCenterDialog($event)"
        >
        </autocomplete>

        <mat-checkbox
            class="select-all"
            [checked]="isChecked(costCentersAssignments)"
            [indeterminate]="isIndeterminate(costCentersAssignments)"
            (change)="toggleAll($event, costCentersAssignments)"
        >
            {{ 'SHARED_COMPONENT.SELECT_ALL' | translate }}
        </mat-checkbox>
    </div>

    <div #scroller class="rotation-list-wrapper">
        <ng-container
            *ngFor="
                let costCentersAssignment of costCentersAssignments;
                let last = last;
                trackBy: trackByCostCenterCode
            "
        >
            <div infinityScrolling class="rotation-list" (elementVisible)="onScroll(last)" [isTargetElement]="last">
                <div class="streetname">
                    <mat-checkbox
                        #checkbox
                        clickAndKeydown
                        (actionTriggered)="$event.stopPropagation()"
                        (change)="toggle(costCentersAssignment, $event)"
                        [checked]="exists(costCentersAssignment)"
                    ></mat-checkbox>
                    <h3>{{ costCentersAssignment.costCenterCode }}, {{ costCentersAssignment.streetNames[0] }}</h3>
                </div>
                <div class="rotation-list-item">
                    <div class="region">{{ costCentersAssignment.region }}</div>
                    <div class="apartment">{{ costCentersAssignment.branch }}</div>
                    <div class="user">
                        {{ costCentersAssignment.contactFirstName }} {{ costCentersAssignment.contactLastName }}
                        <ng-container *ngIf="costCentersAssignment.isUserDeleted">
                            ({{ 'SHARED_COMPONENT.DEACTIVATED' | translate }})</ng-container
                        >
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

<loading-indicator></loading-indicator>
