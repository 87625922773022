<span class="invitees-stats" *ngIf="viewing && viewing.hasInvitees">
    <ng-container *ngIf="numberOfConfirmedAttendees > 0">
        | {{ numberOfConfirmedAttendees }} {{ 'VIEWINGS_VIEW.STATUS.attendee' | translate }}</ng-container
    >
    <ng-container *ngIf="showConfirmed">
        |
        <mat-icon matTooltip="{{ 'PROSPECTS.SCHEDULED' | translate }}">check_circle_filled</mat-icon>
        {{ numberOfConfirmed() }}
    </ng-container>

    <ng-container *ngIf="showUnresponded">
        |
        <mat-icon matTooltip="{{ 'PROSPECTS.PENDING' | translate }}" *ngIf="showUnresponded">warning_filled</mat-icon>
        {{ numberOfUnresponded() }}
    </ng-container>

    <ng-container *ngIf="showDeclined">
        |
        <mat-icon matTooltip="{{ 'PROSPECTS.DECLINED' | translate }}" *ngIf="showDeclined">cancel_filled</mat-icon>
        {{ numberOfDeclined() }}
    </ng-container>
</span>
