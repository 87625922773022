<div class="modal-base">
    <section class="head">
        <article class="subtitle">confirmation message</article>
        <button mat-icon-button matDialogClose clickAndKeydown (actionTriggered)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <ng-container *ngIf="status$ | async as status">
        <section class="warning-dialog">
            <warning-box [type]="status" [text]="getText(status)"></warning-box>
        </section>

        <section class="actions">
            <button mat-flat-button matDialogClose clickAndKeydown (actionTriggered)="close(false)">
                {{ 'SHARED_COMPONENT.CLOSE' | translate }}
            </button>
            <button
                mat-flat-button
                *ngIf="status === 'warning'"
                color="primary"
                clickAndKeydown
                (actionTriggered)="close(true)"
            >
                {{ 'SHARED_COMPONENT.CONFIRM_ASSIGNMENTS' | translate }}
            </button>
        </section>
    </ng-container>
</div>
