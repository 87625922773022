import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IDashboardViewResponseBody, IGeneralInquiryPageResponseBody } from '@models/backend/responses';
import { DashboardService } from '@services/dashboard.service';
import { Feature, FeatureToggleService } from '@services/feature-toggle.service';
import { GeneralInquiryService } from '@services/general-inquiries.service';
import { UserSettingsService } from '@services/user-settings.service';
import { Observable, forkJoin } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';
import { GeneralInquiriesComponent } from './sections/general-inquiries/general-inquiries.component';

type Services = {
    dashboard: Observable<IDashboardViewResponseBody>;
    generalInquiries?: Observable<IGeneralInquiryPageResponseBody>;
};
@Component({
    selector: 'dashboard',
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.less'],
})
export class DashboardComponent extends UnSubscriptionDirective implements OnInit {
    private dashboardService = inject(DashboardService);
    private router = inject(Router);
    private userSettingsService = inject(UserSettingsService);
    private generalInquiryService = inject(GeneralInquiryService);
    private featureToggleService = inject(FeatureToggleService);

    isDataAvailable: boolean = false;
    userName: string = '';
    generalInquiriesTotal = 0;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    async ngOnInit(): Promise<void> {
        this.loadingIndicator.show();

        const services: Services = {
            dashboard: this.dashboardService.getData(),
        };

        const isGeneralInquiryAvailable = this.featureToggleService.isActive(Feature.GeneralInquiry);
        if (isGeneralInquiryAvailable) {
            services.generalInquiries = this.generalInquiryService.getData();
        }

        await this.getDashboardData(services);

        this.router.events
            .pipe(takeUntil(this.unsubscribe$))
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(async () => {
                await this.getDashboardData(services);
            });
    }

    onActivate(component: GeneralInquiriesComponent) {
        if (component.itemCountChange) {
            component.itemCountChange.subscribe((count: number) => {
                this.generalInquiriesTotal = count;
            });
        }
    }

    private async getDashboardData(services: Services): Promise<void> {
        forkJoin(services)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ({ dashboard, generalInquiries }) => this.gotDashboardData(dashboard, generalInquiries),
                () => {
                    this.requestError();
                },
            );
    }

    private gotDashboardData(
        dashBoardResponse: IDashboardViewResponseBody,
        generalInquiriesResponse?: IGeneralInquiryPageResponseBody,
    ): void {
        this.loadingIndicator.hide();

        this.generalInquiriesTotal = generalInquiriesResponse?.totalElements;

        this.dashboardService.setDashboardItems({
            dashboardResponse: dashBoardResponse,
            generalInquiryResponse: generalInquiriesResponse,
        });

        this.userSettingsService
            .getUserName()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((userName) => {
                this.userName = userName;
            });

        this.isDataAvailable = !!dashBoardResponse.data;
    }

    private requestError(): void {
        this.loadingIndicator.hide();
        this.router.navigate(['/404']);
    }
}
