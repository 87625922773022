import { AfterViewChecked, Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdvertViewModel } from '@models/advert/advert';
import { IAdvertFoundResponseBody } from '@models/backend/responses';
import { AdvertService } from '@services/advert.service';
import { AdvertStoreService } from '@services/advert.store';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';

@Component({
    selector: 'advert',
    templateUrl: 'advert.component.html',
})
export class AdvertComponent extends UnSubscriptionDirective implements OnInit, AfterViewChecked {
    private advertService = inject(AdvertService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private advertStore = inject(AdvertStoreService);

    advert: AdvertViewModel;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    view: string;
    viewingId: string;
    scrolled: boolean = false;
    unitId: string;

    ngOnInit(): void {
        this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            this.unitId = params['unitId'];
            this.loadingIndicator.show();
            this.getAdvertById(this.unitId);
        });

        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            this.view = params['view'];
            this.viewingId = params['eventId'];
        });

        this.advertStore.refreshed$.pipe(takeUntil(this.unsubscribe$)).subscribe((isRefreshClicked: boolean) => {
            if (isRefreshClicked) {
                this.getAdvertById(this.unitId);
            }
        });
    }

    ngAfterViewChecked(): void {
        if (this.view || this.viewingId) {
            const tabsElement = document.getElementById('tabs');
            const viewElementId = this.getViewElementId();
            const viewElement = document.getElementById(viewElementId);

            if (tabsElement && viewElement && !this.scrolled) {
                tabsElement.scrollIntoView({ block: 'start' } as ScrollIntoViewOptions);
                this.scrolled = true;
            }
        }
    }

    getViewElementId(): string {
        if (this.router.url.includes(`/viewings`) && this.viewingId) {
            return `viewings-view`;
        }

        return this.view;
    }

    private getAdvertById(advertId: string): void {
        this.advertService
            .getAdvert(advertId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (res) => this.gotAdvert(res),
                (errors) => this.requestError(errors[0]),
            );
    }

    private gotAdvert(response: IAdvertFoundResponseBody): void {
        this.loadingIndicator.hide();
        this.advert = AdvertViewModel.factory(response.data);
        this.advertStore.update(this.advert);
    }

    private requestError(error: { status: number }): void {
        this.loadingIndicator.hide();
        if (error.status === 404) {
            this.router.navigate(['/404']);
        }
    }

    convertAdvert(): void {
        const id = this.advert.id;
        this.advert = null;
        this.loadingIndicator.show();
        this.advertService
            .convertAdvert(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.gotAdvert(res));
    }
}
