import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthorizedResponsibilities } from '@components/rotation/types';
import { IUserRoleResponseBody } from '@models/backend/responses';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { CountryService } from './country.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
    private httpClient = inject(HttpClient);
    private userSettingsService = inject(UserSettingsService);
    private countryService = inject(CountryService);


    private get isRotationFeatureAvailableCountries(): boolean {
        return (
            this.countryService.isUnitedStates() ||
            this.countryService.isCanada() ||
            this.countryService.isGreatBritain() ||
            this.countryService.isCyprus()
        );
    }

    isRotationFeatureAvailable(): Observable<boolean> {
        if (!this.isRotationFeatureAvailableCountries) {
            return of(false);
        }

        return this.getUserRole().pipe(
            map((responsibilityId) => AuthorizedResponsibilities.includes(responsibilityId.data)),
        );
    }

    getUserRole(): Observable<IUserRoleResponseBody> {
        return this.userSettingsService.getHashedUserId().pipe(
            take(1),
            mergeMap((userHash) => {
                return this.httpClient.get<IUserRoleResponseBody>(`users/${userHash}/role`);
            }),
        );
    }
}
