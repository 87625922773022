import { Injectable, inject } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { INotification } from '@models/backend/notifications';
import { ISignalRConnection } from '@models/backend/user-settings';
import { NotificationViewModel } from '@models/notification';
import { Observable, Subject, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserSettingsService } from './user-settings.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private userSettingsService = inject(UserSettingsService);

    private notificationConnection: HubConnection;

    private _notifications: Subject<NotificationViewModel>;
    get notifications() {
        return this._notifications;
    }

    initializeHub(connectionSettings: ISignalRConnection): Observable<void> {
        this._notifications = new Subject();

        return this.userSettingsService.getUserEmail().pipe(
            switchMap((email) => {
                this.notificationConnection = new HubConnectionBuilder()
                    .withUrl(connectionSettings.url, { accessTokenFactory: () => connectionSettings.accessToken })
                    .build();

                this.notificationConnection.on(email, (n: INotification) =>
                    this._notifications.next(NotificationViewModel.factory(n))
                );

                return from(this.notificationConnection.start());
            })
        );
    }
}
