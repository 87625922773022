import { ProspectStatus } from '@models/backend/prospects';
import { Selectable } from '@models/common/selectable';
import { ProspectViewModel } from '@models/prospect';

type ComparisonResult = -1 | 0 | 1;

const statusScoreMap = new Map<ProspectStatus, number>([
    [ProspectStatus.New, 100],
    [ProspectStatus.NotAvailable, 99],
    [ProspectStatus.ViewingScheduled, 98],
    [ProspectStatus.HoldingDepositPaid, 97],
    [ProspectStatus.InReferencing, 96],
    [ProspectStatus.UnderSigning, 95],
    [ProspectStatus.Tenant, 94],
]);

// WARNING: once we migrate to cosmos db check if this solution is still valid
export function compareByStatusAndDateOfReceipt(
    left: Selectable<ProspectViewModel>,
    right: Selectable<ProspectViewModel>
): ComparisonResult {
    const { status: leftStatus, dateOfReceipt: leftDateOfReceipt } = left.context;
    const { status: rightStatus, dateOfReceipt: rightDateOfReceipt } = right.context;

    const comparisonByStatusResult = compareByStatus(leftStatus, rightStatus);
    if (comparisonByStatusResult !== 0) {
        return comparisonByStatusResult;
    }

    return compareByDateOfReceipt(leftDateOfReceipt, rightDateOfReceipt);
}

function compareByDateOfReceipt(leftDateOfReceipt: Date, rightDateOfReceipt: Date): ComparisonResult {
    if (leftDateOfReceipt.getTime() <= rightDateOfReceipt.getTime()) {
        return -1;
    } else {
        return 1;
    }
}

function compareByStatus(leftStatus: ProspectStatus, rightStatus: ProspectStatus): ComparisonResult {
    const leftScore = statusScoreMap.get(leftStatus) || 0;
    const rightScore = statusScoreMap.get(rightStatus) || 0;
    if (leftScore < rightScore) {
        return -1;
    } else if (leftScore === rightScore) {
        return 0;
    } else {
        return 1;
    }
}
