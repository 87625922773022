<section class="stats">
    <div class="stat-group">
        <article>{{ advert.streetNameAndHouseNumber }}</article>
        <article>{{ advert.direction }}</article>
        <article>{{ advert.postalCodeAndCity }}</article>
    </div>
    <div class="teaser-stats-group">
        <article class="item">
            <article *ngIf="advert.showNumberOfRooms">
                {{ advert.numberOfRooms }}
                {{ advert.numberOfRoomsLabel | translate }}
            </article>
            <article *ngIf="advert.showMarketingNumberOfRooms">
                {{ advert.marketingNumberOfRooms }}
                {{ advert.numberOfRoomsLabel | translate }}
            </article>

            <article *ngIf="advert.showNumberOfBedrooms">
                {{ advert.numberOfBedrooms }}
                {{ advert.numberOfBedroomsLabel | translate }}
            </article>
            <article *ngIf="advert.showMarketingNumberOfBedrooms">
                {{ advert.marketingNumberOfBedrooms }}
                {{ advert.numberOfBedroomsLabel | translate }}
            </article>

            <article *ngIf="advert.showUnitSize">{{ advert.size | numeral }} {{ advert.areaSizeUnit }}</article>
            <article *ngIf="advert.showMarketingUnitSize">
                {{ advert.marketingSize | numeral }} {{ advert.areaSizeUnit }}
            </article>
        </article>

        <article class="item">
            <span *ngIf="advert.cost"
                >{{ 'KEY_FACTS.cost' | translate }} {{ advert.cost | numeral }} {{ advert.localization.currency }}</span
            >
            <span *ngIf="!advert.cost">{{ 'KEY_FACTS.cost' | translate }} -</span>
        </article>
        <article>&nbsp;</article>
    </div>
    <div class="teaser-contact">
        <article>
            {{ advert.hasNoContactAssigned ? ('SHARED_COMPONENT.NO_CONTACT' | translate) : advert.contactDisplay }}
        </article>
        <article *ngIf="advert.hasSubstituteContact">
            , {{ advert.substituteContact.firstName }} {{ advert.substituteContact.lastName }}
        </article>
        <article *ngIf="!advert.hasSubstituteContact">&nbsp;</article>
    </div>
</section>
