<div class="time-picker" [ngClass]="{ 'short-form': isShortForm }" [formGroup]="form">
    <mat-form-field [ngClass]="{ 'short-form': isShortForm }">
        <mat-label>{{ 'SHARED_COMPONENT.HOUR' | translate }}</mat-label>
        <mat-select formControlName="hour">
            <mat-option *ngFor="let h of hours; trackBy: byIndex" [value]="h">{{ h }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="{ 'short-form': isShortForm }">
        <mat-label>{{ 'SHARED_COMPONENT.MINUTE' | translate }}</mat-label>
        <mat-select formControlName="minute">
            <mat-option *ngFor="let m of minutes; trackBy: byIndex" [value]="m">{{ m }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="isAmPm" class="am-pm" [ngClass]="{ 'short-form': isShortForm }">
        <mat-label>{{ 'SHARED_COMPONENT.AMPM' | translate }}</mat-label>
        <mat-select formControlName="timeFormat">
            <mat-option [value]="'am'">am</mat-option>
            <mat-option [value]="'pm'">pm</mat-option>
        </mat-select>
    </mat-form-field>
</div>
