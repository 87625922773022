import { Directive, ElementRef, Input, OnChanges, OnInit, inject } from '@angular/core';
import { TimeHelper } from '@helpers/time.helper';
import { IKeyfact } from '@models/backend/keyfacts';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formatTime]',
})
export class FormatTimeDirective implements OnInit, OnChanges {
    private el = inject(ElementRef);

    @Input() formatTime: IKeyfact;

    ngOnInit(): void {
        this.el.nativeElement.value = TimeHelper.getInputFormattedValue(this.formatTime.value as number);
    }

    ngOnChanges(): void {
        // overwrite old value when the api is called
        this.el.nativeElement.value = '';
        this.el.nativeElement.value = TimeHelper.getInputFormattedValue(this.formatTime.value as number);
    }
}
