<div class="option-modal status-update-dialog let-mat-dialog full">
    <section class="head">
        <article class="subtitle">{{ 'PROSPECTS.NEW_SMS' | translate }}</article>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="options">
        <warning-box [type]="'info'" [text]="'PROSPECTS.SMS_STARTING' | translate"></warning-box>
        <article class="heading">{{ 'SHARED_COMPONENT.CHANGE_STATUS' | translate }}</article>
        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.STATUS' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedStatus">
                <mat-option *ngFor="let item of options; trackBy: byIndex" [value]="item.context">{{
                    item.name | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
        <a id="open-sms-app" href="sms:{{ prospectPhone }}"></a>
    </section>

    <section class="actions">
        <button mat-flat-button mat-dialog-close>
            {{ 'SHARED_COMPONENT.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()">
            {{ 'SHARED_COMPONENT.OK' | translate }}
        </button>
    </section>
</div>
