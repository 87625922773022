<div class="rotation-teaser">
    <h2>{{ 'ROTATION.TITLE' | translate }}</h2>
    <rotation-filter
        [regions]="regions"
        (rotationTeaserFilterChanged)="onRotationTeaserFilterChange($event)"
    ></rotation-filter>
    <rotation-list-item
        [contacts]="contacts"
        [costCentersAssignments$]="costCentersAssignments$"
        (scrollEventTriggered)="onScroll()"
        (refresh)="refresh($event)"
    >
    </rotation-list-item>
</div>

<loading-indicator></loading-indicator>
