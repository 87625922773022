import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { AnalyticsService } from '@services/analytics.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { ViewingsService } from '@services/viewings.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'download-ics',
    templateUrl: './download-ics.component.html',
})
export class DownloadIcsComponent extends UnSubscriptionDirective {
    private viewingsService = inject(ViewingsService);
    private browserWindowService = inject(BrowserWindowService);
    private gaService = inject(AnalyticsService);

    @ViewChild('downloadIcsLink', { static: true }) private downloadLinkElement: ElementRef;

    downloadEventCalenderFile(advertId: string, eventId: string): void {
        this.gaService.event(GoogleAnalyticsEvents.ViewingEventDownloaded);

        this.viewingsService
            .getIcs(advertId, eventId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.gotIcs(advertId, res));
    }

    private gotIcs(advertId: string, blob: Blob): void {
        const url = this.browserWindowService.getURLConstructor().createObjectURL(blob);
        const anchor = this.downloadLinkElement.nativeElement as HTMLAnchorElement;
        anchor.href = url;
        anchor.download = `Viewing_${advertId}.ics`;
        anchor.click();
    }
}
