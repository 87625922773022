<div class="viewing-item" id="{{ viewing.id }}">
    <aside class="row">
        <article class="heading">{{ dateTitle }}</article>
        <mat-chip-set>
            <!-- Todo:: refactor this -->
            <mat-chip *ngIf="viewing.isDraft">{{ statusLabel }}</mat-chip>
            <mat-chip *ngIf="viewing.isArchived">{{ statusLabel }}</mat-chip>
            <mat-chip *ngIf="viewing.isPending" class="yellow">{{ statusLabel }}</mat-chip>
            <mat-chip *ngIf="viewing.isDeclined" class="red">{{ statusLabel }}</mat-chip>
            <mat-chip *ngIf="viewing.isScheduled" class="green">{{ statusLabel }}</mat-chip>
            <mat-chip *ngIf="viewing.hasConfirmedAttendees" class="yellow">{{ statusLabel }}</mat-chip>
            <mat-chip *ngIf="viewing.isViewingCarriedOut" class="green">{{ statusLabel }}</mat-chip>
        </mat-chip-set>
    </aside>
    <aside class="row">
        <a *ngIf="viewing.hasInvitees" class="link" clickAndKeydown (actionTriggered)="showInvitees()">
            {{ inviteesLabel }}
            <invitee-stats [viewing]="viewing"></invitee-stats>
        </a>
    </aside>
    <aside class="row">
        <div>
            <button
                *ngIf="showDeleteAction"
                mat-icon-button
                matTooltip="{{ 'SHARED_COMPONENT.DELETE' | translate }}"
                clickAndKeydown
                (actionTriggered)="delete()"
            >
                <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
            <button
                *ngIf="viewing.isDraft"
                mat-icon-button
                matTooltip="{{ 'SHARED_COMPONENT.EDIT' | translate }}"
                clickAndKeydown
                (actionTriggered)="edit()"
            >
                <mat-icon class="material-icons-outlined">edit</mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="{{ 'SHARED_COMPONENT.COPY' | translate }}"
                clickAndKeydown
                (actionTriggered)="copy()"
            >
                <mat-icon class="material-icons-outlined">copy</mat-icon>
            </button>
            <button
                *ngIf="showCalendarEventIcon"
                mat-icon-button
                matTooltip="{{ 'SHARED_COMPONENT.ADD_TO_CALENDER' | translate }}"
                clickAndKeydown
                (actionTriggered)="generateCalendarEventFile()"
            >
                <mat-icon class="material-icons-outlined">save_alt</mat-icon>
            </button>

            <ng-container *ngIf="viewing.hasConfirmedAttendees">
                <button mat-flat-button clickAndKeydown (actionTriggered)="createProspect()">
                    <mat-icon>add</mat-icon>
                    {{ 'PROSPECTS.PROSPECT' | translate }}
                </button>
                <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="showInvitees()">
                    {{ 'SHARED_COMPONENT.CONFIRM_ATTENDEES' | translate }}
                </button>
            </ng-container>
        </div>
    </aside>
</div>
