import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { FeatureToggleService } from '@services/feature-toggle.service';

@Directive({
    selector: '[featureToggle]',
})
export class FeatureToggleDirective implements OnInit {
    private templateRef = inject<TemplateRef<unknown>>(TemplateRef);
    private viewContainer = inject(ViewContainerRef);
    private featureToggleService = inject(FeatureToggleService);

    @Input({ required: true }) featureToggle: string;
    ngOnInit(): void {
        if (this.featureToggleService.isActive(this.featureToggle)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }

        this.viewContainer.clear();
    }
}
