import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import {
	Category,
	CategoryItem,
	ICategory,
	Identifier,
	Operation,
	OperationType,
} from '@components/dashboard/sections/adverts/types';
import { LoadingIndicatorComponent } from '@components/shared/loading-indicator/loading-indicator.component';
import { DashboardHelper } from '@helpers/dashboard.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { CountryService } from '@services/country.service';
import { DashboardItems, DashboardService } from '@services/dashboard.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'adverts',
    templateUrl: './adverts.component.html',
    styleUrl: './adverts.component.less',
})
export class AdvertsComponent extends UnSubscriptionDirective implements OnInit {
    private dashboardService = inject(DashboardService);
    private countryService = inject(CountryService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    bookmarkedCategory: ICategory<AdvertViewModel>;
    topPublishedCategory: ICategory<AdvertViewModel>;

    topPublishedAdverts: AdvertViewModel[] = [];
    bookmarkedAdverts: AdvertViewModel[] = [];

    hasNoPublishedAdverts: boolean = false;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;
    ngOnInit(): void {
        this.loadingIndicator.show();

        this.dashboardService.dashboardData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ dashboardResponse }: DashboardItems) => {
                this.topPublishedAdverts = dashboardResponse.data.topPublishedAdverts.map((a) =>
                    AdvertViewModel.factory(a),
                );

                this.hasNoPublishedAdverts = !this.topPublishedAdverts.length;

                this.bookmarkedAdverts = dashboardResponse.data.userBookmarkedAdverts.map((a) =>
                    AdvertViewModel.factory(a),
                );

                this.setCategories();
                this.changeDetectorRef.detectChanges();
                this.loadingIndicator.hide();
            });
    }

    updateBookmarkedList(event: [item: AdvertViewModel, operation: OperationType]): void {
        const [item, operation] = event;
        DashboardHelper.updateContext(this.bookmarkedCategory.contexts, item, operation);
    }

    updatePublishedList(event: [item: AdvertViewModel, operation: OperationType]): void {
        const [item, operation] = event;
        if (operation === Operation.Remove) {
            DashboardHelper.updateContext(this.topPublishedCategory.contexts, item, operation);
        }

        if (operation === Operation.Update) {
            DashboardHelper.updateContext(this.topPublishedCategory.contexts, item, operation);
        }
    }

    private setCategories(): void {
        let isBookmarksMode = true;
        this.bookmarkedCategory = this.buildDashboardCategory<AdvertViewModel>(
            isBookmarksMode,
            'bookmarked',
            'SHARED_COMPONENT.BOOKMARKED_ADVERTS',
            this.bookmarkedAdverts,
        );

        isBookmarksMode = false;
        this.topPublishedCategory = this.buildDashboardCategory<AdvertViewModel>(
            isBookmarksMode,
            'topPublished',
            'SHARED_COMPONENT.PUBLISHED_ADVERTS',
            [...this.topPublishedAdverts],
        );
    }

    buildDashboardCategory<T extends CategoryItem>(
        isBookmarksMode: boolean,
        id: Identifier,
        title: string,
        entries: T[],
    ): ICategory<T> {
        const contextSetup = DashboardHelper.getCategoryBy(this.countryService.getCurrentCountry());
        const itemsCount = entries.filter((e) => DashboardHelper.isAdvertType(e.type)).length;

        const dashboardCategory: ICategory<T> = {
            id,
            title,
            itemsCount,
            currentFilter: Category.Living,
            contexts: [],
        };

        for (const [type, translationKey] of contextSetup) {
            const items = entries.filter((a) => a.type === type);

            dashboardCategory.contexts.push({
                translationKey,
                type,
                display: dashboardCategory.currentFilter === type,
                items,
                isBookmarksMode,
            });
        }

        return dashboardCategory;
    }

    updateHasPublishedAdverts(): void {
        this.hasNoPublishedAdverts = true;
    }
}
