import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IAdvertPatch } from '@models/advert/advert-patch';
import { SupportedCultureInfo } from '@models/backend/common';
import { IImage } from '@models/backend/images';
import { IKeyfact } from '@models/backend/keyfacts';
import {
    IAdvertEventsPageResponseBody,
    IAdvertFoundResponseBody,
    IAdvertImageRequestParams,
    IAdvertImagesPageResponseBody,
    IAdvertPatchResponseBody,
    IAdvertsPageResponseBody,
    IKeyfactSectionUpdatedResponseBody,
    IStreetNamesResponseBody,
    ITeaserFilterResponseBody,
    IUnitCodesResponseBody,
    NoContentResponse,
} from '@models/backend/responses';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdvertService {
    private httpClient = inject(HttpClient);


    getTeaserFilterData(): Observable<ITeaserFilterResponseBody> {
        return this.httpClient.get<ITeaserFilterResponseBody>('teaser-filter');
    }

    getAdvertTeasers(filterOptions?: ITeaserFilterOptions, pageNumber?: number): Observable<IAdvertsPageResponseBody> {
        let params = new HttpParams();

        if (filterOptions) {
            params = this.mapSearchOptions(filterOptions, params);
        }

        const url = `units/?page=${pageNumber || 0}`;
        return this.httpClient.get<IAdvertsPageResponseBody>(url, { params });
    }

    getAdvert(unitId: string): Observable<IAdvertFoundResponseBody> {
        return this.httpClient.get<IAdvertFoundResponseBody>(`units/${unitId}`);
    }

    getAdvertImages(params: IAdvertImageRequestParams): Observable<IAdvertImagesPageResponseBody> {
        let httpParams = new HttpParams();

        if (params.continuationToken) {
            httpParams = httpParams.set('continuationToken', params.continuationToken);
        }
        if (params.isExamplesVisible) {
            httpParams = httpParams.set('showExamples', String(params.isExamplesVisible || false));
        }

        return this.httpClient.get<IAdvertImagesPageResponseBody>(`units/${params.unitId}/images`, {
            params: httpParams,
        });
    }

    getStreetNames(searchString: string): Observable<IStreetNamesResponseBody> {
        const httpParams = new HttpParams().set('searchString', searchString);

        return this.httpClient.get<IStreetNamesResponseBody>(`street-names`, {
            params: httpParams,
        });
    }

    getUnitCodes(searchString: string): Observable<IUnitCodesResponseBody> {
        const httpParams = new HttpParams().set('searchString', searchString);

        return this.httpClient.get<IUnitCodesResponseBody>(`unit-codes`, {
            params: httpParams,
        });
    }

    saveAdvertImages(advertId: string, images: IImage[]): Observable<NoContentResponse> {
        return this.httpClient.patch<NoContentResponse>(`units/${advertId}/images`, {
            documents: images,
        });
    }

    convertAdvert(advertId: string): Observable<IAdvertFoundResponseBody> {
        return this.httpClient.patch<IAdvertFoundResponseBody>(`units/${advertId}/convert`, null);
    }

    getAdvertEvents(unitId: string, page: number): Observable<IAdvertEventsPageResponseBody> {
        const eventTypes = ['advert-events', 'viewing-events'].join();
        const paramsQueryFilter = { pageSize: 25, page, eventTypes };

        const params = new HttpParams({
            fromObject: paramsQueryFilter,
        });

        return this.httpClient.get<IAdvertEventsPageResponseBody>(`units/${unitId}/events`, {
            params,
        });
    }

    saveKeyFact(kf: IKeyfact, advertId: string): Observable<IKeyfactSectionUpdatedResponseBody> {
        return this.httpClient.patch<IKeyfactSectionUpdatedResponseBody>(`units/${advertId}/keyfacts/${kf.id}`, kf);
    }

    patchAdvertFragment(advertId: string, advertPatch: Partial<IAdvertPatch>): Observable<IAdvertPatchResponseBody> {
        const path = Object.keys(advertPatch)[0];
        return this.httpClient.patch<IAdvertPatchResponseBody>(`units/${advertId}/${path}`, advertPatch);
    }

    getExpose(advertId: string, cultureInfo?: SupportedCultureInfo): Observable<Blob> {
        const cultureInfoParam = cultureInfo ? `?language=${cultureInfo}` : '';
        const url = `units/${advertId}/expose${cultureInfoParam}`;
        return this.httpClient.get(url, { responseType: 'blob' });
    }

    private mapSearchOptions(filterOptions: ITeaserFilterOptions, params: HttpParams): HttpParams {
        if (filterOptions.searchQuery) {
            params = params.set('search', filterOptions.searchQuery);
        }
        if (filterOptions.status != null) {
            params = params.set('status', filterOptions.status.toString());
        }
        if (filterOptions.assignedContactFilter != null) {
            if (typeof filterOptions.assignedContactFilter === 'string') {
                params = params.set('lettingsManager', filterOptions.assignedContactFilter.toString());
            } else {
                params = params.set('lettingsManager', filterOptions.assignedContactFilter.id);
            }
        }
        if (filterOptions.vacancyStatuses != null) {
            params = params.set('vacancyStatuses', filterOptions.vacancyStatuses.toString());
        }

        if (filterOptions.streetName != null) {
            params = params.set('streetName', filterOptions.streetName.toString());
        }

        if (filterOptions.unitCode != null) {
            params = params.set('unitCode', filterOptions.unitCode.toString());
        }

        if (filterOptions.advertType != null) {
            params = params.set('advertType', filterOptions.advertType.toString());
        }
        if (filterOptions.sortBy != null) {
            params = params.set('sortBy', filterOptions.sortBy.toString());
        }
        if (filterOptions.sortDirection != null) {
            params = params.set('sortDirection', filterOptions.sortDirection.toString());
        }
        if (filterOptions.region != null) {
            params = params.set('region', filterOptions.region);
        }
        if (filterOptions.branch != null) {
            params = params.set('branch', filterOptions.branch);
        }
        return params;
    }
}
